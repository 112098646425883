import { DOCUMENT } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import {
    Amplitude,
    HotjarContext,
    HotKey,
    SentryContext,
    SupportChat,
    TenantService,
    TitleService,
    TzAwareDateAdapter
} from '../injectable'
import { UnsubscribeOnDestroy } from '../utility'

@Component({
    selector: 'app-root',
    templateUrl: 'app.html'
})
export class AppComponent extends UnsubscribeOnDestroy {
    constructor(@Inject(DOCUMENT) private _document: HTMLDocument,
                private _amplitude: Amplitude, // Trigger init
                private _hotKey: HotKey,
                private _hotjarContext: HotjarContext, // Trigger init
                private _hubspotChat: SupportChat, // Trigger init
                private _router: Router,
                private _sentryContext: SentryContext, // Trigger init
                private _titleService: TitleService,
                private _tenantService: TenantService,
                private _tzAwareDateAdapter: TzAwareDateAdapter) {
        super()

        // https://bcodes.io/blog/post/theming-css-variables-in-scss
        _document.body.parentElement.className = _tenantService.name().toLowerCase()

        this.bindHotKeys()
        this.initializeApp()
    }

    // Private
    private bindHotKeys() {
        this._subscriptions.push(this._hotKey.addShortcut({ keys: 'control.shift.c' }).subscribe(() => {
            this._router.navigateByUrl('/calendar')
        }))
    }

    private initializeApp() {
        this.setFavicon()
    }

    private setFavicon() {
        let logos = this._tenantService.logos()
        let favIcon = this._document.getElementById('favicon')
        favIcon.setAttribute('rel', 'icon')
        favIcon.setAttribute('href', logos.fav)
    }
}
