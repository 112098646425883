import { AfterContentInit, Component, Input } from '@angular/core'
import { countries } from '../../countries'

@Component({
    selector: 'app-country-code',
    templateUrl: 'country-code.html'
})
export class CountryCodeComponent implements AfterContentInit {
    @Input() countryCode: string
    country: string

    // Public
    ngAfterContentInit() {
        this.country = countries[this.countryCode] || ''
    }
}