import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { Role } from '../../graphql'
import { Auth, RoleManager } from '../injectable'
import { UnsubscribeOnDestroy } from '../utility'

@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard extends UnsubscribeOnDestroy implements CanActivate {
    // Constructor
    constructor(private router: Router,
                private _auth: Auth,
                private _roleManager: RoleManager) {
        super()

        console.log('AuthenticationGuard instantiated')
    }

    knownRoles: Role[]

    // Public
    canActivate(): Promise<boolean> {
        this.knownRoles = this._roleManager.roles

        return new Promise<boolean>(async resolve => {
            await this._auth.checkAuthenticationAsync()

            this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
                if (!authenticated) {
                    return resolve(authenticated)
                }

                const roles = this._roleManager.roles
                if (this.arrayEquals(this.knownRoles, roles)) {
                    return resolve(authenticated)
                }

                console.log('AuthenticationGuard: Detected roles change from ', this.knownRoles, ' to ', roles)
                this.knownRoles = roles

                if (!roles) {
                    return resolve(authenticated)
                }

                // Avoid that people who just signed up with auth0, but didn't finish any onboarding can jump into platform
                if (roles.length == 0) {
                    // TODO: Suggest users to finish their application using CTA?
                    console.log('AuthenticationGuard: Redirecting to /choose-onboarding')
                    this.router.navigate(['/choose-onboarding']);
                    return resolve(false)
                } else if (roles.length == 1 && roles.includes(Role.Applicant)) { // Avoid that people who just submitted their application, can jump into platform
                    // TODO: Suggest users to finish their application using CTA?
                    this.router.navigate(['/application']);
                    return resolve(false)
                } else if (roles.includes(Role.Admin)) {
                    this.router.navigate(['/admin-not-allowed']);
                    return resolve(false)
                } else {
                    return resolve(authenticated)
                }
            }))
        })
    }

    arrayEquals(a: Role[], b: Role[]): boolean {
        if (a === b) {
            return true;
        }
        if (a === undefined || b === undefined) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        a.sort();
        b.sort();

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }

        return true;
    }
}
