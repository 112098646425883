<div class="full-width position-relative" *ngIf="!!images.length">
    <div class="position-relative overflow-hidden viewport">
        <div class="wrapper"
             fxLayout="row"
             [ngStyle]="{ 'margin-left': -100 * currentIndex + '%', width: images.length * 100 + '%' }">
            <div *ngFor="let image of images" [ngStyle]="{ width: 100 / images.length + '%' }">
                <div class="image-wrapper position-relative">
                    <img app-imagekit [source]="image.url" transform="tr:w-{{imageWidth}},h-{{imageHeight}},fo-auto">
                </div>
            </div>
        </div>
    </div>

    <!-- This hook allows calling code to create overlays -->
    <ng-content></ng-content>

    <div class="background-color-white border-radius-2 cursor-pointer previous size-4 noselect"
         [ngClass.gt-sm]="'border-radius-3 size-6'"
         [class.disabled]="!currentIndex"
         (click)="previous()"
         fxLayoutAlign="center center">
        <mat-icon>chevron_left</mat-icon>
    </div>

    <div class="background-color-white border-radius-2 cursor-pointer next size-4 noselect"
         [ngClass.gt-sm]="'border-radius-3 size-6'"
         [class.disabled]="currentIndex === images.length - 1"
         (click)="next()"
         fxLayoutAlign="center center">
        <mat-icon>chevron_right</mat-icon>
    </div>
</div>

<div class="full-width" *ngIf="!images.length" fxLayout="column" fxLayoutAlign="center center">
    <h4 class="color-grey-secondary-important">Photos coming soon</h4>
</div>
