import { AfterContentInit, Component, Input } from '@angular/core'
import { ListingsByMemberGQL, ListingsByMemberQuery } from '../../../../graphql'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-listings-by-member',
    templateUrl: 'listings-by-member.html'
})
export class ListingsByMemberComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    listings: ListingsByMemberQuery['listingsByMember']
    @Input() memberId: string

    // Constructor
    constructor(private _listingsByMemberGQL: ListingsByMemberGQL) {
        super()
    }

    // Public
    ngAfterContentInit() {
        this._subscriptions.push(this._listingsByMemberGQL.watch({ memberId: this.memberId }, { fetchPolicy: 'no-cache' })
            .valueChanges.subscribe(result => {
                if (!result.loading) {
                    this.listings = result.data.listingsByMember
                }
            }))
    }
}
