<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <div class="background-color-yellow full-screen-height overflow-y-scroll" fxFlex fxLayout="column" *ngIf="summary">
        <div class="padding-vertical-4" fxFlex fxLayoutAlign="center center" *ngIf="summary.status == Status.Cancelled">
            <app-invitation-expired [summary]="summary"></app-invitation-expired>
        </div>

        <div class="padding-vertical-4" fxFlex fxLayoutAlign="center center" *ngIf="summary.status == Status.Accepted">
            <app-invitation-accepted [summary]="summary"></app-invitation-accepted>
        </div>

        <div class="padding-vertical-4" fxFlex fxLayoutAlign="center center" *ngIf="summary.status == Status.Invited">
            <app-invitation-welcome-back [summary]="summary"
                                         [existingUserId]="existingUserId"
                                         *ngIf="existingUserId && !acceptInProgress"></app-invitation-welcome-back>

            <app-invitation-invited [summary]="summary"
                                    [user]="user"
                                    (invitationAccepted)="acceptInProgress = $event"
                                    *ngIf="!existingUserId"></app-invitation-invited>
        </div>
    </div>
</div>
