import { Injectable } from '@angular/core'
import { NgxHotjarService } from 'ngx-hotjar'
import { environment } from '../../environments/environment'
import { UnsubscribeOnDestroy } from '../utility'
import { RoleManager } from './role-manager'

@Injectable({
    providedIn: 'root'
})
export class HotjarContext extends UnsubscribeOnDestroy {
    enabled: boolean

    constructor(private _roleManager: RoleManager,
                private _hjService: NgxHotjarService) {
        super()

        if (!!environment.hotjar?.siteId) {
            console.log('Initializing HotjarContext and loading the member\'s account information')

            _roleManager.queryAccountAsync().subscribe(account => {
                if (account) {
                    const user = {
                        'id': account.accountHolderId,
                        'accountId': account.accountId,
                        'email': account.personalProfile?.email,
                        'personalProfile': account.personalProfile
                    }
                    console.log('Configuring Hotjar context with user', user)

                    _hjService.hj('identify', account.accountHolderId, user)
                }
            })
        }
    }
}
