import {
    AmenitiesCriterion,
    AssetTypesCriterion,
    AvailabilityTypeCriterion,
    BrandsCriterion,
    GuestCriterion, 
    MinimumStayNightsCriterion,
    PriceRangeCriterion,
    RegionCriterion,
    RoomsAndBedsCriterion,
    StyleCriterion
} from '../../../../graphql'

export class SearchCriteriaFromStorage {
    constructor(public checkin: string | undefined,
                public checkout: string | undefined,
                public availabilityTypeCriterion: AvailabilityTypeCriterion | undefined,
                public guestCriterion: GuestCriterion | undefined,
                public regionCriterion: RegionCriterion | undefined,
                public priceRangeCriterion: PriceRangeCriterion | undefined,
                public roomsAndBedsCriterion: RoomsAndBedsCriterion | undefined,
                public brandsCriterion: BrandsCriterion | undefined,
                public amenitiesCriterion: AmenitiesCriterion | undefined,
                public assetTypesCriterion: AssetTypesCriterion | undefined,
                public minimumStayNightsCriterion: MinimumStayNightsCriterion | undefined,
                public styleCriterion: StyleCriterion | undefined,
                public paging: {offset: number, size: number}) {}
}
