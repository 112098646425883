import { Component, Input } from '@angular/core'
import { ListingsByMemberQuery } from '../../../../../graphql'

@Component({
    selector: 'app-listing-by-member',
    templateUrl: 'listing-by-member.html'
})
export class ListingByMemberComponent {
    @Input() listing: ListingsByMemberQuery['listingsByMember'][0]
}
