<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <div class="background-color-yellow full-screen-height overflow-y-scroll"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center">
        <div class="container-xxs padding-1">
            <h3 class="margin-top-4 text-align-center">Not found</h3>

            <p class="margin-top-3 text-medium">
                We couldn't find what you were looking for - head back to {{tenantName}} to see more beautiful properties.
            </p>

            <div class="margin-top-3">
                <app-responsive-button-container>
                    <button color="accent"
                            flok-button
                            [fullWidth]="true"
                            [routerLink]="['/']"
                            size="medium">
                        Back to the {{tenantName}}
                    </button>
                </app-responsive-button-container>
            </div>
        </div>
    </div>
</div>
