import { AfterContentInit, Component, Input } from '@angular/core'
import { environment } from '../../../../../environments/environment'
import { BookingStatus } from '../../../../../graphql'

@Component({
    selector: 'app-legal-document-links',
    templateUrl: './legal-document-links.html'
})
export class LegalDocumentLinksComponent implements AfterContentInit {
    /**
     * The type of legal document link to create
     */
    @Input() legalDocumentType: LegalDocumentType

    /**
     * The text that should be used as link
     */
    @Input() text: string

    @Input() customClass: string

    externalLink: string

    ngAfterContentInit(): void {
        if (this.legalDocumentType == LegalDocumentType.gdpr) {
            this.externalLink = environment.tenant.legal.gdpr
        } else if (this.legalDocumentType == LegalDocumentType.privacy) {
            this.externalLink = environment.tenant.legal.privacy
        } else if (this.legalDocumentType == LegalDocumentType.terms) {
            this.externalLink = environment.tenant.legal.terms
        }

        if (!this.text) {
            // Name of enum - https://stackoverflow.com/a/42172322/15092539
            this.text = LegalDocumentType[this.legalDocumentType]
        }
    }
}

export enum LegalDocumentType {
    gdpr,
    privacy,
    terms
}
