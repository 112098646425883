import { Component, Input } from '@angular/core'
import { Auth } from '../../../../injectable'

@Component({
    selector: 'app-logout',
    templateUrl: 'logout.html'
})
export class LogoutComponent {
    @Input() label: string | undefined

    // Constructor
    constructor(private _auth: Auth) {}

    // Public
    logout() {
        this._auth.logoutAsync()
        // Clear out login related cache, e.g. 'graphql.postLoginRedirectUrl' and serialized @@auth0spajs@@ stuff
        window.localStorage.clear()
    }
}
