import { Component, Input } from '@angular/core'
import { Auth } from '../../../../injectable'

@Component({
    selector: 'app-login',
    templateUrl: 'login.html'
})
export class LoginComponent {
    @Input() label: string | undefined

    // Constructor
    constructor(private _auth: Auth) {}

    // Public
    async login() {
        await this._auth.checkAuthenticationAsync()
    }
}
