import { ErrorHandler, Injectable, Injector } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

/**
 * Combination of:
 * https://stackoverflow.com/questions/49198268/how-to-overcome-loading-chunk-failed-with-angular-lazy-loaded-modules
 * https://blog.devgenius.io/advanced-exception-handling-with-power-of-dependency-injection-in-angular-93cd7422f051
 */
@Injectable()
export class LazyLoadingErrorHandler implements ErrorHandler {
    constructor(private _injector: Injector) {}

    handleError(error: any): void {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/
        if (chunkFailedMessage.test(error?.message)) {
            console.log('Lazy loading of chunk failed. Most likely there is a new version of the app available. Going to suggest' +
                'that the user refreshes the browser.')

            this._injector.get(MatSnackBar)
                .open(`A new version is available, please refresh your browser`, 'Refresh', {
                    duration: undefined,
                    verticalPosition: "top",
                    horizontalPosition: "center",
                    politeness: "assertive"
                })
                .onAction()
                .subscribe(() => window.location.reload())
        }
    }
}

