import { Pipe, PipeTransform } from '@angular/core'
import { formatDistance, formatDistanceStrict, parseISO, toDate } from 'date-fns'


@Pipe({
    name: 'upperCaseFirst',
    pure: true
})
export class UpperCaseFirstPipe implements PipeTransform {
    constructor() {}

    transform(value: string): string {
        if (!value || !value.length) {
            return value
        }

        let first = value.substr(0, 1).toUpperCase()
        return first + value.substr(1)
    }
}
