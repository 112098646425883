// Set in /assets/environment.js
const env = (<any>window).environment || {}

export const environment = {
    gitHash: env.gitHash || '',
    tenant: {
        name: env.tenantName || '',
        corporateWebsite: env.tenantCorporateWebsite || '',
        currency: env.tenantCurrency || '',
        faq: env.tenantFaqUrl || '',
        legal: {
            gdpr: env.tenantLegalGdprUrl || '',
            privacy: env.tenantLegalPrivacyUrl || '',
            terms: env.tenantLegalTermsUrl || '',
        },
        logo: {
            light: env.tenantLogoLight || '',
            dark: env.tenantLogoDark || '',
            fav: env.tenantLogoFav || ''
        },
        minimumStayNights: env.tenantMinimumStayNights || 7,
        disableExchangePlatform: env.tenantDisableExchangePlatform === 'true',
    },
    amplitude: {
        apiKey: env.amplitudeApiKey || '',
        deploymentKey: env.amplitudeDeploymentKey || ''
    },
    auth: {
        audience: env.auth0Audience || '',
        clientId: env.auth0ClientId || '',
        domain: env.auth0Domain || ''
    },
    graphql: {
        uri: env.graphQlUri || ''
    },
    calendly: {
        meetingsBaseUrl: env.calendlyMeetingBaseUrl
    },
    google: {
        analyticsId: env.googleAnalyticsId
    },
    hotjar: {
        siteId: env.hotjarSiteId
    },
    hubspot: {
        chatAccountId: env.hubspotChatAccountId,
        meetingsBaseUrl: env.hubspotMeetingBaseUrl
    },
    imagekit: {
        source: env.imagekitSource || '',
        target: env.imagekitTarget || ''
    },
    maps: {
        key: env.mapsKey || ''
    },
    memberUrl: env.memberUrl || '',
    production: env.production === 'true',
    sentry: {
        dsn: env.sentryDsn || '',
        release: env.sentryRelease || ''
    },
    social: {
        faceBook: {
            appId: env.socialFaceBookAppId || ''
        }
    },
    stripe: {
        key: env.stripeKey || '',
        connectedAccount: env.stripeConnectedAccount || ''
    },
    // Basic feature toggle(s), until we switch to hosted version
    features: {
        // executiveAssistant: [
        //     "usr_U8ljzLPniJHI9wOlSVI3", // Vast - Enable for Vast Hotels account to assign HotelSwaps as EA
        //     "usr_V3NtqaxuSmdCJTR0pzxa", // Vast - Enable for Tintswalo to have someone manage all hotels
        //     "usr_H0xu3VSVtMDo2zdwWDt3" // Flok - Enable for Tintswalo to have someone manage all hotels
        // ]
    }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
