import { Component, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { ParamMap } from '@angular/router'
import { format } from 'date-fns'
import { Observable, Subject } from 'rxjs'
import { Subscription } from 'rxjs'
import { take, takeUntil } from 'rxjs/operators'
import { AddressType, Criteria } from '../graphql'
import { searchListingsCriteriaKey } from './constant'
import { SearchCriteriaFromStorage } from './route/search-listings/search-criteria/search-criteria-from-storage'
import PlaceResult = google.maps.places.PlaceResult

export function addressFromPlace(place: PlaceResult): AddressType {
    const addressStructure: any = {
        country: 'long_name',
        locality: 'long_name',
        postal_code: 'short_name',
        route: 'long_name',
        street_number: 'short_name'
    }
    const address: any = {}

    for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0]
        if (addressStructure[addressType]) {
            address[addressType] = place.address_components[i][addressStructure[addressType]]
        }
    }
    for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0]
        if (addressType === 'country') {
            address.countryCode = place.address_components[i]['short_name']
        }
    }
    /*{ // Note - address is structured as follows
        'street_number': '14',
        'route': 'York Street',
        'locality': 'Toronto',
        'country': 'CA',
        'postal_code': 'M5J 0B1'
    }*/

    const line1Tokens: (string | null)[] = [address.street_number, address.route]
    const line1 = line1Tokens.filter(notEmpty).join(' ')


    return {
        city: address.locality,
        countryCode: address.countryCode,
        line1: line1,
        postalCode: address.postal_code,
        region: address.country
    }
}

// https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) { return false }
    const testDummy: TValue = value
    return true
}

export function capitalize(input): string {
    if (!input || !input.length) { return '' }

    return input[0].toUpperCase() + input.slice(1)
}

export function hourFromLocalTime(localTime: string): number {
    return parseInt(localTime.substring(0, localTime.indexOf(':')))
}

export class ImageForSlider {
    url: string
}

export function pad(input: any, width: number, padding?: string) {
    padding = padding || '0'
    input = input + ''
    return input.length >= width ? input : new Array(width - input.length + 1).join(padding) + input
}

export function removeTypeNames(input: any): any {
    if (['boolean', 'function', 'number', 'string'].includes(typeof input)) {
        return input
    } else if (input === null) {
        return input
    } else if (input instanceof Array) {
        return input.map((value) => removeTypeNames(value))
    } else if (input instanceof Object) {
        const clonedObject = {}
        Object.keys(input).forEach((key: string) => {
            if (key !== '__typename') {
                clonedObject[key] = removeTypeNames(input[key])
            }
        })
        return clonedObject
    }
}

export function searchCriteriaFromStorage(): SearchCriteriaFromStorage {
    const criteriaRaw: string = sessionStorage.getItem(searchListingsCriteriaKey)
    if (!criteriaRaw) {
        return {
            checkin: undefined,
            checkout: undefined,
            availabilityTypeCriterion: undefined,
            guestCriterion: undefined,
            regionCriterion: undefined,
            priceRangeCriterion: undefined,
            roomsAndBedsCriterion: undefined,
            brandsCriterion: undefined,
            amenitiesCriterion: undefined,
            assetTypesCriterion: undefined,
            minimumStayNightsCriterion: undefined,
            styleCriterion: undefined,
            paging: undefined,
        }
    }

    const criteria: Criteria = JSON.parse(criteriaRaw)

    return new SearchCriteriaFromStorage(
        criteria.datesCriterion?.checkin,
        criteria.datesCriterion?.checkout,
        criteria.availabilityTypeCriterion,
        criteria.guestCriterion,
        criteria.regionCriterion,
        criteria.priceRangeCriterion,
        criteria.roomsAndBedsCriterion,
        criteria.brandsCriterion,
        criteria.amenitiesCriterion,
        criteria.assetTypesCriterion,
        criteria.minimumStayNightsCriterion,
        criteria.styleCriterion,
        criteria.paging)
}

export function toLocalDate(date: Date): string {
    return format(date, 'yyyy-MM-dd')
}

export function toLocalTime(hour: number): string {
    return `${pad(hour, 2)}:00`
}

export function toYearMonth(date: Date): string {
    return format(date, 'yyyy-MM')
}

export function underscoreToSpace(input: string): string {
    return input.replace(/_/g, ' ')
}

export function roundDown(val: number): number {
    return val >= 0
        ? Math.round(val - 0.5)
        : Math.round(val + 0.5)
}

export function toWholeNumber(val: number): number {
    return Math.round(val)
}

export function readNumber(paramMap: ParamMap, key: string, defaultValue: number): number {
    const value = paramMap.get(key)
    if (!value) {
        return defaultValue
    }
    const number = parseInt(value)
    return isNaN(number)
        ? defaultValue
        : number
}

export function generateId(prefix) {
    if (!prefix) {
        throw 'Identifiers must have a prefix'
    }

    let result = ''
    const length = 20
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return prefix + '_' + result
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#grouping_objects_by_a_property
export function groupBy(objectArray, property): {[key: string]: any[]} {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
}

export function getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string | undefined {
    return Object.keys(myEnum).filter((x) => myEnum[x] === enumValue)[0]
}

export function deepCloneExcludeTypeName(obj: any): any {
    if (obj === null || obj === undefined || typeof obj !== 'object') { return obj }
    const clone = Array.isArray(obj) ? [] : {}
    for (const i in obj) {
        if (obj[i] && typeof obj[i] === 'object') {
            if (i !== '__typename') {
                clone[i] = deepCloneExcludeTypeName(obj[i])
            }
        } else if (i !== '__typename') {
            clone[i] = obj[i]
        }
    }
    return clone
}

// {MD 25/06/2020} Always extended by other components, but Webstorm complains if it's not in a module
// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
    selector: 'app-unsubscribe-on-destroy',
    template: ''
})
export class UnsubscribeOnDestroy implements OnDestroy {
    protected _onDestroy: () => void
    protected _subscriptions: Subscription[] = []
    protected _timeouts: any[] = []
    // See RxJs documentation https://www.learnrxjs.io/learn-rxjs/operators/filtering/takeuntil
    protected destroy$ = new Subject<void>()

    // Public
    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
        this._subscriptions.forEach(subscription => subscription.unsubscribe())
        this._timeouts.forEach(timeout => clearTimeout(timeout))

        if (this._onDestroy) { this._onDestroy() }
    }

    takeExactlyOne<T>(): (source: Observable<T>) => Observable<T> {
        return (source: Observable<T>) => source.pipe(
            takeUntil(this.destroy$),
            take(1)
        );
    }
}

export const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/


// https://stackoverflow.com/questions/47884655/display-custom-validator-error-with-mat-error
/**
 * Custom validator functions for reactive form validation
 */
export class CustomValidators {
    /**
     * Validates that child controls in the form group are equal
     */
    static childrenEqual: ValidatorFn = (formGroup: FormGroup) => {
        const [firstControlName, ...otherControlNames] = Object.keys(formGroup.controls || {})
        const firstControlValue = formGroup.get(firstControlName).value
        const isValid = otherControlNames.every(controlName => formGroup.get(controlName).value === firstControlValue)
        return isValid ? null : { childrenNotEqual: true }
    }
}

/**
 * Custom ErrorStateMatcher which returns true (error exists) when the parent form group is invalid and the control has been touched
 */
export class ConfirmValidParentMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.parent.invalid && control.touched
    }
}
