import { Injectable } from '@angular/core'

class Script {
    loaded = false
}

@Injectable({
    providedIn: 'root'
})
export class ScriptLoader {
    private scripts: { [scriptSrc: string]: Script } = {}

    // Public
    async load(...scripts: string[]) {
        const promises: any[] = []
        scripts.forEach((script) => promises.push(this.loadScript(script)))
        return Promise.all(promises)
    }

    // Private
    private loadScript(scriptSrc: string) {
        return new Promise(resolve => {
            if (!this.scripts[scriptSrc]) {
                this.scripts[scriptSrc] = { loaded: false }
            }

            if (!this.scripts[scriptSrc].loaded) {
                const script: any = window.document.createElement('script')
                script.type = 'text/javascript'
                script.src = scriptSrc

                if (script.readyState) {  // IE
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null
                            this.scripts[scriptSrc].loaded = true
                            resolve({ script: scriptSrc, loaded: true, status: 'Loaded' })
                        }
                    }
                } else {  // Other browsers
                    script.onload = () => {
                        this.scripts[scriptSrc].loaded = true
                        resolve({ script: scriptSrc, loaded: true, status: 'Loaded' })
                    }
                }

                script.onerror = () => resolve({ script: scriptSrc, loaded: false, status: 'Loaded' })
                window.document.getElementsByTagName('head')[0].appendChild(script)
            } else {
                resolve({ script: scriptSrc, loaded: true, status: 'Already Loaded' })
            }
        })
    }
}
