import { AfterContentInit, Component, Input } from '@angular/core'
import { parseISO } from 'date-fns'
import { BalanceLogQuery } from '../../../../../graphql'

@Component({
    selector: 'app-balance-log-line',
    styleUrls: ['balance-log-line.scss'],
    templateUrl: 'balance-log-line.html'
})
export class BalanceLogLineComponent implements AfterContentInit {
    amount: number
    date: Date
    @Input() tokenType: BalanceLogQuery['myBalanceLog']['tokenType']
    @Input() logLine: BalanceLogQuery['myBalanceLog']['logLines'][0]

    // Public
    ngAfterContentInit() {
        this.date = parseISO(this.logLine.date)
    }
}
