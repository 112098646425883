import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'businessName'
})
export class CanonicalNameToBusinessNamePipe implements PipeTransform {
    mappings = {
        clientele: 'clients',
        trips: 'reservations',
        wishlist: 'shortlist',
    }

    transform(word: string): string {
        return this.mappings[word?.toLowerCase()] || word
    }
}
