<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <div class="full-width" fxLayoutAlign="center center" fxFlex>
        <div class="container-sm padding-1" fxLayout="column" fxLayoutAlign="start center">
            <div *ngIf="!showFlokTenantSpecificPreloader">
                <img class="tick" src="/assets/element/application/photos-submitted.svg"/>
            </div>

            <div class="preloader" *ngIf="showFlokTenantSpecificPreloader">
                <div class="inner">
                    <div class="preloader-1" style="background-image: url(/assets/element/preloader/preloader-1.svg)"></div>
                    <div class="preloader-2" style="background-image: url(/assets/element/preloader/preloader-2.svg)"></div>
                    <div class="preloader-3" style="background-image: url(/assets/element/preloader/preloader-3.svg)"></div>
                </div>
            </div>

            <h5 class="margin-top-2 text-align-center">Authenticating</h5>

            <div class="container-xs margin-top-3 text-align-center text-large">
                <div *ngIf="!confirmedFailed && !confirmedSuccess">This should only take a moment.</div>

                <div *ngIf="confirmedSuccess">Authentication successful! Proceeding...</div>

                <div *ngIf="confirmedFailed">
                    <div>Our apologies, authentication has failed.</div>

                    <div>
                        <span *ngIf="!chatEnabled">Our support team has been notified and will follow up with you.</span>

                        <span *ngIf="chatEnabled">
                            Please reach out to
                            <a (click)="openChat()"
                               class="border-dashed-bottom color-primary text-decoration-none cursor-pointer">
                                customer support
                            </a>
                        </span>
                    </div>
                </div>
            </div>

            <div id="bottom" class="spacer size-8"></div>
        </div>
    </div>
</div>
