import * as amplitude from '@amplitude/analytics-browser'
import { Experiment, Variants } from '@amplitude/experiment-js-client'
import { ExperimentClient } from '@amplitude/experiment-js-client/dist/types/src/experimentClient'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { Injectable } from '@angular/core'
import { Observable, of, ReplaySubject, Subject } from 'rxjs'
import { debounceTime, map, switchMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { UnsubscribeOnDestroy } from '../utility'
import { Auth } from './auth'
import { RoleManager } from './role-manager'

@Injectable({
    providedIn: 'root'
})
export class Amplitude extends UnsubscribeOnDestroy {
    private _featuresLoaded = new ReplaySubject<boolean>(1);
    private _enabledFeatures = new Set<string>();
    private eventSubjects: Map<string, Subject<any>> = new Map();

    _experiment: ExperimentClient

    constructor(_roleManager: RoleManager, private _auth: Auth) {
        super()

        this._auth.authenticatedSubject.subscribe(authenticated => {
            // {MD 18/11/2020} Need to be authenticated before querying the api
            if (authenticated) {
                _roleManager.queryAccountAsync().subscribe(account => {
                    _roleManager.rolesSubject.subscribe(roles => {
                        if (!roles) {
                            return
                        }
                        this.initialize(account?.accountHolderId, account?.personalProfile.email, roles)
                    })
                })
            } else {
                this.initialize()
            }
        })
    }

    analyticsEnabled(): boolean {
        return !!environment.amplitude.apiKey
    }

    /**
     * Convenience function to record both an event and store an incremented counter on the analytics user.
     *
     * For example, the code below would increment <code>search.count</code> on the user, and store
     * a detailed event for 'travel.search' including its custom event properties.
     * <pre>
     *     logEventAndIncrement('search.count', 'travel.search', { ... })
     * </pre>
     */
    logEventAndIncrement(userProperty: string, eventName: string, eventProperties?: any): Amplitude {
        this.increment(userProperty)
        this.logEvent(eventName, eventProperties)
        return this
    }

    increment(userProperty: string): Amplitude {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.add(userProperty, 1)
        amplitude.identify(identifyEvent)
        return this
    }

    set(userProperty: string, value: number | Array<string>): Amplitude {
        const identifyEvent = new amplitude.Identify()
        identifyEvent.set(userProperty, value)
        amplitude.identify(identifyEvent)
        return this
    }

    logEvent(eventName: string, eventProperties?: any): Amplitude {
        amplitude.logEvent(eventName, eventProperties)
        return this
    }

    logEventWithDebounce(debounceTimeMs: number, eventName: string, eventProperties: any) {
        if (!this.eventSubjects.has(eventName)) {
            const eventSubject = new Subject<any>();
            eventSubject.pipe(
                debounceTime(debounceTimeMs)
            ).subscribe((eventProps) => {
                this.logEvent(eventName, eventProps);
            });
            this.eventSubjects.set(eventName, eventSubject);
        }

        this.eventSubjects.get(eventName).next(eventProperties);
    }

    /**
     * Use as a guard to check if a feature is enabled. Steps:
     * 1) Add to .html like this:
     * <pre>
     *  <div *ngIf="(_amplitude.isOn('hello-world-feature') | async)">
     *     Hello World
     *  </div>
     * </pre>
     *
     * 2) Go to Amplitude Flags and create a new feature flag called 'hello-world-feature'
     * 3) Add test users to the Flag
     * 4) Make sure that you add 'flok-member' as a so called 'Deployment' to the feature --- IMPORTANT!
     */
    isOn(feature: string): Observable<boolean> {
        return this._featuresLoaded.pipe(
            map(() => {
                return this._enabledFeatures.has(feature)
            })
        );
    }

    private initialize(userId?: string, email?: string, roles?: string[]) {
        if (!this.analyticsEnabled()) {
            return
        }

        const sessionReplayTracking = sessionReplayPlugin({
            sampleRate: 1, // Early stage startup, let's capture all sessions
            privacyConfig: {
                defaultMaskLevel: 'light',
            }
        });
        amplitude.add(sessionReplayTracking);

        if (!userId) {
            amplitude.init(environment.amplitude.apiKey, {
                appVersion: environment.gitHash,
                defaultTracking: {
                    sessions: true
                },
            })
            console.log('Initializing [anonymous] Amplitude metrics using ' + environment.amplitude.apiKey)
            return
        }

        amplitude.init(environment.amplitude.apiKey, {
            userId,
            appVersion: environment.gitHash,
            defaultTracking: {
                sessions: true
            },
            transport: 'beacon',
            identityStorage: 'sessionStorage',
        })
        console.log('Initializing Amplitude metrics for ' + email + ' using ' + environment.amplitude.apiKey)

        const identifyEvent = new amplitude.Identify()
        identifyEvent.set('identityId', userId)
        amplitude.identify(identifyEvent)

        if (roles) {
            amplitude.setGroup('tenant', environment.tenant.name)
            amplitude.setGroup('roles', roles)
        }

        this.initializeExperiments();
    }

    private initializeExperiments(): void {
        if (!!environment.amplitude.deploymentKey) {
            this._experiment = Experiment.initializeWithAmplitudeAnalytics(environment.amplitude.deploymentKey, {
                debug: true,
                automaticFetchOnAmplitudeIdentityChange: true,
            });
            console.log('Initializing Amplitude experiments using ' + environment.amplitude.deploymentKey);

            this._experiment.fetch().then(() => {
                const allFlags: Variants = this._experiment.all();
                Object.entries(allFlags).forEach(([key, variant]) => {
                    if (variant.value === 'on') {
                        console.log('Enabling feature: ', key)
                        this._enabledFeatures.add(key);
                    }
                });
                this._featuresLoaded.next(true);
            }).catch(error => {
                console.error('Error initializing Amplitude:', error);
                this._featuresLoaded.next(false);
            });
        } else {
            console.warn('Amplitude deployment key is missing. Feature flags will not be enabled.');
            this._featuresLoaded.next(false);
        }
    }
}
