import { Directive, HostListener, Input } from '@angular/core'
import { NavigationService } from '../../../../injectable'

@Directive({
    selector: '[back-button]'
})
export class BackButtonDirective {
    @Input() fallbackUrl: string

    constructor(private navigation: NavigationService) {}

    @HostListener('click')
    onClick(): void {
        this.navigation.back(this.fallbackUrl)
    }
}
