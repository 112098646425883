<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <div class="background-color-yellow full-screen-height overflow-y-scroll" fxFlex fxLayout="column">
        <div class="padding-vertical-4" fxFlex fxLayoutAlign="center center">
            <form class="container-xxs padding-1" [formGroup]="formGroup" *ngIf="formGroup">
                <h3 class="text-align-center">Sign up</h3>

                <p class="margin-top-3 text-align-center text-medium">
                    We need to know a little more about you before you can join.
                </p>

                <div class="margin-top-3" fxLayout="column">
                    <mat-form-field appearance="none" class="flok large margin-top-2">
                        <mat-label>First name</mat-label>

                        <input formControlName="firstName" matInput>
                    </mat-form-field>

                    <mat-form-field appearance="none" class="flok large">
                        <mat-label>Last name</mat-label>

                        <input formControlName="lastName" matInput>
                    </mat-form-field>

                    <app-flok-form-field class="margin-top-3">
                        <app-flok-label>Phone number</app-flok-label>

                        <ngx-intl-tel-input [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                            [searchCountryFlag]="true"
                                            [separateDialCode]="true"
                                            [selectedCountryISO]="country"
                                            formControlName="phoneNumber">
                        </ngx-intl-tel-input>
                    </app-flok-form-field>

                    <app-flok-form-field [bottomBorder]="false">
                        <div class="full-width padding-vertical-1" fxLayoutAlign="start center">
                            <mat-checkbox formControlName="termsAndConditionsAccepted"
                                          id="termsAndConditionsAccepted"></mat-checkbox>

                            <span class="size-2"></span>

                            <label for="termsAndConditionsAccepted">
                                <span>I have read, understood and agree to the </span>

                                <app-legal-document-links [customClass]="'color-accent-important'"
                                                          [legalDocumentType]="LegalDocumentType.terms"
                                                          [text]="'terms & conditions'"></app-legal-document-links>

                                <span>, and </span>

                                <app-legal-document-links [customClass]="'color-accent-important'"
                                                          [legalDocumentType]="LegalDocumentType.privacy"
                                                          [text]="'privacy policy'"></app-legal-document-links>
                            </label>

                        </div>

                    </app-flok-form-field>
                </div>

                <div class="margin-top-3">
                    <app-responsive-button-container>
                        <div fxLayoutAlign="center center" *ngIf="loading">
                            <mat-spinner color="accent" diameter="32"></mat-spinner>
                        </div>

                        <div class="size-2"></div>
                        
                        <button (click)="next()"
                                color="accent"
                                [disabled]="!formGroup.valid || loading"
                                flok-button
                                [fullWidth]="true"
                                size="medium">
                            Next
                        </button>
                    </app-responsive-button-container>
                </div>
            </form>
        </div>
    </div>
</div>
