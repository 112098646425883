import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BehaviorSubject, ReplaySubject } from 'rxjs'
import { TokenTypeGQL, TokenType } from '../../graphql'
import { UnsubscribeOnDestroy } from '../utility'
import { Auth } from './auth'

@Injectable({
    providedIn: 'root'
})
export class TokenTypeService extends UnsubscribeOnDestroy {
    tokenTypeSubject = new ReplaySubject<TokenType>(1)

    // Constructor
    constructor(private _auth: Auth,
                private _tokenTypeGQL: TokenTypeGQL,
                private _snackBar: MatSnackBar) {
        super()

        this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
            if (authenticated) {
                this._subscriptions.push(this._tokenTypeGQL
                    .watch({}, { fetchPolicy: 'no-cache' })
                    .valueChanges
                    .subscribe(result => {
                        if (!result.loading) {
                            let tokenType = result.data['tokenType']
                            if (!tokenType) {
                                throw 'Could not fetch token type'
                            }
                            this.tokenTypeSubject.next(tokenType)
                        }
                    }))
            }
        }))
    }
}
