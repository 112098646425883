import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { StayExtensionOrderRequest, } from '../../../../graphql'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-pay-stay-extension',
    templateUrl: 'pay-stay-extension.html'
})
export class PayStayExtensionComponent extends UnsubscribeOnDestroy {
    loading = false
    order: StayExtensionOrderRequest
    tripId: string

    // Constructor
    constructor(private _activatedRoute: ActivatedRoute,
                // private _payStayExtensionBookingGQL: PayStayExtensionBookingGQL,
                // private _payStayExtensionGQL: PayStayExtensionGQL,
                // private _payStayExtensionOrderGQL: PayStayExtensionOrderGQL
    ) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            this.requestOrder(paramMap.get('orderId') || '')
        }))
    }

    // Public
    reschedule() {
        this.loading = true

        const url = window.location.href.split('?')[0]
/*
        const command: PayStayExtensionRequest = {
            bookingRequest: {
                bookingId: this.order.bookingId,
                checkout: this.order.checkout
            },
            cancelUrl: url,
            successUrl: `${url.slice(0, url.indexOf('/confirm'))}/my-trips/${this.tripId}`
        }
*/

/*
        this._payStayExtensionGQL.mutate({ input: command }).subscribe(async response => {
            if (response.data) {
                const stripe = await loadStripe(environment.stripe.key, {
                    stripeAccount: environment.stripe.connectedAccount
                })

                const { error } = await stripe.redirectToCheckout({
                    sessionId: response.data.payStayExtension.sessionId
                })

                console.log(error)
            }
        })
*/
    }

    // Private
    private requestBooking(bookingId: string) {
/*
        this._subscriptions.push(this._payStayExtensionBookingGQL
            .watch({ bookingId: bookingId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) { this.tripId = result.data['booking']['tripId'] }
            }))
*/
    }

    private requestOrder(orderId: string) {
/*
        this._subscriptions.push(this._payStayExtensionOrderGQL
            .watch({ orderId: orderId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.order = result.data['stayExtensionOrder'] as StayExtensionOrderRequest
                    this.requestBooking(this.order.bookingId)
                }
            }))
*/
    }
}
