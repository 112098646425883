import { Component, Input, ViewEncapsulation } from '@angular/core'
import { ThemeColor } from '../../../../constant'

@Component({
    encapsulation: ViewEncapsulation.None,
    host: {
        '[attr.disabled]': 'disabled || null',
        '[class.border-primary]': '!color',
        '[class.color-primary]': '!color',
        '[class.color-white]': 'color',
        '[class.background-color-accent]': 'color === ThemeColor.Accent',
        '[class.background-color-primary]': 'color === ThemeColor.Primary',
        '[class.background-color-warn]': 'color === ThemeColor.Warn',
        '[class.full-width]': 'fullWidth',
        '[class.full-width-lt-xs]': 'fullWidthLtXs && !fullWidth',
        '[class.large]': 'size === ButtonSize.Large',
        '[class.medium]': 'size === ButtonSize.Medium', // This is the default.
        '[class.small]': 'size === ButtonSize.Small',
        '[class.width-auto]': 'widthAuto'
    },
    selector: '[flok-button]',
    styleUrls: ['flok-button.scss'],
    templateUrl: 'flok-button.html'
})
export class FlokButtonComponent {
    ButtonSize = ButtonSize
    @Input() color: ThemeColor | undefined
    @Input() disabled = false
    @Input() fullWidth = false
    @Input() fullWidthLtXs = true
    @Input() size = ButtonSize.Medium
    ThemeColor = ThemeColor
    @Input() widthAuto = false

    // Public
    click(event) {
        if (this.disabled) { event.stopImmediatePropagation() }
    }
}

export enum ButtonSize {
    Large = 'large', Medium = 'medium', Small = 'small'
}
