import { source } from '@angular-devkit/schematics'
import { Component } from '@angular/core'
import { UnsubscribeOnDestroy } from '../../../../utility'

@Component({
    selector: 'app-promotional-side-panel',
    styleUrls: ['promotional-side-panel.scss'],
    templateUrl: 'promotional-side-panel.html'
})
export class PromotionalSidePanelComponent extends UnsubscribeOnDestroy {
    quotes: Array<Quote> = jsonQuotes.quotes.map(quote => new Quote(quote.quote, quote.author, quote.source))
    activeQuote: Quote = this.quotes[Math.floor(Math.random() * this.quotes.length)]

    constructor() {
        super()

        this._timeouts.push(setInterval(() => this.changeQuote(), 12000))
    }

    changeQuote() {
        this.activeQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)]
    }
}

class Quote {
    quote: string;
    author: string;
    source: string;

    constructor(quote: string, author: string, source: string) {
        this.quote = quote;
        this.author = author;
        this.source = source;
    }
}

// Assume this is your JSON object stored in a variable named jsonQuotes
const jsonQuotes = {
    "quotes": [
        {
            "quote": "The world is a book and those who do not travel read only one page.",
            "author": "Saint Augustine",
            "source": "The City of God"
        },
        {
            "quote": "Life is either a daring adventure or nothing at all.",
            "author": "Helen Keller",
            "source": "The Open Door"
        },
        {
            "quote": "Travel makes one modest. You see what a tiny place you occupy in the world.",
            "author": "Gustave Flaubert",
            "source": "Letter to Mlle. Leroyer de Chantepie"
        },
        {
            "quote": "The real voyage of discovery consists not in seeking new landscapes, but in having new eyes.",
            "author": "Marcel Proust",
            "source": "In Search of Lost Time"
        },
        {
            "quote": "To travel is to take a journey into yourself.",
            "author": "Danny Kaye",
            "source": "The Danny Kaye Show"
        },
        {
            "quote": "Twenty years from now you will be more disappointed by the things you didn’t do than by the ones you did do. So throw off the bowlines. Sail away from the safe harbor. Catch the trade winds in your sails. Explore. Dream. Discover.",
            "author": "H. Jackson Brown Jr.",
            "source": "P.S. I Love You"
        },
        {
            "quote": "Man cannot discover new oceans unless he has the courage to lose sight of the shore.",
            "author": "André Gide",
            "source": "The Counterfeiters"
        },
        {
            "quote": "Not all those who wander are lost.",
            "author": "J.R.R. Tolkien",
            "source": "The Fellowship of the Ring"
        },
        {
            "quote": "Adventure is worthwhile in itself.",
            "author": "Amelia Earhart",
            "source": "Last Flight"
        },
        {
            "quote": "The gladdest moment in human life, methinks, is a departure into unknown lands.",
            "author": "Sir Richard Burton",
            "source": "Personal Narrative of a Pilgrimage to Al-Madinah and Meccah"
        },
        {
            "quote": "Travel and change of place impart new vigor to the mind.",
            "author": "Seneca",
            "source": "Letters to Lucilius"
        },
        {
            "quote": "Once a year, go someplace you've never been before.",
            "author": "Dalai Lama",
            "source": "The Book of Joy"
        },
        {
            "quote": "A journey of a thousand miles begins with a single step.",
            "author": "Lao Tzu",
            "source": "Tao Te Ching"
        },
        {
            "quote": "The biggest adventure you can take is to live the life of your dreams.",
            "author": "Oprah Winfrey",
            "source": "What I Know for Sure"
        },
        {
            "quote": "Wherever you go, go with all your heart.",
            "author": "Confucius",
            "source": "The Analects"
        },
        {
            "quote": "Traveling – it leaves you speechless, then turns you into a storyteller.",
            "author": "Ibn Battuta",
            "source": "The Travels of Ibn Battuta"
        },
        {
            "quote": "To awaken alone in a strange town is one of the pleasantest sensations in the world.",
            "author": "Freya Stark",
            "source": "Baghdad Sketches"
        },
        {
            "quote": "Take only memories, leave only footprints.",
            "author": "Chief Seattle",
            "source": "Wisdom Sits in Places"
        },
        {
            "quote": "The journey not the arrival matters.",
            "author": "T.S. Eliot",
            "source": "Four Quartets"
        },
        {
            "quote": "We travel, some of us forever, to seek other states, other lives, other souls.",
            "author": "Anaïs Nin",
            "source": "The Diary of Anaïs Nin"
        },
        {
            "quote": "To travel is to discover that everyone is wrong about other countries.",
            "author": "Aldous Huxley",
            "source": "Jesting Pilate: An Intellectual Holiday"
        },
        {
            "quote": "I am not the same, having seen the moon shine on the other side of the world.",
            "author": "Mary Anne Radmacher",
            "source": "Lean Forward Into Your Life: Begin Each Day as If It Were on Purpose"
        },
        {
            "quote": "Travel far enough, you meet yourself.",
            "author": "David Mitchell",
            "source": "Cloud Atlas"
        },
        {
            "quote": "Wherever you go becomes a part of you somehow.",
            "author": "Anita Desai",
            "source": "Journey to Ithaca"
        },
        {
            "quote": "The more I traveled the more I realized that fear makes strangers of people who should be friends.",
            "author": "Shirley MacLaine",
            "source": "Going Within"
        },
        {
            "quote": "Once you have traveled, the voyage never ends, but is played out over and over again in the quietest chambers. The mind can never break off from the journey.",
            "author": "Pat Conroy",
            "source": "The Prince of Tides"
        },
        {
            "quote": "Travel brings power and love back into your life.",
            "author": "Rumi",
            "source": "The Essential Rumi"
        },
        {
            "quote": "The journey itself is my home.",
            "author": "Matsuo Bashō",
            "source": "Narrow Road to the Interior"
        },
        {
            "quote": "Travel makes a wise man better, and a fool worse.",
            "author": "Thomas Fuller",
            "source": "Gnomologia"
        },
        {
            "quote": "To my mind, the greatest reward and luxury of travel is to be able to experience everyday things as if for the first time.",
            "author": "Bill Bryson",
            "source": "The Lost Continent: Travels in Small Town America"
        },
        {
            "quote": "All journeys have secret destinations of which the traveler is unaware.",
            "author": "Martin Buber",
            "source": "The Legend of the Baal-Shem"
        },
        {
            "quote": "A good traveler has no fixed plans, and is not intent on arriving.",
            "author": "Lao Tzu",
            "source": "Tao Te Ching"
        },
        {
            "quote": "To travel is to live.",
            "author": "Hans Christian Andersen",
            "source": "The Fairy Tale of My Life: An Autobiography"
        },
        {
            "quote": "He who would travel happily must travel light.",
            "author": "Antoine de Saint-Exupéry",
            "source": "Wind, Sand and Stars"
        },
        {
            "quote": "Our happiest moments as tourists always seem to come when we stumble upon one thing while in pursuit of something else.",
            "author": "Lawrence Block",
            "source": "The Lawrence Block Reader"
        },
        {
            "quote": "Travel is the only thing you buy that makes you richer.",
            "author": "Unknown",
            "source": "Attributed"
        },
        {
            "quote": "We travel not to escape life, but for life not to escape us.",
            "author": "Unknown",
            "source": "Attributed"
        },
        {
            "quote": "The life you have led doesn’t need to be the only life you have.",
            "author": "Anna Quindlen",
            "source": "A Short Guide to a Happy Life"
        },
        {
            "quote": "We wander for distraction, but we travel for fulfillment.",
            "author": "Hilaire Belloc",
            "source": "The Path to Rome"
        },
        {
            "quote": "The impulse to travel is one of the hopeful symptoms of life.",
            "author": "Agnes Repplier",
            "source": "Ame de Polichinelle"
        },
        {
            "quote": "Travel is fatal to prejudice, bigotry, and narrow-mindedness.",
            "author": "Mark Twain",
            "source": "The Innocents Abroad"
        },
        {
            "quote": "The man who goes alone can start today; but he who travels with another must wait till that other is ready.",
            "author": "Henry David Thoreau",
            "source": "Walden"
        },
        {
            "quote": "To travel is worth any cost or sacrifice.",
            "author": "Elizabeth Gilbert",
            "source": "Eat, Pray, Love"
        },
        {
            "quote": "A great way to learn about your country is to leave it.",
            "author": "Henry Rollins",
            "source": "Solipsist"
        },
        {
            "quote": "When overseas you learn more about your own country than you do the place you’re visiting.",
            "author": "Clint Borgen",
            "source": "Interview with The Borgen Project"
        },
        {
            "quote": "The journey of a thousand miles begins with one step.",
            "author": "Lao Tzu",
            "source": "Tao Te Ching"
        },
        {
            "quote": "One’s destination is never a place, but a new way of seeing things.",
            "author": "Henry Miller",
            "source": "Big Sur and the Oranges of Hieronymus Bosch"
        },
        {
            "quote": "Travel and change of place impart new vigor to the mind.",
            "author": "Seneca",
            "source": "Letters to Lucilius"
        },
        {
            "quote": "We live in a wonderful world that is full of beauty, charm and adventure. There is no end to the adventures we can have if only we seek them with our eyes open.",
            "author": "Jawaharlal Nehru",
            "source": "The Discovery of India"
        },
        {
            "quote": "Wandering re-establishes the original harmony which once existed between man and the universe.",
            "author": "Anatole France",
            "source": "The Crime of Sylvestre Bonnard"
        },
        {
            "quote": "The journey itself is my home.",
            "author": "Matsuo Bashō",
            "source": "Narrow Road to the Interior"
        },
        {
            "quote": "People don’t take trips, trips take people.",
            "author": "John Steinbeck",
            "source": "Travels with Charley in Search of America"
        },
        {
            "quote": "A good traveler has no fixed plans, and is not intent on arriving.",
            "author": "Lao Tzu",
            "source": "Tao Te Ching"
        },
        {
            "quote": "The use of traveling is to regulate imagination by reality, and instead of thinking how things may be, to see them as they are.",
            "author": "Samuel Johnson",
            "source": "Journey to the Western Islands of Scotland"
        },
        {
            "quote": "There are no foreign lands. It is the traveler only who is foreign.",
            "author": "Robert Louis Stevenson",
            "source": "The Silverado Squatters"
        },
        {
            "quote": "Our happiest moments as tourists always seem to come when we stumble upon one thing while in pursuit of something else.",
            "author": "Lawrence Block",
            "source": "The Lawrence Block Reader"
        },
        {
            "quote": "Travel is the only thing you buy that makes you richer.",
            "author": "Unknown",
            "source": "Attributed"
        },
        {
            "quote": "We travel not to escape life, but for life not to escape us.",
            "author": "Unknown",
            "source": "Attributed"
        },
        {
            "quote": "The life you have led doesn’t need to be the only life you have.",
            "author": "Anna Quindlen",
            "source": "A Short Guide to a Happy Life"
        },
        {
            "quote": "We wander for distraction, but we travel for fulfillment.",
            "author": "Hilaire Belloc",
            "source": "The Path to Rome"
        },
        {
            "quote": "The impulse to travel is one of the hopeful symptoms of life.",
            "author": "Agnes Repplier",
            "source": "Ame de Polichinelle"
        },
        {
            "quote": "Travel is fatal to prejudice, bigotry, and narrow-mindedness.",
            "author": "Mark Twain",
            "source": "The Innocents Abroad"
        },
        {
            "quote": "The man who goes alone can start today; but he who travels with another must wait till that other is ready.",
            "author": "Henry David Thoreau",
            "source": "Walden"
        },
        {
            "quote": "To travel is worth any cost or sacrifice.",
            "author": "Elizabeth Gilbert",
            "source": "Eat, Pray, Love"
        },
        {
            "quote": "A great way to learn about your country is to leave it.",
            "author": "Henry Rollins",
            "source": "Solipsist"
        },
        {
            "quote": "When overseas you learn more about your own country than you do the place you’re visiting.",
            "author": "Clint Borgen",
            "source": "Interview with The Borgen Project"
        }
    ]
}
