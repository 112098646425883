import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-flok-badge',
    styleUrls: ['flok-badge.scss'],
    templateUrl: 'flok-badge.html'
})
export class FlokBadgeComponent {
    @Input() clas: string | undefined
}
