<div class="background-color-white margin-top-1 padding-1 shadow-large" ngClass.gt-xs="padding-3" fxLayoutAlign="start center">
    <p fxFlex="20" class="text-large">{{date | date}}</p>

    <div fxFlex="65" fxFlex.gt-xs="50" fxLayout="column">
        <p class="text-medium">{{logLine.description}}</p>

        <h6 class="margin-top-05">{{logLine.name}}</h6>
    </div>

    <p fxFlex="15" class="text-large">
        <span [ngClass]="(logLine.amount < 0)?'negative-transaction':'positive-transaction'">
            <span fxHide fxShow.gt-xs>{{logLine.amount}} {{tokenType.name | titlecase }}</span>

            <span fxHide fxShow.lt-sm>{{logLine.amount}}{{tokenType.symbol | titlecase }}</span>
        </span>
    </p>

    <p fxFlex="15" fxHide fxShow.gt-xs class="text-large">{{logLine.balance}} {{tokenType.name | titlecase}}</p>
</div>
