import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../../../../environments/environment'
import { Auth, Module, ScopeManager } from '../../../../../injectable'
import { UnsubscribeOnDestroy } from '../../../../../utility'

@Component({
    selector: 'app-anonymous-desktop-nav',
    styleUrls: ['anonymous-desktop-nav.scss'],
    templateUrl: 'anonymous-desktop-nav.html'
})
export class AnonymousDesktopNavComponent extends UnsubscribeOnDestroy {
    constructor(private _auth: Auth,
                private _router: Router,
                private _scopeManager: ScopeManager) {
        super()
    }

    // Public
    hasScope(scope: string): boolean {
        return this._scopeManager.hasScope(scope)
    }

    navigateToLogin() {
        this._auth.checkAuthenticationAsync(false)
    }
}
