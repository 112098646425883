import { AfterContentInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import {
    Commission, CommissionFees,
    ConfirmPriceBreakdownTokenBalanceGQL,
    ConfirmPriceBreakdownTokenBalanceQuery,
    CurrencyAmount,
    Fees
} from '../../../../../graphql'
import { TenantService } from '../../../../injectable'
import { CurrencyService } from '../../../../injectable/currency-service'
import { UnsubscribeOnDestroy } from '../../../../utility'

@Component({
    selector: 'app-confirm-price-breakdown',
    templateUrl: 'confirm-price-breakdown.html'
})
export class ConfirmPriceBreakdownComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    cashCurrency = environment.tenant.currency
    @Input() fees: Fees
    @Input() supplementFee = 0
    @Input() cleaningFees = 0
    @Input() commissionFees: CommissionFees
    balance: ConfirmPriceBreakdownTokenBalanceQuery['myTokenBalance']
    topUpTotal: number | undefined
    @Output() topUpAmount = new EventEmitter<number>()
    topUpTokens: number | undefined

    commissionFee: number | undefined
    bookingFee: number | undefined
    total: number | undefined
    convertedBookingFee$: Observable<CurrencyAmount>

    tokensEnabled: boolean

    constructor(
        private _priceBreakdownTokenBalanceGQL: ConfirmPriceBreakdownTokenBalanceGQL,
        private _currencyService: CurrencyService,
        _tenantService: TenantService,
    ) {
        super()

        this.tokensEnabled = _tenantService.tokensEnabled()
    }

    // Public
    ngAfterContentInit() {
        this.calculateBookingFee()

        this._subscriptions.push(this._priceBreakdownTokenBalanceGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.balance = result.data.myTokenBalance
                    this.cleaningFees /= 1000000
                    this.calculateTopUp()
                    this.calculateTotal()
                }
            }))
    }

    topUp() {
        if (!this.topUpTokens) { return }

        this.topUpAmount.next(this.topUpTokens)
    }

    // Private
    private calculateTopUp() {
        const conversionRate = this.balance.tokenType.exchangeRate.amountMicros / 1_000_000
        const tokenAmount = this.balance.amount

        this.topUpTokens = this.fees.tokenFee.amount - tokenAmount
        this.topUpTokens = Math.max(this.topUpTokens, 0)
        this.topUpTotal = Math.round(this.topUpTokens * conversionRate)
    }

    private calculateBookingFee() {
        console.log('Calculating price breakdown using: ', this.fees)

        if (this.fees.exchangeFee.amountMicros > 0) {
            this.bookingFee = this.fees.exchangeFee.amountMicros / 1_000_000
        } else if (this.fees.bookingFee.amountMicros > 0) {
            this.bookingFee = this.fees.bookingFee.amountMicros / 1_000_000
            this.cashCurrency = this.fees.bookingFee.currency
        }
        if (this.cashCurrency !== environment.tenant.currency) {
            this.convertedBookingFee$ = this._currencyService.convert(environment.tenant.currency, {
                amountMicros: this.bookingFee * 1_000_000,
                currency: this.cashCurrency
            })
        }
        if (this.commissionFees) {
            this.commissionFee = this.commissionFees.commission.amountMicros / 1_000_000
        }
    }

    private calculateTotal() {
        this.total = this.bookingFee + this.supplementFee + this.cleaningFees
        if (this.topUpTotal) {
            this.total += this.topUpTotal
        }
    }
}
