<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <ng-container *ngIf="!loading">
        <div fxFlex
             ngClass.gt-sm="padding-5"
             fxLayout="column"
             fxLayoutAlign="center center"
             *ngIf="(userExistsAlready || status !== BusinessOwnerInvitationStatus.Invited) && !acceptInProgress">
            <ng-container *ngIf="status === BusinessOwnerInvitationStatus.Cancelled">
                <app-screen-filler></app-screen-filler>

                <h3 class="margin-top-7 text-align-center">Invitation cancelled</h3>

                <p class="margin-top-3 padding-1 text-align-center text-large">
                    This invite has been cancelled.
                </p>
            </ng-container>

            <ng-container *ngIf="status === BusinessOwnerInvitationStatus.Accepted">
                <app-screen-filler></app-screen-filler>

                <h3 class="margin-top-7 text-align-center">Invitation accepted</h3>

                <p class="margin-top-3 padding-1 text-align-center text-large">
                    This invite had already been accepted.
                </p>

                <div class="size-3"></div>

                <app-responsive-button-container>
                    <button color="accent"
                            flok-button
                            [fullWidth]="true"
                            size="medium"
                            (click)="navigateToPlatform()">
                        Continue
                    </button>
                </app-responsive-button-container>
            </ng-container>

            <ng-container *ngIf="userExistsAlready && status === BusinessOwnerInvitationStatus.Invited">
                <app-screen-filler></app-screen-filler>

                <h3 class="margin-top-7 text-align-center">Oops!</h3>

                <p class="margin-top-3 padding-1 text-align-center text-large">
                    You cannot accept this invite as you're already logged in.
                </p>

                <div class="size-3"></div>

                <app-responsive-button-container>
                    <button color="accent" flok-button [fullWidth]="true" size="medium">
                        <app-logout></app-logout>
                    </button>
                </app-responsive-button-container>
            </ng-container>
        </div>

        <div class="background-color-yellow full-screen-height overflow-y-scroll" fxFlex fxLayout="column"
             *ngIf="!userExistsAlready && status === BusinessOwnerInvitationStatus.Invited">
            <div class="padding-vertical-4" fxFlex fxLayoutAlign="center center">
                <form class="container-xxs padding-1" [formGroup]="formGroup" *ngIf="formGroup">
                    <h3 class="text-align-center">Accept invitation</h3>

                    <p class="margin-top-3 text-align-center text-medium">
                        We need to know a little more about you before you can join.
                    </p>

                    <div class="margin-top-4" fxLayout="column">
                        <app-flok-form-field class="margin-top-2">
                            <app-flok-label>Phone number</app-flok-label>

                            <ngx-intl-tel-input
                                    [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                    [searchCountryFlag]="true"
                                    [separateDialCode]="true"
                                    [selectedCountryISO]="country"
                                    formControlName="phoneNumber">
                            </ngx-intl-tel-input>
                        </app-flok-form-field>

                        <app-flok-form-field [bottomBorder]="false">
                            <div class="full-width padding-vertical-1" fxLayoutAlign="start center">
                                <mat-checkbox formControlName="termsAndConditionsAccepted"
                                              id="termsAndConditionsAccepted"></mat-checkbox>

                                <span class="size-2"></span>

                                <label for="termsAndConditionsAccepted">
                                    <span>I have read, understood and agree to the </span>

                                    <app-legal-document-links [customClass]="'color-accent-important'"
                                                              [legalDocumentType]="LegalDocumentType.terms"
                                                              [text]="'terms & conditions'"></app-legal-document-links>

                                    <span>, and </span>

                                    <app-legal-document-links [customClass]="'color-accent-important'"
                                                              [legalDocumentType]="LegalDocumentType.privacy"
                                                              [text]="'privacy policy'"></app-legal-document-links>
                                </label>

                            </div>

                        </app-flok-form-field>
                    </div>

                    <div class="margin-top-3">
                        <app-responsive-button-container>
                            <button (click)="next()"
                                    color="accent"
                                    [disabled]="!formGroup.valid"
                                    flok-button
                                    [fullWidth]="true"
                                    size="medium">
                                Next
                            </button>
                        </app-responsive-button-container>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</div>
