import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { from, Observable, of } from 'rxjs'
import { concatMap, map } from 'rxjs/operators'
import { Role } from '../../graphql'
import { Auth, RoleManager } from '../injectable'
import { AuthenticationGuard } from './authentication'

@Injectable({
    providedIn: 'root'
})
export class LandingPageGuard implements CanActivate {
    constructor(private roleManager: RoleManager, private router: Router, private authenticationGuard: AuthenticationGuard) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return from(this.authenticationGuard
            .canActivate())
            .pipe(
                concatMap(_ => // Don't care about return type, auth guard issues a redirect, so this value is almost certainly true
                    this.roleManager.roles$.pipe(
                        map(roles => {
                            if (roles?.includes(Role.PmcAgent) || roles?.includes(Role.PmcBusinessOwner)) {
                                return this.router.parseUrl('/properties')
                            }
                            if (roles?.includes(Role.BrandAgent) || roles?.includes(Role.BrandBusinessOwner)) {
                                return this.router.parseUrl('/properties')
                            }
                            if (roles?.includes(Role.TravelAgent) || roles?.includes(Role.TravelAgentBusinessOwner)) {
                                return this.router.parseUrl('/search-listings')
                            }
                            if (roles?.includes(Role.Admin)) {
                                return this.router.parseUrl('/not-allowed')
                            }
                            return this.router.parseUrl('/search-listings')
                        })
                    ))
            )
    }
}
