import { Directive, Input, OnInit, OnDestroy, TemplateRef, ViewContainerRef, ElementRef } from '@angular/core'
import { Subscription } from 'rxjs'
import { Amplitude } from '../../../../injectable'

@Directive({
    selector: '[feature]'
})
export class FeatureToggle implements OnInit, OnDestroy {
    @Input('feature') featureKey: string
    private subscription: Subscription

    constructor(private _templateRef: TemplateRef<any>,
                private _viewContainer: ViewContainerRef,
                private _amplitude: Amplitude) {}

    ngOnInit() {
        this.subscription = this._amplitude.isOn(this.featureKey).subscribe(enabled => {
            if (enabled) {
                this._viewContainer.createEmbeddedView(this._templateRef);
            } else {
                this._viewContainer.clear();
            }
        })
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
}
