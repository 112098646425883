import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { CurrencyAmountType, PresentmentCurrencyExchangeRatesGQL } from '../../graphql'
import { UnsubscribeOnDestroy } from '../utility'

@Injectable({
    providedIn: 'root'
})
export class CurrencyService extends UnsubscribeOnDestroy {
    private presentmentCurrency: string = environment.tenant.currency
    currencyToReferenceRate$ = new ReplaySubject<Map<string, number>>(1)
    presentmentCurrency$ = new ReplaySubject<string>(1)

    // Constructor
    constructor(
        private _tokenTypeGQL: PresentmentCurrencyExchangeRatesGQL,
    ) {
        super()
        this.refresh()
        this.presentmentCurrency$.next(environment.tenant.currency)
        this.presentmentCurrency$.subscribe(currency => this.presentmentCurrency = currency)
    }

    private refresh() {
        const sub = this._tokenTypeGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    const presentmentCurrencies = result.data?.presentmentCurrencyExchangeRates
                    if (!presentmentCurrencies) {
                        throw Error('Could not fetch presentment currency exchange rates')
                    }
                    const currencyToReferenceRate = new Map<string, number>()
                    presentmentCurrencies.forEach(exchangeRate => {
                        currencyToReferenceRate.set(exchangeRate.currency, parseFloat(exchangeRate.rate))
                    })
                    this.currencyToReferenceRate$.next(currencyToReferenceRate)
                }
            })
        this._subscriptions.push(sub)
    }

    getPresentmentCurrency() {
        return this.presentmentCurrency ?? environment.tenant.currency
    }

    setPresentmentCurrency(currency: string) {
        this.presentmentCurrency$.next(currency)
        this.refresh()
    }

    convert(currency: string, source: CurrencyAmountType) {
        return this.currencyToReferenceRate$
            .pipe(
                map(ctr => {
                    if (!ctr.has(currency)) {
                        throw new Error(`Currency of type ${currency} not supported`)
                    }

                    if (!ctr.has(source.currency)) {
                        throw new Error(`Currency of type ${source.currency} not expected`)
                    }
                    const conversionRate = ctr.get(source.currency) / ctr.get(currency)
                    return  {
                        currency,
                        amountMicros: Math.round(source.amountMicros * conversionRate),
                    }

                })
            )

    }

    convertToPresentmentCurrency(source: CurrencyAmountType) {
        return this.currencyToReferenceRate$
            .pipe(map(ctr => this.doConvert(ctr, source)))
    }

    batchConvertToPresentmentCurrency(sources: CurrencyAmountType[]) {
        return this.currencyToReferenceRate$
            .pipe(
                map(ctr => {
                    return sources.map(source => this.doConvert(ctr, source))
                })
            )
    }

    private doConvert(ctr: Map<string, number>, source: CurrencyAmountType) {
        if (!ctr.has(this.presentmentCurrency)) {
            throw new Error(`Currency of type ${this.presentmentCurrency} not supported`)
        }

        if (!ctr.has(source.currency)) {
            throw new Error(`Currency of type ${source.currency} not expected`)
        }
        const conversionRate = ctr.get(source.currency) / ctr.get(this.presentmentCurrency)
        return {
            currency: this.presentmentCurrency,
            amountMicros: source.amountMicros * conversionRate,
        }
    }
}
