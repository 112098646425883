<div class="container">
    <button flok-button fxLayoutAlign="start center" [widthAuto]="true" mat-dialog-close>
        <mat-icon>chevron_left</mat-icon>

        <span>Back</span>

        <div class="size-1"></div>
    </button>

    <div fxLayoutAlign="center" class="margin-top-5">
        <div id="gallery" class="container-fluid">
            <div fxLayoutAlign="center center" *ngIf="loading">
                <mat-spinner color="accent" diameter="64"></mat-spinner>
            </div>
            <img
                    app-imagekit [source]="photo.thumb"
                    class="img-responsive"
                    (click)="open(i)"
                    (load)="onImageLoad($event)"
                    *ngFor="let photo of album; let i=index;">

        </div>
    </div>
</div>
