import { Injectable } from '@angular/core'
import createAuth0Client from '@auth0/auth0-spa-js'
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client'
import { ReplaySubject } from 'rxjs'
import { environment } from '../../environments/environment'

const postLoginRedirectUrlKey = 'graphql.postLoginRedirectUrl'
const postLogoutRedirectUri = `${window.location.origin}/logged-out`
const redirectUri = `${window.location.origin}/logging-in`
const clearStorageUri = `${window.location.origin}/clear-storage`
const testTokenKey = 'token'

@Injectable({
    providedIn: 'root'
})
export class Auth {
    auth0Client: Auth0Client | undefined
    authenticatedSubject = new ReplaySubject<boolean>(1)
    testToken: string | undefined
    tokenPromise: Promise<string> | undefined
    authenticatedCheckPromise: Promise<void> | undefined

    private authenticated: boolean

    constructor() {
        this.testToken = sessionStorage.getItem(testTokenKey)
        if (this.testToken) {
            this.markAsAuthenticated()
        } else {
            this.authenticatedCheckPromise = this.userAsync().then((user) => {
                this.markAsAuthenticated(!!user)
            })
        }
    }

    // Public
    async accessTokenAsync(): Promise<string> {
        if (this.testToken) {
            return new Promise(resolve => resolve(this.testToken))
        }

        if (this.tokenPromise) {
            console.log('Access token already being fetched by other component. Re-using that token promise.')
            return this.tokenPromise
        }

        if (!this.authenticated) {
            if (!this.tokenPromise) {
                console.log('Trying to access token without an authenticated user. Beginning background authentication check')
                // await this.checkAuthenticationAsync()
                // return Promise.reject('Unauthenticated user')
                return null
            } else {
                return Promise.reject('Tried to get an access token without an authenticated user')
            }
        }
        console.log('User authenticated, fetching access token.')

        const token = await (this.tokenPromise = this.auth0Client.getTokenSilently())
        this.tokenPromise = undefined

        // console.log('Successfully fetched access token: ', {access_token: token}) // Wrapping in object to avoid spamming logs with token

        return token
    }

    async checkAuthenticationAsync(goToSignUp = false): Promise<void> {
        await this.createAuth0ClientAsync()

        if (this.testToken) {
            return
        }

        if (await this.auth0Client.isAuthenticated()) {
            if (!this.authenticated) {
                this.markAsAuthenticated()
            }
            return Promise.resolve()
        }

        if (window.location?.toString().indexOf(redirectUri) >= 0 || window.location?.toString().indexOf(clearStorageUri) >= 0) {
            let redirectUrl = localStorage.getItem(postLoginRedirectUrlKey) || `${environment.memberUrl}`
            console.log('Not storing ' + window.location.pathname + ' in localstorage as to avoid infinite redirect loop. Keeping former value '
                + redirectUrl + ' instead')
        } else {
            localStorage.setItem(postLoginRedirectUrlKey, window.location.pathname)
        }

        const parameters = { redirect_uri: redirectUri }
        if (goToSignUp) {
            parameters['screen_hint'] = 'signup'
        }

        await this.auth0Client.loginWithRedirect(parameters)
    }

    async logoutAsync(): Promise<void> {
        await this.createAuth0ClientAsync()

        await this.auth0Client.logout({ returnTo: postLogoutRedirectUri })

        this.markAsAuthenticated(false)
    }

    async processLoginAsync(): Promise<string> {
        await this.createAuth0ClientAsync()

        return new Promise<string>(async (resolve, reject) => {
            try {
                console.log('Start redirect callback flow - ', window.location.href)
                await this.auth0Client.handleRedirectCallback()
                this.markAsAuthenticated()

                console.log('Notifying all listeners of user having authenticated')

                let postLoginRedirectUrl = localStorage.getItem(postLoginRedirectUrlKey)
                console.log('Post login redirect: ' + postLoginRedirectUrl)
                resolve(postLoginRedirectUrl)
            } catch (error) {
                console.error(error)
                reject(error)
            }
        })
    }

    private markAsAuthenticated(authenticated = true) {
        this.authenticated = authenticated
        this.authenticatedSubject.next(authenticated)
    }

    async userAsync(): Promise<User | undefined> {
        await this.createAuth0ClientAsync()

        const user = await this.auth0Client.getUser()

        return user ? {
            countryCode: user['https://jointheflok.com/geoip'].country_code.toLowerCase(),
            email: user.email,
            firstName: user.given_name,
            lastName: user.family_name,
            sub: user.sub
        } : undefined
    }

    // Private
    private async createAuth0ClientAsync(): Promise<void> {
        if (this.auth0Client) {
            console.log('Request for Auth0Client - Using existing', this.auth0Client)
            return
        }

        console.log('Request for Auth0Client - Fetching new auth0 client for audience', environment.auth.audience)
        this.auth0Client = await createAuth0Client({
			audience: environment.auth.audience,
            cacheLocation: 'localstorage',
            client_id: environment.auth.clientId,
            domain: environment.auth.domain,
            scope: 'openid profile email user_metadata picture'
        })
        console.log('Finished requesting Auth0Client - Resulting client', this.auth0Client)
    }
}

export class User {
    countryCode: string
    email: string
    firstName?: string
    lastName?: string
    sub: string
}
