<nav class="background-color-white full-width" fxHide fxShow.gt-sm fxLayoutAlign="start end">
    <div [routerLink]="['/search-listings']">
        <img class="height-5 logo" app-logo/>
    </div>

    <div fxLayoutAlign="center center" fxFlex class="cursor-pointer">
        <div [routerLink]="['/search-listings']"
             *ngIf="canAccessSearchModule"
             routerLinkActive="active"
             class="menu-main">Explore</div>

        <div class="position-relative" *ngIf="hasScope('read:account_balance') && hasScope('read:listing')">
            <div class="absolute token-balance">
                <app-token-balance></app-token-balance>

                <div class="token-balance-pointer"></div>
            </div>

            <div [routerLink]="['/calendar']"
                 routerLinkActive="active"
                 class="menu-main">Calendar</div>
        </div>

        <div *ngIf="hasScope('read:stay')"
             [routerLink]="['/my-guests']"
             routerLinkActive="active"
             class="menu-main">
            My Guests
        </div>

        <div *ngIf="hasScope('read:trip')"
             [routerLink]="['/my-trips']"
             routerLinkActive="active"
             class="menu-main">
            My {{ "trips" | businessName | upperCaseFirst}}
        </div>

        <div *ngIf="hasScope('read:property')"
             [routerLink]="['/properties']"
             class="menu-main"
             routerLinkActive="active">
            Villas
        </div>

        <div *ngIf="hasScope('read:hotel')"
             [routerLink]="['/hotels']"
             class="menu-main"
             routerLinkActive="active">
            Hotels
        </div>
    </div>

    <div class="cursor-pointer">
        <app-user-pill [matMenuTriggerFor]="menu"></app-user-pill>
    </div>

    <mat-menu #menu="matMenu" class="menu arrow-up">
        <div class="menu-main" mat-menu-item [routerLink]="['/favourites']">Favourites</div>

        <div class="menu-main" mat-menu-item [routerLink]="['/balance', 'balance-log']">Balance</div>

        <div class="menu-main" mat-menu-item
                *ngIf="hasScope('read:account')"
                [routerLink]="['/account']">Account
        </div>

        <div class="menu-divider"></div>

        <div class="menu-main" mat-menu-item (click)="navigateToPrivacyPolicy()">Privacy policy</div>

        <div class="menu-main" mat-menu-item (click)="navigateToTerms()">Terms & conditions</div>

        <div fxHide class="menu-main" mat-menu-item (click)="navigateToFaq()">FAQ</div>

        <div class="menu-divider"></div>

        <div class="menu-main" mat-menu-item>
            <app-logout></app-logout>
        </div>
    </mat-menu>
</nav>
