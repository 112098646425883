import { Component, Input } from '@angular/core'
import { DecidingFactor } from '../../../../../../../graphql'

@Component({
    selector: 'app-listing-deciding-factor',
    templateUrl: './listing-deciding-factor.html'
})
export class ListingDecidingFactorComponent {
    /**
     * When set to true, will show title but no description.
     *
     * It will also reduce the gap between each line. This summarized
     * version is used by e.g. the search listing page to only
     * show the titles.
     */
    @Input() titleOnly = false

    @Input() decidingFactor: DecidingFactor
}
