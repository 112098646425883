import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { UnsubscribeOnDestroy } from '../utility'
import { RoleManager } from './role-manager'

@Injectable({
    providedIn: 'root'
})
export class SentryContext extends UnsubscribeOnDestroy {
    constructor(_roleManager: RoleManager) {
        super()

        console.log('Initializing SentryContext and loading the member\'s account information')

        _roleManager.queryAccountAsync().subscribe(account => {
            if (account) {
                Sentry.configureScope((scope) => {
                    const user = {
                        id: account.accountHolderId,
                        accountId: account.accountId,
                        email: account.personalProfile?.email,
                        personalProfile: account.personalProfile
                    }
                    console.log('Configuring sentry scope with user', user)
                    scope.setUser(user)
                })
            }
        })
    }
}
