<div class="full-width" fxLayoutAlign="start center" *ngIf="listing">
    <div fxFlex="50">
        <app-image-slider [images]="listing.summary.photos"></app-image-slider>
    </div>

    <div fxFlex="50" fxLayout="column">
        <div fxLayoutAlign="start center" fxLayoutGap="20px">
            <span>{{listing.summary.city}}</span>

            <app-country-code [countryCode]="listing.summary.countryCode"></app-country-code>
        </div>

        <h4>{{listing.summary.title}}</h4>

        <div class="margin-top-2"
             fxLayoutAlign="start start"
             fxLayoutGap="20px"
             *ngIf="listing.summary.highlightedFeatures.features.length">
            <span class="highlighted-features">
                <app-listing-highlighted-feature [highlightedFeatures]="listing.summary.highlightedFeatures.features"></app-listing-highlighted-feature>
            </span>
        </div>

        <div class="margin-top-2" fxLayoutAlign="start center" fxLayoutGap="20px">
            <span *ngFor="let amenity of listing.summary.highlightedAmenities">{{amenity}}</span>
        </div>
    </div>
</div>
