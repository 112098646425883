import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'faIconMap',
    pure: true
})
export class FaIconMapPipe implements PipeTransform {
    constructor() {}

    // Map from legacy Mat Icon names to Font Awesome names.
    // For use where icon name is coming from data and we
    // need a quicker way, than updating data, to show new icons.
    map = {
        bed: 'fa-bed-front',
        shower: 'fa-bath',
        family_restroom: 'fa-family',
        brightness_3: 'fa-moon'
    }

    transform(value: string): string {
        if (!value || !value.length || !this.map[value]) {
            return value
        }

        return this.map[value]
    }
}
