import { Component, EventEmitter, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Role } from '../../../../../../graphql'
import { Auth, ScopeManager } from '../../../../../injectable'

@Component({
    selector: 'app-anonymous-mobile-nav',
    templateUrl: 'anonymous-mobile-nav.html'
})
export class AnonymousMobileNavComponent {
    constructor(private _auth: Auth) {
    }

    navigateToLogin() {
        this._auth.checkAuthenticationAsync(false)
    }
}
