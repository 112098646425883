import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { Auth } from '../../injectable'

@Component({
    selector: 'app-logging-in',
    templateUrl: 'logging-in.html'
})
export class LoggingInComponent {
    // Constructor
    constructor(private _auth: Auth, private _router: Router) {
        this._auth.processLoginAsync().then(redirectUri => /logged-out/.test(redirectUri)
            ? this._router.navigateByUrl('/')
            : this._router.navigateByUrl(redirectUri))
    }
}
