import { AfterContentInit, Directive, Input } from '@angular/core'
import { TenantService } from '../../../../injectable/tenant-service'

@Directive({
    host: {
        '[attr.src]': 'src',
        '[attr.alt]': 'alt',
    },
    selector: '[app-logo]'
})
export class LogoDirective implements AfterContentInit {
    @Input() useLightVersion: boolean | false
    src: string
    alt: string


    constructor(private _tenantService: TenantService) {}

    // Public
    ngAfterContentInit() {
        let logos = this._tenantService.logos()

        this.alt = this._tenantService.name()
        this.src = this.useLightVersion
            ? logos.light
            : logos.dark
    }
}
