<nav class="background-color-white full-width padding-2" fxHide.gt-sm fxLayoutAlign="center space-between">

    <div fxFlex="35" fxLayoutAlign="start center">
        <div class="background-color-pink-lightest padding-1">
            <app-token-balance></app-token-balance>
        </div>
    </div>

    <a fxFlex="40" [routerLink]="['/search-listings']"  fxLayoutAlign="center center">
        <img class="height-5" app-logo/>
    </a>

    <div fxFlex="25" fxFlex fxLayoutAlign="end center">
        <button (click)="toggleNav.next()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</nav>
