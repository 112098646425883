import { Component } from '@angular/core'
import { TenantService } from '../../injectable'

@Component({
    selector: 'app-not-found',
    templateUrl: 'not-found.html'
})
export class NotFoundComponent {
    tenantName: string

    constructor(private _tenantService: TenantService) {
        this.tenantName = _tenantService.name()
    }
}
