import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-flok-form-field',
    styleUrls: ['flok-form-field.scss'],
    templateUrl: 'flok-form-field.html'
})
export class FlokFormFieldComponent {
    @Input() bottomBorder = true
}
