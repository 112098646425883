import { Component, Input } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { AcceptStaffMemberInvitationCommand, AcceptStaffMemberInvitationGQL, StaffMemberInvitationSummary } from '../../../../graphql'
import { RoleManager } from '../../../injectable'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-invitation-welcome-back',
    templateUrl: 'invitation-welcome-back.html',
    styleUrls: ['invitation-welcome-back.scss']
})
export class InvitationWelcomeBackComponent extends UnsubscribeOnDestroy {
    @Input() summary: StaffMemberInvitationSummary
    @Input() existingUserId: string
    tenantName = environment.tenant.name
    _accountUpdated = false

    constructor(private _activatedRoute: ActivatedRoute,
                private _router: Router,
                private _snackBar: MatSnackBar,
                private _roleManager: RoleManager,
                private _acceptInvitationGQL: AcceptStaffMemberInvitationGQL) {
        super()
    }

    // Public
    goToMemberArea() {
        // Eventually consistent. Wait for Account to be created.
        this._subscriptions.push(this._roleManager.accountIdSubject.subscribe(accountId => {
            this._accountUpdated = !!accountId
            if (this._accountUpdated) {
                this._router.navigate(['/'], { relativeTo: this._activatedRoute })
                return
            }
        }))
        this.waitForAccountCreation()


        const command: AcceptStaffMemberInvitationCommand = {
            invitationId: this.summary.invitationId
        }

        this._subscriptions.push(this._acceptInvitationGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                const message = `We are experiencing technical difficulties. Please try again later.`
                this._snackBar.open(message, 'OK', {
                    duration: undefined
                })
                throw new Error('Error accepting property owner invitation' + JSON.stringify(response.errors))
            }
        }))
    }

    private waitForAccountCreation(attempt = 0) {
        if (attempt >= 20) { return }
        if (this._accountUpdated) { return }

        this._roleManager.refresh()

        setTimeout(() => this.waitForAccountCreation(++attempt), 500)
    }
}
