<div class="background-color-primary color-white full-screen-min-height padding-5 wrapper" fxLayout="column">
    <div fxLayoutAlign="center center">
        <img class="height-5" app-logo [useLightVersion]="true"/>
    </div>

    <div class="illustration margin-top-5" fxFlex></div>

    <p class="margin-top-5 text-align-center text-medium quote padding-horizontal-3">
        "{{ activeQuote.quote }}"
    </p>

    <p class="margin-top-3 text-align-center text-medium author">
        {{ activeQuote.author }} - {{activeQuote.source }}
    </p>
</div>
