import { AfterContentInit, Component, Input } from '@angular/core'
import { ConfirmBookingGQL, ConfirmBookingQuery } from '../../../../../graphql'
import { UnsubscribeOnDestroy } from '../../../../utility'

@Component({
    selector: 'app-confirm-booking',
    templateUrl: 'confirm-booking.html'
})
export class ConfirmBookingComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    booking: ConfirmBookingQuery['booking']
    @Input() bookingId: string

    // Constructor
    constructor(private _confirmBookingGQL: ConfirmBookingGQL) {
        super()
    }

    // Public
    ngAfterContentInit() {
        this.requestTrip()
    }

    // Private
    private requestTrip() {
        this._subscriptions.push(this._confirmBookingGQL
            .watch({ bookingId: this.bookingId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) { this.booking = result.data['booking'] }
            }))
    }
}
