import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

interface Logos {
    light: string
    dark: string
    fav: string
}

@Injectable({
    providedIn: 'root'
})
export class TenantService {
    // Constructor
    constructor() {}

    name(): string {
        return environment.tenant.name
    }

    logos(): Logos {
       return {
           light: environment.tenant.logo.light,
           dark: environment.tenant.logo.dark,
           fav: environment.tenant.logo.fav
       }
    }

    minimumStayNights(): number {
        return environment.tenant.minimumStayNights
    }

    tokensEnabled(): boolean {
        return environment.tenant.disableExchangePlatform
    }

    maximumPriceModificationPercentage(): number {
        return 20
    }
}
