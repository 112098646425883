import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core'
import { AssetPhotoType } from '../../../../../graphql'

@Component({
    selector: 'app-feature-image',
    styleUrls: ['feature-image.scss'],
    templateUrl: 'feature-image.html'
})
export class FeatureImageComponent implements OnInit, OnChanges {
    currentIndex = 0
    @Input() imageWidth = 1280
    @Input() imageHeight = 640
    @Input() images: AssetPhotoType[] = []
    @Output() imageClickEvent = new EventEmitter<void>()
    featureImage: AssetPhotoType

    ngOnInit(): void {
        this.setFeatureImage()
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setFeatureImage()
    }

    setFeatureImage() {
        const coverImages = this.images.filter(image => image.useAsCover)
        this.featureImage = coverImages[0] || this.images[0]
    }
}
