import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs'
import { ScopeManagerResourceScopesGQL, ScopeManagerScopesGQL } from '../../graphql'
import { UnsubscribeOnDestroy } from '../utility'
import { Auth } from './auth'

@Injectable({
    providedIn: 'root'
})
export class ScopeManager extends UnsubscribeOnDestroy {
    scopes: string[] | undefined
    scopesSubject = new ReplaySubject<string[]>(1)

    // Constructor
    constructor(private _auth: Auth,
                private _scopeManagerResourceScopesGQL: ScopeManagerResourceScopesGQL,
                private _scopeManagerScopesGQL: ScopeManagerScopesGQL) {
        super()

        this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
            if (authenticated && !this.scopes) { this.queryScopes() }
        }))
    }

    // Public
    hasScope(scope: string): boolean {
        if (!this.scopes) { return false }

        return this.scopes.includes(scope)
    }

    hasAnyScope(scopes: string[]): boolean {
        return scopes.some(scope => this.scopes.includes(scope))
    }

    queryScopes() {
        this._subscriptions.push(this._scopeManagerScopesGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.scopes = result.data['scopes']
                    this.scopesSubject.next(this.scopes)
                }
            }))
    }

    /**
     * Slightly different from #hasResourceScope in the sense that
     * the async version doesn't have a default 'false' value. From
     * a usage point this means that the async version always requires
     * a callback
     * <pre>
     * this._scopeManager.hasResourceScope(this.propertyId, 'write:property').subscribe(hasRequiredScope => {
     *     this.canWrite = hasRequiredScope
     * }
     * </pre>
     *
     * While the regular #hasResourceScope allows for:
     * <pre>
     *     this.canWrite = this._scopeManager.hasResourceScope(this.propertyId, 'write:property')
     * </pre>
     */
    hasResourceScopeAsync(resourceId: string, requiredScope: string) {
        let subject = new Subject<boolean>()
        this._subscriptions.push(this._scopeManagerResourceScopesGQL
            .watch({ resourceId: resourceId}, { fetchPolicy: 'cache-and-network' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    let resourceScopes = result.data?.resourceScopes || []
                    subject.next(resourceScopes.includes(requiredScope))
                }
            }))
        return subject
    }

    hasModuleScope(module: Module): Observable<boolean> {
        if (module == Module.search) {
            return this.hasResourceScopeAsync('search', 'module:search')
        }
    }

    hasResourceScope(resourceId: string, requiredScope: string) {
        let subject = new BehaviorSubject<boolean>(false)
        this._subscriptions.push(this._scopeManagerResourceScopesGQL
            .watch({ resourceId: resourceId}, { fetchPolicy: 'cache-and-network' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    let resourceScopes = result.data?.resourceScopes || []
                    subject.next(resourceScopes.includes(requiredScope))
                }
            }))
        return subject
    }
}

export enum Module {
    search
}
