import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { AssetPhotoType, PublicWishlist, PublicWishlistListingsGQL, SearchListing } from '../../../graphql'
import { Amplitude } from '../../injectable'
import { GalleryComponent } from '../../module/shared-flok/organism/gallery/gallery'

import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-public-wishlist',
    templateUrl: 'public-wishlist.html',
    styleUrls: ['public-wishlist.scss']
})
export class PublicWishlistComponent extends UnsubscribeOnDestroy {

    wishlistId: string
    publicWishlist: PublicWishlist
    corporateSiteUrl: string
    corporateName: string

    constructor(private _activatedRoute: ActivatedRoute,
                private _amplitude: Amplitude,
                private _fetchPublicWishlistGQL: PublicWishlistListingsGQL,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _title: Title) {
        super()

        this.corporateSiteUrl = environment.tenant.corporateWebsite
        this.corporateName = environment.tenant.name

        this._activatedRoute.paramMap.subscribe(paramMap => {
            this.wishlistId = paramMap.get('wishlistId')
            this.loadPublicWishlists()
        })
    }

    private loadPublicWishlists() {
        console.log('Wishlist ID', this.wishlistId)

        this._fetchPublicWishlistGQL.fetch({wishlistId: this.wishlistId}, {fetchPolicy: 'no-cache'})
            .subscribe(result => {
                this.publicWishlist = result.data?.publicWishlist as PublicWishlist
                this._title.setTitle(this.publicWishlist?.name)

                this._amplitude.logEvent('Wishlist Public Page Viewed', {
                    wishlistId: this.publicWishlist?.wishlistId,
                    wishesCount: this.publicWishlist?.wishes?.length
                })
            }, error => {
                console.log('Error fetching public wishlist', error)
                this._snackBar.open('Error fetching public wishlist, please try again')
            })
    }

    public coverPhotoUrl(photos: AssetPhotoType[]): string {
        return photos.find(photo => photo.useAsCover)?.url || photos[0].url
    }

    viewPhotos(listing: SearchListing) {
        this._dialog.open(GalleryComponent, {
            data: {
                subjectId: listing?.listingId,
                photos: listing.summary.photos
            },
            panelClass: 'bookCardDialog',
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
        })
    }

    onClick() {
        window.open(this.corporateSiteUrl, '_blank')
    }
}
