import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'

@Component({
    selector: 'app-responsive-button-container',
    styleUrls: ['responsive-button-container.scss'],
    templateUrl: 'responsive-button-container.html'
})
export class ResponsiveButtonContainerComponent implements AfterViewInit {
    @ViewChild('buttonWrapper', { static: true }) buttonWrapper: ElementRef
    @Input() height: number

    // Public
    ngAfterViewInit() {
        setTimeout(() => {
            this.height = this.height !== undefined ? this.height : this.buttonWrapper.nativeElement.offsetHeight
        }, 1)
    }
}
