import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AcceptStaffMemberInvitationSummaryGQL, StaffMemberInvitationStatus, StaffMemberInvitationSummary } from '../../../graphql'
import { Auth, RoleManager, User } from '../../injectable'
import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-member-member-invitation',
    templateUrl: 'accept-staff-member-invitation.html'
})
export class AcceptStaffMemberInvitationComponent extends UnsubscribeOnDestroy {
    invitationId: string
    summary: StaffMemberInvitationSummary
    Status = StaffMemberInvitationStatus

    user: User | undefined
    existingUserId: string | undefined
    acceptInProgress = false

    // Constructor
    constructor(private _activatedRoute: ActivatedRoute,
                private _fetchSummaryGQL: AcceptStaffMemberInvitationSummaryGQL,
                private _auth: Auth,
                private _roleManager: RoleManager,
                private _router: Router) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            this.invitationId = paramMap.get('invitationId')

            this.initializeAsync()
        }))
    }

    // Private
    private async initializeAsync() {
        await this._auth.checkAuthenticationAsync(true)

        this.user = await this._auth.userAsync()
        if (!this.user) { return } // {MD 30/07/2020} Avoids throwing if user is not logged in

        // Check if an existing user
        this._subscriptions.push(this._roleManager.accountHolderIdSubject.subscribe(accountHolderId => {
            if (!accountHolderId) { return }

            console.log('Receiving account holder, user\'s account is confirmed to exist - User ID: ', accountHolderId)
            this.existingUserId = accountHolderId
        }))

        // Load the status of the invitation
        this._subscriptions.push(this._fetchSummaryGQL
            .watch({ invitationId: this.invitationId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    if (!result.data.staffMemberInvitationSummary) {
                        this._router.navigateByUrl('/not-found', { skipLocationChange: true })
                        return
                    }

                    this.summary = result.data.staffMemberInvitationSummary
                }
            }))
    }
}
