<div fxLayout="column wrap" fxLayoutGap="10px">
    <ng-container *ngFor="let feature of highlightedFeatures">
        <div fxLayout="row">
            <mat-icon>{{feature.iconName}}</mat-icon>

            <div class="size-1"></div>

            <div fxLayoutAlign="start start" fxLayout="column">
                <div class="text-medium">{{feature.title}}</div>

                <div class="text-medium padding-vertical-1" *ngIf="!titleOnly && feature.description">
                    {{feature.description}}
                </div>
            </div>
        </div>
    </ng-container>
</div>
