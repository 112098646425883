import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { of } from 'rxjs'
import { delay } from 'rxjs/operators'

@Component({
    selector: 'app-logged-out',
    templateUrl: 'logged-out.html'
})
export class LoggedOutComponent {
    constructor(private router: Router) {
        localStorage.clear()
        sessionStorage.clear()
        of('/')
            .pipe(
                delay(3000)
            )
            .subscribe(
                url => router.navigate([url]))

    }
}
