import { AfterContentInit, Component } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSelectChange } from '@angular/material/select'
import { CurrencyService } from '../../../../../injectable/currency-service'
import { UnsubscribeOnDestroy } from '../../../../../utility'
import { presentmentCurrencyKey } from '../../../../../constant'

@Component({
    selector: 'app-currency-select',
    styleUrls: ['currency-select.scss'],
    templateUrl: 'currency-select.html'
})
export class CurrencySelectComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    // Constructor
    selectedCurrency = new FormControl(this._currencyService.getPresentmentCurrency())
    currencies: Array<string> = []
    constructor(
        private _currencyService: CurrencyService,
    ) {
        super()
        this._currencyService.currencyToReferenceRate$
            .subscribe(rates => this.currencies = Array.from(rates.keys()))

    }

    ngAfterContentInit() {
        const storedPresentmentCurrency = sessionStorage.getItem(presentmentCurrencyKey)

        if (storedPresentmentCurrency) {
            this._currencyService.setPresentmentCurrency(storedPresentmentCurrency)
            this.selectedCurrency.setValue(storedPresentmentCurrency)
        }
    }

    onSelectionChanged($event: MatSelectChange) {
        this._currencyService.setPresentmentCurrency($event.value)
        sessionStorage.setItem(presentmentCurrencyKey, $event.value)
    }
}
