<nav class="background-color-white">
    <div class="logo-container cursor-pointer" [routerLink]="['/search-listings']">
        <img class="height-5" app-logo/>
    </div>

    <div class="search-container cursor-pointer">
        <ng-content></ng-content>
    </div>

    <div class="menu-items-container">
        <div *ngIf="hasScope('read:trip')"
            [routerLink]="['/my-trips']"
            routerLinkActive="active"
            class="client-trips-link text-small cursor-pointer">
            Client trips
        </div>

        <div class="currency-select-container cursor-pointer">
            <app-currency-select></app-currency-select>
        </div>
    
        <div class="user-profile-container cursor-pointer">
            <app-user-pill [matMenuTriggerFor]="menu"></app-user-pill>
        </div>
    </div>

    <mat-menu #menu="matMenu" class="menu arrow-up">
        <div class="menu-main" 
             mat-menu-item
            *ngIf="hasScope('read:account')"
            [routerLink]="['/account']">Account
        </div>

        <div class="menu-main"
             mat-menu-item
             *feature="'res-141-flok-member-show-shortlist'"
             [routerLink]="['/wishlists']">
            {{ "wishlist" | businessName | upperCaseFirst }}s
        </div>

        <div class="menu-main"
             mat-menu-item
             [routerLink]="['/clientele']"
             *feature="'res-141-flok-member-show-shortlist'">
            {{ "clientele" | businessName | upperCaseFirst }}
        </div>

        <div class="menu-divider"></div>

        <div class="menu-main" mat-menu-item (click)="navigateToPrivacyPolicy()">Privacy policy</div>

        <div class="menu-main" mat-menu-item (click)="navigateToTerms()">Terms & conditions</div>

        <div fxHide class="menu-main" mat-menu-item (click)="navigateToFaq()">FAQ</div>

        <div class="menu-divider"></div>

        <div class="menu-main" mat-menu-item>
            <app-logout></app-logout>
        </div>
    </mat-menu>
</nav>
