import { Inject, Injectable, Optional } from '@angular/core'
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { toDate } from 'date-fns'
import { NGX_MAT_DATEFNS_LOCALES, NgxDateFnsDateAdapter } from 'ngx-mat-datefns-date-adapter'
import { zonedTimeToUtc } from 'date-fns-tz'

@Injectable({providedIn: 'root'})
export class TzAwareDateAdapter extends NgxDateFnsDateAdapter {

    constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
                @Inject(NGX_MAT_DATEFNS_LOCALES) locales: Locale[]) {
        super(dateLocale, locales);
    }

    createDate(year: number, month: number, date: number): Date {
        // Check for invalid month and date (except upper bound on date which we have to check after
        // creating the Date).
        if (month < 0 || month > 11) {
            throw Error(
                `Invalid month index "${month}". Month index has to be between 0 and 11.`
            );
        }

        if (date < 1) {
            throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
        }

        const result = this._createDateWithOverflow2(year, month, date);
        // Check that the date wasn't above the upper bound for the month, causing the month to overflow
        if (result.getUTCMonth() !== month) {
            throw Error(`Invalid date "${date}" for month with index "${month}".`);
        }

        return result;
    }

    /** Creates a date but allows the month and date to overflow. */
    private _createDateWithOverflow2(year: number, month: number, date: number) {
        // https://stackoverflow.com/questions/48172772/time-zone-issue-involving-date-fns-format
        const getUTCDate = (date = new Date()) => {
            return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
        }
        const plain = new Date(year, month, date)
        // console.log('plain: ', plain)
        // console.log('plain date: ', plain.getDate())
        // console.log('plain UTCdate: ', plain.getUTCDate())

        const utcDate = getUTCDate(plain)
        // console.log('UTC: ', utcDate)
        // console.log('UTC date: ', utcDate.getDate())
        // console.log('UTC UTCdate: ', utcDate.getUTCDate())

        const dateMidnight = toDate(utcDate)
        // console.log('dateMidnight: ', dateMidnight)
        // console.log('dateMidnight date: ', dateMidnight.getDate())
        // console.log('dateMidnight UTCdate: ', dateMidnight.getUTCDate())

        // We need to correct for the fact that JS native Date treats years in range [0, 99] as
        // abbreviations for 19xx.
        if (year >= 0 && year < 100) {
            dateMidnight.setFullYear(this.getYear(dateMidnight) - 1900);
        }
        return dateMidnight
    }


}
