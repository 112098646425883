import { Component } from '@angular/core'
import { TokenBalanceGQL, TokenBalanceQuery, TokenType } from '../../../../../../graphql'
import { Amplitude } from '../../../../../injectable'

@Component({
    selector: 'app-token-balance',
    templateUrl: 'token-balance.html'
})
export class TokenBalanceComponent {
    tokenAmount: string | undefined
    tokenType: TokenBalanceQuery['myTokenBalance']['tokenType'] | undefined

    // Constructor
    constructor(private _amplitude: Amplitude,
                private _tokenBalanceGQL: TokenBalanceGQL) {
        this._tokenBalanceGQL.watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges.forEach(result => {
            if (!result.loading) {
                if (!result.data) { throw 'Could not load token balance'}

                let balance = result.data.myTokenBalance
                const tokenAmount = balance.amount
                if (tokenAmount > 1000) {
                    this.tokenAmount = `${Math.round(tokenAmount / 1000)}k`
                } else if (tokenAmount !== undefined) {
                    this.tokenAmount = `${tokenAmount}`
                } else {
                    this.tokenAmount = ' '
                }
                this.tokenType = balance.tokenType

                this._amplitude.set('account.token-balance', tokenAmount)
            }
        })
    }
}
