
<div class="feature-image">
    <div class="_w-full _relative" *ngIf="!!images.length">
        <div class="_relative _overflow-hidden">
            <div class="_flex _flex-row _w-full" >
                <div class="_relative md:_rounded-md _overflow-hidden -_mx-2 md:_mx-0">
                    <img app-imagekit [source]="featureImage.url" transform="tr:w-{{imageWidth}},h-{{imageHeight}},fo-auto,tr:q-95" (click)="imageClickEvent.next()">
                </div>
            </div>
        </div>

        <!-- This hook allows calling code to create overlays -->
        <ng-content></ng-content>
    </div>

    <div class="_w-full _flex _justify-center _items-center _placeholder-16-9" *ngIf="!images.length">
        <span class="_h4">Photos coming soon</span>
    </div>

    <div class="_flex _relative _w-full _gap-2 _pt-2" *ngIf="!!images.length">
        <div *ngFor="let image of images | slice:1:7; let last = last; let i = index" 
            [ngClass]="{'_hidden xs:_block': i > 1 && (i < 5 && !last)}"
            class="thumbnail-container _overflow-hidden _rounded _relative _w-1/3 xs:_w-1/6">
            <img app-imagekit
                 [source]="image.url"
                 (click)="imageClickEvent.next()"
                 transform="tr:ar-1-0.7,w-170,tr:q-95"
                 class="_flex-1 _w-full">
            <ng-content select="[slot=modal-trigger]" *ngIf="last"></ng-content>
        </div>
    </div>
</div>
