<form class="container-xxs padding-1" [formGroup]="formGroup" *ngIf="formGroup">
    <h3 class="text-align-center">Accept invitation</h3>

    <p class="margin-top-3 text-align-center text-medium">
        We need to know a little more about you before you can join.
    </p>

    <div class="margin-top-4" fxLayout="column">
        <app-flok-form-field class="margin-top-2">
            <app-flok-label>Phone number</app-flok-label>

            <ngx-intl-tel-input [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom]"
                                [searchCountryFlag]="true"
                                [separateDialCode]="true"
                                [selectedCountryISO]="country"
                                formControlName="phoneNumber">
            </ngx-intl-tel-input>
        </app-flok-form-field>

        <app-flok-form-field [bottomBorder]="false">
            <div class="full-width padding-vertical-2" fxLayoutAlign="start center">
                <mat-checkbox formControlName="termsAndConditionsAccepted"
                              id="termsAndConditionsAccepted"></mat-checkbox>

                <span class="size-2"></span>

                <label for="termsAndConditionsAccepted">
                    <span>I have read, understood and agree to the </span>

                    <app-legal-document-links [customClass]="'color-accent-important'"
                                              [legalDocumentType]="LegalDocumentType.terms"
                                              [text]="'terms & conditions'"></app-legal-document-links>

                    <span>, and </span>

                    <app-legal-document-links [customClass]="'color-accent-important'"
                                              [legalDocumentType]="LegalDocumentType.privacy"
                                              [text]="'privacy policy'"></app-legal-document-links>
                </label>
            </div>
        </app-flok-form-field>
    </div>

    <div class="margin-top-3">
        <app-responsive-button-container>
            <button (click)="accept()"
                    color="accent"
                    [disabled]="!formGroup.valid"
                    flok-button
                    [fullWidth]="true"
                    size="medium">
                Next
            </button>
        </app-responsive-button-container>
    </div>
</form>
