import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http'
import { ApplicationRef, ErrorHandler, isDevMode, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgxCurrencyModule } from 'ngx-currency'
import { DateFnsModule } from 'ngx-date-fns'
import { DropzoneModule } from 'ngx-dropzone-wrapper'
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics'
import { NgxHotjarModule, NgxHotjarRouterModule } from 'ngx-hotjar'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { NGX_MAT_DATEFNS_DATE_FORMATS, NgxMatDateFnsDateModule } from 'ngx-mat-datefns-date-adapter'
import { NgxPaginationModule } from 'ngx-pagination'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { GraphQLModule } from './graphql.module'
import { SentryErrorHandler, TzAwareDateAdapter } from './injectable'
import { ERROR_HANDLERS, GlobalErrorHandler } from './injectable/error/global-error-handler'
import { LazyLoadingErrorHandler } from './injectable/error/lazy-loading-error-handler'
import { SharedFlokModule } from './module/shared-flok/shared-flok.module'
import { SharedMemberModule } from './module/shared-member/shared-member.module'
import { AcceptBrandBusinessOwnerInvitationComponent } from './route/accept-brand-business-owner-invitation/accept-brand-business-owner-invitation'
import { AcceptPmcBusinessOwnerInvitationComponent } from './route/accept-pmc-business-owner-invitation/accept-pmc-business-owner-invitation'
import { acceptStaffMemberInvitationComponents } from './route/accept-staff-member-invitation'
import { AcceptTravelAgentBusinessOwnerInvitationComponent } from './route/accept-travel-agent-business-owner-invitation/accept-travel-agent-business-owner-invitation'
import { AdminNotAllowed } from './route/admin-not-allowed'
import { AppComponent } from './route/app'
import { balanceComponents } from './route/balance'
import { ClearStorageComponent } from './route/clear-storage/clear-storage'
import { confirmComponents } from './route/confirm'
import { consumerSignupComponents } from './route/consumer-signup'
import { featuredListingComponents } from './route/featured-listing'
import { LoggedOutComponent } from './route/logged-out/logged-out'
import { LoggingInComponent } from './route/logging-in/logging-in'
import { NotFoundComponent } from './route/not-found'
import { PasswordlessLoginComponent } from './route/passwordless-login/passwordless-login'
import { profileComponents } from './route/profile'
import {publicWishlistComponents} from "./route/public-wishlist";

console.log('Environment:', environment)

@NgModule({
    bootstrap: [
        AppComponent
    ],
    declarations: [
        AcceptBrandBusinessOwnerInvitationComponent,
        AcceptPmcBusinessOwnerInvitationComponent,
        AcceptTravelAgentBusinessOwnerInvitationComponent,
        ...acceptStaffMemberInvitationComponents,
        ...consumerSignupComponents,
        ...featuredListingComponents,
        AdminNotAllowed,
        AppComponent,
        ...balanceComponents,
        ClearStorageComponent,
        ...confirmComponents,
        LoggedOutComponent,
        LoggingInComponent,
        NotFoundComponent,
        PasswordlessLoginComponent,
        ...profileComponents,
        ...publicWishlistComponents
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        DateFnsModule.forRoot(),
        DropzoneModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        GraphQLModule,
        HttpClientJsonpModule,
        HttpClientModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        NgxCurrencyModule,
        NgxIntlTelInputModule,
        NgxMatDateFnsDateModule,
        NgxHotjarModule.forRoot(environment.hotjar?.siteId),
        NgxHotjarRouterModule,
        NgxGoogleAnalyticsModule.forRoot(environment.google?.analyticsId),
        NgxGoogleAnalyticsRouterModule,
        NgxPaginationModule,
        NgxSliderModule,
        SharedFlokModule,
        SharedMemberModule
    ],
    providers: [
        { provide: DateAdapter, useClass: TzAwareDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: NGX_MAT_DATEFNS_DATE_FORMATS },
        // { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: ERROR_HANDLERS, useClass: LazyLoadingErrorHandler, multi: true },
        { provide: ERROR_HANDLERS, useClass: SentryErrorHandler, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        { provide: 'window', useValue: window }
    ]
})
export class AppModule {
    constructor(applicationRef: ApplicationRef) {
        if (1 == 1) { // DISABLED DUE TO LOG OVERHEAD
            return
        }

        if (isDevMode()) {
            // First, store the original tick function
            const originalTick = applicationRef.tick

            applicationRef.tick = function () {
                // Save start time
                const windowsPerfomance = window.performance
                const before = windowsPerfomance.now()

                // Run the original tick() function
                const returnValue = originalTick.apply(this, arguments)

                // Save end time, calculate the delta, then log to console
                const after = windowsPerfomance.now()
                const runTime = after - before
                window.console.log('[Profiler] CHANGE DETECTION TIME', runTime, 'ms')
                return returnValue
            }
        }
    }
}
