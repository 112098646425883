import { Injectable } from '@angular/core'
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs'
import {
    MemberOnboardingStatus,
    Role,
    RoleManagerAccountGQL,
    RoleManagerMemberOnboardingStatusGQL,
    RoleManagerRolesGQL
} from '../../graphql'
import { UnsubscribeOnDestroy } from '../utility'
import { Auth } from './auth'

@Injectable({
    providedIn: 'root'
})
export class RoleManager extends UnsubscribeOnDestroy {
    /**
     * @deprecated Use {@link #queryAccountAsync} instead
     */
    accountHolderIdSubject = new ReplaySubject<string>(1)
    /**
     * @deprecated Use {@link #queryAccountAsync} instead
     */
    accountIdSubject = new ReplaySubject<string>(1)
    memberOnboardingStatus$ = new ReplaySubject<MemberOnboardingStatus>(1)
    rolesSubject = new ReplaySubject<Role[]>(1)
    roles: Role[] | undefined

    public roles$ = this.rolesSubject.asObservable();

    // Constructor
    constructor(private _auth: Auth,
                private _onboardingStatusGQL: RoleManagerMemberOnboardingStatusGQL,
                private _roleManagerAccountGQL: RoleManagerAccountGQL,
                private _roleManagerRolesGQL: RoleManagerRolesGQL) {
        super()

        this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
            if (authenticated && !this.roles) {
                this.refresh()
            }
        }))
    }

    // Public
    hasAtLeastOneRole(roles: Role[]): boolean {
        if (!this.roles) { return false }

        return !!roles.find(role => this.hasRole(role))
    }

    hasRole(role: Role): boolean {
        if (!this.roles) { return false }

        return this.roles.includes(role)
    }

    queryPermissions() {
        this._subscriptions.push(this._roleManagerRolesGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.roles = result.data['roles']
                    this.rolesSubject.next(this.roles)
                }
            }))
    }

    queryOnboardingStatus() {
        this._subscriptions.push(this._onboardingStatusGQL
            .watch({ })
            .valueChanges
            .subscribe(result => {
                if (result.data?.memberOnboardingStatus) {
                    const onboardingStatus = result.data.memberOnboardingStatus as MemberOnboardingStatus
                    this.memberOnboardingStatus$.next(onboardingStatus)
                }
            }))

    }

    refresh() {
        this.queryAccount()
        this.queryPermissions()
        this.queryOnboardingStatus()
    }

    // Private
    private queryAccount() {
        this._subscriptions.push(this._roleManagerAccountGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    const account = result.data['account']
                    this.accountHolderIdSubject.next(account?.accountHolderId)
                    this.accountIdSubject.next(account?.accountId)

                    console.log('Current user ID: ', account?.accountHolderId)
                }
            }))
    }

    /**
     * Slightly different from #queryAccount in the sense that
     * the async version doesn't have a default 'false' value. From
     * a usage point this means that the async version always requires
     * a subscription like so:
     * <pre>
     * this._roleManager.queryAccountAsync().subscribe(account => {
     *     this.account = account
     * }
     * </pre>
     *
     * Note that the account is cached. Use {@param forceReload} to circumvent
     * the cache and reload from the server.
     */
    queryAccountAsync(forceReload: boolean = false) {
        if (forceReload) {
            console.log('Ignoring any cached account and performing a forced reload instead')
        }

        let subject = new Subject<Account>()
        this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
            if (authenticated) {
                this._roleManagerAccountGQL
                    .watch({}, { fetchPolicy: forceReload ? 'no-cache' : 'cache-and-network' })
                    .valueChanges
                    .subscribe(result => {
                        if (!result.loading) {
                            const account = result.data['account']
                            if (!account) {
                                subject.next(null)
                            } else {
                                subject.next({
                                    accountId: account.accountId,
                                    accountHolderId: account.accountHolderId,
                                    personalProfile: {
                                        firstName: account.personalProfile?.firstName,
                                        lastName: account.personalProfile?.lastName,
                                        email: account.personalProfile?.email,
                                        photoUrl: account.personalProfile?.photoUrl
                                    }
                                } as Account)
                            }
                        }
                    })
            }
        }))
        return subject
    }
}

export interface Account {
    accountId: string
    accountHolderId: string
    personalProfile: {
        firstName: string
        lastName: string
        email: string
        photoUrl: string
    }
}
