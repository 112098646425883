import { Component, Input } from '@angular/core'
import { ImageForSlider } from '../../../../utility'

@Component({
    selector: 'app-image-slider',
    styleUrls: ['image-slider.scss'],
    templateUrl: 'image-slider.html'
})
export class ImageSliderComponent {
    currentIndex = 0
    @Input() imageWidth = 940
    @Input() imageHeight = 470
    @Input() images: ImageForSlider[] = []

    // Public
    next() {
        if (this.currentIndex < this.images.length - 1) {
            this.currentIndex++
        }
    }

    previous() {
        if (this.currentIndex > 0) {
            this.currentIndex--
        }
    }
}
