import { Component } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { Auth } from '../../injectable'

@Component({
    selector: 'app-clear-storage',
    template: ''
})
export class ClearStorageComponent {
    // Constructor
    constructor(private _router: Router,
                private _snackBar: MatSnackBar,
                private _auth: Auth) {
        localStorage?.clear()
        sessionStorage?.clear()
        this._snackBar.open('Storage cleared')
        setTimeout(() => window.location.replace(`https://${environment.auth.domain}/v2/logout`), 1000)
    }
}
