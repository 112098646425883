<div class="container-sm padding-1" fxLayout="column" fxLayoutAlign="start center">
    <div class="position-relative">
        <img src="assets/element/application/received-flok.svg"/>

        <img class="received" src="assets/element/application/received.svg"/>
    </div>

    <h3 class="margin-top-2 text-align-center">Invitation already accepted</h3>

    <p class="container-xs margin-top-3 text-align-center text-large">
        Feel free to finish onboarding your home onto {{tenantName}}
    </p>

    <div class="margin-top-5">
        <app-responsive-button-container>
            <button (click)="goToMemberArea()"
                    color="accent"
                    flok-button
                    [fullWidth]="true"
                    size="medium">
                Proceed
            </button>
        </app-responsive-button-container>
    </div>
</div>
