import {Injectable} from "@angular/core";
import {capitalize, UnsubscribeOnDestroy} from "../utility";
import {NavigationEnd, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {BehaviorSubject, Subject} from "rxjs";

interface UpdatedTitle {
    readonly urlAfterRedirects: string
    readonly originalTitle: string
    readonly updatedTitle: string
}

@Injectable({
    providedIn: 'root'
})
export class TitleService extends UnsubscribeOnDestroy {
    titleUpdatedSubject: BehaviorSubject<UpdatedTitle>

    // Constructor
    constructor(private _router: Router,
                private _title: Title) {
        super()

        this.titleUpdatedSubject= new BehaviorSubject<UpdatedTitle>({
            urlAfterRedirects: this._router.url,
            originalTitle: null,
            updatedTitle: TitleService.parseReadableTitle(this._title.getTitle())
        })

        this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const readableTitle = TitleService.parseReadableTitle(event.urlAfterRedirects)
                const previousTitle = this._title.getTitle();
                this._title.setTitle(readableTitle)
                this.titleUpdatedSubject.next({
                    urlAfterRedirects: event.urlAfterRedirects,
                    originalTitle: previousTitle,
                    updatedTitle: readableTitle
                })
            }
        })
    }

    private static parseReadableTitle(urlAfterRedirect: string): string {
        try {
            const tokens = urlAfterRedirect.split('/')
            const readableTitle = tokens.length == 0
                ? 'Home'
                : tokens[tokens.length - 1]
            return capitalize(readableTitle)
                .replace(/_/g, ' ')
                .replace(/-/g, ' ')
        } catch (e) {
            return 'Flok'
        }
    }
}
