import { Component, Input } from '@angular/core'
import { ListingHighlightedFeature } from '../../../../../../../graphql'

@Component({
    selector: 'app-listing-highlighted-feature',
    templateUrl: './listing-highlighted-feature.html'
})
export class ListingHighlightedFeatureComponent {
    /**
     * When set to true, will show title but no description.
     *
     * It will also reduce the gap between each line. This summarized
     * version is used by e.g. the search listing page to only
     * show the titles.
     */
    @Input() titleOnly = false

    @Input() highlightedFeatures: ListingHighlightedFeature[]
}
