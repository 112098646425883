import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../../../../environments/environment'
import { Module, ScopeManager } from '../../../../../injectable'
import { UnsubscribeOnDestroy } from '../../../../../utility'

@Component({
    selector: 'app-desktop-nav',
    styleUrls: ['desktop-nav.scss'],
    templateUrl: 'desktop-nav.html'
})
export class DesktopNavComponent extends UnsubscribeOnDestroy {
    canAccessSearchModule: boolean

    constructor(private _router: Router,
                private _scopeManager: ScopeManager) {
        super()

        this._subscriptions.push(this._scopeManager.hasModuleScope(Module.search).subscribe(hasAccess => {
            this.canAccessSearchModule = hasAccess
        }))
    }

    // Public
    hasScope(scope: string): boolean {
        return this._scopeManager.hasScope(scope)
    }

    navigateToPrivacyPolicy() {
        window.open(environment.tenant.legal.privacy, '_blank');
    }

    navigateToTerms() {
        window.open(environment.tenant.legal.terms, '_blank');
    }

    navigateToFaq() {
        window.open(environment.tenant.faq, '_blank');
    }
}
