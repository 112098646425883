import { Component, EventEmitter, Output } from '@angular/core'
import { Role } from '../../../../../../graphql'
import { ScopeManager } from '../../../../../injectable'

@Component({
    selector: 'app-mobile-nav',
    styleUrls: ['mobile-nav.scss'],
    templateUrl: 'mobile-nav.html'
})
export class MobileNavComponent {
    Role = Role
    @Output() toggleNav = new EventEmitter<void>()

    // Constructor
    constructor(private _scopeManager: ScopeManager) {}

    // Public
    hasScope(scope: string): boolean {
        return this._scopeManager.hasScope(scope)
    }
}
