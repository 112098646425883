<div fxLayout="column" fxLayoutAlign="start center" class="background-color-yellow" *ngIf="publicWishlist">
    <nav class="container-md padding-1 margin-top-5" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" style="height: 50px" class="margin-top-2 margin-bottom-2">
            <button flok-button fxLayoutAlign="start center" [widthAuto]="true" (click)="onClick()">
                <mat-icon>chevron_left</mat-icon>

                <span>To {{corporateName | upperCaseFirst }} homepage</span>

                <div class="size-1"></div>
            </button>
        </div>

        <a href="{{corporateSiteUrl}}" target="_blank" fxLayout="row" fxLayoutAlign="center center" class="margin-bottom-3">
            <img class="height-5" app-logo/>
        </a>
    </nav>

    <h3 class="margin-top-5">{{publicWishlist.name}}</h3>

    <div  fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutGap="25px" class="container-md padding-1 margin-top-5">
        <div *ngFor="let wish of publicWishlist.wishes" fxFlex="calc(33.3%)">
            <div class="search-listing position-relative margin-bottom-5"
                 fxLayout="column">
                <div>
                    <div class="info"
                         fxLayout="column"
                         fxLayoutAlign="center center">

                        <h5>
                            <span>{{wish.searchListing.summary.title | slice:0:40}}</span>
                        </h5>

                        <div class="size-1"></div>

                        <div class="text-medium" fxLayoutAlign="end center">
                            <ng-container *ngIf="wish.searchListing.summary.city">
                                <span>{{wish.searchListing.summary.city}}, </span>

                                <span class="size-05"></span>
                            </ng-container>

                            <app-country-code [countryCode]="wish.searchListing.summary.countryCode"></app-country-code>
                        </div>

                        <div class="size-1" ngClass.gt-sm="size-2"></div>

                        <div fxLayout="row wrap"
                             fxLayoutGap="10px"
                             fxLayoutGap.gt-sm="25px"
                             fxLayoutAlign="start start">
                            <ng-container *ngFor="let decidingFactor of wish.searchListing.summary.decidingFactors?.primaryDecidingFactors">
                                <app-listing-deciding-factor [titleOnly]="true" [decidingFactor]="decidingFactor">
                                </app-listing-deciding-factor>
                            </ng-container>
                        </div>

                        <div class="size-1" ngClass.gt-sm="size-2"></div>

                    </div>
                    <div>
<!--                        <div class="circle-device" data-animation="scale-from&#45;&#45;80%">-->
<!--                            <div class="circle"></div>-->
<!--                        </div>-->
<!--                        <img app-imagekit class="full-width full-height" [source]="coverPhotoUrl(listing.photos)"  width="300" height="200" transform="tr:w-400,h-200,tr:q-30">-->

                        <app-feature-image [images]="wish.searchListing.summary.photos" (imageClickEvent)="viewPhotos(wish.searchListing)">
                            <div class="photo-count position-absolute">
                                <app-flok-badge clas="background-color-white" (click)="viewPhotos(wish.searchListing)">
                                    <div fxLayout="row"
                                         fxLayoutAlign="center center"
                                         fxLayoutGap="6px"
                                         class="padding-horizontal-1">
                                        <span class="material-icons text-large">grid_on</span>

                                        <a class="photo-text text-medium">Show all photos</a>
                                    </div>
                                </app-flok-badge>
                            </div>
                        </app-feature-image>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>


