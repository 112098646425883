import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PasswordlessLoginExchangeAuthCodeGQL } from '../../../graphql'
import { Amplitude, SupportChat, TenantService } from '../../injectable'
import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-passwordless-login',
    templateUrl: 'passwordless-login.html'
})
export class PasswordlessLoginComponent extends UnsubscribeOnDestroy {
    tenantName: string
    confirmedFailed = false
    confirmedSuccess = false
    chatEnabled: boolean
    showFlokTenantSpecificPreloader = false

    constructor(private _activatedRoute: ActivatedRoute,
                private _amplitude: Amplitude,
                private _exchangeAuthCodeGQL: PasswordlessLoginExchangeAuthCodeGQL,
                private _supportChat: SupportChat,
                private _tenantService: TenantService) {
        super()

        this.chatEnabled = _supportChat.chatEnabled()
        this.tenantName = _tenantService.name()
        this.showFlokTenantSpecificPreloader = _tenantService.name().toLowerCase() == 'flok'

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            const code = paramMap.get('code')

            this._amplitude.logEvent('Login Started', {
                loginType: 'Passwordless'
            })

            this._exchangeAuthCodeGQL
                .watch({ code: code }, { fetchPolicy: 'no-cache' })
                .valueChanges
                .subscribe(result => {
                    if (!result.loading) {
                        console.log('Passwordless login: ', result)
                        if (result.errors && result.errors[0]?.extensions.violations?.length) {
                            this._amplitude.logEvent('Login Failed', {
                                loginType: 'Passwordless',
                                failureType: 'Invalid Code'
                            })
                            // TODO: LEX-2266, LEX-2267, LEX-2280
                            // throw 'Validation error when exchanging auth code for partner - ' + result.errors[0]?.extensions.violations
                            this.confirmedFailed = true
                        } else if (result.data.exchangeAuthCode?.accessToken) {
                            this.confirmedSuccess = true

                            sessionStorage.setItem('token', result.data.exchangeAuthCode.accessToken)
                            setTimeout(() => window.location.replace('/search-listings'), 1500)

                            this._amplitude.logEvent('Login Completed', {
                                loginType: 'Passwordless',
                                failureType: 'Invalid Code'
                            })
                        } else {
                            this.confirmedFailed = true
                            // TODO: LEX-2266, LEX-2267, LEX-2280
                            // throw 'General error when exchanging auth code for partner ' + result.errors[0]
                        }
                    }
                })
        }))
    }

    openChat() {
        this._supportChat.openChat()
    }
}
