import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core'


export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector){}

    handleError(error: any): any {
        const handlers = this.injector.get<ErrorHandler[]>(ERROR_HANDLERS, []);

        let counter = 0
        handlers.forEach((errorHandler: ErrorHandler) => {
            try {
                counter++
                errorHandler.handleError(error)
            } catch (e) {
                console.log('Error handler #' + counter + ' failed. Proceeding with remaining error handlers', e)
            }
        });
    }
}
