<ng-container *ngIf="booking">
    <div class="background-color-white margin-top-2 shadow-large"
         fxLayout="column"
         fxLayout.gt-xs="row"
         fxLayoutAlign="start stretch"
         fxLayoutAlign.gt-sm="start center">
        <div>
            <img app-imagekit class="display-block full-width"
                 [source]="booking.summary.imageUrl"
                 transform="tr:w-252,h-160">
        </div>

        <div class="padding-2" fxFlex fxLayout="column">
            <div class="text-medium" fxLayoutAlign="start center">
                <span>{{booking.summary.location.address.city}}, </span>

                <span class="size-05"></span>

                <app-country-code [countryCode]="booking.summary.location.address.countryCode"></app-country-code>
            </div>

            <h4 class="margin-top-05 truncate">{{booking.summary.name}}</h4>
        </div>
    </div>

    <div class="padding-horizontal-2 padding-top-2 text-medium" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center">
            <span>Current checkin - checkout:</span>

            <span fxFlex></span>

            <span>{{booking.checkin | date:'mediumDate'}}</span>

            <span class="size-1"></span>

            <span>{{booking.checkout | date:'mediumDate'}}</span>
        </div>
    </div>
</ng-container>
