<div fxLayout="column" *ngIf="fees">
    <div class="background-color-yellow-lightest padding-horizontal-2 padding-vertical-3" *ngIf="tokensEnabled">
        <div class="text-medium" fxLayoutAlign="start start">
            <span class="border-dashed-bottom">Due</span>

            <span fxFlex></span>

            <span>{{fees.tokenFee.amount | number:'1.0-0'}}</span>

            <span class="size-05"></span>

            <span>{{fees.tokenType.name}}</span>
        </div>

        <div class="margin-top-2 text-medium" fxLayoutAlign="space-between start" *ngIf="balance">
            <span class="border-dashed-bottom">Balance</span>

            <span fxFlex></span>

            <span>{{balance.amount | number:'1.0-0'}} {{balance.tokenType.name}}</span>
        </div>

<!-- Disabled due to time constraints.
        <p class="color-grey-secondary-important cursor-pointer margin-top-3 text-align-center text-medium"
           (click)="topUp()"
           *ngIf="topUpTokens"
           fxHide
           fxShow.gt-sm>
            Add availability and travel for free
        </p>
-->
    </div>

    <div class="margin-top-2 padding-horizontal-2" fxLayoutAlign="space-between start" *ngIf="tokensEnabled && topUpTokens">
        <span class="border-dashed-bottom">{{topUpTokens}} {{balance.tokenType.name | titlecase }} top-up</span>

        <span>{{topUpTotal | number:'1.0-0'}} {{cashCurrency}}</span>
    </div>

    <div class="margin-top-2 padding-horizontal-2" fxLayoutAlign="start start">
        <span class="border-dashed-bottom">Booking fee</span>

        <span fxFlex></span>

        <span class="text-bold">{{bookingFee | currency : cashCurrency : 'symbol-narrow' : '1.0-0'}}</span>
        <span *ngIf="(convertedBookingFee$ | async)  as fee"> ({{(fee.amountMicros / 1000000) | currency : fee.currency : 'symbol-narrow' : '1.0-0'}})</span>
    </div>

    <div class="margin-top-2 padding-horizontal-2" fxLayoutAlign="start start" *ngIf="commissionFees">
        <span class="border-dashed-bottom">Your commission is {{ commissionFees.commissionRate | number:'1.0-1' }}%</span>

        <span fxFlex></span>

        <span>{{commissionFee | currency : cashCurrency : 'symbol-narrow' : '1.0-0'}}</span>
    </div>

    <div class="margin-top-2 padding-horizontal-2" fxLayoutAlign="start start" *ngIf="supplementFee > 0">
        <span class="border-dashed-bottom">Supplement fee</span>

        <span fxFlex></span>

        <span>{{supplementFee | currency : cashCurrency : 'symbol-narrow' : '1.0-0'}} {{cashCurrency}}</span>
    </div>

    <div class="margin-top-2 padding-horizontal-2" fxLayoutAlign="start start" *ngIf="cleaningFees > 0">
        <span class="border-dashed-bottom">Cleaning fee</span>

        <span fxFlex></span>

        <span>{{cleaningFees | currency : cashCurrency : 'symbol-narrow' : '1.0-0'}} {{cashCurrency}}</span>
    </div>

    <div class="margin-top-4" fxLayoutAlign="center center">
        <img src="assets/element/flok-swoosh-left.svg"/>

        <span class="size-1"></span>

        <h6>Total</h6>

        <span class="size-1"></span>

        <img src="assets/element/flok-swoosh-right.svg"/>
    </div>

    <h4 class="margin-top-1"
        fxLayoutAlign="center center"
        *ngIf="total">
        {{total | currency : cashCurrency : 'symbol-narrow' : '1.0-0'}}
    </h4>
</div>
