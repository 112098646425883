import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { CountryISO } from 'ngx-intl-tel-input'
import { Subject } from 'rxjs'
import { of } from 'rxjs'
import { timer } from 'rxjs'
import { catchError, concatMap, map, take, takeUntil } from 'rxjs/operators'
import {
    AcceptConsumerTermsAndConditionsCommand,
    AcceptTravelAgentBusinessOwnerInvitationGQL,
    AcceptTravelAgentBusinessOwnerInvitationInvitationGQL,
    AcceptTravelAgentBusinessOwnerInvitationPhoneGQL,
    AcceptTravelAgentBusinessOwnerInvitationTermsGQL,
    Role,
    TravelAgentBusinessOwnerInvitationStatus,
    UpdatePersonalPhoneNumberCommand
} from '../../../graphql'
import { Auth, RoleManager } from '../../injectable'
import { LegalDocumentType } from '../../module/shared-member/organism/legal-document-links/legal-document-links'
import { countryCodeToCountryIso } from '../../phone-utility'
import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-accept-travel-agent-business-owner-invitation',
    templateUrl: 'accept-travel-agent-business-owner-invitation.html'
})
export class AcceptTravelAgentBusinessOwnerInvitationComponent extends UnsubscribeOnDestroy {
    loading = true
    private accountCreated$ = new Subject()

    Role = Role
    CountryISO = CountryISO
    country: CountryISO
    LegalDocumentType = LegalDocumentType
    formGroup: FormGroup
    invitationId: string
    listingId: string

    userExistsAlready: boolean
    status: TravelAgentBusinessOwnerInvitationStatus
    // The Template uses the below
    // tslint:disable-next-line:variable-name
    BusinessOwnerInvitationStatus = TravelAgentBusinessOwnerInvitationStatus
    acceptInProgress = false

    constructor(private _activatedRoute: ActivatedRoute,
                private _acceptBusinessOwnerInvitationGQL: AcceptTravelAgentBusinessOwnerInvitationGQL,
                private _acceptBusinessOwnerInvitationInvitationGQL: AcceptTravelAgentBusinessOwnerInvitationInvitationGQL,
                private _acceptBusinessOwnerInvitationPhoneGQL: AcceptTravelAgentBusinessOwnerInvitationPhoneGQL,
                private _acceptBusinessOwnerInvitationTermsGQL: AcceptTravelAgentBusinessOwnerInvitationTermsGQL,
                private _auth: Auth,
                private _roleManager: RoleManager,
                private _router: Router,
                private _snackBar: MatSnackBar,
                ) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            this.invitationId = paramMap.get('invitationId')
            this.listingId = paramMap.get('listingId')

            this.initializeAsync().then(() => {
                // a signed-up user
                this._roleManager.rolesSubject.subscribe(roles => {
                    this.loading = false
                    this.userExistsAlready = roles && roles.length > 0
                })
                this._roleManager.refresh()
        })
        }))
    }

    private acceptBusinessOwnerInvitationSuccessCommand$(invitationId: string) {
        return this._acceptBusinessOwnerInvitationGQL
            .mutate({ invitationId})
            .pipe(map(response => {
                if (response.errors && response.errors.length) {
                    const message = `Error accepting staff member invitation', ${response.errors}`
                    throw new Error(message)
                }
                return of(true)
            }))
    }

    acceptInvitation() {
        if (!this.invitationId) {
            throw new Error(`Tried to accept a host invitation without invitation id`)
        }

        this.loading = true
        this.acceptInProgress = true
        this.acceptBusinessOwnerInvitationSuccessCommand$(this.invitationId)
            .pipe(
                concatMap((_) => {
                    return this._roleManager.accountIdSubject
                }),
                // @ts-ignore
                catchError((error) => {
                    console.error('Error accepting staff member invitation', error.message)
                    this.loading = false
                    this.acceptInProgress = false
                }),
                takeUntil(this.accountCreated$),
            ).subscribe((accountId) => {
            const accountCreated = !!accountId
            if (accountCreated) {
                this.accountCreated$.next()
                this.accountCreated$.complete()

                this.updatePhoneNumber()
                this.acceptTerms()

                setTimeout(() => {
                    this.navigateToPlatform()
                }, 1000)
            }
        })
        this.waitForAccountCreation()
    }

    next() {
        this.acceptInvitation()
    }

    navigateToPlatform() {
        this._router.navigate(['../../'], { relativeTo: this._activatedRoute })
    }

    private acceptTerms() {
        const command: AcceptConsumerTermsAndConditionsCommand = {
            accepted: true
        }

        this._subscriptions.push(this._acceptBusinessOwnerInvitationTermsGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.error('Error accepting terms for invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error accepting terms for invitation')
            }
        }, (error) => {
            console.error('Error accepting terms for host invitation', error)
        }))
    }

    private checkInvitation() {
        this._subscriptions.push(this._acceptBusinessOwnerInvitationInvitationGQL
            .watch({ invitationId: this.invitationId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    if (!result.data?.travelAgentBusinessOwnerInvitation) {
                        this._router.navigateByUrl('/not-found', { skipLocationChange: true })
                        return
                    }

                    this.status = result.data.travelAgentBusinessOwnerInvitation.status
                }
            }))
    }

    private createFormGroup() {
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', Validators.required),
            termsAndConditionsAccepted: new FormControl(false, Validators.requiredTrue)
        })
    }

    private async initializeAsync() {
        await this._auth.checkAuthenticationAsync(true)

        const user = await this._auth.userAsync()
        if (user) { // {MD 30/07/2020} Avoids throwing if user is not logged in
            this.country = countryCodeToCountryIso(user.countryCode) || CountryISO.UnitedStates
            this.checkInvitation()
            this.createFormGroup()
        }
    }

    private updatePhoneNumber() {
        if (!this.formGroup.valid) { return }

        const phoneNumber = this.formGroup.value.phoneNumber
        console.log('Registering phone number', phoneNumber)

        const command: UpdatePersonalPhoneNumberCommand = {
            phoneNumber: {
                countryCode: phoneNumber.dialCode,
                nationalNumber: phoneNumber.nationalNumber
            }
        }

        this._subscriptions.push(this._acceptBusinessOwnerInvitationPhoneGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.log('Error updating phone number host invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error updating phone number')
            } else {
                this._roleManager.refresh()
            }
        }, (error) => {
            console.log('Error updating phone number for host invitation', error)
            this._snackBar.open('Error updating phone number')
        }))
    }

    private waitForAccountCreation() {
        timer(500)
            .pipe(
                take(20),
                takeUntil(this.accountCreated$),
            )
            .subscribe(() => this._roleManager.refresh())
    }
}
