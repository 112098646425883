import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
// import { ProfileMemberGQL, ProfileMemberQuery } from '../../../graphql'
import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-profile',
    templateUrl: 'profile.html'
})
export class ProfileComponent extends UnsubscribeOnDestroy {
    member: any // ProfileMemberQuery['member']

    // Constructor
    constructor(private _activatedRoute: ActivatedRoute/*, private _profileMemberGQL: ProfileMemberGQL*/) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            const memberId = paramMap.get('memberId')

            /*this._subscriptions.push(this._profileMemberGQL.watch({ memberId: memberId }, { fetchPolicy: 'no-cache' })
                .valueChanges.subscribe(result => {
                    if (!result.loading) {
                        this.member = result.data.member ? result.data.member : undefined
                    }
                }))*/
        }))
    }
}
