import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatNativeDateModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { NgxSliderModule } from '@angular-slider/ngx-slider'
import { RouterModule } from '@angular/router'
import { CountUpModule } from 'ngx-countup'
import { DateFnsModule } from 'ngx-date-fns'
import { DropzoneModule } from 'ngx-dropzone-wrapper'
import { EllipsisModule } from 'ngx-ellipsis'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input'
import { NgxMatDateFnsDateModule } from 'ngx-mat-datefns-date-adapter'
import { SortablejsModule } from 'ngx-sortablejs'
import { SwiperModule } from 'swiper/angular'
import { SharedFlokModule } from '../shared-flok/shared-flok.module'
import { LinkExternalCalendarComponent, memberOrganismComponents } from './organism'

@NgModule({
    declarations: [
        ...memberOrganismComponents
    ],
    entryComponents: [
        LinkExternalCalendarComponent
    ],
    exports: [
        ...memberOrganismComponents
    ],
    imports: [
        CommonModule,
        CountUpModule,
        DateFnsModule,
        DropzoneModule,
        EllipsisModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        NgxMatDateFnsDateModule,
        NgxSliderModule,
        SwiperModule,
        RouterModule,
        SharedFlokModule,
        SortablejsModule.forRoot({animation: 150}),
        NgxIntlTelInputModule
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
    ]
})
export class SharedMemberModule {}
