import { NgModule } from '@angular/core'
import { ExtraOptions, RouterModule, Routes } from '@angular/router'
import { AuthenticationGuard } from './guard/authentication'
import { LandingPageGuard } from './guard/landing-page-guard'
import { LoginComponent } from './module/shared-member/organism/login/login'
import { AcceptBrandBusinessOwnerInvitationComponent } from './route/accept-brand-business-owner-invitation/accept-brand-business-owner-invitation'
import { AcceptPmcBusinessOwnerInvitationComponent } from './route/accept-pmc-business-owner-invitation/accept-pmc-business-owner-invitation'
import { AcceptStaffMemberInvitationComponent } from './route/accept-staff-member-invitation'
import { AcceptTravelAgentBusinessOwnerInvitationComponent } from './route/accept-travel-agent-business-owner-invitation/accept-travel-agent-business-owner-invitation'
import { AdminNotAllowed } from './route/admin-not-allowed'
import { BalanceLogComponent } from './route/balance'
import { ClearStorageComponent } from './route/clear-storage/clear-storage'
import { PayRescheduleBookingComponent, PayStayExtensionComponent } from './route/confirm'
import { ConsumerSignupComponent } from './route/consumer-signup'
import { FeaturedListingComponent } from './route/featured-listing/featured-listing'
import { LoggedOutComponent } from './route/logged-out/logged-out'
import { LoggingInComponent } from './route/logging-in/logging-in'
import { NotFoundComponent } from './route/not-found'
import { PasswordlessLoginComponent } from './route/passwordless-login/passwordless-login'
import { ProfileComponent } from './route/profile'
import {PublicWishlistComponent} from './route/public-wishlist/public-wishlist'

// https://medium.com/front-end-weekly/handling-scrolling-on-angular-router-transitions-e7652e57d964
const extraOptions: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    // Restore the last scroll position
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: 'enabled',
}

const routes: Routes = [
    {
        component: LoginComponent,
        path: 'login'
    },
    {
        component: PublicWishlistComponent,
        path: 'public-wishlist/:wishlistId'
    },
    {
        component: FeaturedListingComponent,
        path: 'featured-listings/:featuredListingCode'
    },
    {
        component: AcceptStaffMemberInvitationComponent,
        path: 'accept-staff-member-invitation/:invitationId'
    },
    {
        component: AcceptBrandBusinessOwnerInvitationComponent,
        path: 'accept-brand-business-owner-invitation/:invitationId'
    },
    {
        component: AcceptPmcBusinessOwnerInvitationComponent,
        path: 'accept-pmc-business-owner-invitation/:invitationId'
    },
    {
        component: AcceptTravelAgentBusinessOwnerInvitationComponent,
        path: 'accept-travel-agent-business-owner-invitation/:invitationId'
    },
    {
        component: PasswordlessLoginComponent,
        path: 'passwordless/:code'
    },
    {
        component: ConsumerSignupComponent,
        path: 'signup/public-listing/:targetListingId'
    },
    {
        component: ConsumerSignupComponent,
        path: 'signup/public-listing/:targetListingId/:checkin/:checkout'
    },
    {
        component: ConsumerSignupComponent,
        path: 'signup'
    },
    {
        path: 'account',
        loadChildren: () => import('./route/account/account.module').then(m => m.AccountModule)
    },
    {
        path: 'application',
        loadChildren: () => import('./route/application/application.module').then(m => m.ApplicationModule)
    },
    {
        canActivate: [AuthenticationGuard],
        component: BalanceLogComponent,
        path: 'balance/balance-log'
    },
    {
        path: 'calendar',
        loadChildren: () => import('./route/calendar/calendar.module').then(m => m.CalendarModule)
    },
    {
        component: ClearStorageComponent,
        path: 'clear-storage'
    },
    {
        canActivate: [AuthenticationGuard],
        component: PayStayExtensionComponent,
        path: 'confirm/extend-stay/:orderId'
    },
    {
        canActivate: [AuthenticationGuard],
        component: PayRescheduleBookingComponent,
        path: 'confirm/reschedule-booking/:orderId'
    },
    {
        path: 'hotels',
        loadChildren: () => import('./route/hotels/hotels.module').then(m => m.HotelsModule)
    },
    {
        path: 'inbox',
        loadChildren: () => import('./route/inbox/inbox.module').then(m => m.InboxModule)
    },
    {
        path: 'listing',
        loadChildren: () => import('./route/listing/listing.module').then(m => m.ListingModule)
    },
    {
        component: LoggedOutComponent,
        path: 'logged-out'
    },
    {
        component: LoggingInComponent,
        path: 'logging-in'
    },
    {
        path: 'my-guests',
        loadChildren: () => import('./route/my-guests/my-guests.module').then(m => m.MyGuestsModule)
    },
    {
        path: 'my-trips',
        loadChildren: () => import('./route/my-trips/my-trips.module').then(m => m.MyTripsModule)
    },
    {
        path: 'trips',
        loadChildren: () => import('./route/my-trips/my-trips.module').then(m => m.MyTripsModule)
    },
    {
        path: 'clientele',
        loadChildren: () => import('./route/clientele/clientele.module').then(m => m.ClienteleModule)
    },
    {
        path: 'wishlists',
        loadChildren: () => import('./route/wishlists/wishlists.module').then(m => m.WishlistsModule)
    },
    {
        path: 'favourites',
        loadChildren: () => import('./route/favourites/favourites.module').then(m => m.FavouritesModule)
    },
    {
        component: NotFoundComponent,
        path: 'not-found'
    },
    {
        component: AdminNotAllowed,
        path: 'admin-not-allowed'
    },
    {
        canActivate: [AuthenticationGuard],
        component: ProfileComponent,
        path: 'profile/:memberId'
    },
    {
        path: 'properties',
        loadChildren: () => import('./route/properties/properties.module').then(m => m.PropertiesModule)
    },
    {
        path: 'property-onboarding',
        loadChildren: () => import('./route/property-onboarding/property-onboarding.module')
            .then(m => m.PropertyOnboardingModule)
    },
    {
        path: 'search-listings',
        loadChildren: () => import('./route/search-listings/search-listings.module')
            .then(m => m.SearchListingsModule)
    },
    {
        path: '',
        canActivate: [LandingPageGuard],
        pathMatch: 'full',
        redirectTo: ''
    },
    {
        path: '**',
        component: NotFoundComponent
    }
]

@NgModule({
    imports: [RouterModule.forRoot(routes, extraOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
