import * as amplitude from '@amplitude/analytics-browser'
import { NgModule } from '@angular/core'
import { InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { ErrorLink } from '@apollo/client/link/error'
import { APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { environment } from '../environments/environment'

import { Auth } from './injectable'

const uri = environment.graphql.uri


export function createApollo(auth: Auth, httpLink: HttpLink) {
    const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ extensions }) => {
                if (extensions && extensions.code === `UNAUTHENTICATED`) {
                    return auth.checkAuthenticationAsync(false)
                }
            });
        }
        else if (networkError) {
            console.log('Caught network error when executing graphql', networkError)
            // TODO: Not sure what to do here? Something Sentry.
        }
    });

    const authLink = setContext((_, { headers }) => {
        return new Promise(async resolve => {
            let accessToken = await auth.accessTokenAsync()
            let deviceId = amplitude?.getDeviceId() || ''
            if (accessToken) {
                resolve({
                    headers: {
                        ...headers,
                        Authorization: 'Bearer ' + accessToken,
                        'Device-Id': deviceId
                    }
                })
            } else {
                resolve({
                    headers: {
                        ...headers,
                        'Device-Id': deviceId
                    }
                })
            }
        })
    })

    const directionalLink = authLink.concat(errorLink.concat(httpLink.create({ uri })))

    return {
        // https://www.apollographql.com/docs/react/caching/cache-configuration/#generating-unique-identifiers
        cache: new InMemoryCache({
            typePolicies: {
                Account: {
                    keyFields: ['accountId', 'personalProfile', ['email']]
                }
            }
        }),
        defaultOptions: {
            mutate: {
                errorPolicy: 'all'
            },
            watchQuery: {
                errorPolicy: 'all'
            }
        },
        link: directionalLink,
    }
}

@NgModule({
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [Auth, HttpLink]
        }
    ]
})
export class GraphQLModule {}
