import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  LocalDate: any;
  LocalTime: any;
  Long: any;
  OffsetDateTime: any;
  Percentage: any;
  YearMonth: any;
};




export type AcceptAssetOwnerInvitationCommand = {
  assetId: Scalars['ID'];
  invitationId: Scalars['String'];
};

export type AcceptBookingRequest = {
  bookingId: Scalars['String'];
  returnUrl: Scalars['String'];
  hostingTeamReturnUrl: Scalars['String'];
};

export type AcceptConsumerTermsAndConditionsCommand = {
  accepted: Scalars['Boolean'];
};

export type AcceptPropertyOwnerInvitationCommand = {
  propertyId: Scalars['ID'];
  invitationId: Scalars['String'];
};

export type AcceptStaffMemberInvitationCommand = {
  invitationId: Scalars['String'];
};

export type AccessToken = {
  __typename?: 'AccessToken';
  accessToken: Scalars['String'];
  tokenType: Scalars['String'];
  expiresIn: Scalars['Int'];
};

export type Account = {
  __typename?: 'Account';
  accountId: Scalars['ID'];
  accountHolderId: Scalars['String'];
  personalProfile: PersonalProfile;
  bankAccount: BankAccount;
  membership?: Maybe<Membership>;
};

export type AddApplicationPhotosCommand = {
  memberId: Scalars['ID'];
  photos?: Maybe<Array<Scalars['String']>>;
};

export type AddBusinessCustomerToClienteleCommand = {
  clienteleId: Scalars['String'];
  userId: Scalars['String'];
};

export type AddHostAndManagementCommand = {
  listingManagementId: Scalars['ID'];
  hostId: Scalars['String'];
  managementId: Scalars['String'];
};

export type AddToFavouritesCommand = {
  type: FavouriteType;
  referenceId: Scalars['String'];
};

export type AddWishToWishlistCommand = {
  wishlistId: Scalars['ID'];
  wishId: Scalars['String'];
  listingId: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
};

export type Address = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type AddressType = {
  __typename?: 'AddressType';
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  additionalInstructions?: Maybe<Scalars['String']>;
};

export type Airport = {
  __typename?: 'Airport';
  type: AirportType;
  name: Scalars['String'];
  location: GeoLocation;
  distance?: Maybe<Scalars['Float']>;
};

export enum AirportType {
  MediumAirport = 'medium_airport',
  LargeAirport = 'large_airport'
}

export type AllocateDailyAvailabilityCommand = {
  listingId: Scalars['ID'];
  dailyAvailabilities: Array<DailyAvailability>;
};

export type AllocateInitialMonthlyAvailabilityRequest = {
  listingId: Scalars['ID'];
  blockedDays?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
};

export type AllocateMonthlyAvailabilityCommand = {
  listingId: Scalars['ID'];
  blockedDays?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
  activeMonths: Array<Maybe<Scalars['YearMonth']>>;
};

export type AmenitiesCriterion = {
  selectedAmenityIds?: Maybe<Array<Scalars['String']>>;
};

export type AmenitiesCriterionType = {
  __typename?: 'AmenitiesCriterionType';
  selectedAmenityIds?: Maybe<Array<Scalars['String']>>;
};

export type AmenityBundleSectionType = {
  __typename?: 'AmenityBundleSectionType';
  sectionId: Scalars['ID'];
  name: Scalars['String'];
  amenities: Array<AmenityType>;
};

export type AmenityBundleType = {
  __typename?: 'AmenityBundleType';
  bundleId: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<AmenityBundleSectionType>;
  enabled: Scalars['Boolean'];
};

export type AmenityType = {
  __typename?: 'AmenityType';
  amenityId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  iconName?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type ApplicationPhotoSignUrlRequest = {
  applicantId: Scalars['String'];
  filename: Scalars['String'];
};

export type AreaDefinitionType = {
  __typename?: 'AreaDefinitionType';
  areaDefinitionId: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
};

export type AreaType = {
  __typename?: 'AreaType';
  areaDefinitionId: Scalars['String'];
  size?: Maybe<Scalars['Float']>;
  unit?: Maybe<AreaUnit>;
};

export enum AreaUnit {
  M2 = 'm2',
  Ft2 = 'ft2'
}

export type Asset = {
  __typename?: 'Asset';
  assetId: Scalars['ID'];
  assetType?: Maybe<AssetType>;
};

export type AssetOnboardingStatus = {
  __typename?: 'AssetOnboardingStatus';
  finished: Scalars['Boolean'];
  ownerAssigned: Scalars['Boolean'];
  generalUpdated: Scalars['Boolean'];
  amenitiesUpdated: Scalars['Boolean'];
  houseRulesUpdated: Scalars['Boolean'];
  locationUpdated: Scalars['Boolean'];
  goodToKnowUpdated: Scalars['Boolean'];
  photosAdded: Scalars['Boolean'];
};

export type AssetOwner = {
  __typename?: 'AssetOwner';
  ownerId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
};

export enum AssetOwnerInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type AssetOwnerInvitationSummary = {
  __typename?: 'AssetOwnerInvitationSummary';
  assetId: Scalars['ID'];
  invitationId: Scalars['String'];
  status: AssetOwnerInvitationStatus;
};

export type AssetPhoto = {
  url: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  useAsCover: Scalars['Boolean'];
  useAsFloorplan: Scalars['Boolean'];
};

export type AssetPhotoType = {
  __typename?: 'AssetPhotoType';
  url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  useAsCover: Scalars['Boolean'];
  useAsFloorplan: Scalars['Boolean'];
};

export enum AssetType {
  Property = 'property',
  Hotel = 'hotel'
}

export type AssetTypesCriterion = {
  assetTypes?: Maybe<Array<Maybe<AssetType>>>;
};

export type AssignPersonalStaffToMemberCommand = {
  staffId: Scalars['String'];
};

export type AssignStaffToBrandCommand = {
  brandId: Scalars['ID'];
  staffId: Scalars['String'];
};

export type AssignStaffToHotelCommand = {
  hotelId: Scalars['ID'];
  staffId: Scalars['String'];
};

export type AssignStaffToPmcCommand = {
  pmcId: Scalars['ID'];
  staffId: Scalars['String'];
};

export type AssignStaffToTravelAgentCommand = {
  travelAgentId: Scalars['ID'];
  staffId: Scalars['String'];
};

export type AssociatedBooking = {
  __typename?: 'AssociatedBooking';
  bookingId: Scalars['ID'];
  commissionFees: CommissionFees;
  commissionPlan: CommissionPlan;
  booking: Booking;
};

export type AvailabilityCalendar = {
  __typename?: 'AvailabilityCalendar';
  availabilityType?: Maybe<AvailabilityType>;
  dailyAllocations: Array<Maybe<DailyAllocationType>>;
};

export enum AvailabilityType {
  Exchange = 'exchange',
  Rental = 'rental'
}

export type AvailabilityTypeCriterion = {
  availabilityType: AvailabilityType;
};

export type AvailabilityTypeCriterionType = {
  __typename?: 'AvailabilityTypeCriterionType';
  availabilityType: AvailabilityType;
};

export type AvailableFromCriterion = {
  start: Scalars['LocalDate'];
  minimumConsecutiveNights: Scalars['Int'];
};

export type AvailableFromCriterionType = {
  __typename?: 'AvailableFromCriterionType';
  start: Scalars['LocalDate'];
  minimumConsecutiveNights: Scalars['Int'];
};

export type BalanceLog = {
  __typename?: 'BalanceLog';
  accountBalanceId: Scalars['ID'];
  tokenType: TokenType;
  logLines: Array<BalanceLogLine>;
};

export type BalanceLogLine = {
  __typename?: 'BalanceLogLine';
  transactionId: Scalars['ID'];
  date: Scalars['LocalDate'];
  status: TransactionStatus;
  name: Scalars['String'];
  description: Scalars['String'];
  amount: Scalars['Int'];
  balance: Scalars['Int'];
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountType?: Maybe<BankAccountType>;
  accountHolderName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
};

export enum BankAccountType {
  Checking = 'checking',
  Saving = 'saving'
}

export type BedMeasurement = {
  __typename?: 'BedMeasurement';
  bedSize: BedSize;
  width: Scalars['Int'];
  length: Scalars['Int'];
};

export enum BedSize {
  Crib = 'crib',
  RollawayExtra = 'rollaway_extra',
  ModernCot = 'modern_cot',
  Single = 'single',
  Twin = 'twin',
  StandardDouble = 'standard_double',
  Queen = 'queen',
  OlympicQueen = 'olympic_queen',
  King = 'king',
  SuperKing = 'super_king',
  GrandKing = 'grand_king'
}

export type Bedroom = {
  bedSizes?: Maybe<Array<Maybe<BedSize>>>;
};

export type BedroomType = {
  __typename?: 'BedroomType';
  bedSizes?: Maybe<Array<Maybe<BedSize>>>;
};

export enum BillingPeriod {
  Yearly = 'yearly'
}

export type BlockAvailabilityCommand = {
  listingId: Scalars['ID'];
  blockedDays?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
  reason: BlockReason;
};

export enum BlockReason {
  Manual = 'manual',
  Booked = 'booked',
  External = 'external'
}

export type BookedBy = {
  __typename?: 'BookedBy';
  userId: Scalars['String'];
  agencyId?: Maybe<Scalars['String']>;
  bookedByType?: Maybe<BookedByType>;
};

export enum BookedByType {
  Admin = 'admin',
  Reseller = 'reseller',
  Traveller = 'traveller'
}

export type Booking = {
  __typename?: 'Booking';
  tripId: Scalars['ID'];
  bookingId: Scalars['ID'];
  listingId: Scalars['ID'];
  status?: Maybe<BookingStatus>;
  bookingReference?: Maybe<Scalars['String']>;
  checkin?: Maybe<Scalars['LocalDate']>;
  checkout?: Maybe<Scalars['LocalDate']>;
  nights?: Maybe<Scalars['Int']>;
  numberOfGuests?: Maybe<Scalars['Int']>;
  fees: Fees;
  guest: Guest;
  summary: TripSummary;
  bookingTimestamp: Scalars['OffsetDateTime'];
  bookedBy?: Maybe<BookedBy>;
  originalFees?: Maybe<Fees>;
  priceModification?: Maybe<PriceModificationType>;
  confirmationFlow?: Maybe<BookingConfirmationFlow>;
  bookingSupplementsDetails?: Maybe<BookingSupplementsDetails>;
};

export type BookingCheckoutRequest = {
  bookingId: Scalars['String'];
  psp: Psp;
  redirects: Redirects;
};

export enum BookingConfirmationFlow {
  RequestToBook = 'request_to_book',
  InstantBook = 'instant_book'
}

export enum BookingStatus {
  Booked = 'booked',
  StayStarted = 'stay_started',
  Unconfirmed = 'unconfirmed',
  ConfirmationExpired = 'confirmation_expired',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  StayShortened = 'stay_shortened',
  StayCompleted = 'stay_completed',
  Closed = 'closed'
}

export type BookingSupplement = {
  supplementId: Scalars['String'];
  isMandatory: Scalars['Boolean'];
};

export type BookingSupplementType = {
  __typename?: 'BookingSupplementType';
  supplementId: Scalars['String'];
  iconName: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: CurrencyAmountType;
};

export type BookingSupplements = {
  supplements: Array<BookingSupplement>;
};

export type BookingSupplementsDetails = {
  __typename?: 'BookingSupplementsDetails';
  supplementDetails: Array<BookingSupplementType>;
};

export type BoundingBox = {
  __typename?: 'BoundingBox';
  swLat: Scalars['Float'];
  swLng: Scalars['Float'];
  neLat: Scalars['Float'];
  neLng: Scalars['Float'];
};

export type Brand = {
  __typename?: 'Brand';
  brandId: Scalars['ID'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
};

export type BrandBusinessOwnerInvitation = {
  __typename?: 'BrandBusinessOwnerInvitation';
  invitationId: Scalars['ID'];
  businessOwnerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status: BrandBusinessOwnerInvitationStatus;
  updateDate: Scalars['OffsetDateTime'];
};

export enum BrandBusinessOwnerInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type BrandsCriterion = {
  brandIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BrandsCriterionType = {
  __typename?: 'BrandsCriterionType';
  brandIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BusinessCustomer = {
  __typename?: 'BusinessCustomer';
  userId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumberType;
  photoUrl?: Maybe<Scalars['String']>;
  trips: Array<Trip>;
};

export type CalendarReference = {
  provider: Scalars['String'];
  reference: Scalars['String'];
};

export type CalendarReferenceType = {
  __typename?: 'CalendarReferenceType';
  provider: Scalars['String'];
  reference: Scalars['String'];
};

export type CancelCheckoutRequest = {
  sessionId: Scalars['ID'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  psp: Psp;
  sessionId: Scalars['String'];
};

export type CleaningFee = {
  __typename?: 'CleaningFee';
  amount: CurrencyAmountType;
  description?: Maybe<Scalars['String']>;
};

export type CleaningFeeInput = {
  amount: CurrencyAmount;
  description?: Maybe<Scalars['String']>;
};

export type CleaningService = {
  __typename?: 'CleaningService';
  perDayCleaningFee?: Maybe<CleaningFee>;
  perStayCleaningFee?: Maybe<CleaningFee>;
  paymentMode?: Maybe<PaymentMode>;
  isUpdated?: Maybe<Scalars['Boolean']>;
};

export type Clientele = {
  __typename?: 'Clientele';
  clienteleId: Scalars['ID'];
  businessId: Scalars['String'];
  customers: Array<BusinessCustomer>;
};

export type Commission = {
  __typename?: 'Commission';
  rate: Scalars['Float'];
};

export type CommissionFees = {
  __typename?: 'CommissionFees';
  commission: CurrencyAmountType;
  commissionRate: Scalars['Float'];
};

export enum CommissionModelType {
  FlatPercentage = 'flat_percentage'
}

export type CommissionPlan = {
  __typename?: 'CommissionPlan';
  commissionPlanId: Scalars['ID'];
  vendorId: Scalars['String'];
  commissionModelType: CommissionModelType;
  flatPercentageCommissionPlan?: Maybe<FlatPercentageCommissionPlan>;
};

export type ConnectLinkedinAccountCommand = {
  memberId: Scalars['ID'];
  authorizationCode: Scalars['String'];
};

export type ConnectSocialMediaAccountCommand = {
  memberId: Scalars['ID'];
  provider: Scalars['String'];
  reference: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  publicProfileUrl: Scalars['String'];
  publicProfilePicture?: Maybe<Scalars['String']>;
};

export type Consumer = {
  __typename?: 'Consumer';
  userId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumberType;
  photoUrl?: Maybe<Scalars['String']>;
};

export type ConversationToken = {
  identity: Scalars['String'];
  token: Scalars['String'];
  flokId: Scalars['String'];
};

export type ConversationTokenType = {
  __typename?: 'ConversationTokenType';
  identity: Scalars['String'];
  token: Scalars['String'];
  flokId: Scalars['String'];
};

export type CreateBookingRequest = {
  listingId: Scalars['String'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
  adults: Scalars['Int'];
  children: Scalars['Int'];
  infants: Scalars['Int'];
  onBehalfOf?: Maybe<OnBehalfOf>;
  acceptBookingUrl: Scalars['String'];
  declineBookingUrl: Scalars['String'];
  priceModification?: Maybe<PriceModification>;
  confirmationFlow?: Maybe<BookingConfirmationFlow>;
};

export type CreateBusinessCustomerRequest = {
  userId: Scalars['String'];
  businessId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
};

export type CreateHotelRoomCommand = {
  hotelId: Scalars['ID'];
  hotelRoomId: Scalars['String'];
  roomType: Scalars['String'];
  maximumOccupancy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type CreateStaffCommand = {
  staffId: Scalars['ID'];
  email: Scalars['String'];
  photoUrl: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumber;
};

export type CreateWishlistCommand = {
  wishlistId: Scalars['ID'];
  onBehalfOf?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Criteria = {
  metadata: CriteriaMetadata;
  paging: CriteriaPaging;
  regionCriterion?: Maybe<RegionCriterion>;
  locationCriterion?: Maybe<LocationCriterion>;
  datesCriterion?: Maybe<DatesCriterion>;
  availableFromCriterion?: Maybe<AvailableFromCriterion>;
  availabilityTypeCriterion?: Maybe<AvailabilityTypeCriterion>;
  guestCriterion?: Maybe<GuestCriterion>;
  listingFilter?: Maybe<ListingFilter>;
  amenitiesCriterion?: Maybe<AmenitiesCriterion>;
  priceRangeCriterion?: Maybe<PriceRangeCriterion>;
  roomsAndBedsCriterion?: Maybe<RoomsAndBedsCriterion>;
  brandsCriterion?: Maybe<BrandsCriterion>;
  assetTypesCriterion?: Maybe<AssetTypesCriterion>;
  minimumStayNightsCriterion?: Maybe<MinimumStayNightsCriterion>;
  styleCriterion?: Maybe<StyleCriterion>;
};

export type CriteriaMetadata = {
  screenSize: ScreenSize;
  includeComingSoon?: Maybe<Scalars['Boolean']>;
};

export type CriteriaMetadataType = {
  __typename?: 'CriteriaMetadataType';
  screenSize: ScreenSize;
  includeComingSoon?: Maybe<Scalars['Boolean']>;
};

export type CriteriaPaging = {
  offset: Scalars['Int'];
  size: Scalars['Int'];
};

export type CriteriaPagingType = {
  __typename?: 'CriteriaPagingType';
  offset: Scalars['Int'];
  size: Scalars['Int'];
};

export type CriteriaType = {
  __typename?: 'CriteriaType';
  metadata: CriteriaMetadataType;
  regionCriterion?: Maybe<RegionCriterionType>;
  locationCriterion?: Maybe<LocationCriterionType>;
  datesCriterion?: Maybe<DatesCriterionType>;
  availableFromCriterion?: Maybe<AvailableFromCriterionType>;
  availabilityTypeCriterion?: Maybe<AvailabilityTypeCriterionType>;
  guestCriterion?: Maybe<GuestCriterionType>;
  listingFilter?: Maybe<ListingFilterType>;
  amenitiesCriterion?: Maybe<AmenitiesCriterionType>;
  priceRangeCriterion?: Maybe<PriceRangeCriterionType>;
  roomsAndBedsCriterion?: Maybe<RoomsAndBedsCriterionType>;
  brandsCriterion?: Maybe<BrandsCriterionType>;
};

export type CurrencyAmount = {
  currency: Scalars['String'];
  amountMicros: Scalars['Long'];
};

export type CurrencyAmountType = {
  __typename?: 'CurrencyAmountType';
  currency: Scalars['String'];
  amountMicros: Scalars['Long'];
};

export type DailyAllocationType = {
  __typename?: 'DailyAllocationType';
  day: Scalars['LocalDate'];
  allocated?: Maybe<Scalars['Int']>;
  consumed?: Maybe<Scalars['Int']>;
};

export type DailyAvailability = {
  day: Scalars['LocalDate'];
  allocated: Scalars['Int'];
};

export type DateRange = {
  __typename?: 'DateRange';
  start: Scalars['LocalDate'];
  end: Scalars['LocalDate'];
};

export type DatesCriterion = {
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
};

export type DatesCriterionType = {
  __typename?: 'DatesCriterionType';
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
};

export type DecidingFactor = {
  __typename?: 'DecidingFactor';
  enabled: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
};

export type DecidingFactors = {
  __typename?: 'DecidingFactors';
  primaryDecidingFactors: Array<DecidingFactor>;
};

export type DeclineBookingRequest = {
  bookingId: Scalars['String'];
  reason: Scalars['String'];
  returnUrl: Scalars['String'];
};

export type DisconnectSocialMediaAccountCommand = {
  memberId: Scalars['ID'];
  provider: Scalars['String'];
};

export type Earnings = {
  __typename?: 'Earnings';
  tokenAmount?: Maybe<TokenAmountType>;
  currencyAmount?: Maybe<CurrencyAmountType>;
};

export type EarningsCalendar = {
  __typename?: 'EarningsCalendar';
  perDayEarnings: Array<PerDayEarning>;
};

export type ExchangeRateEntry = {
  __typename?: 'ExchangeRateEntry';
  currency?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
};

export type ExpireWishlistCommand = {
  wishlistId: Scalars['ID'];
};

export type Favourite = {
  __typename?: 'Favourite';
  reference?: Maybe<FavouriteReference>;
  addedAsFavouriteByOthers: Scalars['Int'];
};

export type FavouriteReference = {
  __typename?: 'FavouriteReference';
  type: FavouriteType;
  referenceId: Scalars['String'];
};

export enum FavouriteType {
  Listing = 'listing'
}

export type Fees = {
  __typename?: 'Fees';
  tokenType: TokenType;
  tokenFee: TokenAmountType;
  exchangeFee: CurrencyAmountType;
  bookingFee: CurrencyAmountType;
};

export type FeesCalendar = {
  __typename?: 'FeesCalendar';
  perDayFees: Array<PerDayFees>;
};

export type FlatPercentageCommissionPlan = {
  __typename?: 'FlatPercentageCommissionPlan';
  commissionPlanId: Scalars['ID'];
  flatPercentage: Scalars['Float'];
};

export type GeoLocation = {
  __typename?: 'GeoLocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Guest = {
  __typename?: 'Guest';
  guestId?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type GuestCriterion = {
  adults?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  infants?: Maybe<Scalars['Int']>;
};

export type GuestCriterionType = {
  __typename?: 'GuestCriterionType';
  adults?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  infants?: Maybe<Scalars['Int']>;
};

export type HighlightedFeature = {
  iconName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type HighlightedFeatures = {
  __typename?: 'HighlightedFeatures';
  listingId: Scalars['ID'];
  features: Array<ListingHighlightedFeature>;
};

export type HistoricEarningRecord = {
  __typename?: 'HistoricEarningRecord';
  earnedTotal: TokenAmountType;
  earnedDelayed: TokenAmountType;
  earnedInstantly: TokenAmountType;
  percentageInstantly: Scalars['Int'];
};

export type Host = {
  __typename?: 'Host';
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type Hotel = {
  __typename?: 'Hotel';
  hotelId: Scalars['ID'];
  status: HotelStatus;
  general?: Maybe<HotelGeneral>;
  amenities?: Maybe<HotelAmenities>;
  houseRules?: Maybe<HotelHouseRules>;
  goodToKnow?: Maybe<HotelGoodToKnow>;
  location?: Maybe<HotelLocation>;
  imagery: HotelImagery;
  owner?: Maybe<AssetOwner>;
  rooms: Array<HotelRoom>;
  suites: Array<HotelSuite>;
};

export type HotelAmenities = {
  __typename?: 'HotelAmenities';
  hotelId: Scalars['ID'];
  amenityIds?: Maybe<Array<Scalars['String']>>;
};

export type HotelGeneral = {
  __typename?: 'HotelGeneral';
  hotelId: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['Int']>;
  averageDailyRate?: Maybe<CurrencyAmountType>;
  externalListingUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type HotelGoodToKnow = {
  __typename?: 'HotelGoodToKnow';
  hotelId: Scalars['ID'];
  description: Scalars['String'];
};

export type HotelHouseRules = {
  __typename?: 'HotelHouseRules';
  hotelId: Scalars['ID'];
  minimumStay: Scalars['Int'];
  maximumStay: Scalars['Int'];
  earliestCheckin: Scalars['LocalTime'];
  latestCheckin: Scalars['LocalTime'];
  allowsChildren: Scalars['Boolean'];
};

export type HotelImagery = {
  __typename?: 'HotelImagery';
  hotelId: Scalars['ID'];
  photos?: Maybe<Array<Maybe<AssetPhotoType>>>;
};

export type HotelLocation = {
  __typename?: 'HotelLocation';
  hotelId: Scalars['ID'];
  address?: Maybe<AddressType>;
  geoLocation?: Maybe<GeoLocation>;
};

export type HotelPhotoSignUrlRequest = {
  hotelId: Scalars['String'];
  filename: Scalars['String'];
};

export type HotelRoom = {
  __typename?: 'HotelRoom';
  hotelRoomId: Scalars['ID'];
  roomType: Scalars['String'];
  maximumOccupancy: Scalars['Int'];
  packageDeal?: Maybe<PackageDeal>;
  description?: Maybe<Scalars['String']>;
  imagery: HotelRoomImagery;
};

export type HotelRoomImagery = {
  __typename?: 'HotelRoomImagery';
  photos?: Maybe<Array<Maybe<AssetPhotoType>>>;
};

export type HotelRoomPhotoSignUrlRequest = {
  hotelId: Scalars['String'];
  hotelRoomId: Scalars['String'];
  filename: Scalars['String'];
};

export enum HotelStatus {
  OnboardingInProgress = 'onboarding_in_progress',
  ReadyForListing = 'ready_for_listing'
}

export type HotelSuite = {
  __typename?: 'HotelSuite';
  hotelSuiteId: Scalars['ID'];
  suiteType: HotelSuiteType;
  maximumOccupancy: Scalars['Int'];
  packageDeal?: Maybe<PackageDeal>;
  description?: Maybe<Scalars['String']>;
  imagery: HotelSuiteImagery;
  rooms: HotelSuiteRooms;
};

export type HotelSuiteImagery = {
  __typename?: 'HotelSuiteImagery';
  photos?: Maybe<Array<Maybe<AssetPhotoType>>>;
};

export type HotelSuiteRooms = {
  __typename?: 'HotelSuiteRooms';
  bedrooms: Array<Maybe<BedroomType>>;
  areas: Array<Maybe<AreaType>>;
};

export enum HotelSuiteType {
  Suite = 'suite',
  JuniorSuite = 'junior_suite',
  ResidentialSuite = 'residential_suite',
  PresidentialSuite = 'presidential_suite'
}

export enum HttpMethod {
  Options = 'OPTIONS',
  Get = 'GET',
  Head = 'HEAD',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Trace = 'TRACE',
  Connect = 'CONNECT',
  Patch = 'PATCH'
}

export type Inbox = {
  __typename?: 'Inbox';
  messageThreads: Array<MessageThread>;
};

export type InputAcceptedResponse = {
  __typename?: 'InputAcceptedResponse';
  id: Scalars['ID'];
};

export type InputGeoLocation = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type InviteStaffMemberCommand = {
  staffId: Scalars['ID'];
  invitationId: Scalars['String'];
  returnUrl: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  host: Scalars['Boolean'];
  manager: Scalars['Boolean'];
};

export type LatestMessage = {
  __typename?: 'LatestMessage';
  sender: Participant;
  text?: Maybe<Scalars['String']>;
  created: Scalars['OffsetDateTime'];
};

export type LinkExternalCalendarCommand = {
  listingId: Scalars['ID'];
  name: Scalars['String'];
  externalUrl: Scalars['String'];
};

export type LinkedCalendar = {
  __typename?: 'LinkedCalendar';
  listingId: Scalars['ID'];
  name: Scalars['String'];
  externalUrl: Scalars['String'];
};

export type Listing = {
  __typename?: 'Listing';
  listingId: Scalars['ID'];
  status: ListingStatus;
  asset: Asset;
  obfuscatedLocation: ObfuscatedLocation;
  summary: ListingSummary;
  availabilityCalendar: AvailabilityCalendar;
  checkin?: Maybe<Scalars['LocalDate']>;
  checkout?: Maybe<Scalars['LocalDate']>;
  fees?: Maybe<Fees>;
  priceRange?: Maybe<PriceRange>;
  commission?: Maybe<Commission>;
  operator?: Maybe<Operator>;
  supplements: ListingSupplements;
  cleaningService: CleaningService;
  stayDurationConditions: StayDurationConditions;
  allowInstantBook: Scalars['Boolean'];
};

export type ListingDefinition = {
  __typename?: 'ListingDefinition';
  listingId: Scalars['ID'];
  listingManagementId: Scalars['String'];
  status: ListingStatus;
  asset: Asset;
  obfuscatedLocation: ObfuscatedLocation;
};

export type ListingFilter = {
  listingId: Scalars['String'];
  exclude: Scalars['Boolean'];
};

export type ListingFilterType = {
  __typename?: 'ListingFilterType';
  listingId: Scalars['String'];
  exclude: Scalars['Boolean'];
};

export type ListingHighlightedFeature = {
  __typename?: 'ListingHighlightedFeature';
  iconName?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ListingHouseRule = {
  __typename?: 'ListingHouseRule';
  enabled: Scalars['Boolean'];
  icon?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
};

export type ListingHouseRules = {
  __typename?: 'ListingHouseRules';
  rules: Array<ListingHouseRule>;
  otherRules?: Maybe<Scalars['String']>;
};

export type ListingId = {
  __typename?: 'ListingId';
  listingId: Scalars['ID'];
  listingManagementId: Scalars['String'];
};

export type ListingOnboardingStatus = {
  __typename?: 'ListingOnboardingStatus';
  membershipActive: Scalars['Boolean'];
  memberOnboardingCompleted: Scalars['Boolean'];
  assetOnboardingCompleted: Scalars['Boolean'];
  canInviteOwner: Scalars['Boolean'];
  ownerAssigned: Scalars['Boolean'];
  hostingTeamAssigned: Scalars['Boolean'];
  priceAssigned: Scalars['Boolean'];
  meetsMinimumRequiredAvailability: Scalars['Boolean'];
  readyForPublishing: Scalars['Boolean'];
  completed: Scalars['Boolean'];
};

export enum ListingStatus {
  Draft = 'draft',
  Published = 'published'
}

export type ListingSummary = {
  __typename?: 'ListingSummary';
  photos: Array<AssetPhotoType>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  subtitle?: Maybe<Scalars['String']>;
  highlightedFeatures: HighlightedFeatures;
  highlightedAmenities: Array<Scalars['String']>;
  houseRules: ListingHouseRules;
  decidingFactors: DecidingFactors;
};

export type ListingSupplement = {
  id: Scalars['ID'];
  iconName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sectionName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: CurrencyAmount;
};

export type ListingSupplementEntry = {
  __typename?: 'ListingSupplementEntry';
  id: Scalars['ID'];
  iconName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  sectionName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  price: CurrencyAmountType;
};

export type ListingSupplements = {
  __typename?: 'ListingSupplements';
  listingId: Scalars['ID'];
  mandatorySupplements?: Maybe<Array<ListingSupplementEntry>>;
  optionalSupplements?: Maybe<Array<ListingSupplementEntry>>;
};



export type Location = {
  __typename?: 'Location';
  address: AddressType;
  geoLocation: GeoLocation;
};

export type LocationCriterion = {
  qry?: Maybe<Scalars['String']>;
  swLat: Scalars['Float'];
  swLng: Scalars['Float'];
  neLat: Scalars['Float'];
  neLng: Scalars['Float'];
};

export type LocationCriterionType = {
  __typename?: 'LocationCriterionType';
  qry?: Maybe<Scalars['String']>;
  swLat: Scalars['Float'];
  swLng: Scalars['Float'];
  neLat: Scalars['Float'];
  neLng: Scalars['Float'];
};


export type Member = {
  __typename?: 'Member';
  memberId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: PhoneNumberType;
  photoUrl?: Maybe<Scalars['String']>;
};

export type MemberApplication = {
  __typename?: 'MemberApplication';
  memberId: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: PhoneNumberType;
  calendarReference?: Maybe<CalendarReferenceType>;
  address?: Maybe<AddressType>;
  geoLocation?: Maybe<GeoLocation>;
  preliminaryPropertyDetails?: Maybe<PreliminaryPropertyDetails>;
  preliminaryHotelDetails?: Maybe<PreliminaryHotelDetails>;
  connectedSocialAccounts?: Maybe<Array<Maybe<SocialReferenceType>>>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  status: MemberApplicationStatus;
};

export enum MemberApplicationStatus {
  None = 'none',
  Submitted = 'submitted',
  Preapproved = 'preapproved',
  Rejected = 'rejected',
  Approved = 'approved'
}

export enum MemberApplicationType {
  Full = 'full',
  Invitation = 'invitation'
}

export type MemberOnboardingStatus = {
  __typename?: 'MemberOnboardingStatus';
  completed: Scalars['Boolean'];
  assetBeingOnboarded: Asset;
};

export type Membership = {
  __typename?: 'Membership';
  membershipPlan: MembershipPlan;
  start: Scalars['LocalDate'];
  end: Scalars['LocalDate'];
};

export type MembershipPlan = {
  __typename?: 'MembershipPlan';
  id: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type MembershipSubscription = {
  __typename?: 'MembershipSubscription';
  membershipPlan: MembershipPlan;
  recurringPrice: CurrencyAmountType;
  billingPeriod: BillingPeriod;
};

export type MessageThread = {
  __typename?: 'MessageThread';
  messageThreadId: Scalars['ID'];
  type: MessageThreadType;
  subject?: Maybe<Subject>;
  participants: Array<Participant>;
  latestMessage?: Maybe<LatestMessage>;
};

export enum MessageThreadType {
  Conversation = 'conversation'
}

export type MinimumRequiredAvailability = {
  __typename?: 'MinimumRequiredAvailability';
  required: Array<SeasonalMinimum>;
};

export type MinimumStayNightsCriterion = {
  minimumStayNights?: Maybe<Scalars['Int']>;
};

export type MinimumStayNightsCriterionType = {
  __typename?: 'MinimumStayNightsCriterionType';
  minimumStayNights?: Maybe<Scalars['Int']>;
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  signupConsumer: InputAcceptedResponse;
  submitMemberApplication: InputAcceptedResponse;
  connectSocialMediaAccount: InputAcceptedResponse;
  disconnectSocialMediaAccount: InputAcceptedResponse;
  connectLinkedinAccount: InputAcceptedResponse;
  applicationPhotoSignUrl: PhotoUrlSignature;
  addApplicationPhotos: InputAcceptedResponse;
  acceptConsumerTermsAndConditions: InputAcceptedResponse;
  startPropertyOnboarding: InputAcceptedResponse;
  updatePropertyGeneral: InputAcceptedResponse;
  updatePropertyAmenities: InputAcceptedResponse;
  updatePropertyHouseRules: InputAcceptedResponse;
  updatePropertyGoodToKnow: InputAcceptedResponse;
  updatePropertyLocation: InputAcceptedResponse;
  updatePropertyPhotos: InputAcceptedResponse;
  propertyPhotoSignUrl: PhotoUrlSignature;
  updateHotelGeneral: InputAcceptedResponse;
  updateHotelAmenities: InputAcceptedResponse;
  updateHotelHouseRules: InputAcceptedResponse;
  updateHotelGoodToKnow: InputAcceptedResponse;
  updateHotelLocation: InputAcceptedResponse;
  updateHotelPhotos: InputAcceptedResponse;
  createHotelRoom: InputAcceptedResponse;
  updateHotelRoom: InputAcceptedResponse;
  updateHotelRoomPhotos: InputAcceptedResponse;
  hotelPhotoSignUrl: PhotoUrlSignature;
  hotelRoomPhotoSignUrl: PhotoUrlSignature;
  assignStaffToHotel: InputAcceptedResponse;
  /** @deprecated Field no longer supported */
  acceptPropertyOwnerInvitation: InputAcceptedResponse;
  acceptAssetOwnerInvitation: InputAcceptedResponse;
  acceptBrandBusinessOwnerInvitation: InputAcceptedResponse;
  assignStaffToBrand: InputAcceptedResponse;
  acceptPmcBusinessOwnerInvitation: InputAcceptedResponse;
  assignStaffToPmc: InputAcceptedResponse;
  acceptTravelAgentBusinessOwnerInvitation: InputAcceptedResponse;
  assignStaffToTravelAgent: InputAcceptedResponse;
  createStaff: InputAcceptedResponse;
  updateStaff: InputAcceptedResponse;
  staffPhotoSignUrl: PhotoUrlSignature;
  inviteStaffMember: InputAcceptedResponse;
  removeMemberFromStaff: InputAcceptedResponse;
  acceptStaffMemberInvitation: InputAcceptedResponse;
  updateBookingSettings: InputAcceptedResponse;
  updateListingSupplements: InputAcceptedResponse;
  updateListingCleaningService: InputAcceptedResponse;
  addToFavourites: InputAcceptedResponse;
  removeFromFavourites: InputAcceptedResponse;
  createWishlist: InputAcceptedResponse;
  updateWishlist: InputAcceptedResponse;
  expireWishlist: InputAcceptedResponse;
  addWishToWishlist: InputAcceptedResponse;
  removeWishFromWishlist: InputAcceptedResponse;
  allocateInitialMonthlyAvailability: InputAcceptedResponse;
  allocateMonthlyAvailability: InputAcceptedResponse;
  allocateDailyAvailability: InputAcceptedResponse;
  blockAvailability: InputAcceptedResponse;
  unblockAvailability: InputAcceptedResponse;
  linkExternalCalendar: InputAcceptedResponse;
  unlinkExternalCalendar: InputAcceptedResponse;
  publishListing: InputAcceptedResponse;
  profilePhotoSignUrl: PhotoUrlSignature;
  updatePersonalInformation: InputAcceptedResponse;
  updatePersonalPhoto: InputAcceptedResponse;
  updatePersonalPhoneNumber: InputAcceptedResponse;
  updatePersonalAddress: InputAcceptedResponse;
  payMembershipSubscription: CheckoutSession;
  assignPersonalStaffToMember: InputAcceptedResponse;
  referMember: InputAcceptedResponse;
  updateBankAccount: InputAcceptedResponse;
  createBooking: InputAcceptedResponse;
  createBusinessCustomer: InputAcceptedResponse;
  addBusinessCustomerToClientele: InputAcceptedResponse;
  submitBookingEnquiry: InputAcceptedResponse;
  checkout: CheckoutSession;
  cancelCheckout: InputAcceptedResponse;
  acceptBooking: InputAcceptedResponse;
  declineBooking: InputAcceptedResponse;
  sendConversationMessage: InputAcceptedResponse;
};


export type MutationRootSignupConsumerArgs = {
  input: SignupConsumerRequest;
};


export type MutationRootSubmitMemberApplicationArgs = {
  input: SubmitMemberApplicationCommand;
};


export type MutationRootConnectSocialMediaAccountArgs = {
  input: ConnectSocialMediaAccountCommand;
};


export type MutationRootDisconnectSocialMediaAccountArgs = {
  input: DisconnectSocialMediaAccountCommand;
};


export type MutationRootConnectLinkedinAccountArgs = {
  input: ConnectLinkedinAccountCommand;
};


export type MutationRootApplicationPhotoSignUrlArgs = {
  input: ApplicationPhotoSignUrlRequest;
};


export type MutationRootAddApplicationPhotosArgs = {
  input: AddApplicationPhotosCommand;
};


export type MutationRootAcceptConsumerTermsAndConditionsArgs = {
  input: AcceptConsumerTermsAndConditionsCommand;
};


export type MutationRootStartPropertyOnboardingArgs = {
  input: StartPropertyOnboardingCommand;
};


export type MutationRootUpdatePropertyGeneralArgs = {
  input: UpdatePropertyGeneralCommand;
};


export type MutationRootUpdatePropertyAmenitiesArgs = {
  input: UpdatePropertyAmenitiesCommand;
};


export type MutationRootUpdatePropertyHouseRulesArgs = {
  input: UpdatePropertyHouseRulesCommand;
};


export type MutationRootUpdatePropertyGoodToKnowArgs = {
  input: UpdatePropertyGoodToKnowCommand;
};


export type MutationRootUpdatePropertyLocationArgs = {
  input: UpdatePropertyLocationCommand;
};


export type MutationRootUpdatePropertyPhotosArgs = {
  input: UpdatePropertyPhotosCommand;
};


export type MutationRootPropertyPhotoSignUrlArgs = {
  input: PropertyPhotoSignUrlRequest;
};


export type MutationRootUpdateHotelGeneralArgs = {
  input: UpdateHotelGeneralCommand;
};


export type MutationRootUpdateHotelAmenitiesArgs = {
  input: UpdateHotelAmenitiesCommand;
};


export type MutationRootUpdateHotelHouseRulesArgs = {
  input: UpdateHotelHouseRulesCommand;
};


export type MutationRootUpdateHotelGoodToKnowArgs = {
  input: UpdateHotelGoodToKnowCommand;
};


export type MutationRootUpdateHotelLocationArgs = {
  input: UpdateHotelLocationCommand;
};


export type MutationRootUpdateHotelPhotosArgs = {
  input: UpdateHotelPhotosCommand;
};


export type MutationRootCreateHotelRoomArgs = {
  input: CreateHotelRoomCommand;
};


export type MutationRootUpdateHotelRoomArgs = {
  input: UpdateHotelRoomCommand;
};


export type MutationRootUpdateHotelRoomPhotosArgs = {
  input: UpdateHotelRoomPhotosCommand;
};


export type MutationRootHotelPhotoSignUrlArgs = {
  input: HotelPhotoSignUrlRequest;
};


export type MutationRootHotelRoomPhotoSignUrlArgs = {
  input: HotelRoomPhotoSignUrlRequest;
};


export type MutationRootAssignStaffToHotelArgs = {
  input: AssignStaffToHotelCommand;
};


export type MutationRootAcceptPropertyOwnerInvitationArgs = {
  input: AcceptPropertyOwnerInvitationCommand;
};


export type MutationRootAcceptAssetOwnerInvitationArgs = {
  input: AcceptAssetOwnerInvitationCommand;
};


export type MutationRootAcceptBrandBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationRootAssignStaffToBrandArgs = {
  input: AssignStaffToBrandCommand;
};


export type MutationRootAcceptPmcBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationRootAssignStaffToPmcArgs = {
  input: AssignStaffToPmcCommand;
};


export type MutationRootAcceptTravelAgentBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type MutationRootAssignStaffToTravelAgentArgs = {
  input: AssignStaffToTravelAgentCommand;
};


export type MutationRootCreateStaffArgs = {
  input: CreateStaffCommand;
};


export type MutationRootUpdateStaffArgs = {
  input: UpdateStaffCommand;
};


export type MutationRootStaffPhotoSignUrlArgs = {
  input: StaffPhotoSignUrlRequest;
};


export type MutationRootInviteStaffMemberArgs = {
  input?: Maybe<InviteStaffMemberCommand>;
};


export type MutationRootRemoveMemberFromStaffArgs = {
  input?: Maybe<RemoveMemberFromStaffCommand>;
};


export type MutationRootAcceptStaffMemberInvitationArgs = {
  input: AcceptStaffMemberInvitationCommand;
};


export type MutationRootUpdateBookingSettingsArgs = {
  input: UpdateBookingSettingsCommand;
};


export type MutationRootUpdateListingSupplementsArgs = {
  input: UpdateListingSupplementsCommand;
};


export type MutationRootUpdateListingCleaningServiceArgs = {
  input: UpdateListingCleaningServiceCommand;
};


export type MutationRootAddToFavouritesArgs = {
  input: AddToFavouritesCommand;
};


export type MutationRootRemoveFromFavouritesArgs = {
  input: RemoveFromFavouritesCommand;
};


export type MutationRootCreateWishlistArgs = {
  input: CreateWishlistCommand;
};


export type MutationRootUpdateWishlistArgs = {
  input: UpdateWishlistCommand;
};


export type MutationRootExpireWishlistArgs = {
  input: ExpireWishlistCommand;
};


export type MutationRootAddWishToWishlistArgs = {
  input: AddWishToWishlistCommand;
};


export type MutationRootRemoveWishFromWishlistArgs = {
  input: RemoveWishFromWishlistCommand;
};


export type MutationRootAllocateInitialMonthlyAvailabilityArgs = {
  input: AllocateInitialMonthlyAvailabilityRequest;
};


export type MutationRootAllocateMonthlyAvailabilityArgs = {
  input: AllocateMonthlyAvailabilityCommand;
};


export type MutationRootAllocateDailyAvailabilityArgs = {
  input: AllocateDailyAvailabilityCommand;
};


export type MutationRootBlockAvailabilityArgs = {
  input: BlockAvailabilityCommand;
};


export type MutationRootUnblockAvailabilityArgs = {
  input: UnblockAvailabilityCommand;
};


export type MutationRootLinkExternalCalendarArgs = {
  input: LinkExternalCalendarCommand;
};


export type MutationRootUnlinkExternalCalendarArgs = {
  input: UnlinkExternalCalendarCommand;
};


export type MutationRootPublishListingArgs = {
  input: PublishListingCommand;
};


export type MutationRootProfilePhotoSignUrlArgs = {
  input: ProfilePhotoSignUrlRequest;
};


export type MutationRootUpdatePersonalInformationArgs = {
  input: UpdatePersonalInformationCommand;
};


export type MutationRootUpdatePersonalPhotoArgs = {
  input: UpdatePersonalPhotoCommand;
};


export type MutationRootUpdatePersonalPhoneNumberArgs = {
  input: UpdatePersonalPhoneNumberCommand;
};


export type MutationRootUpdatePersonalAddressArgs = {
  input: UpdatePersonalAddressCommand;
};


export type MutationRootPayMembershipSubscriptionArgs = {
  input: PayMembershipSubscriptionRequest;
};


export type MutationRootAssignPersonalStaffToMemberArgs = {
  input: AssignPersonalStaffToMemberCommand;
};


export type MutationRootReferMemberArgs = {
  input: ReferMemberCommand;
};


export type MutationRootUpdateBankAccountArgs = {
  input: UpdateBankAccountCommand;
};


export type MutationRootCreateBookingArgs = {
  input: CreateBookingRequest;
};


export type MutationRootCreateBusinessCustomerArgs = {
  input: CreateBusinessCustomerRequest;
};


export type MutationRootAddBusinessCustomerToClienteleArgs = {
  input: AddBusinessCustomerToClienteleCommand;
};


export type MutationRootSubmitBookingEnquiryArgs = {
  input: SubmitBookingEnquiryRequest;
};


export type MutationRootCheckoutArgs = {
  input: BookingCheckoutRequest;
};


export type MutationRootCancelCheckoutArgs = {
  input: CancelCheckoutRequest;
};


export type MutationRootAcceptBookingArgs = {
  input: AcceptBookingRequest;
};


export type MutationRootDeclineBookingArgs = {
  input: DeclineBookingRequest;
};


export type MutationRootSendConversationMessageArgs = {
  input: SendConversationMessageRequest;
};

export type ObfuscatedLocation = {
  __typename?: 'ObfuscatedLocation';
  location: GeoLocation;
  range: Scalars['Int'];
};


export type OnBehalfOf = {
  identityId?: Maybe<Scalars['String']>;
  externalReference?: Maybe<Scalars['String']>;
};

export type Operator = {
  __typename?: 'Operator';
  operatorId: Scalars['ID'];
  name: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
};

export type PackageDeal = {
  __typename?: 'PackageDeal';
  text: Scalars['String'];
  helpText: Scalars['String'];
  showCurrency: Scalars['Boolean'];
};

export type Participant = {
  __typename?: 'Participant';
  participantId: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  chatServerIdentity?: Maybe<Scalars['String']>;
};

export type PayMembershipSubscriptionRequest = {
  membershipPlanId: Scalars['String'];
  promotionalCodeId?: Maybe<Scalars['String']>;
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};

export type PayRescheduleBookingRequest = {
  bookingRequest: RescheduleBookingRequest;
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};

export type PayStayExtensionRequest = {
  bookingRequest: StayExtensionRequest;
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};

export type PayTopUpRequest = {
  amount: Scalars['Long'];
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};

export enum PaymentMode {
  Cash = 'cash',
  Voucher = 'voucher'
}

export type PerDayEarning = {
  __typename?: 'PerDayEarning';
  day: Scalars['LocalDate'];
  earnings: Earnings;
  history: Array<HistoricEarningRecord>;
  percentageInstantly: Scalars['Int'];
};

export type PerDayFees = {
  __typename?: 'PerDayFees';
  day: Scalars['LocalDate'];
  fees: Fees;
};


export type PersonalProfile = {
  __typename?: 'PersonalProfile';
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumberType;
};

export type PhoneNumber = {
  countryCode: Scalars['String'];
  nationalNumber: Scalars['String'];
};

export type PhoneNumberType = {
  __typename?: 'PhoneNumberType';
  countryCode: Scalars['String'];
  nationalNumber: Scalars['String'];
};

export type PhotoUrlSignature = {
  __typename?: 'PhotoUrlSignature';
  httpMethod: HttpMethod;
  contentType: Scalars['String'];
  signedUrl: Scalars['String'];
  url: Scalars['String'];
};

export type Pmc = {
  __typename?: 'Pmc';
  pmcId: Scalars['ID'];
  name: Scalars['String'];
};

export type PmcBusinessOwnerInvitation = {
  __typename?: 'PmcBusinessOwnerInvitation';
  invitationId: Scalars['ID'];
  businessOwnerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status: PmcBusinessOwnerInvitationStatus;
  updateDate: Scalars['OffsetDateTime'];
};

export enum PmcBusinessOwnerInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type PreliminaryHotelDetails = {
  __typename?: 'PreliminaryHotelDetails';
  hotelName: Scalars['String'];
  website: Scalars['String'];
  stars: Scalars['Int'];
  averageDailyRate: CurrencyAmountType;
};

export type PreliminaryPropertyDetails = {
  __typename?: 'PreliminaryPropertyDetails';
  propertyName: Scalars['String'];
  bedrooms: Scalars['Int'];
  bathrooms: Scalars['Int'];
  sleeps: Scalars['Int'];
  approximateValue: CurrencyAmountType;
};

export type PriceModification = {
  adjustedBookingFee?: Maybe<CurrencyAmount>;
};

export type PriceModificationType = {
  __typename?: 'PriceModificationType';
  adjustedBookingFee?: Maybe<CurrencyAmountType>;
};

export type PriceRange = {
  __typename?: 'PriceRange';
  lowest: Fees;
  highest: Fees;
};

export type PriceRangeCriterion = {
  minimum?: Maybe<CurrencyAmount>;
  maximum?: Maybe<CurrencyAmount>;
};

export type PriceRangeCriterionType = {
  __typename?: 'PriceRangeCriterionType';
  minimum?: Maybe<CurrencyAmountType>;
  maximum?: Maybe<CurrencyAmountType>;
};

export type ProfilePhotoSignUrlRequest = {
  filename: Scalars['String'];
};

export type Property = {
  __typename?: 'Property';
  propertyId: Scalars['ID'];
  status: PropertyStatus;
  general?: Maybe<PropertyGeneral>;
  amenities?: Maybe<PropertyAmenities>;
  houseRules?: Maybe<PropertyHouseRules>;
  goodToKnow?: Maybe<PropertyGoodToKnow>;
  location?: Maybe<PropertyLocation>;
  imagery: PropertyImagery;
  created: Scalars['OffsetDateTime'];
  owner?: Maybe<AssetOwner>;
};

export type PropertyAmenities = {
  __typename?: 'PropertyAmenities';
  propertyId: Scalars['ID'];
  amenityIds?: Maybe<Array<Scalars['String']>>;
};

export type PropertyGeneral = {
  __typename?: 'PropertyGeneral';
  propertyId: Scalars['ID'];
  propertyStyle?: Maybe<PropertyStyle>;
  propertyType?: Maybe<PropertyType>;
  approximateValue?: Maybe<CurrencyAmountType>;
  bedrooms?: Maybe<Array<Maybe<BedroomType>>>;
  bathrooms?: Maybe<Scalars['Int']>;
  sleeps?: Maybe<Scalars['Int']>;
  externalListingUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type PropertyGoodToKnow = {
  __typename?: 'PropertyGoodToKnow';
  propertyId: Scalars['ID'];
  description: Scalars['String'];
};

export type PropertyHouseRules = {
  __typename?: 'PropertyHouseRules';
  propertyId: Scalars['ID'];
  allowsInfants: Scalars['Boolean'];
  allowsChildren: Scalars['Boolean'];
  allowsEvents: Scalars['Boolean'];
  allowsPets: Scalars['Boolean'];
  allowsSmoking: Scalars['Boolean'];
};

export type PropertyImagery = {
  __typename?: 'PropertyImagery';
  propertyId: Scalars['ID'];
  photos?: Maybe<Array<Maybe<AssetPhotoType>>>;
};

export type PropertyLocation = {
  __typename?: 'PropertyLocation';
  propertyId: Scalars['ID'];
  address?: Maybe<AddressType>;
  geoLocation?: Maybe<GeoLocation>;
};

export enum PropertyOwnerInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type PropertyOwnerInvitationSummary = {
  __typename?: 'PropertyOwnerInvitationSummary';
  propertyId: Scalars['ID'];
  invitationId: Scalars['String'];
  status: PropertyOwnerInvitationStatus;
};

export type PropertyPhotoSignUrlRequest = {
  propertyId: Scalars['String'];
  filename: Scalars['String'];
};

export enum PropertyStatus {
  OnboardingInProgress = 'onboarding_in_progress',
  ReadyForListing = 'ready_for_listing',
  Archived = 'archived'
}

export enum PropertyStyle {
  CityPad = 'city_pad',
  ByTheSea = 'by_the_sea',
  SkiChalet = 'ski_chalet',
  LeafySuburbs = 'leafy_suburbs',
  RuralRetreat = 'rural_retreat',
  MountainView = 'mountain_view',
  OnTheRiver = 'on_the_river',
  SafariLodge = 'safari_lodge'
}

export enum PropertyType {
  Villa = 'villa',
  Yacht = 'yacht',
  MainHome = 'main_home',
  Apartment = 'apartment',
  Chalet = 'chalet',
  BedAndBreakfast = 'bed_and_breakfast',
  Cabin = 'cabin',
  Cottage = 'cottage',
  GuestSuite = 'guest_suite',
  Guesthouse = 'guesthouse',
  Resort = 'resort',
  ServicedApartment = 'serviced_apartment'
}

export enum Psp {
  Stripe = 'stripe',
  Backoffice = 'backoffice',
  Mock = 'mock'
}

export type PublicListing = {
  __typename?: 'PublicListing';
  listingCode: Scalars['String'];
  name: Scalars['String'];
  publicListingEntries?: Maybe<Array<PublicListingEntry>>;
};

export type PublicListingEntry = {
  __typename?: 'PublicListingEntry';
  listingId: Scalars['String'];
  obfuscatedLocation: ObfuscatedLocation;
  photos: Array<AssetPhotoType>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  decidingFactors: DecidingFactors;
};

export type PublicWish = {
  __typename?: 'PublicWish';
  wishId: Scalars['ID'];
  searchListing: SearchListing;
};

export type PublicWishlist = {
  __typename?: 'PublicWishlist';
  wishlistId: Scalars['ID'];
  name: Scalars['String'];
  wishes: Array<PublicWish>;
};

export type PublishListingCommand = {
  listingId: Scalars['ID'];
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  /** @deprecated Field no longer supported */
  propertyOwnerInvitationSummary?: Maybe<PropertyOwnerInvitationSummary>;
  assetOwnerInvitationSummary?: Maybe<AssetOwnerInvitationSummary>;
  /** @deprecated Field no longer supported */
  staffMemberInvitationSummary?: Maybe<StaffMemberInvitationSummary>;
  supportChat?: Maybe<SupportChat>;
  exchangeAuthCode: AccessToken;
  activeMemberApplication?: Maybe<MemberApplication>;
  memberOnboardingStatus: MemberOnboardingStatus;
  bedMeasurements: Array<Maybe<BedMeasurement>>;
  connectedSocialAccount?: Maybe<SocialReferenceType>;
  eligibleMembershipSubscriptions: Array<MembershipSubscription>;
  verifyPromotionalCode?: Maybe<VerifiedPromotionalCode>;
  account?: Maybe<Account>;
  bankAccountDetails?: Maybe<Account>;
  roles: Array<Role>;
  scopes: Array<Scalars['String']>;
  resourceScopes: Array<Scalars['String']>;
  amenityBundleByAsset?: Maybe<AmenityBundleType>;
  supplementBundles: Array<SupplementBundleType>;
  supplementBundleByAsset?: Maybe<SupplementBundleType>;
  property?: Maybe<Property>;
  properties: Array<Property>;
  hotel: Hotel;
  hotels: Array<Hotel>;
  hotelStaff?: Maybe<Staff>;
  hotelListing: Hotel;
  assetOnboardingStatus: AssetOnboardingStatus;
  brands: Array<Brand>;
  brandByAssignedAsset?: Maybe<Brand>;
  brandBusinessOwnerInvitation: BrandBusinessOwnerInvitation;
  brandStaff?: Maybe<Staff>;
  ownedBrands?: Maybe<Array<Maybe<Brand>>>;
  pmcBusinessOwnerInvitation: PmcBusinessOwnerInvitation;
  pmcStaff?: Maybe<Staff>;
  ownedPmcs?: Maybe<Array<Maybe<Pmc>>>;
  travelAgent?: Maybe<TravelAgent>;
  travelAgentBusinessOwnerInvitation: TravelAgentBusinessOwnerInvitation;
  travelAgentStaff?: Maybe<Staff>;
  ownedTravelAgents?: Maybe<Array<Maybe<TravelAgent>>>;
  travelAgentByStaffMember?: Maybe<TravelAgent>;
  resellerByBookingId?: Maybe<Reseller>;
  resellerByBusinessId?: Maybe<Reseller>;
  commission?: Maybe<CommissionFees>;
  staffMemberInvitation: StaffMemberInvitation;
  staffMemberInvitations: Array<StaffMemberInvitation>;
  staffMembers: Array<StaffMember>;
  clientele: Clientele;
  clienteleByBusinessId: Clientele;
  businessCustomer: BusinessCustomer;
  wishlists?: Maybe<Array<Maybe<Wishlist>>>;
  wishlist?: Maybe<Wishlist>;
  publicWishlist?: Maybe<PublicWishlist>;
  listingId?: Maybe<ListingId>;
  listingOnboardingStatus?: Maybe<ListingOnboardingStatus>;
  listingOnboardingStatuses: Array<ListingOnboardingStatus>;
  initialEarningsCalendar?: Maybe<EarningsCalendar>;
  earningsCalendar?: Maybe<EarningsCalendar>;
  availabilityCalendar?: Maybe<AvailabilityCalendar>;
  feesCalendar?: Maybe<FeesCalendar>;
  minimumRequiredAvailability?: Maybe<MinimumRequiredAvailability>;
  listingDefinition?: Maybe<ListingDefinition>;
  listing: Listing;
  listingByTravelDates: Listing;
  listingsByMember: Array<Listing>;
  exportUrl: Scalars['String'];
  hostingTeam?: Maybe<Host>;
  hostingTeamByListing?: Maybe<Host>;
  visibilityConstraint: VisibilityConstraint;
  searchOptions: SearchOptions;
  searchListings: SearchResults;
  otherNests: SearchResults;
  airports: Array<Airport>;
  trips: Array<Trip>;
  trip: Trip;
  booking: Booking;
  bookingFees: Fees;
  linkedCalendars: Array<Maybe<LinkedCalendar>>;
  myGuest?: Maybe<Booking>;
  myGuests: Array<Maybe<Booking>>;
  myPersonalStaff?: Maybe<Staff>;
  rescheduleBookingOrder: RescheduleBookingOrderRequest;
  stayExtensionOrder: StayExtensionOrderRequest;
  /** @deprecated Field no longer supported */
  searchMembers: Array<Member>;
  userExists?: Maybe<UserIdentifier>;
  myTokenBalance: TokenBalance;
  myBalanceLog: BalanceLog;
  tokenBalance: TokenBalance;
  balanceLog: BalanceLog;
  tokenType: TokenType;
  presentmentCurrencyExchangeRates: Array<ExchangeRateEntry>;
  favourites: Array<Maybe<Favourite>>;
  favourite?: Maybe<Favourite>;
  conversationToken: ConversationTokenType;
  inbox: Inbox;
  messageThread?: Maybe<MessageThread>;
  featuredListings: PublicListing;
};


export type QueryRootPropertyOwnerInvitationSummaryArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootAssetOwnerInvitationSummaryArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootStaffMemberInvitationSummaryArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootExchangeAuthCodeArgs = {
  code: Scalars['String'];
};


export type QueryRootConnectedSocialAccountArgs = {
  provider: Scalars['String'];
};


export type QueryRootVerifyPromotionalCodeArgs = {
  promotionalCode: Scalars['String'];
};


export type QueryRootResourceScopesArgs = {
  resourceId: Scalars['ID'];
};


export type QueryRootAmenityBundleByAssetArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootSupplementBundleByAssetArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootPropertyArgs = {
  propertyId: Scalars['ID'];
};


export type QueryRootPropertiesArgs = {
  skip: Scalars['Int'];
  limit: Scalars['Int'];
};


export type QueryRootHotelArgs = {
  hotelId: Scalars['ID'];
};


export type QueryRootHotelStaffArgs = {
  hotelId: Scalars['ID'];
};


export type QueryRootHotelListingArgs = {
  hotelId: Scalars['ID'];
};


export type QueryRootAssetOnboardingStatusArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootBrandByAssignedAssetArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootBrandBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootBrandStaffArgs = {
  brandId: Scalars['ID'];
};


export type QueryRootPmcBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootPmcStaffArgs = {
  pmcId: Scalars['ID'];
};


export type QueryRootTravelAgentArgs = {
  travelAgentId: Scalars['ID'];
};


export type QueryRootTravelAgentBusinessOwnerInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootTravelAgentStaffArgs = {
  travelAgentId: Scalars['ID'];
};


export type QueryRootTravelAgentByStaffMemberArgs = {
  staffMemberId: Scalars['ID'];
};


export type QueryRootResellerByBookingIdArgs = {
  bookingId: Scalars['ID'];
};


export type QueryRootResellerByBusinessIdArgs = {
  businessId: Scalars['ID'];
};


export type QueryRootCommissionArgs = {
  listingId: Scalars['ID'];
  adjustedPrice?: Maybe<CurrencyAmount>;
  originalPrice?: Maybe<CurrencyAmount>;
};


export type QueryRootStaffMemberInvitationArgs = {
  invitationId: Scalars['ID'];
};


export type QueryRootStaffMemberInvitationsArgs = {
  staffId: Scalars['ID'];
};


export type QueryRootStaffMembersArgs = {
  staffId: Scalars['ID'];
};


export type QueryRootClienteleArgs = {
  clienteleId: Scalars['ID'];
};


export type QueryRootClienteleByBusinessIdArgs = {
  businessId: Scalars['ID'];
};


export type QueryRootBusinessCustomerArgs = {
  businessId: Scalars['ID'];
  userId: Scalars['String'];
};


export type QueryRootWishlistArgs = {
  wishlistId: Scalars['ID'];
};


export type QueryRootPublicWishlistArgs = {
  wishlistId: Scalars['ID'];
};


export type QueryRootListingIdArgs = {
  propertyOrHotelRoomId: Scalars['ID'];
};


export type QueryRootListingOnboardingStatusArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootListingOnboardingStatusesArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootInitialEarningsCalendarArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootEarningsCalendarArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootAvailabilityCalendarArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootFeesCalendarArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootMinimumRequiredAvailabilityArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootListingDefinitionArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootListingArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootListingByTravelDatesArgs = {
  listingId: Scalars['ID'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
};


export type QueryRootListingsByMemberArgs = {
  memberId: Scalars['ID'];
};


export type QueryRootExportUrlArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootHostingTeamArgs = {
  assetId: Scalars['ID'];
};


export type QueryRootHostingTeamByListingArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootSearchOptionsArgs = {
  includeComingSoon: Scalars['Boolean'];
  availabilityTypes: Array<Maybe<AvailabilityType>>;
};


export type QueryRootSearchListingsArgs = {
  input?: Maybe<Criteria>;
};


export type QueryRootOtherNestsArgs = {
  input?: Maybe<Criteria>;
};


export type QueryRootAirportsArgs = {
  location?: Maybe<InputGeoLocation>;
};


export type QueryRootTripArgs = {
  tripId: Scalars['ID'];
};


export type QueryRootBookingArgs = {
  bookingId: Scalars['ID'];
};


export type QueryRootBookingFeesArgs = {
  bookingId: Scalars['ID'];
};


export type QueryRootLinkedCalendarsArgs = {
  listingId: Scalars['ID'];
};


export type QueryRootMyGuestArgs = {
  bookingId: Scalars['ID'];
};


export type QueryRootRescheduleBookingOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryRootStayExtensionOrderArgs = {
  orderId: Scalars['ID'];
};


export type QueryRootSearchMembersArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryRootUserExistsArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryRootTokenBalanceArgs = {
  accountHolderId: Scalars['ID'];
};


export type QueryRootBalanceLogArgs = {
  accountHolderId: Scalars['ID'];
};


export type QueryRootFavouriteArgs = {
  favouriteType?: Maybe<FavouriteType>;
  referenceId: Scalars['String'];
};


export type QueryRootConversationTokenArgs = {
  conversationId: Scalars['ID'];
  device: Scalars['String'];
};


export type QueryRootMessageThreadArgs = {
  subjectId: Scalars['ID'];
  subjectType: SubjectType;
};


export type QueryRootFeaturedListingsArgs = {
  featuredListingCode?: Maybe<Scalars['String']>;
};

export type Redirects = {
  successUrl: Scalars['String'];
  cancelUrl: Scalars['String'];
};

export type ReferMemberCommand = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
};

export type RegionCriterion = {
  regionId: Scalars['String'];
  subRegionId?: Maybe<Scalars['String']>;
};

export type RegionCriterionType = {
  __typename?: 'RegionCriterionType';
  regionId: Scalars['String'];
  subRegionId?: Maybe<Scalars['String']>;
};

export type RemoveFromFavouritesCommand = {
  type: FavouriteType;
  referenceId: Scalars['String'];
};

export type RemoveHostCommand = {
  listingManagementId: Scalars['ID'];
  hostId: Scalars['String'];
  managementId: Scalars['String'];
};

export type RemoveManagementCommand = {
  listingManagementId: Scalars['ID'];
  managementId: Scalars['String'];
};

export type RemoveMemberFromStaffCommand = {
  staffId: Scalars['ID'];
  userId: Scalars['String'];
};

export type RemovePropertyPhotoCommand = {
  propertyId: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};

export type RemoveWishFromWishlistCommand = {
  wishlistId: Scalars['ID'];
  wishId: Scalars['String'];
};

export type RescheduleBookingOrderRequest = {
  __typename?: 'RescheduleBookingOrderRequest';
  bookingId: Scalars['String'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
  feesDue?: Maybe<Fees>;
};

export type RescheduleBookingRequest = {
  bookingId: Scalars['String'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
};

export type Reseller = {
  __typename?: 'Reseller';
  resellerId: Scalars['ID'];
  businessId: Scalars['String'];
  name: Scalars['String'];
  associatedBookings: Array<AssociatedBooking>;
};

export enum Role {
  Consumer = 'consumer',
  Applicant = 'applicant',
  Traveller = 'traveller',
  Owner = 'owner',
  Earner = 'earner',
  Admin = 'admin',
  User = 'user',
  Staffmember = 'staffmember',
  BrandAgent = 'brand_agent',
  BrandBusinessOwner = 'brand_business_owner',
  PmcAgent = 'pmc_agent',
  PmcBusinessOwner = 'pmc_business_owner',
  TravelAgent = 'travel_agent',
  TravelAgentBusinessOwner = 'travel_agent_business_owner'
}

export type RoomsAndBedsCriterion = {
  beds?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
};

export type RoomsAndBedsCriterionType = {
  __typename?: 'RoomsAndBedsCriterionType';
  beds?: Maybe<Scalars['Int']>;
  bedrooms?: Maybe<Scalars['Int']>;
  bathrooms?: Maybe<Scalars['Int']>;
};

export enum ScreenSize {
  Large = 'large'
}

export type SearchGroupedRegions = {
  __typename?: 'SearchGroupedRegions';
  groupName: Scalars['String'];
  regions: Array<SearchRegion>;
};

export type SearchListing = {
  __typename?: 'SearchListing';
  listingId: Scalars['ID'];
  summary: ListingSummary;
  asset: Asset;
  obfuscatedLocation: ObfuscatedLocation;
  fees?: Maybe<Fees>;
  status: SearchListingStatus;
  availabilityType?: Maybe<AvailabilityType>;
  availableFrom?: Maybe<DateRange>;
  priceRange?: Maybe<PriceRange>;
  commission?: Maybe<Commission>;
  operator?: Maybe<Operator>;
};

export enum SearchListingStatus {
  Available = 'available',
  AvailableOtherDates = 'available_other_dates',
  ComingSoon = 'coming_soon'
}

export type SearchOptions = {
  __typename?: 'SearchOptions';
  groupedRegions: Array<SearchGroupedRegions>;
};

export type SearchRegion = {
  __typename?: 'SearchRegion';
  parentRegionId?: Maybe<Scalars['ID']>;
  regionId: Scalars['ID'];
  name: Scalars['String'];
  boundingBox?: Maybe<BoundingBox>;
};

export type SearchResultPaging = {
  __typename?: 'SearchResultPaging';
  offset: Scalars['Int'];
  size: Scalars['Int'];
};

export type SearchResults = {
  __typename?: 'SearchResults';
  count: Scalars['Int'];
  paging: SearchResultPaging;
  results: Array<SearchListing>;
};

export type SeasonalMinimum = {
  __typename?: 'SeasonalMinimum';
  seasonality: Seasonality;
  minimumDays: Scalars['Int'];
};

export enum Seasonality {
  OffPeak = 'off_peak',
  Shoulder = 'shoulder',
  Peak = 'peak',
  SuperPeak = 'super_peak'
}

export type SendConversationMessageRequest = {
  conversationId: Scalars['ID'];
  conversationToken: ConversationToken;
  text: Scalars['String'];
  returnUrl: Scalars['String'];
};

export type SignupConsumerRequest = {
  userId: Scalars['String'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumber>;
};

export type SocialReference = {
  provider: Scalars['String'];
  reference: Scalars['String'];
};

export type SocialReferenceType = {
  __typename?: 'SocialReferenceType';
  provider: Scalars['String'];
  reference: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  publicProfileUrl?: Maybe<Scalars['String']>;
  publicProfilePicture?: Maybe<Scalars['String']>;
};

export type Staff = {
  __typename?: 'Staff';
  staffId: Scalars['ID'];
  email: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<PhoneNumberType>;
};

export type StaffMember = {
  __typename?: 'StaffMember';
  userId: Scalars['ID'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  host: Scalars['Boolean'];
  manager: Scalars['Boolean'];
  boss: Scalars['Boolean'];
};

export type StaffMemberInvitation = {
  __typename?: 'StaffMemberInvitation';
  staffId: Scalars['ID'];
  invitationId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status: StaffMemberInvitationStatus;
  updateDate: Scalars['LocalDate'];
  host?: Maybe<Scalars['Boolean']>;
  manager?: Maybe<Scalars['Boolean']>;
};

export enum StaffMemberInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type StaffMemberInvitationSummary = {
  __typename?: 'StaffMemberInvitationSummary';
  staffId: Scalars['ID'];
  invitationId: Scalars['String'];
  status: StaffMemberInvitationStatus;
};

export type StaffPhotoSignUrlRequest = {
  staffId: Scalars['String'];
  filename: Scalars['String'];
};

export type StartPropertyOnboardingCommand = {
  propertyId: Scalars['ID'];
  name: Scalars['String'];
};

export type StayDurationConditions = {
  __typename?: 'StayDurationConditions';
  minimum: Scalars['Int'];
};

export type StayExtensionOrderRequest = {
  __typename?: 'StayExtensionOrderRequest';
  bookingId: Scalars['String'];
  checkout: Scalars['LocalDate'];
  feesDue?: Maybe<Fees>;
};

export type StayExtensionRequest = {
  bookingId: Scalars['String'];
  checkout: Scalars['LocalDate'];
};

export type StyleCriterion = {
  propertyStyles?: Maybe<Array<Maybe<PropertyStyle>>>;
};

export type StyleCriterionType = {
  __typename?: 'StyleCriterionType';
  propertyStyles?: Maybe<Array<Maybe<PropertyStyle>>>;
};

export type Subject = {
  __typename?: 'Subject';
  subjectId: Scalars['ID'];
  subjectType: SubjectType;
};

export enum SubjectType {
  Booking = 'booking'
}

export type SubmitBookingEnquiryRequest = {
  listingId: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
  phoneNumber?: Maybe<PhoneNumber>;
  email: Scalars['String'];
  enquiry?: Maybe<Scalars['String']>;
};

export type SubmitMemberApplicationCommand = {
  memberId: Scalars['String'];
  assetId: Scalars['String'];
  applicationType: MemberApplicationType;
  calendarReference?: Maybe<CalendarReference>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: PhoneNumber;
  termsAndConditionsAccepted: Scalars['Boolean'];
  address: Address;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  propertyName: Scalars['String'];
  propertyType: PropertyType;
  bedrooms: Scalars['Int'];
  bathrooms: Scalars['Int'];
  sleeps: Scalars['Int'];
  approximateValue: CurrencyAmount;
  externalListingUrl?: Maybe<Scalars['String']>;
  welcomeReturnUrl: Scalars['String'];
};

export type SupplementBundleSectionType = {
  __typename?: 'SupplementBundleSectionType';
  sectionId: Scalars['ID'];
  name: Scalars['String'];
  supplementIds: Array<Scalars['String']>;
  supplements: Array<SupplementType>;
};

export type SupplementBundleType = {
  __typename?: 'SupplementBundleType';
  bundleId: Scalars['ID'];
  name: Scalars['String'];
  sections: Array<SupplementBundleSectionType>;
  enabled: Scalars['Boolean'];
};

export type SupplementType = {
  __typename?: 'SupplementType';
  supplementId: Scalars['ID'];
  name: Scalars['String'];
  iconName?: Maybe<Scalars['String']>;
  price: CurrencyAmountType;
  description?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  usageCount: UsageCount;
};

export type SupportChat = {
  __typename?: 'SupportChat';
  identificationToken?: Maybe<Scalars['String']>;
  identificationEmail?: Maybe<Scalars['String']>;
};

export enum TimeUnit {
  Week = 'week',
  Day = 'day'
}

export type TokenAmountType = {
  __typename?: 'TokenAmountType';
  amount: Scalars['Long'];
};

export type TokenBalance = {
  __typename?: 'TokenBalance';
  accountBalanceId: Scalars['ID'];
  tokenType: TokenType;
  amount?: Maybe<Scalars['Int']>;
};

export type TokenExchangeRate = {
  __typename?: 'TokenExchangeRate';
  currency: Scalars['String'];
  amountMicros: Scalars['Long'];
};

export type TokenType = {
  __typename?: 'TokenType';
  name: Scalars['String'];
  symbol: Scalars['String'];
  exchangeRate: TokenExchangeRate;
};

export enum TransactionStatus {
  Authorized = 'authorized',
  Captured = 'captured'
}

export type TravelAgent = {
  __typename?: 'TravelAgent';
  travelAgentId: Scalars['ID'];
  name: Scalars['String'];
};

export type TravelAgentBusinessOwnerInvitation = {
  __typename?: 'TravelAgentBusinessOwnerInvitation';
  invitationId: Scalars['ID'];
  businessOwnerId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  status: TravelAgentBusinessOwnerInvitationStatus;
  updateDate: Scalars['OffsetDateTime'];
};

export enum TravelAgentBusinessOwnerInvitationStatus {
  Invited = 'invited',
  Accepted = 'accepted',
  Cancelled = 'cancelled'
}

export type Trip = {
  __typename?: 'Trip';
  tripId: Scalars['ID'];
  bookings: Array<Maybe<Booking>>;
};

export type TripSummary = {
  __typename?: 'TripSummary';
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  location: Location;
};

export type UnblockAvailabilityCommand = {
  listingId: Scalars['ID'];
  unblockedDays?: Maybe<Array<Maybe<Scalars['LocalDate']>>>;
};

export type UnlinkExternalCalendarCommand = {
  listingId: Scalars['ID'];
  externalUrl: Scalars['String'];
};

export type UpdateBankAccountCommand = {
  accountType?: Maybe<BankAccountType>;
  accountHolderName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  transitNumber?: Maybe<Scalars['String']>;
  accountNumber?: Maybe<Scalars['String']>;
};

export type UpdateBookingSettingsCommand = {
  listingId: Scalars['ID'];
  allowInstantBook: Scalars['Boolean'];
};

export type UpdateHighlightedFeatureCommand = {
  listingId: Scalars['ID'];
  highlightedFeatures?: Maybe<Array<HighlightedFeature>>;
};

export type UpdateHotelAmenitiesCommand = {
  hotelId: Scalars['ID'];
  amenityIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdateHotelGeneralCommand = {
  hotelId: Scalars['ID'];
  name: Scalars['String'];
  website: Scalars['String'];
  stars: Scalars['Int'];
  averageDailyRate: CurrencyAmount;
  externalListingUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateHotelGoodToKnowCommand = {
  hotelId: Scalars['ID'];
  description: Scalars['String'];
};

export type UpdateHotelHouseRulesCommand = {
  hotelId: Scalars['ID'];
  minimumStay: Scalars['Int'];
  maximumStay: Scalars['Int'];
  earliestCheckin: Scalars['LocalTime'];
  latestCheckin: Scalars['LocalTime'];
  allowsChildren: Scalars['Boolean'];
};

export type UpdateHotelLocationCommand = {
  hotelId: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  additionalInstructions?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type UpdateHotelPhotosCommand = {
  hotelId: Scalars['ID'];
  photos?: Maybe<Array<AssetPhoto>>;
};

export type UpdateHotelRoomCommand = {
  hotelId: Scalars['ID'];
  hotelRoomId: Scalars['String'];
  roomType: Scalars['String'];
  maximumOccupancy: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
};

export type UpdateHotelRoomPhotosCommand = {
  hotelId: Scalars['ID'];
  hotelRoomId: Scalars['String'];
  photos?: Maybe<Array<AssetPhoto>>;
};

export type UpdateListingCleaningServiceCommand = {
  listingId: Scalars['ID'];
  perDayCleaningFee?: Maybe<CleaningFeeInput>;
  perStayCleaningFee?: Maybe<CleaningFeeInput>;
  paymentMode?: Maybe<PaymentMode>;
};

export type UpdateListingSupplementsCommand = {
  listingId: Scalars['ID'];
  mandatorySupplements: Array<ListingSupplement>;
  optionalSupplements: Array<ListingSupplement>;
};

export type UpdatePersonalAddressCommand = {
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type UpdatePersonalInformationCommand = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumber;
};

export type UpdatePersonalPhoneNumberCommand = {
  phoneNumber: PhoneNumber;
};

export type UpdatePersonalPhotoCommand = {
  photoUrl: Scalars['String'];
};

export type UpdatePropertyAmenitiesCommand = {
  propertyId: Scalars['ID'];
  amenityIds?: Maybe<Array<Scalars['String']>>;
};

export type UpdatePropertyGeneralCommand = {
  propertyId: Scalars['ID'];
  propertyStyle?: Maybe<PropertyStyle>;
  propertyType?: Maybe<PropertyType>;
  bedrooms?: Maybe<Array<Maybe<Bedroom>>>;
  bathrooms?: Maybe<Scalars['Int']>;
  sleeps?: Maybe<Scalars['Int']>;
  approximateValue?: Maybe<CurrencyAmount>;
  externalListingUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type UpdatePropertyGoodToKnowCommand = {
  propertyId: Scalars['ID'];
  description: Scalars['String'];
};

export type UpdatePropertyHouseRulesCommand = {
  propertyId: Scalars['ID'];
  allowsInfants: Scalars['Boolean'];
  allowsChildren: Scalars['Boolean'];
  allowsEvents: Scalars['Boolean'];
  allowsPets: Scalars['Boolean'];
  allowsSmoking: Scalars['Boolean'];
};

export type UpdatePropertyLocationCommand = {
  propertyId: Scalars['ID'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  additionalInstructions?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type UpdatePropertyPhotosCommand = {
  propertyId: Scalars['ID'];
  photos?: Maybe<Array<AssetPhoto>>;
};

export type UpdateStaffCommand = {
  staffId: Scalars['ID'];
  email: Scalars['String'];
  photoUrl: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  phoneNumber: PhoneNumber;
};

export type UpdateWishlistCommand = {
  wishlistId: Scalars['ID'];
  onBehalfOf?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UsageCount = {
  __typename?: 'UsageCount';
  mandatoryUsageCount: Scalars['Int'];
  optionalUsageCount: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  userId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type UserIdentifier = {
  __typename?: 'UserIdentifier';
  userId: Scalars['ID'];
};

export enum UserType {
  Member = 'member',
  Consumer = 'consumer'
}

export type VerifiedPromotionalCode = {
  __typename?: 'VerifiedPromotionalCode';
  promotionalCodeId: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type VisibilityConstraint = {
  __typename?: 'VisibilityConstraint';
  canSeeRentalProperties: Scalars['Boolean'];
  canSeeExchangeProperties: Scalars['Boolean'];
  canSeeHotels: Scalars['Boolean'];
};

export type Wish = {
  __typename?: 'Wish';
  wishId: Scalars['ID'];
  searchListing: SearchListing;
  notes?: Maybe<Scalars['String']>;
};

export type Wishlist = {
  __typename?: 'Wishlist';
  wishlistId: Scalars['ID'];
  userId: Scalars['String'];
  name: Scalars['String'];
  status: WishlistStatus;
  wishes: Array<Wish>;
};

export enum WishlistStatus {
  Active = 'active',
  Expired = 'expired'
}


export type PresentmentCurrencyExchangeRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type PresentmentCurrencyExchangeRatesQuery = (
  { __typename?: 'QueryRoot' }
  & { presentmentCurrencyExchangeRates: Array<(
    { __typename?: 'ExchangeRateEntry' }
    & Pick<ExchangeRateEntry, 'currency' | 'rate'>
  )> }
);

export type RoleManagerAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleManagerAccountQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId' | 'accountHolderId'>
    & { personalProfile: (
      { __typename?: 'PersonalProfile' }
      & Pick<PersonalProfile, 'email' | 'firstName' | 'lastName' | 'photoUrl'>
    ) }
  )> }
);

export type RoleManagerRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleManagerRolesQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'roles'>
);

export type RoleManagerMemberOnboardingStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleManagerMemberOnboardingStatusQuery = (
  { __typename?: 'QueryRoot' }
  & { memberOnboardingStatus: (
    { __typename?: 'MemberOnboardingStatus' }
    & Pick<MemberOnboardingStatus, 'completed'>
    & { assetBeingOnboarded: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ) }
  ) }
);

export type ScopeManagerScopesQueryVariables = Exact<{ [key: string]: never; }>;


export type ScopeManagerScopesQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'scopes'>
);

export type ScopeManagerResourceScopesQueryVariables = Exact<{
  resourceId: Scalars['ID'];
}>;


export type ScopeManagerResourceScopesQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'resourceScopes'>
);

export type SupportChatQueryVariables = Exact<{ [key: string]: never; }>;


export type SupportChatQuery = (
  { __typename?: 'QueryRoot' }
  & { supportChat?: Maybe<(
    { __typename?: 'SupportChat' }
    & Pick<SupportChat, 'identificationToken' | 'identificationEmail'>
  )> }
);

export type TokenTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenTypeQuery = (
  { __typename?: 'QueryRoot' }
  & { tokenType: (
    { __typename?: 'TokenType' }
    & Pick<TokenType, 'name' | 'symbol'>
    & { exchangeRate: (
      { __typename?: 'TokenExchangeRate' }
      & Pick<TokenExchangeRate, 'currency' | 'amountMicros'>
    ) }
  ) }
);

export type TravelAgentByStaffMemberQueryVariables = Exact<{
  staffMemberId: Scalars['ID'];
}>;


export type TravelAgentByStaffMemberQuery = (
  { __typename?: 'QueryRoot' }
  & { travelAgentByStaffMember?: Maybe<(
    { __typename?: 'TravelAgent' }
    & Pick<TravelAgent, 'travelAgentId' | 'name'>
  )> }
);

export type TravelAgentClienteleByBusinessIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type TravelAgentClienteleByBusinessIdQuery = (
  { __typename?: 'QueryRoot' }
  & { clienteleByBusinessId: (
    { __typename?: 'Clientele' }
    & Pick<Clientele, 'clienteleId' | 'businessId'>
    & { customers: Array<(
      { __typename?: 'BusinessCustomer' }
      & Pick<BusinessCustomer, 'userId' | 'firstName' | 'lastName' | 'email' | 'photoUrl'>
      & { phoneNumber: (
        { __typename?: 'PhoneNumberType' }
        & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
      ) }
    )> }
  ) }
);

export type TravelAgentResellerByBusinessIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type TravelAgentResellerByBusinessIdQuery = (
  { __typename?: 'QueryRoot' }
  & { resellerByBusinessId?: Maybe<(
    { __typename?: 'Reseller' }
    & Pick<Reseller, 'resellerId' | 'businessId' | 'name'>
  )> }
);

export type AcceptBookingMutationVariables = Exact<{
  input: AcceptBookingRequest;
}>;


export type AcceptBookingMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptBooking: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptBookingBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type AcceptBookingBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'status'>
  ) }
);

export type BusinessCustomerAutocompleteAddBusinessCustomerToClienteleMutationVariables = Exact<{
  input: AddBusinessCustomerToClienteleCommand;
}>;


export type BusinessCustomerAutocompleteAddBusinessCustomerToClienteleMutation = (
  { __typename?: 'MutationRoot' }
  & { addBusinessCustomerToClientele: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type BusinessCustomerUserExistsQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type BusinessCustomerUserExistsQuery = (
  { __typename?: 'QueryRoot' }
  & { userExists?: Maybe<(
    { __typename?: 'UserIdentifier' }
    & Pick<UserIdentifier, 'userId'>
  )> }
);

export type CreateBusinessCustomerMutationVariables = Exact<{
  input: CreateBusinessCustomerRequest;
}>;


export type CreateBusinessCustomerMutation = (
  { __typename?: 'MutationRoot' }
  & { createBusinessCustomer: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CalendarListingAllocateDailyAvailabilityMutationVariables = Exact<{
  input: AllocateDailyAvailabilityCommand;
}>;


export type CalendarListingAllocateDailyAvailabilityMutation = (
  { __typename?: 'MutationRoot' }
  & { allocateDailyAvailability: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CalendarListingAllocateMonthlyAvailabilityMutationVariables = Exact<{
  input: AllocateMonthlyAvailabilityCommand;
}>;


export type CalendarListingAllocateMonthlyAvailabilityMutation = (
  { __typename?: 'MutationRoot' }
  & { allocateMonthlyAvailability: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CalendarListingAllocateInitialMonthlyAvailabilityMutationVariables = Exact<{
  input: AllocateInitialMonthlyAvailabilityRequest;
}>;


export type CalendarListingAllocateInitialMonthlyAvailabilityMutation = (
  { __typename?: 'MutationRoot' }
  & { allocateInitialMonthlyAvailability: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CalendarListingMinimumRequiredAvailabilityQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type CalendarListingMinimumRequiredAvailabilityQuery = (
  { __typename?: 'QueryRoot' }
  & { minimumRequiredAvailability?: Maybe<(
    { __typename?: 'MinimumRequiredAvailability' }
    & { required: Array<(
      { __typename?: 'SeasonalMinimum' }
      & Pick<SeasonalMinimum, 'minimumDays' | 'seasonality'>
    )> }
  )> }
);

export type CalendarTokenBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type CalendarTokenBalanceQuery = (
  { __typename?: 'QueryRoot' }
  & { myTokenBalance: (
    { __typename?: 'TokenBalance' }
    & Pick<TokenBalance, 'amount'>
    & { tokenType: (
      { __typename?: 'TokenType' }
      & Pick<TokenType, 'name' | 'symbol'>
    ) }
  ) }
);

export type DepositCalendarInitialEarningsCalendarQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DepositCalendarInitialEarningsCalendarQuery = (
  { __typename?: 'QueryRoot' }
  & { initialEarningsCalendar?: Maybe<(
    { __typename?: 'EarningsCalendar' }
    & { perDayEarnings: Array<(
      { __typename?: 'PerDayEarning' }
      & Pick<PerDayEarning, 'day' | 'percentageInstantly'>
      & { earnings: (
        { __typename?: 'Earnings' }
        & { tokenAmount?: Maybe<(
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        )>, currencyAmount?: Maybe<(
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        )> }
      ), history: Array<(
        { __typename?: 'HistoricEarningRecord' }
        & Pick<HistoricEarningRecord, 'percentageInstantly'>
        & { earnedTotal: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), earnedInstantly: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), earnedDelayed: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ) }
      )> }
    )> }
  )> }
);

export type DepositCalendarEarningsCalendarQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DepositCalendarEarningsCalendarQuery = (
  { __typename?: 'QueryRoot' }
  & { earningsCalendar?: Maybe<(
    { __typename?: 'EarningsCalendar' }
    & { perDayEarnings: Array<(
      { __typename?: 'PerDayEarning' }
      & Pick<PerDayEarning, 'day' | 'percentageInstantly'>
      & { earnings: (
        { __typename?: 'Earnings' }
        & { tokenAmount?: Maybe<(
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        )>, currencyAmount?: Maybe<(
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        )> }
      ), history: Array<(
        { __typename?: 'HistoricEarningRecord' }
        & Pick<HistoricEarningRecord, 'percentageInstantly'>
        & { earnedTotal: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), earnedInstantly: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), earnedDelayed: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ) }
      )> }
    )> }
  )> }
);

export type DepositCalendarListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DepositCalendarListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'listingId' | 'status'>
    & { availabilityCalendar: (
      { __typename?: 'AvailabilityCalendar' }
      & { dailyAllocations: Array<Maybe<(
        { __typename?: 'DailyAllocationType' }
        & Pick<DailyAllocationType, 'allocated' | 'consumed' | 'day'>
      )>> }
    ) }
  ) }
);

export type DepositCalendarLinkedCalendarsQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DepositCalendarLinkedCalendarsQuery = (
  { __typename?: 'QueryRoot' }
  & { linkedCalendars: Array<Maybe<(
    { __typename?: 'LinkedCalendar' }
    & Pick<LinkedCalendar, 'externalUrl' | 'name'>
  )>> }
);

export type LinkExternalCalendarMutationVariables = Exact<{
  input: LinkExternalCalendarCommand;
}>;


export type LinkExternalCalendarMutation = (
  { __typename?: 'MutationRoot' }
  & { linkExternalCalendar: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type LinkedCalendarsQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type LinkedCalendarsQuery = (
  { __typename?: 'QueryRoot' }
  & { linkedCalendars: Array<Maybe<(
    { __typename?: 'LinkedCalendar' }
    & Pick<LinkedCalendar, 'externalUrl' | 'name'>
  )>> }
);

export type LinkCalendarsUnlinkExternalCalendarMutationVariables = Exact<{
  input: UnlinkExternalCalendarCommand;
}>;


export type LinkCalendarsUnlinkExternalCalendarMutation = (
  { __typename?: 'MutationRoot' }
  & { unlinkExternalCalendar: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ListingsWithCleaningByMemberQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type ListingsWithCleaningByMemberQuery = (
  { __typename?: 'QueryRoot' }
  & { listingsByMember: Array<(
    { __typename?: 'Listing' }
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ), summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'title'>
    ), operator?: Maybe<(
      { __typename?: 'Operator' }
      & Pick<Operator, 'operatorId' | 'name' | 'logoUrl'>
    )>, cleaningService: (
      { __typename?: 'CleaningService' }
      & Pick<CleaningService, 'paymentMode' | 'isUpdated'>
      & { perStayCleaningFee?: Maybe<(
        { __typename?: 'CleaningFee' }
        & { amount: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>, perDayCleaningFee?: Maybe<(
        { __typename?: 'CleaningFee' }
        & { amount: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )> }
    ) }
  )> }
);

export type BankAccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type BankAccountDetailsQuery = (
  { __typename?: 'QueryRoot' }
  & { bankAccountDetails?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
  )> }
);

export type ConnectFacebookAccountMutationVariables = Exact<{
  input: ConnectSocialMediaAccountCommand;
}>;


export type ConnectFacebookAccountMutation = (
  { __typename?: 'MutationRoot' }
  & { connectSocialMediaAccount: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConnectFacebookConnectedSocialAccountQueryVariables = Exact<{
  provider: Scalars['String'];
}>;


export type ConnectFacebookConnectedSocialAccountQuery = (
  { __typename?: 'QueryRoot' }
  & { connectedSocialAccount?: Maybe<(
    { __typename?: 'SocialReferenceType' }
    & Pick<SocialReferenceType, 'provider'>
  )> }
);

export type ConnectLinkedinAccountMutationVariables = Exact<{
  input: ConnectLinkedinAccountCommand;
}>;


export type ConnectLinkedinAccountMutation = (
  { __typename?: 'MutationRoot' }
  & { connectLinkedinAccount: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConnectLinkedinConnectedSocialAccountQueryVariables = Exact<{
  provider: Scalars['String'];
}>;


export type ConnectLinkedinConnectedSocialAccountQuery = (
  { __typename?: 'QueryRoot' }
  & { connectedSocialAccount?: Maybe<(
    { __typename?: 'SocialReferenceType' }
    & Pick<SocialReferenceType, 'provider'>
  )> }
);

export type DeclineBookingMutationVariables = Exact<{
  input: DeclineBookingRequest;
}>;


export type DeclineBookingMutation = (
  { __typename?: 'MutationRoot' }
  & { declineBooking: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type DeclineBookingBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type DeclineBookingBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'status'>
  ) }
);

export type ListingAvailabilityCalendarQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingAvailabilityCalendarQuery = (
  { __typename?: 'QueryRoot' }
  & { availabilityCalendar?: Maybe<(
    { __typename?: 'AvailabilityCalendar' }
    & { dailyAllocations: Array<Maybe<(
      { __typename?: 'DailyAllocationType' }
      & Pick<DailyAllocationType, 'allocated' | 'consumed' | 'day'>
    )>> }
  )> }
);

export type ListingAvailabilityFeesCalendarQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingAvailabilityFeesCalendarQuery = (
  { __typename?: 'QueryRoot' }
  & { feesCalendar?: Maybe<(
    { __typename?: 'FeesCalendar' }
    & { perDayFees: Array<(
      { __typename?: 'PerDayFees' }
      & Pick<PerDayFees, 'day'>
      & { fees: (
        { __typename?: 'Fees' }
        & { tokenFee: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), exchangeFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ), bookingFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ) }
      ) }
    )> }
  )> }
);

export type ListingHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type ListingHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotelListing: (
    { __typename?: 'Hotel' }
    & { general?: Maybe<(
      { __typename?: 'HotelGeneral' }
      & Pick<HotelGeneral, 'description' | 'name'>
    )>, goodToKnow?: Maybe<(
      { __typename?: 'HotelGoodToKnow' }
      & Pick<HotelGoodToKnow, 'description'>
    )>, location?: Maybe<(
      { __typename?: 'HotelLocation' }
      & { geoLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lng'>
      )> }
    )>, amenities?: Maybe<(
      { __typename?: 'HotelAmenities' }
      & Pick<HotelAmenities, 'amenityIds'>
    )>, houseRules?: Maybe<(
      { __typename?: 'HotelHouseRules' }
      & Pick<HotelHouseRules, 'allowsChildren' | 'earliestCheckin' | 'latestCheckin' | 'maximumStay' | 'minimumStay'>
    )>, rooms: Array<(
      { __typename?: 'HotelRoom' }
      & Pick<HotelRoom, 'hotelRoomId' | 'roomType' | 'maximumOccupancy' | 'description'>
      & { packageDeal?: Maybe<(
        { __typename?: 'PackageDeal' }
        & Pick<PackageDeal, 'text' | 'helpText' | 'showCurrency'>
      )>, imagery: (
        { __typename?: 'HotelRoomImagery' }
        & { photos?: Maybe<Array<Maybe<(
          { __typename?: 'AssetPhotoType' }
          & Pick<AssetPhotoType, 'url' | 'description' | 'useAsCover' | 'useAsFloorplan'>
        )>>> }
      ) }
    )>, suites: Array<(
      { __typename?: 'HotelSuite' }
      & Pick<HotelSuite, 'hotelSuiteId' | 'maximumOccupancy' | 'suiteType' | 'description'>
      & { imagery: (
        { __typename?: 'HotelSuiteImagery' }
        & { photos?: Maybe<Array<Maybe<(
          { __typename?: 'AssetPhotoType' }
          & Pick<AssetPhotoType, 'description' | 'url' | 'useAsCover' | 'useAsFloorplan'>
        )>>> }
      ), packageDeal?: Maybe<(
        { __typename?: 'PackageDeal' }
        & Pick<PackageDeal, 'text' | 'helpText' | 'showCurrency'>
      )>, rooms: (
        { __typename?: 'HotelSuiteRooms' }
        & { bedrooms: Array<Maybe<(
          { __typename?: 'BedroomType' }
          & Pick<BedroomType, 'bedSizes'>
        )>>, areas: Array<Maybe<(
          { __typename?: 'AreaType' }
          & Pick<AreaType, 'areaDefinitionId' | 'size' | 'unit'>
        )>> }
      ) }
    )> }
  ) }
);

export type ListingAmenitiesAmenityBundleByAssetQueryVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type ListingAmenitiesAmenityBundleByAssetQuery = (
  { __typename?: 'QueryRoot' }
  & { amenityBundleByAsset?: Maybe<(
    { __typename?: 'AmenityBundleType' }
    & Pick<AmenityBundleType, 'bundleId' | 'name' | 'enabled'>
    & { sections: Array<(
      { __typename?: 'AmenityBundleSectionType' }
      & Pick<AmenityBundleSectionType, 'sectionId' | 'name'>
      & { amenities: Array<(
        { __typename?: 'AmenityType' }
        & Pick<AmenityType, 'amenityId' | 'name' | 'description' | 'iconName' | 'enabled'>
      )> }
    )> }
  )> }
);

export type ListingPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type ListingPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { amenities?: Maybe<(
      { __typename?: 'PropertyAmenities' }
      & Pick<PropertyAmenities, 'amenityIds'>
    )>, general?: Maybe<(
      { __typename?: 'PropertyGeneral' }
      & Pick<PropertyGeneral, 'bathrooms' | 'description' | 'name' | 'propertyStyle' | 'sleeps'>
      & { bedrooms?: Maybe<Array<Maybe<(
        { __typename?: 'BedroomType' }
        & Pick<BedroomType, 'bedSizes'>
      )>>> }
    )>, goodToKnow?: Maybe<(
      { __typename?: 'PropertyGoodToKnow' }
      & Pick<PropertyGoodToKnow, 'description'>
    )>, houseRules?: Maybe<(
      { __typename?: 'PropertyHouseRules' }
      & Pick<PropertyHouseRules, 'allowsChildren' | 'allowsEvents' | 'allowsInfants' | 'allowsPets' | 'allowsSmoking'>
    )>, location?: Maybe<(
      { __typename?: 'PropertyLocation' }
      & { geoLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lng'>
      )> }
    )>, owner?: Maybe<(
      { __typename?: 'AssetOwner' }
      & Pick<AssetOwner, 'firstName' | 'lastName' | 'photoUrl'>
    )> }
  )> }
);

export type ListingTravelDatesQueryVariables = Exact<{
  listingId: Scalars['ID'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
}>;


export type ListingTravelDatesQuery = (
  { __typename?: 'QueryRoot' }
  & { listingByTravelDates: (
    { __typename?: 'Listing' }
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetType'>
    ), fees?: Maybe<(
      { __typename?: 'Fees' }
      & { tokenType: (
        { __typename?: 'TokenType' }
        & Pick<TokenType, 'name' | 'symbol'>
      ), tokenFee: (
        { __typename?: 'TokenAmountType' }
        & Pick<TokenAmountType, 'amount'>
      ), exchangeFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ), bookingFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ) }
    )>, commission?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'rate'>
    )>, stayDurationConditions: (
      { __typename?: 'StayDurationConditions' }
      & Pick<StayDurationConditions, 'minimum'>
    ) }
  ) }
);

export type ListingTravelDatesByIdQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingTravelDatesByIdQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & { commission?: Maybe<(
      { __typename?: 'Commission' }
      & Pick<Commission, 'rate'>
    )>, priceRange?: Maybe<(
      { __typename?: 'PriceRange' }
      & { lowest: (
        { __typename?: 'Fees' }
        & { bookingFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ), exchangeFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ), tokenFee: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ) }
      ) }
    )>, stayDurationConditions: (
      { __typename?: 'StayDurationConditions' }
      & Pick<StayDurationConditions, 'minimum'>
    ) }
  ) }
);

export type ListingTravelDatesFetchCommissionQueryVariables = Exact<{
  listingId: Scalars['ID'];
  adjustedPrice: CurrencyAmount;
  originalPrice: CurrencyAmount;
}>;


export type ListingTravelDatesFetchCommissionQuery = (
  { __typename?: 'QueryRoot' }
  & { commission?: Maybe<(
    { __typename?: 'CommissionFees' }
    & Pick<CommissionFees, 'commissionRate'>
    & { commission: (
      { __typename?: 'CurrencyAmountType' }
      & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
    ) }
  )> }
);

export type ListingCreateBookingMutationVariables = Exact<{
  input: CreateBookingRequest;
}>;


export type ListingCreateBookingMutation = (
  { __typename?: 'MutationRoot' }
  & { createBooking: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ListingBookingCheckoutMutationVariables = Exact<{
  input: BookingCheckoutRequest;
}>;


export type ListingBookingCheckoutMutation = (
  { __typename?: 'MutationRoot' }
  & { checkout: (
    { __typename?: 'CheckoutSession' }
    & Pick<CheckoutSession, 'sessionId'>
  ) }
);

export type ListingCancelCheckoutSessionMutationVariables = Exact<{
  input: CancelCheckoutRequest;
}>;


export type ListingCancelCheckoutSessionMutation = (
  { __typename?: 'MutationRoot' }
  & { cancelCheckout: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ListingBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type ListingBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'bookingId' | 'tripId'>
  ) }
);

export type ListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'listingId' | 'status'>
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ), availabilityCalendar: (
      { __typename?: 'AvailabilityCalendar' }
      & { dailyAllocations: Array<Maybe<(
        { __typename?: 'DailyAllocationType' }
        & Pick<DailyAllocationType, 'allocated' | 'day' | 'consumed'>
      )>> }
    ), obfuscatedLocation: (
      { __typename?: 'ObfuscatedLocation' }
      & Pick<ObfuscatedLocation, 'range'>
      & { location: (
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lng'>
      ) }
    ), summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle' | 'highlightedAmenities'>
      & { highlightedFeatures: (
        { __typename?: 'HighlightedFeatures' }
        & { features: Array<(
          { __typename?: 'ListingHighlightedFeature' }
          & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
        )> }
      ), houseRules: (
        { __typename?: 'ListingHouseRules' }
        & Pick<ListingHouseRules, 'otherRules'>
        & { rules: Array<(
          { __typename?: 'ListingHouseRule' }
          & Pick<ListingHouseRule, 'enabled' | 'icon' | 'subtitle' | 'title'>
        )> }
      ), photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover' | 'description'>
      )> }
    ), stayDurationConditions: (
      { __typename?: 'StayDurationConditions' }
      & Pick<StayDurationConditions, 'minimum'>
    ) }
  ) }
);

export type ListingIdQueryVariables = Exact<{
  propertyOrHotelRoomId: Scalars['ID'];
}>;


export type ListingIdQuery = (
  { __typename?: 'QueryRoot' }
  & { listingId?: Maybe<(
    { __typename?: 'ListingId' }
    & Pick<ListingId, 'listingId'>
  )> }
);

export type ListingManagementIdQueryVariables = Exact<{
  propertyOrHotelRoomId: Scalars['ID'];
}>;


export type ListingManagementIdQuery = (
  { __typename?: 'QueryRoot' }
  & { listingId?: Maybe<(
    { __typename?: 'ListingId' }
    & Pick<ListingId, 'listingManagementId'>
  )> }
);

export type OtherNestsQueryVariables = Exact<{
  input: Criteria;
}>;


export type OtherNestsQuery = (
  { __typename?: 'QueryRoot' }
  & { otherNests: (
    { __typename?: 'SearchResults' }
    & { results: Array<(
      { __typename?: 'SearchListing' }
      & Pick<SearchListing, 'listingId' | 'status'>
      & { summary: (
        { __typename?: 'ListingSummary' }
        & Pick<ListingSummary, 'city' | 'countryCode' | 'highlightedAmenities' | 'title' | 'subtitle'>
        & { highlightedFeatures: (
          { __typename?: 'HighlightedFeatures' }
          & { features: Array<(
            { __typename?: 'ListingHighlightedFeature' }
            & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
          )> }
        ), photos: Array<(
          { __typename?: 'AssetPhotoType' }
          & Pick<AssetPhotoType, 'url' | 'useAsCover'>
        )> }
      ) }
    )> }
  ) }
);

export type ListingHostQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingHostQuery = (
  { __typename?: 'QueryRoot' }
  & { hostingTeamByListing?: Maybe<(
    { __typename?: 'Host' }
    & Pick<Host, 'displayName' | 'imageUrl'>
  )> }
);

export type ListingTransportAirportsQueryVariables = Exact<{
  location: InputGeoLocation;
}>;


export type ListingTransportAirportsQuery = (
  { __typename?: 'QueryRoot' }
  & { airports: Array<(
    { __typename?: 'Airport' }
    & Pick<Airport, 'distance' | 'name' | 'type'>
  )> }
);

export type TokenBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type TokenBalanceQuery = (
  { __typename?: 'QueryRoot' }
  & { myTokenBalance: (
    { __typename?: 'TokenBalance' }
    & Pick<TokenBalance, 'amount'>
    & { tokenType: (
      { __typename?: 'TokenType' }
      & Pick<TokenType, 'name' | 'symbol'>
      & { exchangeRate: (
        { __typename?: 'TokenExchangeRate' }
        & Pick<TokenExchangeRate, 'currency' | 'amountMicros'>
      ) }
    ) }
  ) }
);

export type VerifyPromotionalCodeQueryVariables = Exact<{
  promotionalCode: Scalars['String'];
}>;


export type VerifyPromotionalCodeQuery = (
  { __typename?: 'QueryRoot' }
  & { verifyPromotionalCode?: Maybe<(
    { __typename?: 'VerifiedPromotionalCode' }
    & Pick<VerifiedPromotionalCode, 'promotionalCodeId' | 'valid'>
  )> }
);

export type ResellerCommissionQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type ResellerCommissionQuery = (
  { __typename?: 'QueryRoot' }
  & { resellerByBookingId?: Maybe<(
    { __typename?: 'Reseller' }
    & Pick<Reseller, 'resellerId' | 'businessId' | 'name'>
    & { associatedBookings: Array<(
      { __typename?: 'AssociatedBooking' }
      & Pick<AssociatedBooking, 'bookingId'>
      & { commissionFees: (
        { __typename?: 'CommissionFees' }
        & { commission: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      ), commissionPlan: (
        { __typename?: 'CommissionPlan' }
        & Pick<CommissionPlan, 'commissionPlanId' | 'vendorId' | 'commissionModelType'>
        & { flatPercentageCommissionPlan?: Maybe<(
          { __typename?: 'FlatPercentageCommissionPlan' }
          & Pick<FlatPercentageCommissionPlan, 'flatPercentage'>
        )> }
      ), booking: (
        { __typename?: 'Booking' }
        & Pick<Booking, 'bookingId' | 'bookingReference' | 'checkin' | 'checkout' | 'status'>
        & { bookingSupplementsDetails?: Maybe<(
          { __typename?: 'BookingSupplementsDetails' }
          & { supplementDetails: Array<(
            { __typename?: 'BookingSupplementType' }
            & Pick<BookingSupplementType, 'supplementId' | 'iconName' | 'name' | 'description'>
            & { price: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
            ) }
          )> }
        )>, fees: (
          { __typename?: 'Fees' }
          & { bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
          ) }
        ), guest: (
          { __typename?: 'Guest' }
          & Pick<Guest, 'firstName' | 'imageUrl' | 'lastName'>
        ), summary: (
          { __typename?: 'TripSummary' }
          & Pick<TripSummary, 'imageUrl' | 'name'>
          & { location: (
            { __typename?: 'Location' }
            & { address: (
              { __typename?: 'AddressType' }
              & Pick<AddressType, 'city' | 'countryCode'>
            ) }
          ) }
        ) }
      ) }
    )> }
  )> }
);

export type UpdatePropertyAmenitiesMutationVariables = Exact<{
  input: UpdatePropertyAmenitiesCommand;
}>;


export type UpdatePropertyAmenitiesMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePropertyAmenities: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyAmenitiesPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type UpdatePropertyAmenitiesPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { amenities?: Maybe<(
      { __typename?: 'PropertyAmenities' }
      & Pick<PropertyAmenities, 'amenityIds'>
    )> }
  )> }
);

export type UpdatePropertyAmenityBundleByAssetQueryVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type UpdatePropertyAmenityBundleByAssetQuery = (
  { __typename?: 'QueryRoot' }
  & { amenityBundleByAsset?: Maybe<(
    { __typename?: 'AmenityBundleType' }
    & Pick<AmenityBundleType, 'bundleId' | 'name' | 'enabled'>
    & { sections: Array<(
      { __typename?: 'AmenityBundleSectionType' }
      & Pick<AmenityBundleSectionType, 'sectionId' | 'name'>
      & { amenities: Array<(
        { __typename?: 'AmenityType' }
        & Pick<AmenityType, 'amenityId' | 'name' | 'description' | 'iconName' | 'enabled'>
      )> }
    )> }
  )> }
);

export type UpdatePropertyCleaningFeesListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type UpdatePropertyCleaningFeesListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & { cleaningService: (
      { __typename?: 'CleaningService' }
      & Pick<CleaningService, 'paymentMode'>
      & { perDayCleaningFee?: Maybe<(
        { __typename?: 'CleaningFee' }
        & Pick<CleaningFee, 'description'>
        & { amount: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>, perStayCleaningFee?: Maybe<(
        { __typename?: 'CleaningFee' }
        & Pick<CleaningFee, 'description'>
        & { amount: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )> }
    ) }
  ) }
);

export type UpdatePropertyCleaningServiceMutationVariables = Exact<{
  input: UpdateListingCleaningServiceCommand;
}>;


export type UpdatePropertyCleaningServiceMutation = (
  { __typename?: 'MutationRoot' }
  & { updateListingCleaningService: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyGeneralMutationVariables = Exact<{
  input: UpdatePropertyGeneralCommand;
}>;


export type UpdatePropertyGeneralMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePropertyGeneral: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyGeneralPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type UpdatePropertyGeneralPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & Pick<Property, 'propertyId'>
    & { general?: Maybe<(
      { __typename?: 'PropertyGeneral' }
      & Pick<PropertyGeneral, 'bathrooms' | 'description' | 'name' | 'propertyStyle' | 'propertyType' | 'sleeps'>
      & { approximateValue?: Maybe<(
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      )>, bedrooms?: Maybe<Array<Maybe<(
        { __typename?: 'BedroomType' }
        & Pick<BedroomType, 'bedSizes'>
      )>>> }
    )>, location?: Maybe<(
      { __typename?: 'PropertyLocation' }
      & { address?: Maybe<(
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1'>
      )> }
    )> }
  )> }
);

export type UpdatePropertyHouseRulesMutationVariables = Exact<{
  input: UpdatePropertyHouseRulesCommand;
}>;


export type UpdatePropertyHouseRulesMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePropertyHouseRules: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyHouseRulesPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type UpdatePropertyHouseRulesPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { houseRules?: Maybe<(
      { __typename?: 'PropertyHouseRules' }
      & Pick<PropertyHouseRules, 'allowsChildren' | 'allowsEvents' | 'allowsInfants' | 'allowsPets' | 'allowsSmoking'>
    )> }
  )> }
);

export type UpdatePropertyLocationMutationVariables = Exact<{
  input: UpdatePropertyLocationCommand;
}>;


export type UpdatePropertyLocationMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePropertyLocation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyLocationPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type UpdatePropertyLocationPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { location?: Maybe<(
      { __typename?: 'PropertyLocation' }
      & { address?: Maybe<(
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'additionalInstructions' | 'city' | 'countryCode' | 'line1' | 'line2' | 'postalCode' | 'region'>
      )>, geoLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lng'>
      )> }
    )> }
  )> }
);

export type UpdatePropertyPhotosMutationVariables = Exact<{
  input: UpdatePropertyPhotosCommand;
}>;


export type UpdatePropertyPhotosMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePropertyPhotos: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePropertyPhotosPropertyQueryVariables = Exact<{
  propertyId: Scalars['ID'];
}>;


export type UpdatePropertyPhotosPropertyQuery = (
  { __typename?: 'QueryRoot' }
  & { property?: Maybe<(
    { __typename?: 'Property' }
    & { imagery: (
      { __typename?: 'PropertyImagery' }
      & { photos?: Maybe<Array<Maybe<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'description' | 'url' | 'useAsCover' | 'useAsFloorplan'>
      )>>> }
    ) }
  )> }
);

export type UpdatePropertyPhotosSignedUrlMutationVariables = Exact<{
  input: PropertyPhotoSignUrlRequest;
}>;


export type UpdatePropertyPhotosSignedUrlMutation = (
  { __typename?: 'MutationRoot' }
  & { propertyPhotoSignUrl: (
    { __typename?: 'PhotoUrlSignature' }
    & Pick<PhotoUrlSignature, 'contentType' | 'httpMethod' | 'signedUrl' | 'url'>
  ) }
);

export type UpdatePropertySupplementBundlesQueryVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type UpdatePropertySupplementBundlesQuery = (
  { __typename?: 'QueryRoot' }
  & { supplementBundleByAsset?: Maybe<(
    { __typename?: 'SupplementBundleType' }
    & Pick<SupplementBundleType, 'bundleId' | 'name' | 'enabled'>
    & { sections: Array<(
      { __typename?: 'SupplementBundleSectionType' }
      & Pick<SupplementBundleSectionType, 'sectionId' | 'name'>
      & { supplements: Array<(
        { __typename?: 'SupplementType' }
        & Pick<SupplementType, 'supplementId' | 'name' | 'description' | 'iconName' | 'enabled'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdatePropertySupplementIdsListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type UpdatePropertySupplementIdsListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & { supplements: (
      { __typename?: 'ListingSupplements' }
      & Pick<ListingSupplements, 'listingId'>
      & { mandatorySupplements?: Maybe<Array<(
        { __typename?: 'ListingSupplementEntry' }
        & Pick<ListingSupplementEntry, 'id' | 'description'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>>, optionalSupplements?: Maybe<Array<(
        { __typename?: 'ListingSupplementEntry' }
        & Pick<ListingSupplementEntry, 'id' | 'description'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>> }
    ) }
  ) }
);

export type UpdatePropertySupplementsListingMutationVariables = Exact<{
  input: UpdateListingSupplementsCommand;
}>;


export type UpdatePropertySupplementsListingMutation = (
  { __typename?: 'MutationRoot' }
  & { updateListingSupplements: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type InviteStaffMemberMutationVariables = Exact<{
  input: InviteStaffMemberCommand;
}>;


export type InviteStaffMemberMutation = (
  { __typename?: 'MutationRoot' }
  & { inviteStaffMember: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type RemoveMemberFromStaffMutationVariables = Exact<{
  input: RemoveMemberFromStaffCommand;
}>;


export type RemoveMemberFromStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { removeMemberFromStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type StaffMemberInvitationsQueryVariables = Exact<{
  staffId: Scalars['ID'];
}>;


export type StaffMemberInvitationsQuery = (
  { __typename?: 'QueryRoot' }
  & { staffMemberInvitations: Array<(
    { __typename?: 'StaffMemberInvitation' }
    & Pick<StaffMemberInvitation, 'email' | 'firstName' | 'host' | 'invitationId' | 'lastName' | 'manager' | 'status'>
  )> }
);

export type StaffMembersQueryVariables = Exact<{
  staffId: Scalars['ID'];
}>;


export type StaffMembersQuery = (
  { __typename?: 'QueryRoot' }
  & { staffMembers: Array<(
    { __typename?: 'StaffMember' }
    & Pick<StaffMember, 'userId' | 'email' | 'firstName' | 'lastName' | 'host' | 'manager' | 'boss'>
  )> }
);

export type UpdateStaffPhotoSignedUrlMutationVariables = Exact<{
  input: StaffPhotoSignUrlRequest;
}>;


export type UpdateStaffPhotoSignedUrlMutation = (
  { __typename?: 'MutationRoot' }
  & { staffPhotoSignUrl: (
    { __typename?: 'PhotoUrlSignature' }
    & Pick<PhotoUrlSignature, 'contentType' | 'httpMethod' | 'signedUrl' | 'url'>
  ) }
);

export type AcceptBrandBusinessOwnerInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptBrandBusinessOwnerInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptBrandBusinessOwnerInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptBrandBusinessOwnerInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptBrandBusinessOwnerInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptBrandBusinessOwnerInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptBrandBusinessOwnerInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptBrandBusinessOwnerInvitationInvitationQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptBrandBusinessOwnerInvitationInvitationQuery = (
  { __typename?: 'QueryRoot' }
  & { brandBusinessOwnerInvitation: (
    { __typename?: 'BrandBusinessOwnerInvitation' }
    & Pick<BrandBusinessOwnerInvitation, 'status'>
  ) }
);

export type AcceptPmcBusinessOwnerInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptPmcBusinessOwnerInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptPmcBusinessOwnerInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPmcBusinessOwnerInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptPmcBusinessOwnerInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPmcBusinessOwnerInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptPmcBusinessOwnerInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPmcBusinessOwnerInvitationInvitationQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptPmcBusinessOwnerInvitationInvitationQuery = (
  { __typename?: 'QueryRoot' }
  & { pmcBusinessOwnerInvitation: (
    { __typename?: 'PmcBusinessOwnerInvitation' }
    & Pick<PmcBusinessOwnerInvitation, 'status'>
  ) }
);

export type AcceptStaffMemberInvitationMutationVariables = Exact<{
  input: AcceptStaffMemberInvitationCommand;
}>;


export type AcceptStaffMemberInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptStaffMemberInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptStaffMemberInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptStaffMemberInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptStaffMemberInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptStaffMemberInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptStaffMemberInvitationSummaryQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptStaffMemberInvitationSummaryQuery = (
  { __typename?: 'QueryRoot' }
  & { staffMemberInvitationSummary?: Maybe<(
    { __typename?: 'StaffMemberInvitationSummary' }
    & Pick<StaffMemberInvitationSummary, 'invitationId' | 'staffId' | 'status'>
  )> }
);

export type AcceptTravelAgentBusinessOwnerInvitationMutationVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptTravelAgentBusinessOwnerInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptTravelAgentBusinessOwnerInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptTravelAgentBusinessOwnerInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptTravelAgentBusinessOwnerInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptTravelAgentBusinessOwnerInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptTravelAgentBusinessOwnerInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptTravelAgentBusinessOwnerInvitationInvitationQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptTravelAgentBusinessOwnerInvitationInvitationQuery = (
  { __typename?: 'QueryRoot' }
  & { travelAgentBusinessOwnerInvitation: (
    { __typename?: 'TravelAgentBusinessOwnerInvitation' }
    & Pick<TravelAgentBusinessOwnerInvitation, 'status'>
  ) }
);

export type AccountOwnedPmcsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountOwnedPmcsQuery = (
  { __typename?: 'QueryRoot' }
  & { ownedPmcs?: Maybe<Array<Maybe<(
    { __typename?: 'Pmc' }
    & Pick<Pmc, 'pmcId' | 'name'>
  )>>> }
);

export type AccountOwnedTravelAgentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountOwnedTravelAgentsQuery = (
  { __typename?: 'QueryRoot' }
  & { ownedTravelAgents?: Maybe<Array<Maybe<(
    { __typename?: 'TravelAgent' }
    & Pick<TravelAgent, 'travelAgentId' | 'name'>
  )>>> }
);

export type UpdateBankAccountMutationVariables = Exact<{
  input: UpdateBankAccountCommand;
}>;


export type UpdateBankAccountMutation = (
  { __typename?: 'MutationRoot' }
  & { updateBankAccount: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateBankAccountDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdateBankAccountDetailsQuery = (
  { __typename?: 'QueryRoot' }
  & { bankAccountDetails?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { bankAccount: (
      { __typename?: 'BankAccount' }
      & Pick<BankAccount, 'accountType' | 'accountHolderName' | 'bankName' | 'countryCode' | 'bankCode' | 'transitNumber' | 'accountNumber'>
    ) }
  )> }
);

export type UpdatePersonalInformationMutationVariables = Exact<{
  input: UpdatePersonalInformationCommand;
}>;


export type UpdatePersonalInformationMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalInformation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePersonalInformationProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdatePersonalInformationProfileQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { personalProfile: (
      { __typename?: 'PersonalProfile' }
      & Pick<PersonalProfile, 'email' | 'firstName' | 'lastName'>
      & { phoneNumber: (
        { __typename?: 'PhoneNumberType' }
        & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
      ) }
    ) }
  )> }
);

export type UpdatePersonalPhotoMutationVariables = Exact<{
  input: UpdatePersonalPhotoCommand;
}>;


export type UpdatePersonalPhotoMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoto: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePersonalPhotoProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdatePersonalPhotoProfileQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { personalProfile: (
      { __typename?: 'PersonalProfile' }
      & Pick<PersonalProfile, 'email' | 'photoUrl'>
    ) }
  )> }
);

export type UpdateMemberPhotoSignedUrlMutationVariables = Exact<{
  input: ProfilePhotoSignUrlRequest;
}>;


export type UpdateMemberPhotoSignedUrlMutation = (
  { __typename?: 'MutationRoot' }
  & { profilePhotoSignUrl: (
    { __typename?: 'PhotoUrlSignature' }
    & Pick<PhotoUrlSignature, 'contentType' | 'httpMethod' | 'signedUrl' | 'url'>
  ) }
);

export type CreateStaffMutationVariables = Exact<{
  input: CreateStaffCommand;
}>;


export type CreateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { createStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AssignPersonalStaffToMemberMutationVariables = Exact<{
  input: AssignPersonalStaffToMemberCommand;
}>;


export type AssignPersonalStaffToMemberMutation = (
  { __typename?: 'MutationRoot' }
  & { assignPersonalStaffToMember: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdatePersonalStaffQueryVariables = Exact<{ [key: string]: never; }>;


export type UpdatePersonalStaffQuery = (
  { __typename?: 'QueryRoot' }
  & { myPersonalStaff?: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'displayName' | 'email' | 'photoUrl' | 'staffId'>
    & { phoneNumber?: Maybe<(
      { __typename?: 'PhoneNumberType' }
      & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
    )> }
  )> }
);

export type UpdateStaffMutationVariables = Exact<{
  input: UpdateStaffCommand;
}>;


export type UpdateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { updateStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdatePmcAssignStaffToPmcMutationVariables = Exact<{
  input: AssignStaffToPmcCommand;
}>;


export type PartnerUpdatePmcAssignStaffToPmcMutation = (
  { __typename?: 'MutationRoot' }
  & { assignStaffToPmc: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdatePmcCreateStaffMutationVariables = Exact<{
  input: CreateStaffCommand;
}>;


export type PartnerUpdatePmcCreateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { createStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdatePmcListStaffQueryVariables = Exact<{
  pmcId: Scalars['ID'];
}>;


export type PartnerUpdatePmcListStaffQuery = (
  { __typename?: 'QueryRoot' }
  & { pmcStaff?: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'displayName' | 'email' | 'photoUrl' | 'staffId'>
    & { phoneNumber?: Maybe<(
      { __typename?: 'PhoneNumberType' }
      & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
    )> }
  )> }
);

export type PartnerUpdatePmcUpdateStaffMutationVariables = Exact<{
  input: UpdateStaffCommand;
}>;


export type PartnerUpdatePmcUpdateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { updateStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdateTravelAgentAssignStaffToTravelAgentMutationVariables = Exact<{
  input: AssignStaffToTravelAgentCommand;
}>;


export type PartnerUpdateTravelAgentAssignStaffToTravelAgentMutation = (
  { __typename?: 'MutationRoot' }
  & { assignStaffToTravelAgent: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdateTravelAgentCreateStaffMutationVariables = Exact<{
  input: CreateStaffCommand;
}>;


export type PartnerUpdateTravelAgentCreateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { createStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type PartnerUpdateTravelAgentListStaffQueryVariables = Exact<{
  travelAgentId: Scalars['ID'];
}>;


export type PartnerUpdateTravelAgentListStaffQuery = (
  { __typename?: 'QueryRoot' }
  & { travelAgentStaff?: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'displayName' | 'email' | 'photoUrl' | 'staffId'>
    & { phoneNumber?: Maybe<(
      { __typename?: 'PhoneNumberType' }
      & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
    )> }
  )> }
);

export type PartnerUpdateTravelAgentUpdateStaffMutationVariables = Exact<{
  input: UpdateStaffCommand;
}>;


export type PartnerUpdateTravelAgentUpdateStaffMutation = (
  { __typename?: 'MutationRoot' }
  & { updateStaff: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AddApplicationPhotosMutationVariables = Exact<{
  input: AddApplicationPhotosCommand;
}>;


export type AddApplicationPhotosMutation = (
  { __typename?: 'MutationRoot' }
  & { addApplicationPhotos: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ApplicationPhotosSignedUrlMutationVariables = Exact<{
  input: ApplicationPhotoSignUrlRequest;
}>;


export type ApplicationPhotosSignedUrlMutation = (
  { __typename?: 'MutationRoot' }
  & { applicationPhotoSignUrl: (
    { __typename?: 'PhotoUrlSignature' }
    & Pick<PhotoUrlSignature, 'contentType' | 'httpMethod' | 'signedUrl' | 'url'>
  ) }
);

export type ApplicationMutationVariables = Exact<{
  input: SubmitMemberApplicationCommand;
}>;


export type ApplicationMutation = (
  { __typename?: 'MutationRoot' }
  & { submitMemberApplication: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ApplicationActiveMemberApplicationQueryVariables = Exact<{ [key: string]: never; }>;


export type ApplicationActiveMemberApplicationQuery = (
  { __typename?: 'QueryRoot' }
  & { activeMemberApplication?: Maybe<(
    { __typename?: 'MemberApplication' }
    & Pick<MemberApplication, 'status'>
  )> }
);

export type BalanceLogQueryVariables = Exact<{ [key: string]: never; }>;


export type BalanceLogQuery = (
  { __typename?: 'QueryRoot' }
  & { myBalanceLog: (
    { __typename?: 'BalanceLog' }
    & { tokenType: (
      { __typename?: 'TokenType' }
      & Pick<TokenType, 'name' | 'symbol'>
    ), logLines: Array<(
      { __typename?: 'BalanceLogLine' }
      & Pick<BalanceLogLine, 'amount' | 'balance' | 'date' | 'description' | 'name'>
    )> }
  ) }
);

export type CalendarListingsQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type CalendarListingsQuery = (
  { __typename?: 'QueryRoot' }
  & { listingsByMember: Array<(
    { __typename?: 'Listing' }
    & Pick<Listing, 'status' | 'listingId'>
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ), summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle'>
      & { photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover'>
      )> }
    ) }
  )> }
);

export type GuestbookBusinessCustomerQueryVariables = Exact<{
  businessId: Scalars['ID'];
  userId: Scalars['String'];
}>;


export type GuestbookBusinessCustomerQuery = (
  { __typename?: 'QueryRoot' }
  & { businessCustomer: (
    { __typename?: 'BusinessCustomer' }
    & Pick<BusinessCustomer, 'userId' | 'email' | 'firstName' | 'lastName' | 'photoUrl'>
    & { phoneNumber: (
      { __typename?: 'PhoneNumberType' }
      & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
    ), trips: Array<(
      { __typename?: 'Trip' }
      & Pick<Trip, 'tripId'>
      & { bookings: Array<Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'tripId' | 'bookingId' | 'listingId' | 'status' | 'bookingReference' | 'checkin' | 'checkout' | 'nights' | 'numberOfGuests'>
        & { fees: (
          { __typename?: 'Fees' }
          & { tokenType: (
            { __typename?: 'TokenType' }
            & Pick<TokenType, 'name' | 'symbol'>
          ), tokenFee: (
            { __typename?: 'TokenAmountType' }
            & Pick<TokenAmountType, 'amount'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ), bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ) }
        ), guest: (
          { __typename?: 'Guest' }
          & Pick<Guest, 'guestId' | 'firstName' | 'lastName' | 'imageUrl'>
        ), summary: (
          { __typename?: 'TripSummary' }
          & Pick<TripSummary, 'name' | 'imageUrl'>
          & { location: (
            { __typename?: 'Location' }
            & { geoLocation: (
              { __typename?: 'GeoLocation' }
              & Pick<GeoLocation, 'lat' | 'lng'>
            ), address: (
              { __typename?: 'AddressType' }
              & Pick<AddressType, 'line1' | 'line2' | 'city' | 'postalCode' | 'countryCode' | 'additionalInstructions'>
            ) }
          ) }
        ), bookingSupplementsDetails?: Maybe<(
          { __typename?: 'BookingSupplementsDetails' }
          & { supplementDetails: Array<(
            { __typename?: 'BookingSupplementType' }
            & Pick<BookingSupplementType, 'supplementId' | 'iconName' | 'name' | 'description'>
            & { price: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ) }
          )> }
        )> }
      )>> }
    )> }
  ) }
);

export type TripSummaryHostQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type TripSummaryHostQuery = (
  { __typename?: 'QueryRoot' }
  & { hostingTeamByListing?: Maybe<(
    { __typename?: 'Host' }
    & Pick<Host, 'displayName' | 'imageUrl'>
  )> }
);

export type GuestbookClienteleByBusinessIdQueryVariables = Exact<{
  businessId: Scalars['ID'];
}>;


export type GuestbookClienteleByBusinessIdQuery = (
  { __typename?: 'QueryRoot' }
  & { clienteleByBusinessId: (
    { __typename?: 'Clientele' }
    & Pick<Clientele, 'clienteleId' | 'businessId'>
    & { customers: Array<(
      { __typename?: 'BusinessCustomer' }
      & Pick<BusinessCustomer, 'userId' | 'firstName' | 'lastName' | 'email' | 'photoUrl'>
      & { phoneNumber: (
        { __typename?: 'PhoneNumberType' }
        & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
      ) }
    )> }
  ) }
);

export type PayRescheduleBookingBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type PayRescheduleBookingBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'tripId'>
  ) }
);

export type PayRescheduleBookingOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type PayRescheduleBookingOrderQuery = (
  { __typename?: 'QueryRoot' }
  & { rescheduleBookingOrder: (
    { __typename?: 'RescheduleBookingOrderRequest' }
    & Pick<RescheduleBookingOrderRequest, 'bookingId' | 'checkin' | 'checkout'>
    & { feesDue?: Maybe<(
      { __typename?: 'Fees' }
      & { tokenType: (
        { __typename?: 'TokenType' }
        & Pick<TokenType, 'name' | 'symbol'>
        & { exchangeRate: (
          { __typename?: 'TokenExchangeRate' }
          & Pick<TokenExchangeRate, 'amountMicros' | 'currency'>
        ) }
      ), tokenFee: (
        { __typename?: 'TokenAmountType' }
        & Pick<TokenAmountType, 'amount'>
      ), exchangeFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ) }
    )> }
  ) }
);

export type PayStayExtensionBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type PayStayExtensionBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'tripId'>
  ) }
);

export type PayStayExtensionOrderQueryVariables = Exact<{
  orderId: Scalars['ID'];
}>;


export type PayStayExtensionOrderQuery = (
  { __typename?: 'QueryRoot' }
  & { stayExtensionOrder: (
    { __typename?: 'StayExtensionOrderRequest' }
    & Pick<StayExtensionOrderRequest, 'bookingId' | 'checkout'>
    & { feesDue?: Maybe<(
      { __typename?: 'Fees' }
      & { tokenType: (
        { __typename?: 'TokenType' }
        & Pick<TokenType, 'name' | 'symbol'>
        & { exchangeRate: (
          { __typename?: 'TokenExchangeRate' }
          & Pick<TokenExchangeRate, 'amountMicros' | 'currency'>
        ) }
      ), tokenFee: (
        { __typename?: 'TokenAmountType' }
        & Pick<TokenAmountType, 'amount'>
      ), exchangeFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ) }
    )> }
  ) }
);

export type ConfirmListingByTravelDatesQueryVariables = Exact<{
  listingId: Scalars['ID'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
}>;


export type ConfirmListingByTravelDatesQuery = (
  { __typename?: 'QueryRoot' }
  & { listingByTravelDates: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'checkin' | 'checkout' | 'listingId'>
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ), fees?: Maybe<(
      { __typename?: 'Fees' }
      & { tokenType: (
        { __typename?: 'TokenType' }
        & Pick<TokenType, 'name' | 'symbol'>
      ), tokenFee: (
        { __typename?: 'TokenAmountType' }
        & Pick<TokenAmountType, 'amount'>
      ), exchangeFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ), bookingFee: (
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      ) }
    )>, summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle'>
      & { houseRules: (
        { __typename?: 'ListingHouseRules' }
        & Pick<ListingHouseRules, 'otherRules'>
        & { rules: Array<(
          { __typename?: 'ListingHouseRule' }
          & Pick<ListingHouseRule, 'enabled' | 'icon' | 'subtitle' | 'title'>
        )> }
      ), photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url'>
      )> }
    ) }
  ) }
);

export type ConfirmBookingCancelCheckoutMutationVariables = Exact<{
  input: CancelCheckoutRequest;
}>;


export type ConfirmBookingCancelCheckoutMutation = (
  { __typename?: 'MutationRoot' }
  & { cancelCheckout: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConfirmBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type ConfirmBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'checkin' | 'checkout'>
    & { summary: (
      { __typename?: 'TripSummary' }
      & Pick<TripSummary, 'imageUrl' | 'name'>
      & { location: (
        { __typename?: 'Location' }
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'countryCode'>
        ) }
      ) }
    ) }
  ) }
);

export type ConfirmPriceBreakdownTokenBalanceQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfirmPriceBreakdownTokenBalanceQuery = (
  { __typename?: 'QueryRoot' }
  & { myTokenBalance: (
    { __typename?: 'TokenBalance' }
    & Pick<TokenBalance, 'accountBalanceId' | 'amount'>
    & { tokenType: (
      { __typename?: 'TokenType' }
      & Pick<TokenType, 'name'>
      & { exchangeRate: (
        { __typename?: 'TokenExchangeRate' }
        & Pick<TokenExchangeRate, 'currency' | 'amountMicros'>
      ) }
    ) }
  ) }
);

export type ConsumerSignupMutationVariables = Exact<{
  input: SignupConsumerRequest;
}>;


export type ConsumerSignupMutation = (
  { __typename?: 'MutationRoot' }
  & { signupConsumer: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConsumerSignupUpdatePersonalInformationMutationVariables = Exact<{
  input: UpdatePersonalInformationCommand;
}>;


export type ConsumerSignupUpdatePersonalInformationMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalInformation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConsumerSignupAcceptTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type ConsumerSignupAcceptTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ConsumerSignupAccountExistsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsumerSignupAccountExistsQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId' | 'accountHolderId'>
    & { personalProfile: (
      { __typename?: 'PersonalProfile' }
      & Pick<PersonalProfile, 'email' | 'firstName' | 'lastName'>
      & { phoneNumber: (
        { __typename?: 'PhoneNumberType' }
        & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
      ) }
    ) }
  )> }
);

export type FavouriteQueryVariables = Exact<{
  favouriteType?: Maybe<FavouriteType>;
  referenceId: Scalars['String'];
}>;


export type FavouriteQuery = (
  { __typename?: 'QueryRoot' }
  & { favourite?: Maybe<(
    { __typename?: 'Favourite' }
    & Pick<Favourite, 'addedAsFavouriteByOthers'>
    & { reference?: Maybe<(
      { __typename?: 'FavouriteReference' }
      & Pick<FavouriteReference, 'type' | 'referenceId'>
    )> }
  )> }
);

export type AddToFavouritesMutationVariables = Exact<{
  input: AddToFavouritesCommand;
}>;


export type AddToFavouritesMutation = (
  { __typename?: 'MutationRoot' }
  & { addToFavourites: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type RemoveFromFavouritesMutationVariables = Exact<{
  input: RemoveFromFavouritesCommand;
}>;


export type RemoveFromFavouritesMutation = (
  { __typename?: 'MutationRoot' }
  & { removeFromFavourites: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type FavouriteListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type FavouriteListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'listingId' | 'status'>
    & { summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'title' | 'subtitle' | 'city' | 'countryCode'>
      & { decidingFactors: (
        { __typename?: 'DecidingFactors' }
        & { primaryDecidingFactors: Array<(
          { __typename?: 'DecidingFactor' }
          & Pick<DecidingFactor, 'enabled' | 'icon' | 'title' | 'subtitle'>
        )> }
      ), highlightedFeatures: (
        { __typename?: 'HighlightedFeatures' }
        & { features: Array<(
          { __typename?: 'ListingHighlightedFeature' }
          & Pick<ListingHighlightedFeature, 'title' | 'description' | 'iconName'>
        )> }
      ), photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'description' | 'useAsCover' | 'useAsFloorplan'>
      )> }
    ) }
  ) }
);

export type FavouritesQueryVariables = Exact<{ [key: string]: never; }>;


export type FavouritesQuery = (
  { __typename?: 'QueryRoot' }
  & { favourites: Array<Maybe<(
    { __typename?: 'Favourite' }
    & Pick<Favourite, 'addedAsFavouriteByOthers'>
    & { reference?: Maybe<(
      { __typename?: 'FavouriteReference' }
      & Pick<FavouriteReference, 'type' | 'referenceId'>
    )> }
  )>> }
);

export type FeaturedListingListingsQueryVariables = Exact<{
  featuredListingCode: Scalars['String'];
}>;


export type FeaturedListingListingsQuery = (
  { __typename?: 'QueryRoot' }
  & { featuredListings: (
    { __typename?: 'PublicListing' }
    & Pick<PublicListing, 'listingCode' | 'name'>
    & { publicListingEntries?: Maybe<Array<(
      { __typename?: 'PublicListingEntry' }
      & Pick<PublicListingEntry, 'listingId' | 'city' | 'countryCode' | 'title'>
      & { photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'description' | 'useAsCover'>
      )>, decidingFactors: (
        { __typename?: 'DecidingFactors' }
        & { primaryDecidingFactors: Array<(
          { __typename?: 'DecidingFactor' }
          & Pick<DecidingFactor, 'enabled' | 'icon' | 'title' | 'subtitle'>
        )> }
      ) }
    )>> }
  ) }
);

export type AcceptAssetOwnerInvitationMutationVariables = Exact<{
  input: AcceptAssetOwnerInvitationCommand;
}>;


export type AcceptAssetOwnerInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptAssetOwnerInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptAssetOwnerAddressMutationVariables = Exact<{
  input: UpdatePersonalAddressCommand;
}>;


export type AcceptAssetOwnerAddressMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalAddress: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptAssetOwnerInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptAssetOwnerInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptAssetOwnerInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptAssetOwnerInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptAssetOwnerInvitationSummaryQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptAssetOwnerInvitationSummaryQuery = (
  { __typename?: 'QueryRoot' }
  & { assetOwnerInvitationSummary?: Maybe<(
    { __typename?: 'AssetOwnerInvitationSummary' }
    & Pick<AssetOwnerInvitationSummary, 'invitationId' | 'assetId' | 'status'>
  )> }
);

export type HotelHeaderHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type HotelHeaderHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { general?: Maybe<(
      { __typename?: 'HotelGeneral' }
      & Pick<HotelGeneral, 'name'>
    )>, imagery: (
      { __typename?: 'HotelImagery' }
      & { photos?: Maybe<Array<Maybe<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url'>
      )>>> }
    ), location?: Maybe<(
      { __typename?: 'HotelLocation' }
      & { address?: Maybe<(
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'city' | 'countryCode'>
      )> }
    )> }
  ) }
);

export type UpdateHotelAmenitiesMutationVariables = Exact<{
  input: UpdateHotelAmenitiesCommand;
}>;


export type UpdateHotelAmenitiesMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelAmenities: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelAmenitiesHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelAmenitiesHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { amenities?: Maybe<(
      { __typename?: 'HotelAmenities' }
      & Pick<HotelAmenities, 'amenityIds'>
    )> }
  ) }
);

export type UpdateHotelAmenityBundleByAssetQueryVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type UpdateHotelAmenityBundleByAssetQuery = (
  { __typename?: 'QueryRoot' }
  & { amenityBundleByAsset?: Maybe<(
    { __typename?: 'AmenityBundleType' }
    & Pick<AmenityBundleType, 'bundleId' | 'name' | 'enabled'>
    & { sections: Array<(
      { __typename?: 'AmenityBundleSectionType' }
      & Pick<AmenityBundleSectionType, 'sectionId' | 'name'>
      & { amenities: Array<(
        { __typename?: 'AmenityType' }
        & Pick<AmenityType, 'amenityId' | 'name' | 'description' | 'iconName' | 'enabled'>
      )> }
    )> }
  )> }
);

export type UpdateHotelGeneralMutationVariables = Exact<{
  input: UpdateHotelGeneralCommand;
}>;


export type UpdateHotelGeneralMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelGeneral: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelGeneralHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelGeneralHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'hotelId'>
    & { general?: Maybe<(
      { __typename?: 'HotelGeneral' }
      & Pick<HotelGeneral, 'description' | 'name' | 'stars' | 'website'>
      & { averageDailyRate?: Maybe<(
        { __typename?: 'CurrencyAmountType' }
        & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
      )> }
    )>, location?: Maybe<(
      { __typename?: 'HotelLocation' }
      & { address?: Maybe<(
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'line1'>
      )> }
    )> }
  ) }
);

export type UpdateHotelHouseRulesMutationVariables = Exact<{
  input: UpdateHotelHouseRulesCommand;
}>;


export type UpdateHotelHouseRulesMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelHouseRules: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelHouseRulesHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelHouseRulesHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { houseRules?: Maybe<(
      { __typename?: 'HotelHouseRules' }
      & Pick<HotelHouseRules, 'allowsChildren' | 'earliestCheckin' | 'latestCheckin' | 'maximumStay' | 'minimumStay'>
    )> }
  ) }
);

export type UpdateHotelLocationMutationVariables = Exact<{
  input: UpdateHotelLocationCommand;
}>;


export type UpdateHotelLocationMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelLocation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelLocationHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelLocationHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { location?: Maybe<(
      { __typename?: 'HotelLocation' }
      & { address?: Maybe<(
        { __typename?: 'AddressType' }
        & Pick<AddressType, 'additionalInstructions' | 'city' | 'countryCode' | 'line1' | 'line2' | 'postalCode' | 'region'>
      )>, geoLocation?: Maybe<(
        { __typename?: 'GeoLocation' }
        & Pick<GeoLocation, 'lat' | 'lng'>
      )> }
    )> }
  ) }
);

export type UpdateHotelPhotosMutationVariables = Exact<{
  input: UpdateHotelPhotosCommand;
}>;


export type UpdateHotelPhotosMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelPhotos: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelPhotosHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelPhotosHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { imagery: (
      { __typename?: 'HotelImagery' }
      & { photos?: Maybe<Array<Maybe<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'description' | 'url' | 'useAsCover' | 'useAsFloorplan'>
      )>>> }
    ) }
  ) }
);

export type UpdateHotelPhotosSignedUrlMutationVariables = Exact<{
  input: HotelPhotoSignUrlRequest;
}>;


export type UpdateHotelPhotosSignedUrlMutation = (
  { __typename?: 'MutationRoot' }
  & { hotelPhotoSignUrl: (
    { __typename?: 'PhotoUrlSignature' }
    & Pick<PhotoUrlSignature, 'contentType' | 'httpMethod' | 'signedUrl' | 'url'>
  ) }
);

export type UpdateHotelGoodToKnowMutationVariables = Exact<{
  input: UpdateHotelGoodToKnowCommand;
}>;


export type UpdateHotelGoodToKnowMutation = (
  { __typename?: 'MutationRoot' }
  & { updateHotelGoodToKnow: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelGoodToKnowHotelQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelGoodToKnowHotelQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { goodToKnow?: Maybe<(
      { __typename?: 'HotelGoodToKnow' }
      & Pick<HotelGoodToKnow, 'description'>
    )> }
  ) }
);

export type AssignStaffToHotelMutationVariables = Exact<{
  input: AssignStaffToHotelCommand;
}>;


export type AssignStaffToHotelMutation = (
  { __typename?: 'MutationRoot' }
  & { assignStaffToHotel: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateHotelStaffQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelStaffQuery = (
  { __typename?: 'QueryRoot' }
  & { hotelStaff?: Maybe<(
    { __typename?: 'Staff' }
    & Pick<Staff, 'displayName' | 'email' | 'photoUrl' | 'staffId'>
    & { phoneNumber?: Maybe<(
      { __typename?: 'PhoneNumberType' }
      & Pick<PhoneNumberType, 'countryCode' | 'nationalNumber'>
    )> }
  )> }
);

export type UpdateHotelSupplementsQueryVariables = Exact<{
  hotelId: Scalars['ID'];
}>;


export type UpdateHotelSupplementsQuery = (
  { __typename?: 'QueryRoot' }
  & { hotel: (
    { __typename?: 'Hotel' }
    & { rooms: Array<(
      { __typename?: 'HotelRoom' }
      & Pick<HotelRoom, 'hotelRoomId' | 'roomType'>
    )> }
  ) }
);

export type UpdateHotelSupplementBundlesQueryVariables = Exact<{
  assetId: Scalars['ID'];
}>;


export type UpdateHotelSupplementBundlesQuery = (
  { __typename?: 'QueryRoot' }
  & { supplementBundleByAsset?: Maybe<(
    { __typename?: 'SupplementBundleType' }
    & Pick<SupplementBundleType, 'bundleId' | 'name' | 'enabled'>
    & { sections: Array<(
      { __typename?: 'SupplementBundleSectionType' }
      & Pick<SupplementBundleSectionType, 'sectionId' | 'name'>
      & { supplements: Array<(
        { __typename?: 'SupplementType' }
        & Pick<SupplementType, 'supplementId' | 'name' | 'description' | 'iconName' | 'enabled'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )> }
    )> }
  )> }
);

export type UpdateHotelSupplementIdsListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type UpdateHotelSupplementIdsListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & { supplements: (
      { __typename?: 'ListingSupplements' }
      & Pick<ListingSupplements, 'listingId'>
      & { mandatorySupplements?: Maybe<Array<(
        { __typename?: 'ListingSupplementEntry' }
        & Pick<ListingSupplementEntry, 'id' | 'description'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>>, optionalSupplements?: Maybe<Array<(
        { __typename?: 'ListingSupplementEntry' }
        & Pick<ListingSupplementEntry, 'id' | 'description'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'currency' | 'amountMicros'>
        ) }
      )>> }
    ) }
  ) }
);

export type UpdateHotelSupplementsListingMutationVariables = Exact<{
  input: UpdateListingSupplementsCommand;
}>;


export type UpdateHotelSupplementsListingMutation = (
  { __typename?: 'MutationRoot' }
  & { updateListingSupplements: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type HotelsQueryVariables = Exact<{ [key: string]: never; }>;


export type HotelsQuery = (
  { __typename?: 'QueryRoot' }
  & { hotels: Array<(
    { __typename?: 'Hotel' }
    & Pick<Hotel, 'hotelId'>
    & { general?: Maybe<(
      { __typename?: 'HotelGeneral' }
      & Pick<HotelGeneral, 'name'>
    )>, imagery: (
      { __typename?: 'HotelImagery' }
      & { photos?: Maybe<Array<Maybe<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover'>
      )>>> }
    ) }
  )> }
);

export type ConversationQueryVariables = Exact<{
  conversationId: Scalars['ID'];
  device: Scalars['String'];
}>;


export type ConversationQuery = (
  { __typename?: 'QueryRoot' }
  & { conversationToken: (
    { __typename?: 'ConversationTokenType' }
    & Pick<ConversationTokenType, 'identity' | 'token' | 'flokId'>
  ) }
);

export type SendConversationMessageRequestMutationVariables = Exact<{
  input: SendConversationMessageRequest;
}>;


export type SendConversationMessageRequestMutation = (
  { __typename?: 'MutationRoot' }
  & { sendConversationMessage: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type InboxQueryVariables = Exact<{ [key: string]: never; }>;


export type InboxQuery = (
  { __typename?: 'QueryRoot' }
  & { inbox: (
    { __typename?: 'Inbox' }
    & { messageThreads: Array<(
      { __typename?: 'MessageThread' }
      & Pick<MessageThread, 'messageThreadId'>
      & { latestMessage?: Maybe<(
        { __typename?: 'LatestMessage' }
        & Pick<LatestMessage, 'text' | 'created'>
        & { sender: (
          { __typename?: 'Participant' }
          & Pick<Participant, 'displayName' | 'imageUrl' | 'participantId'>
        ) }
      )>, participants: Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'chatServerIdentity' | 'displayName' | 'imageUrl' | 'participantId'>
      )>, subject?: Maybe<(
        { __typename?: 'Subject' }
        & Pick<Subject, 'subjectId' | 'subjectType'>
      )> }
    )> }
  ) }
);

export type MessageThreadBookingQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type MessageThreadBookingQuery = (
  { __typename?: 'QueryRoot' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'bookingId' | 'tripId' | 'checkin' | 'checkout' | 'nights' | 'status'>
    & { summary: (
      { __typename?: 'TripSummary' }
      & Pick<TripSummary, 'imageUrl' | 'name'>
      & { location: (
        { __typename?: 'Location' }
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'countryCode'>
        ) }
      ) }
    ) }
  ) }
);

export type MessageThreadResourceScopesQueryVariables = Exact<{
  resourceId: Scalars['ID'];
}>;


export type MessageThreadResourceScopesQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'resourceScopes'>
);

export type BookingEnquiryListingByTravelDatesQueryVariables = Exact<{
  listingId: Scalars['ID'];
  checkin: Scalars['LocalDate'];
  checkout: Scalars['LocalDate'];
}>;


export type BookingEnquiryListingByTravelDatesQuery = (
  { __typename?: 'QueryRoot' }
  & { listingByTravelDates: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'listingId' | 'checkin' | 'checkout'>
    & { summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle'>
    ) }
  ) }
);

export type SubmitBookingEnquiryMutationVariables = Exact<{
  input: SubmitBookingEnquiryRequest;
}>;


export type SubmitBookingEnquiryMutation = (
  { __typename?: 'MutationRoot' }
  & { submitBookingEnquiry: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type MyGuestQueryVariables = Exact<{
  bookingId: Scalars['ID'];
}>;


export type MyGuestQuery = (
  { __typename?: 'QueryRoot' }
  & { myGuest?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'bookingId' | 'listingId' | 'tripId' | 'bookingReference' | 'checkin' | 'checkout' | 'nights' | 'status'>
    & { guest: (
      { __typename?: 'Guest' }
      & Pick<Guest, 'firstName' | 'imageUrl' | 'lastName'>
    ), summary: (
      { __typename?: 'TripSummary' }
      & Pick<TripSummary, 'imageUrl' | 'name'>
      & { location: (
        { __typename?: 'Location' }
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'countryCode'>
        ) }
      ) }
    ), bookingSupplementsDetails?: Maybe<(
      { __typename?: 'BookingSupplementsDetails' }
      & { supplementDetails: Array<(
        { __typename?: 'BookingSupplementType' }
        & Pick<BookingSupplementType, 'supplementId' | 'iconName' | 'name' | 'description'>
        & { price: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ) }
      )> }
    )> }
  )> }
);

export type MyGuestResourceScopesQueryVariables = Exact<{
  resourceId: Scalars['ID'];
}>;


export type MyGuestResourceScopesQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'resourceScopes'>
);

export type MyGuestsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyGuestsQuery = (
  { __typename?: 'QueryRoot' }
  & { myGuests: Array<Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'bookingId' | 'bookingReference' | 'checkin' | 'checkout' | 'listingId' | 'nights' | 'status' | 'tripId'>
    & { guest: (
      { __typename?: 'Guest' }
      & Pick<Guest, 'firstName' | 'imageUrl' | 'lastName'>
    ), summary: (
      { __typename?: 'TripSummary' }
      & Pick<TripSummary, 'imageUrl' | 'name'>
      & { location: (
        { __typename?: 'Location' }
        & { address: (
          { __typename?: 'AddressType' }
          & Pick<AddressType, 'city' | 'countryCode'>
        ) }
      ) }
    ) }
  )>> }
);

export type MyTripHostQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type MyTripHostQuery = (
  { __typename?: 'QueryRoot' }
  & { hostingTeamByListing?: Maybe<(
    { __typename?: 'Host' }
    & Pick<Host, 'displayName' | 'imageUrl'>
  )> }
);

export type MyTripQueryVariables = Exact<{
  tripId: Scalars['ID'];
}>;


export type MyTripQuery = (
  { __typename?: 'QueryRoot' }
  & { trip: (
    { __typename?: 'Trip' }
    & Pick<Trip, 'tripId'>
    & { bookings: Array<Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'bookingId' | 'bookingReference' | 'checkin' | 'checkout' | 'listingId' | 'nights' | 'status'>
      & { fees: (
        { __typename?: 'Fees' }
        & { bookingFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ), exchangeFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ), tokenFee: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), tokenType: (
          { __typename?: 'TokenType' }
          & Pick<TokenType, 'name' | 'symbol'>
        ) }
      ), summary: (
        { __typename?: 'TripSummary' }
        & Pick<TripSummary, 'imageUrl' | 'name'>
        & { location: (
          { __typename?: 'Location' }
          & { address: (
            { __typename?: 'AddressType' }
            & Pick<AddressType, 'city' | 'countryCode'>
          ) }
        ) }
      ) }
    )>> }
  ) }
);

export type MyTripSummaryHostQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type MyTripSummaryHostQuery = (
  { __typename?: 'QueryRoot' }
  & { hostingTeamByListing?: Maybe<(
    { __typename?: 'Host' }
    & Pick<Host, 'displayName' | 'imageUrl'>
  )> }
);

export type MyTripsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyTripsQuery = (
  { __typename?: 'QueryRoot' }
  & { trips: Array<(
    { __typename?: 'Trip' }
    & Pick<Trip, 'tripId'>
    & { bookings: Array<Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'bookingId' | 'listingId' | 'checkin' | 'checkout' | 'status'>
      & { summary: (
        { __typename?: 'TripSummary' }
        & Pick<TripSummary, 'imageUrl' | 'name'>
        & { location: (
          { __typename?: 'Location' }
          & { address: (
            { __typename?: 'AddressType' }
            & Pick<AddressType, 'city' | 'countryCode'>
          ) }
        ) }
      ) }
    )>> }
  )> }
);

export type PasswordlessLoginExchangeAuthCodeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type PasswordlessLoginExchangeAuthCodeQuery = (
  { __typename?: 'QueryRoot' }
  & { exchangeAuthCode: (
    { __typename?: 'AccessToken' }
    & Pick<AccessToken, 'accessToken' | 'expiresIn' | 'tokenType'>
  ) }
);

export type ListingsByMemberQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type ListingsByMemberQuery = (
  { __typename?: 'QueryRoot' }
  & { listingsByMember: Array<(
    { __typename?: 'Listing' }
    & { summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'highlightedAmenities' | 'title' | 'subtitle'>
      & { highlightedFeatures: (
        { __typename?: 'HighlightedFeatures' }
        & { features: Array<(
          { __typename?: 'ListingHighlightedFeature' }
          & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
        )> }
      ), photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover'>
      )> }
    ) }
  )> }
);

export type PropertiesQueryVariables = Exact<{
  skip: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type PropertiesQuery = (
  { __typename?: 'QueryRoot' }
  & { properties: Array<(
    { __typename?: 'Property' }
    & Pick<Property, 'propertyId' | 'status'>
    & { general?: Maybe<(
      { __typename?: 'PropertyGeneral' }
      & Pick<PropertyGeneral, 'name'>
    )>, imagery: (
      { __typename?: 'PropertyImagery' }
      & { photos?: Maybe<Array<Maybe<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover'>
      )>>> }
    ) }
  )> }
);

export type PropertiesListingOnboardingStatusQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type PropertiesListingOnboardingStatusQuery = (
  { __typename?: 'QueryRoot' }
  & { listingOnboardingStatus?: Maybe<(
    { __typename?: 'ListingOnboardingStatus' }
    & Pick<ListingOnboardingStatus, 'completed'>
  )> }
);

export type StartPropertyOnboardingMutationVariables = Exact<{
  input: StartPropertyOnboardingCommand;
}>;


export type StartPropertyOnboardingMutation = (
  { __typename?: 'MutationRoot' }
  & { startPropertyOnboarding: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ExportUrlQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ExportUrlQuery = (
  { __typename?: 'QueryRoot' }
  & Pick<QueryRoot, 'exportUrl'>
);

export type UpdateBookingSettingsMutationVariables = Exact<{
  input: UpdateBookingSettingsCommand;
}>;


export type UpdateBookingSettingsMutation = (
  { __typename?: 'MutationRoot' }
  & { updateBookingSettings: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type UpdateBookingSettingsListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type UpdateBookingSettingsListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'allowInstantBook'>
  ) }
);

export type PropertyHeaderListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type PropertyHeaderListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & { summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle'>
      & { photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url'>
      )> }
    ) }
  ) }
);

export type ListingOnboardingInProgressOnboardingStatusQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingOnboardingInProgressOnboardingStatusQuery = (
  { __typename?: 'QueryRoot' }
  & { listingOnboardingStatus?: Maybe<(
    { __typename?: 'ListingOnboardingStatus' }
    & Pick<ListingOnboardingStatus, 'completed'>
  )> }
);

export type AcceptPropertyOwnerInvitationMutationVariables = Exact<{
  input: AcceptPropertyOwnerInvitationCommand;
}>;


export type AcceptPropertyOwnerInvitationMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptPropertyOwnerInvitation: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPropertyOwnerAddressMutationVariables = Exact<{
  input: UpdatePersonalAddressCommand;
}>;


export type AcceptPropertyOwnerAddressMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalAddress: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPropertyOwnerInvitationPhoneMutationVariables = Exact<{
  input: UpdatePersonalPhoneNumberCommand;
}>;


export type AcceptPropertyOwnerInvitationPhoneMutation = (
  { __typename?: 'MutationRoot' }
  & { updatePersonalPhoneNumber: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPropertyOwnerInvitationTermsMutationVariables = Exact<{
  input: AcceptConsumerTermsAndConditionsCommand;
}>;


export type AcceptPropertyOwnerInvitationTermsMutation = (
  { __typename?: 'MutationRoot' }
  & { acceptConsumerTermsAndConditions: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type AcceptPropertyOwnerInvitationSummaryQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type AcceptPropertyOwnerInvitationSummaryQuery = (
  { __typename?: 'QueryRoot' }
  & { propertyOwnerInvitationSummary?: Maybe<(
    { __typename?: 'PropertyOwnerInvitationSummary' }
    & Pick<PropertyOwnerInvitationSummary, 'invitationId' | 'propertyId' | 'status'>
  )> }
);

export type DepositDaysListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type DepositDaysListingQuery = (
  { __typename?: 'QueryRoot' }
  & { listing: (
    { __typename?: 'Listing' }
    & Pick<Listing, 'status' | 'listingId'>
    & { asset: (
      { __typename?: 'Asset' }
      & Pick<Asset, 'assetId' | 'assetType'>
    ), summary: (
      { __typename?: 'ListingSummary' }
      & Pick<ListingSummary, 'city' | 'countryCode' | 'title' | 'subtitle'>
      & { photos: Array<(
        { __typename?: 'AssetPhotoType' }
        & Pick<AssetPhotoType, 'url' | 'useAsCover'>
      )> }
    ) }
  ) }
);

export type PayMembershipSubscriptionMutationVariables = Exact<{
  input: PayMembershipSubscriptionRequest;
}>;


export type PayMembershipSubscriptionMutation = (
  { __typename?: 'MutationRoot' }
  & { payMembershipSubscription: (
    { __typename?: 'CheckoutSession' }
    & Pick<CheckoutSession, 'sessionId'>
  ) }
);

export type PayMembershipSubscriptionAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type PayMembershipSubscriptionAccountQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { membership?: Maybe<(
      { __typename?: 'Membership' }
      & { membershipPlan: (
        { __typename?: 'MembershipPlan' }
        & Pick<MembershipPlan, 'id' | 'title' | 'description'>
      ) }
    )> }
  )> }
);

export type PayMembershipSubscriptionEligibleSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PayMembershipSubscriptionEligibleSubscriptionsQuery = (
  { __typename?: 'QueryRoot' }
  & { eligibleMembershipSubscriptions: Array<(
    { __typename?: 'MembershipSubscription' }
    & Pick<MembershipSubscription, 'billingPeriod'>
    & { membershipPlan: (
      { __typename?: 'MembershipPlan' }
      & Pick<MembershipPlan, 'id' | 'title' | 'description'>
    ), recurringPrice: (
      { __typename?: 'CurrencyAmountType' }
      & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
    ) }
  )> }
);

export type CompletePropertyOnboardingAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type CompletePropertyOnboardingAccountQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { membership?: Maybe<(
      { __typename?: 'Membership' }
      & { membershipPlan: (
        { __typename?: 'MembershipPlan' }
        & Pick<MembershipPlan, 'id' | 'title' | 'description'>
      ) }
    )> }
  )> }
);

export type CompletePropertyOnboardingStatusQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type CompletePropertyOnboardingStatusQuery = (
  { __typename?: 'QueryRoot' }
  & { listingOnboardingStatus?: Maybe<(
    { __typename?: 'ListingOnboardingStatus' }
    & Pick<ListingOnboardingStatus, 'assetOnboardingCompleted' | 'completed' | 'meetsMinimumRequiredAvailability' | 'memberOnboardingCompleted' | 'membershipActive' | 'canInviteOwner' | 'ownerAssigned' | 'hostingTeamAssigned' | 'readyForPublishing'>
  )> }
);

export type CompletePropertyOnboardingPublishListingMutationVariables = Exact<{
  input: PublishListingCommand;
}>;


export type CompletePropertyOnboardingPublishListingMutation = (
  { __typename?: 'MutationRoot' }
  & { publishListing: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CompletePropertyOnboardingReferMemberMutationVariables = Exact<{
  input: ReferMemberCommand;
}>;


export type CompletePropertyOnboardingReferMemberMutation = (
  { __typename?: 'MutationRoot' }
  & { referMember: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type ListingOnboardingFinishedOnboardingStatusQueryVariables = Exact<{
  listingId: Scalars['ID'];
}>;


export type ListingOnboardingFinishedOnboardingStatusQuery = (
  { __typename?: 'QueryRoot' }
  & { listingOnboardingStatus?: Maybe<(
    { __typename?: 'ListingOnboardingStatus' }
    & Pick<ListingOnboardingStatus, 'completed'>
  )> }
);

export type PublicWishlistListingsQueryVariables = Exact<{
  wishlistId: Scalars['ID'];
}>;


export type PublicWishlistListingsQuery = (
  { __typename?: 'QueryRoot' }
  & { publicWishlist?: Maybe<(
    { __typename?: 'PublicWishlist' }
    & Pick<PublicWishlist, 'wishlistId' | 'name'>
    & { wishes: Array<(
      { __typename?: 'PublicWish' }
      & Pick<PublicWish, 'wishId'>
      & { searchListing: (
        { __typename?: 'SearchListing' }
        & Pick<SearchListing, 'listingId' | 'status' | 'availabilityType'>
        & { fees?: Maybe<(
          { __typename?: 'Fees' }
          & { tokenType: (
            { __typename?: 'TokenType' }
            & Pick<TokenType, 'name'>
          ), tokenFee: (
            { __typename?: 'TokenAmountType' }
            & Pick<TokenAmountType, 'amount'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ), bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ) }
        )>, priceRange?: Maybe<(
          { __typename?: 'PriceRange' }
          & { lowest: (
            { __typename?: 'Fees' }
            & { tokenType: (
              { __typename?: 'TokenType' }
              & Pick<TokenType, 'name'>
            ), tokenFee: (
              { __typename?: 'TokenAmountType' }
              & Pick<TokenAmountType, 'amount'>
            ), exchangeFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ), bookingFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ) }
          ), highest: (
            { __typename?: 'Fees' }
            & { tokenType: (
              { __typename?: 'TokenType' }
              & Pick<TokenType, 'name'>
            ), tokenFee: (
              { __typename?: 'TokenAmountType' }
              & Pick<TokenAmountType, 'amount'>
            ), exchangeFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ), bookingFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ) }
          ) }
        )>, availableFrom?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'start' | 'end'>
        )>, summary: (
          { __typename?: 'ListingSummary' }
          & Pick<ListingSummary, 'city' | 'countryCode' | 'highlightedAmenities' | 'title' | 'subtitle'>
          & { highlightedFeatures: (
            { __typename?: 'HighlightedFeatures' }
            & { features: Array<(
              { __typename?: 'ListingHighlightedFeature' }
              & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
            )> }
          ), decidingFactors: (
            { __typename?: 'DecidingFactors' }
            & { primaryDecidingFactors: Array<(
              { __typename?: 'DecidingFactor' }
              & Pick<DecidingFactor, 'enabled' | 'icon' | 'title' | 'subtitle'>
            )> }
          ), photos: Array<(
            { __typename?: 'AssetPhotoType' }
            & Pick<AssetPhotoType, 'url' | 'useAsCover'>
          )> }
        ), operator?: Maybe<(
          { __typename?: 'Operator' }
          & Pick<Operator, 'operatorId' | 'name' | 'logoUrl'>
        )> }
      ) }
    )> }
  )> }
);

export type SearchCriteriaBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCriteriaBrandsQuery = (
  { __typename?: 'QueryRoot' }
  & { brands: Array<(
    { __typename?: 'Brand' }
    & Pick<Brand, 'brandId' | 'name' | 'logoUrl'>
  )> }
);

export type SearchCriteriaVisibilityConstraintQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchCriteriaVisibilityConstraintQuery = (
  { __typename?: 'QueryRoot' }
  & { visibilityConstraint: (
    { __typename?: 'VisibilityConstraint' }
    & Pick<VisibilityConstraint, 'canSeeExchangeProperties' | 'canSeeRentalProperties' | 'canSeeHotels'>
  ) }
);

export type SearchCriteriaWhereToMenuOptionsQueryVariables = Exact<{
  includeComingSoon: Scalars['Boolean'];
  availabilityTypes: Array<Maybe<AvailabilityType>> | Maybe<AvailabilityType>;
}>;


export type SearchCriteriaWhereToMenuOptionsQuery = (
  { __typename?: 'QueryRoot' }
  & { searchOptions: (
    { __typename?: 'SearchOptions' }
    & { groupedRegions: Array<(
      { __typename?: 'SearchGroupedRegions' }
      & Pick<SearchGroupedRegions, 'groupName'>
      & { regions: Array<(
        { __typename?: 'SearchRegion' }
        & Pick<SearchRegion, 'parentRegionId' | 'regionId' | 'name'>
        & { boundingBox?: Maybe<(
          { __typename?: 'BoundingBox' }
          & Pick<BoundingBox, 'swLat' | 'swLng' | 'neLat' | 'neLng'>
        )> }
      )> }
    )> }
  ) }
);

export type SearchListingGreetingPersonalProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchListingGreetingPersonalProfileQuery = (
  { __typename?: 'QueryRoot' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'accountId'>
    & { personalProfile: (
      { __typename?: 'PersonalProfile' }
      & Pick<PersonalProfile, 'email' | 'firstName'>
    ) }
  )> }
);

export type SearchListingsQueryVariables = Exact<{
  input: Criteria;
}>;


export type SearchListingsQuery = (
  { __typename?: 'QueryRoot' }
  & { searchListings: (
    { __typename?: 'SearchResults' }
    & Pick<SearchResults, 'count'>
    & { paging: (
      { __typename?: 'SearchResultPaging' }
      & Pick<SearchResultPaging, 'offset' | 'size'>
    ), results: Array<(
      { __typename?: 'SearchListing' }
      & Pick<SearchListing, 'listingId' | 'status' | 'availabilityType'>
      & { fees?: Maybe<(
        { __typename?: 'Fees' }
        & { tokenType: (
          { __typename?: 'TokenType' }
          & Pick<TokenType, 'name'>
        ), tokenFee: (
          { __typename?: 'TokenAmountType' }
          & Pick<TokenAmountType, 'amount'>
        ), exchangeFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ), bookingFee: (
          { __typename?: 'CurrencyAmountType' }
          & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
        ) }
      )>, priceRange?: Maybe<(
        { __typename?: 'PriceRange' }
        & { lowest: (
          { __typename?: 'Fees' }
          & { tokenType: (
            { __typename?: 'TokenType' }
            & Pick<TokenType, 'name'>
          ), tokenFee: (
            { __typename?: 'TokenAmountType' }
            & Pick<TokenAmountType, 'amount'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ), bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ) }
        ), highest: (
          { __typename?: 'Fees' }
          & { tokenType: (
            { __typename?: 'TokenType' }
            & Pick<TokenType, 'name'>
          ), tokenFee: (
            { __typename?: 'TokenAmountType' }
            & Pick<TokenAmountType, 'amount'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ), bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ) }
        ) }
      )>, commission?: Maybe<(
        { __typename?: 'Commission' }
        & Pick<Commission, 'rate'>
      )>, availableFrom?: Maybe<(
        { __typename?: 'DateRange' }
        & Pick<DateRange, 'start' | 'end'>
      )>, summary: (
        { __typename?: 'ListingSummary' }
        & Pick<ListingSummary, 'city' | 'countryCode' | 'highlightedAmenities' | 'title' | 'subtitle'>
        & { highlightedFeatures: (
          { __typename?: 'HighlightedFeatures' }
          & { features: Array<(
            { __typename?: 'ListingHighlightedFeature' }
            & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
          )> }
        ), decidingFactors: (
          { __typename?: 'DecidingFactors' }
          & { primaryDecidingFactors: Array<(
            { __typename?: 'DecidingFactor' }
            & Pick<DecidingFactor, 'enabled' | 'icon' | 'title' | 'subtitle'>
          )> }
        ), photos: Array<(
          { __typename?: 'AssetPhotoType' }
          & Pick<AssetPhotoType, 'url' | 'useAsCover'>
        )> }
      ), obfuscatedLocation: (
        { __typename?: 'ObfuscatedLocation' }
        & { location: (
          { __typename?: 'GeoLocation' }
          & Pick<GeoLocation, 'lat' | 'lng'>
        ) }
      ), operator?: Maybe<(
        { __typename?: 'Operator' }
        & Pick<Operator, 'operatorId' | 'name' | 'logoUrl'>
      )> }
    )> }
  ) }
);

export type AddToWishlistWishlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type AddToWishlistWishlistsQuery = (
  { __typename?: 'QueryRoot' }
  & { wishlists?: Maybe<Array<Maybe<(
    { __typename?: 'Wishlist' }
    & Pick<Wishlist, 'wishlistId' | 'userId' | 'name' | 'status'>
    & { wishes: Array<(
      { __typename?: 'Wish' }
      & Pick<Wish, 'wishId'>
      & { searchListing: (
        { __typename?: 'SearchListing' }
        & Pick<SearchListing, 'listingId'>
      ) }
    )> }
  )>>> }
);

export type AddWishToWishlistMutationVariables = Exact<{
  input: AddWishToWishlistCommand;
}>;


export type AddWishToWishlistMutation = (
  { __typename?: 'MutationRoot' }
  & { addWishToWishlist: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type RemoveWishFromWishlistMutationVariables = Exact<{
  input: RemoveWishFromWishlistCommand;
}>;


export type RemoveWishFromWishlistMutation = (
  { __typename?: 'MutationRoot' }
  & { removeWishFromWishlist: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type CreateWishlistMutationVariables = Exact<{
  input: CreateWishlistCommand;
}>;


export type CreateWishlistMutation = (
  { __typename?: 'MutationRoot' }
  & { createWishlist: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type SelectWishlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectWishlistsQuery = (
  { __typename?: 'QueryRoot' }
  & { wishlists?: Maybe<Array<Maybe<(
    { __typename?: 'Wishlist' }
    & Pick<Wishlist, 'wishlistId' | 'userId' | 'name' | 'status'>
    & { wishes: Array<(
      { __typename?: 'Wish' }
      & Pick<Wish, 'wishId'>
      & { searchListing: (
        { __typename?: 'SearchListing' }
        & Pick<SearchListing, 'listingId'>
      ) }
    )> }
  )>>> }
);

export type UpdateWishlistMutationVariables = Exact<{
  input: UpdateWishlistCommand;
}>;


export type UpdateWishlistMutation = (
  { __typename?: 'MutationRoot' }
  & { updateWishlist: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export type WishlistQueryVariables = Exact<{
  wishlistId: Scalars['ID'];
}>;


export type WishlistQuery = (
  { __typename?: 'QueryRoot' }
  & { wishlist?: Maybe<(
    { __typename?: 'Wishlist' }
    & Pick<Wishlist, 'wishlistId' | 'userId' | 'name' | 'status'>
    & { wishes: Array<(
      { __typename?: 'Wish' }
      & Pick<Wish, 'wishId'>
      & { searchListing: (
        { __typename?: 'SearchListing' }
        & Pick<SearchListing, 'listingId' | 'status' | 'availabilityType'>
        & { fees?: Maybe<(
          { __typename?: 'Fees' }
          & { tokenType: (
            { __typename?: 'TokenType' }
            & Pick<TokenType, 'name'>
          ), tokenFee: (
            { __typename?: 'TokenAmountType' }
            & Pick<TokenAmountType, 'amount'>
          ), exchangeFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ), bookingFee: (
            { __typename?: 'CurrencyAmountType' }
            & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
          ) }
        )>, priceRange?: Maybe<(
          { __typename?: 'PriceRange' }
          & { lowest: (
            { __typename?: 'Fees' }
            & { tokenType: (
              { __typename?: 'TokenType' }
              & Pick<TokenType, 'name'>
            ), tokenFee: (
              { __typename?: 'TokenAmountType' }
              & Pick<TokenAmountType, 'amount'>
            ), exchangeFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ), bookingFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ) }
          ), highest: (
            { __typename?: 'Fees' }
            & { tokenType: (
              { __typename?: 'TokenType' }
              & Pick<TokenType, 'name'>
            ), tokenFee: (
              { __typename?: 'TokenAmountType' }
              & Pick<TokenAmountType, 'amount'>
            ), exchangeFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ), bookingFee: (
              { __typename?: 'CurrencyAmountType' }
              & Pick<CurrencyAmountType, 'amountMicros' | 'currency'>
            ) }
          ) }
        )>, availableFrom?: Maybe<(
          { __typename?: 'DateRange' }
          & Pick<DateRange, 'start' | 'end'>
        )>, summary: (
          { __typename?: 'ListingSummary' }
          & Pick<ListingSummary, 'city' | 'countryCode' | 'highlightedAmenities' | 'title' | 'subtitle'>
          & { highlightedFeatures: (
            { __typename?: 'HighlightedFeatures' }
            & { features: Array<(
              { __typename?: 'ListingHighlightedFeature' }
              & Pick<ListingHighlightedFeature, 'iconName' | 'title' | 'description'>
            )> }
          ), decidingFactors: (
            { __typename?: 'DecidingFactors' }
            & { primaryDecidingFactors: Array<(
              { __typename?: 'DecidingFactor' }
              & Pick<DecidingFactor, 'enabled' | 'icon' | 'title' | 'subtitle'>
            )> }
          ), photos: Array<(
            { __typename?: 'AssetPhotoType' }
            & Pick<AssetPhotoType, 'url' | 'useAsCover'>
          )> }
        ), operator?: Maybe<(
          { __typename?: 'Operator' }
          & Pick<Operator, 'operatorId' | 'name' | 'logoUrl'>
        )> }
      ) }
    )> }
  )> }
);

export type WishlistsQueryVariables = Exact<{ [key: string]: never; }>;


export type WishlistsQuery = (
  { __typename?: 'QueryRoot' }
  & { wishlists?: Maybe<Array<Maybe<(
    { __typename?: 'Wishlist' }
    & Pick<Wishlist, 'wishlistId' | 'userId' | 'name' | 'status'>
    & { wishes: Array<(
      { __typename?: 'Wish' }
      & Pick<Wish, 'wishId'>
    )> }
  )>>> }
);

export type ExpireWishlistMutationVariables = Exact<{
  input: ExpireWishlistCommand;
}>;


export type ExpireWishlistMutation = (
  { __typename?: 'MutationRoot' }
  & { expireWishlist: (
    { __typename?: 'InputAcceptedResponse' }
    & Pick<InputAcceptedResponse, 'id'>
  ) }
);

export const PresentmentCurrencyExchangeRatesDocument = gql`
    query PresentmentCurrencyExchangeRates {
  presentmentCurrencyExchangeRates {
    currency
    rate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PresentmentCurrencyExchangeRatesGQL extends Apollo.Query<PresentmentCurrencyExchangeRatesQuery, PresentmentCurrencyExchangeRatesQueryVariables> {
    document = PresentmentCurrencyExchangeRatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleManagerAccountDocument = gql`
    query RoleManagerAccount {
  account {
    accountId
    accountHolderId
    personalProfile {
      email
      firstName
      lastName
      photoUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleManagerAccountGQL extends Apollo.Query<RoleManagerAccountQuery, RoleManagerAccountQueryVariables> {
    document = RoleManagerAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleManagerRolesDocument = gql`
    query RoleManagerRoles {
  roles
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleManagerRolesGQL extends Apollo.Query<RoleManagerRolesQuery, RoleManagerRolesQueryVariables> {
    document = RoleManagerRolesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RoleManagerMemberOnboardingStatusDocument = gql`
    query RoleManagerMemberOnboardingStatus {
  memberOnboardingStatus {
    completed
    assetBeingOnboarded {
      assetId
      assetType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RoleManagerMemberOnboardingStatusGQL extends Apollo.Query<RoleManagerMemberOnboardingStatusQuery, RoleManagerMemberOnboardingStatusQueryVariables> {
    document = RoleManagerMemberOnboardingStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScopeManagerScopesDocument = gql`
    query ScopeManagerScopes {
  scopes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScopeManagerScopesGQL extends Apollo.Query<ScopeManagerScopesQuery, ScopeManagerScopesQueryVariables> {
    document = ScopeManagerScopesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ScopeManagerResourceScopesDocument = gql`
    query ScopeManagerResourceScopes($resourceId: ID!) {
  resourceScopes(resourceId: $resourceId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ScopeManagerResourceScopesGQL extends Apollo.Query<ScopeManagerResourceScopesQuery, ScopeManagerResourceScopesQueryVariables> {
    document = ScopeManagerResourceScopesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupportChatDocument = gql`
    query SupportChat {
  supportChat {
    identificationToken
    identificationEmail
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupportChatGQL extends Apollo.Query<SupportChatQuery, SupportChatQueryVariables> {
    document = SupportChatDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TokenTypeDocument = gql`
    query TokenType {
  tokenType {
    name
    symbol
    exchangeRate {
      currency
      amountMicros
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TokenTypeGQL extends Apollo.Query<TokenTypeQuery, TokenTypeQueryVariables> {
    document = TokenTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TravelAgentByStaffMemberDocument = gql`
    query TravelAgentByStaffMember($staffMemberId: ID!) {
  travelAgentByStaffMember(staffMemberId: $staffMemberId) {
    travelAgentId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TravelAgentByStaffMemberGQL extends Apollo.Query<TravelAgentByStaffMemberQuery, TravelAgentByStaffMemberQueryVariables> {
    document = TravelAgentByStaffMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TravelAgentClienteleByBusinessIdDocument = gql`
    query TravelAgentClienteleByBusinessId($businessId: ID!) {
  clienteleByBusinessId(businessId: $businessId) {
    clienteleId
    businessId
    customers {
      userId
      firstName
      lastName
      email
      phoneNumber {
        countryCode
        nationalNumber
      }
      photoUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TravelAgentClienteleByBusinessIdGQL extends Apollo.Query<TravelAgentClienteleByBusinessIdQuery, TravelAgentClienteleByBusinessIdQueryVariables> {
    document = TravelAgentClienteleByBusinessIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TravelAgentResellerByBusinessIdDocument = gql`
    query TravelAgentResellerByBusinessId($businessId: ID!) {
  resellerByBusinessId(businessId: $businessId) {
    resellerId
    businessId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TravelAgentResellerByBusinessIdGQL extends Apollo.Query<TravelAgentResellerByBusinessIdQuery, TravelAgentResellerByBusinessIdQueryVariables> {
    document = TravelAgentResellerByBusinessIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBookingDocument = gql`
    mutation AcceptBooking($input: AcceptBookingRequest!) {
  acceptBooking(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBookingGQL extends Apollo.Mutation<AcceptBookingMutation, AcceptBookingMutationVariables> {
    document = AcceptBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBookingBookingDocument = gql`
    query AcceptBookingBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBookingBookingGQL extends Apollo.Query<AcceptBookingBookingQuery, AcceptBookingBookingQueryVariables> {
    document = AcceptBookingBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BusinessCustomerAutocompleteAddBusinessCustomerToClienteleDocument = gql`
    mutation BusinessCustomerAutocompleteAddBusinessCustomerToClientele($input: AddBusinessCustomerToClienteleCommand!) {
  addBusinessCustomerToClientele(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BusinessCustomerAutocompleteAddBusinessCustomerToClienteleGQL extends Apollo.Mutation<BusinessCustomerAutocompleteAddBusinessCustomerToClienteleMutation, BusinessCustomerAutocompleteAddBusinessCustomerToClienteleMutationVariables> {
    document = BusinessCustomerAutocompleteAddBusinessCustomerToClienteleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BusinessCustomerUserExistsDocument = gql`
    query BusinessCustomerUserExists($email: String!) {
  userExists(email: $email) {
    userId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BusinessCustomerUserExistsGQL extends Apollo.Query<BusinessCustomerUserExistsQuery, BusinessCustomerUserExistsQueryVariables> {
    document = BusinessCustomerUserExistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBusinessCustomerDocument = gql`
    mutation CreateBusinessCustomer($input: CreateBusinessCustomerRequest!) {
  createBusinessCustomer(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBusinessCustomerGQL extends Apollo.Mutation<CreateBusinessCustomerMutation, CreateBusinessCustomerMutationVariables> {
    document = CreateBusinessCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarListingAllocateDailyAvailabilityDocument = gql`
    mutation CalendarListingAllocateDailyAvailability($input: AllocateDailyAvailabilityCommand!) {
  allocateDailyAvailability(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarListingAllocateDailyAvailabilityGQL extends Apollo.Mutation<CalendarListingAllocateDailyAvailabilityMutation, CalendarListingAllocateDailyAvailabilityMutationVariables> {
    document = CalendarListingAllocateDailyAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarListingAllocateMonthlyAvailabilityDocument = gql`
    mutation CalendarListingAllocateMonthlyAvailability($input: AllocateMonthlyAvailabilityCommand!) {
  allocateMonthlyAvailability(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarListingAllocateMonthlyAvailabilityGQL extends Apollo.Mutation<CalendarListingAllocateMonthlyAvailabilityMutation, CalendarListingAllocateMonthlyAvailabilityMutationVariables> {
    document = CalendarListingAllocateMonthlyAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarListingAllocateInitialMonthlyAvailabilityDocument = gql`
    mutation CalendarListingAllocateInitialMonthlyAvailability($input: AllocateInitialMonthlyAvailabilityRequest!) {
  allocateInitialMonthlyAvailability(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarListingAllocateInitialMonthlyAvailabilityGQL extends Apollo.Mutation<CalendarListingAllocateInitialMonthlyAvailabilityMutation, CalendarListingAllocateInitialMonthlyAvailabilityMutationVariables> {
    document = CalendarListingAllocateInitialMonthlyAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarListingMinimumRequiredAvailabilityDocument = gql`
    query CalendarListingMinimumRequiredAvailability($listingId: ID!) {
  minimumRequiredAvailability(listingId: $listingId) {
    required {
      minimumDays
      seasonality
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarListingMinimumRequiredAvailabilityGQL extends Apollo.Query<CalendarListingMinimumRequiredAvailabilityQuery, CalendarListingMinimumRequiredAvailabilityQueryVariables> {
    document = CalendarListingMinimumRequiredAvailabilityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarTokenBalanceDocument = gql`
    query CalendarTokenBalance {
  myTokenBalance {
    amount
    tokenType {
      name
      symbol
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarTokenBalanceGQL extends Apollo.Query<CalendarTokenBalanceQuery, CalendarTokenBalanceQueryVariables> {
    document = CalendarTokenBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepositCalendarInitialEarningsCalendarDocument = gql`
    query DepositCalendarInitialEarningsCalendar($listingId: ID!) {
  initialEarningsCalendar(listingId: $listingId) {
    perDayEarnings {
      day
      earnings {
        tokenAmount {
          amount
        }
        currencyAmount {
          amountMicros
          currency
        }
      }
      percentageInstantly
      history {
        earnedTotal {
          amount
        }
        earnedInstantly {
          amount
        }
        earnedDelayed {
          amount
        }
        percentageInstantly
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepositCalendarInitialEarningsCalendarGQL extends Apollo.Query<DepositCalendarInitialEarningsCalendarQuery, DepositCalendarInitialEarningsCalendarQueryVariables> {
    document = DepositCalendarInitialEarningsCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepositCalendarEarningsCalendarDocument = gql`
    query DepositCalendarEarningsCalendar($listingId: ID!) {
  earningsCalendar(listingId: $listingId) {
    perDayEarnings {
      day
      earnings {
        tokenAmount {
          amount
        }
        currencyAmount {
          amountMicros
          currency
        }
      }
      percentageInstantly
      history {
        earnedTotal {
          amount
        }
        earnedInstantly {
          amount
        }
        earnedDelayed {
          amount
        }
        percentageInstantly
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepositCalendarEarningsCalendarGQL extends Apollo.Query<DepositCalendarEarningsCalendarQuery, DepositCalendarEarningsCalendarQueryVariables> {
    document = DepositCalendarEarningsCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepositCalendarListingDocument = gql`
    query DepositCalendarListing($listingId: ID!) {
  listing(listingId: $listingId) {
    listingId
    status
    availabilityCalendar {
      dailyAllocations {
        allocated
        consumed
        day
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepositCalendarListingGQL extends Apollo.Query<DepositCalendarListingQuery, DepositCalendarListingQueryVariables> {
    document = DepositCalendarListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepositCalendarLinkedCalendarsDocument = gql`
    query DepositCalendarLinkedCalendars($listingId: ID!) {
  linkedCalendars(listingId: $listingId) {
    externalUrl
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepositCalendarLinkedCalendarsGQL extends Apollo.Query<DepositCalendarLinkedCalendarsQuery, DepositCalendarLinkedCalendarsQueryVariables> {
    document = DepositCalendarLinkedCalendarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkExternalCalendarDocument = gql`
    mutation LinkExternalCalendar($input: LinkExternalCalendarCommand!) {
  linkExternalCalendar(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkExternalCalendarGQL extends Apollo.Mutation<LinkExternalCalendarMutation, LinkExternalCalendarMutationVariables> {
    document = LinkExternalCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkedCalendarsDocument = gql`
    query LinkedCalendars($listingId: ID!) {
  linkedCalendars(listingId: $listingId) {
    externalUrl
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkedCalendarsGQL extends Apollo.Query<LinkedCalendarsQuery, LinkedCalendarsQueryVariables> {
    document = LinkedCalendarsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LinkCalendarsUnlinkExternalCalendarDocument = gql`
    mutation LinkCalendarsUnlinkExternalCalendar($input: UnlinkExternalCalendarCommand!) {
  unlinkExternalCalendar(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LinkCalendarsUnlinkExternalCalendarGQL extends Apollo.Mutation<LinkCalendarsUnlinkExternalCalendarMutation, LinkCalendarsUnlinkExternalCalendarMutationVariables> {
    document = LinkCalendarsUnlinkExternalCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingsWithCleaningByMemberDocument = gql`
    query ListingsWithCleaningByMember($memberId: ID!) {
  listingsByMember(memberId: $memberId) {
    asset {
      assetId
      assetType
    }
    summary {
      title
    }
    operator {
      operatorId
      name
      logoUrl
    }
    cleaningService {
      perStayCleaningFee {
        amount {
          currency
          amountMicros
        }
      }
      perDayCleaningFee {
        amount {
          currency
          amountMicros
        }
      }
      paymentMode
      isUpdated
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingsWithCleaningByMemberGQL extends Apollo.Query<ListingsWithCleaningByMemberQuery, ListingsWithCleaningByMemberQueryVariables> {
    document = ListingsWithCleaningByMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BankAccountDetailsDocument = gql`
    query BankAccountDetails {
  bankAccountDetails {
    accountId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BankAccountDetailsGQL extends Apollo.Query<BankAccountDetailsQuery, BankAccountDetailsQueryVariables> {
    document = BankAccountDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectFacebookAccountDocument = gql`
    mutation ConnectFacebookAccount($input: ConnectSocialMediaAccountCommand!) {
  connectSocialMediaAccount(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectFacebookAccountGQL extends Apollo.Mutation<ConnectFacebookAccountMutation, ConnectFacebookAccountMutationVariables> {
    document = ConnectFacebookAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectFacebookConnectedSocialAccountDocument = gql`
    query ConnectFacebookConnectedSocialAccount($provider: String!) {
  connectedSocialAccount(provider: $provider) {
    provider
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectFacebookConnectedSocialAccountGQL extends Apollo.Query<ConnectFacebookConnectedSocialAccountQuery, ConnectFacebookConnectedSocialAccountQueryVariables> {
    document = ConnectFacebookConnectedSocialAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectLinkedinAccountDocument = gql`
    mutation ConnectLinkedinAccount($input: ConnectLinkedinAccountCommand!) {
  connectLinkedinAccount(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectLinkedinAccountGQL extends Apollo.Mutation<ConnectLinkedinAccountMutation, ConnectLinkedinAccountMutationVariables> {
    document = ConnectLinkedinAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConnectLinkedinConnectedSocialAccountDocument = gql`
    query ConnectLinkedinConnectedSocialAccount($provider: String!) {
  connectedSocialAccount(provider: $provider) {
    provider
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConnectLinkedinConnectedSocialAccountGQL extends Apollo.Query<ConnectLinkedinConnectedSocialAccountQuery, ConnectLinkedinConnectedSocialAccountQueryVariables> {
    document = ConnectLinkedinConnectedSocialAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeclineBookingDocument = gql`
    mutation DeclineBooking($input: DeclineBookingRequest!) {
  declineBooking(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeclineBookingGQL extends Apollo.Mutation<DeclineBookingMutation, DeclineBookingMutationVariables> {
    document = DeclineBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeclineBookingBookingDocument = gql`
    query DeclineBookingBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeclineBookingBookingGQL extends Apollo.Query<DeclineBookingBookingQuery, DeclineBookingBookingQueryVariables> {
    document = DeclineBookingBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingAvailabilityCalendarDocument = gql`
    query ListingAvailabilityCalendar($listingId: ID!) {
  availabilityCalendar(listingId: $listingId) {
    dailyAllocations {
      allocated
      consumed
      day
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingAvailabilityCalendarGQL extends Apollo.Query<ListingAvailabilityCalendarQuery, ListingAvailabilityCalendarQueryVariables> {
    document = ListingAvailabilityCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingAvailabilityFeesCalendarDocument = gql`
    query ListingAvailabilityFeesCalendar($listingId: ID!) {
  feesCalendar(listingId: $listingId) {
    perDayFees {
      day
      fees {
        tokenFee {
          amount
        }
        exchangeFee {
          amountMicros
          currency
        }
        bookingFee {
          amountMicros
          currency
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingAvailabilityFeesCalendarGQL extends Apollo.Query<ListingAvailabilityFeesCalendarQuery, ListingAvailabilityFeesCalendarQueryVariables> {
    document = ListingAvailabilityFeesCalendarDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingHotelDocument = gql`
    query ListingHotel($hotelId: ID!) {
  hotelListing(hotelId: $hotelId) {
    general {
      description
      name
    }
    goodToKnow {
      description
    }
    location {
      geoLocation {
        lat
        lng
      }
    }
    amenities {
      amenityIds
    }
    houseRules {
      allowsChildren
      earliestCheckin
      latestCheckin
      maximumStay
      minimumStay
    }
    rooms {
      hotelRoomId
      roomType
      maximumOccupancy
      packageDeal {
        text
        helpText
        showCurrency
      }
      description
      imagery {
        photos {
          url
          description
          useAsCover
          useAsFloorplan
        }
      }
    }
    suites {
      hotelSuiteId
      imagery {
        photos {
          description
          url
          useAsCover
          useAsFloorplan
        }
      }
      maximumOccupancy
      packageDeal {
        text
        helpText
        showCurrency
      }
      suiteType
      description
      rooms {
        bedrooms {
          bedSizes
        }
        areas {
          areaDefinitionId
          size
          unit
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingHotelGQL extends Apollo.Query<ListingHotelQuery, ListingHotelQueryVariables> {
    document = ListingHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingAmenitiesAmenityBundleByAssetDocument = gql`
    query ListingAmenitiesAmenityBundleByAsset($assetId: ID!) {
  amenityBundleByAsset(assetId: $assetId) {
    bundleId
    name
    sections {
      sectionId
      name
      amenities {
        amenityId
        name
        description
        iconName
        enabled
      }
    }
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingAmenitiesAmenityBundleByAssetGQL extends Apollo.Query<ListingAmenitiesAmenityBundleByAssetQuery, ListingAmenitiesAmenityBundleByAssetQueryVariables> {
    document = ListingAmenitiesAmenityBundleByAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingPropertyDocument = gql`
    query ListingProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    amenities {
      amenityIds
    }
    general {
      bathrooms
      bedrooms {
        bedSizes
      }
      description
      name
      propertyStyle
      sleeps
    }
    goodToKnow {
      description
    }
    houseRules {
      allowsChildren
      allowsEvents
      allowsInfants
      allowsPets
      allowsSmoking
    }
    location {
      geoLocation {
        lat
        lng
      }
    }
    owner {
      firstName
      lastName
      photoUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingPropertyGQL extends Apollo.Query<ListingPropertyQuery, ListingPropertyQueryVariables> {
    document = ListingPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingTravelDatesDocument = gql`
    query ListingTravelDates($listingId: ID!, $checkin: LocalDate!, $checkout: LocalDate!) {
  listingByTravelDates(
    listingId: $listingId
    checkin: $checkin
    checkout: $checkout
  ) {
    asset {
      assetType
    }
    fees {
      tokenType {
        name
        symbol
      }
      tokenFee {
        amount
      }
      exchangeFee {
        amountMicros
        currency
      }
      bookingFee {
        amountMicros
        currency
      }
    }
    commission {
      rate
    }
    stayDurationConditions {
      minimum
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingTravelDatesGQL extends Apollo.Query<ListingTravelDatesQuery, ListingTravelDatesQueryVariables> {
    document = ListingTravelDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingTravelDatesByIdDocument = gql`
    query ListingTravelDatesById($listingId: ID!) {
  listing(listingId: $listingId) {
    commission {
      rate
    }
    priceRange {
      lowest {
        bookingFee {
          currency
          amountMicros
        }
        exchangeFee {
          currency
          amountMicros
        }
        tokenFee {
          amount
        }
      }
    }
    stayDurationConditions {
      minimum
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingTravelDatesByIdGQL extends Apollo.Query<ListingTravelDatesByIdQuery, ListingTravelDatesByIdQueryVariables> {
    document = ListingTravelDatesByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingTravelDatesFetchCommissionDocument = gql`
    query ListingTravelDatesFetchCommission($listingId: ID!, $adjustedPrice: CurrencyAmount!, $originalPrice: CurrencyAmount!) {
  commission(
    listingId: $listingId
    adjustedPrice: $adjustedPrice
    originalPrice: $originalPrice
  ) {
    commission {
      amountMicros
      currency
    }
    commissionRate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingTravelDatesFetchCommissionGQL extends Apollo.Query<ListingTravelDatesFetchCommissionQuery, ListingTravelDatesFetchCommissionQueryVariables> {
    document = ListingTravelDatesFetchCommissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingCreateBookingDocument = gql`
    mutation ListingCreateBooking($input: CreateBookingRequest!) {
  createBooking(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingCreateBookingGQL extends Apollo.Mutation<ListingCreateBookingMutation, ListingCreateBookingMutationVariables> {
    document = ListingCreateBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingBookingCheckoutDocument = gql`
    mutation ListingBookingCheckout($input: BookingCheckoutRequest!) {
  checkout(input: $input) {
    sessionId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingBookingCheckoutGQL extends Apollo.Mutation<ListingBookingCheckoutMutation, ListingBookingCheckoutMutationVariables> {
    document = ListingBookingCheckoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingCancelCheckoutSessionDocument = gql`
    mutation ListingCancelCheckoutSession($input: CancelCheckoutRequest!) {
  cancelCheckout(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingCancelCheckoutSessionGQL extends Apollo.Mutation<ListingCancelCheckoutSessionMutation, ListingCancelCheckoutSessionMutationVariables> {
    document = ListingCancelCheckoutSessionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingBookingDocument = gql`
    query ListingBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    bookingId
    tripId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingBookingGQL extends Apollo.Query<ListingBookingQuery, ListingBookingQueryVariables> {
    document = ListingBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingDocument = gql`
    query Listing($listingId: ID!) {
  listing(listingId: $listingId) {
    listingId
    asset {
      assetId
      assetType
    }
    availabilityCalendar {
      dailyAllocations {
        allocated
        day
        consumed
      }
    }
    obfuscatedLocation {
      location {
        lat
        lng
      }
      range
    }
    summary {
      city
      countryCode
      title
      subtitle
      highlightedAmenities
      highlightedFeatures {
        features {
          iconName
          title
          description
        }
      }
      houseRules {
        otherRules
        rules {
          enabled
          icon
          subtitle
          title
        }
      }
      photos {
        url
        useAsCover
        description
      }
    }
    status
    stayDurationConditions {
      minimum
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingGQL extends Apollo.Query<ListingQuery, ListingQueryVariables> {
    document = ListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingIdDocument = gql`
    query ListingId($propertyOrHotelRoomId: ID!) {
  listingId(propertyOrHotelRoomId: $propertyOrHotelRoomId) {
    listingId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingIdGQL extends Apollo.Query<ListingIdQuery, ListingIdQueryVariables> {
    document = ListingIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingManagementIdDocument = gql`
    query ListingManagementId($propertyOrHotelRoomId: ID!) {
  listingId(propertyOrHotelRoomId: $propertyOrHotelRoomId) {
    listingManagementId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingManagementIdGQL extends Apollo.Query<ListingManagementIdQuery, ListingManagementIdQueryVariables> {
    document = ListingManagementIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OtherNestsDocument = gql`
    query OtherNests($input: Criteria!) {
  otherNests(input: $input) {
    results {
      listingId
      status
      summary {
        city
        countryCode
        highlightedFeatures {
          features {
            iconName
            title
            description
          }
        }
        highlightedAmenities
        title
        subtitle
        photos {
          url
          useAsCover
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OtherNestsGQL extends Apollo.Query<OtherNestsQuery, OtherNestsQueryVariables> {
    document = OtherNestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingHostDocument = gql`
    query ListingHost($listingId: ID!) {
  hostingTeamByListing(listingId: $listingId) {
    displayName
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingHostGQL extends Apollo.Query<ListingHostQuery, ListingHostQueryVariables> {
    document = ListingHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingTransportAirportsDocument = gql`
    query ListingTransportAirports($location: InputGeoLocation!) {
  airports(location: $location) {
    distance
    name
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingTransportAirportsGQL extends Apollo.Query<ListingTransportAirportsQuery, ListingTransportAirportsQueryVariables> {
    document = ListingTransportAirportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TokenBalanceDocument = gql`
    query TokenBalance {
  myTokenBalance {
    amount
    tokenType {
      name
      symbol
      exchangeRate {
        currency
        amountMicros
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TokenBalanceGQL extends Apollo.Query<TokenBalanceQuery, TokenBalanceQueryVariables> {
    document = TokenBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VerifyPromotionalCodeDocument = gql`
    query VerifyPromotionalCode($promotionalCode: String!) {
  verifyPromotionalCode(promotionalCode: $promotionalCode) {
    promotionalCodeId
    valid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyPromotionalCodeGQL extends Apollo.Query<VerifyPromotionalCodeQuery, VerifyPromotionalCodeQueryVariables> {
    document = VerifyPromotionalCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResellerCommissionDocument = gql`
    query ResellerCommission($bookingId: ID!) {
  resellerByBookingId(bookingId: $bookingId) {
    resellerId
    businessId
    name
    associatedBookings {
      bookingId
      commissionFees {
        commission {
          currency
          amountMicros
        }
      }
      commissionPlan {
        commissionPlanId
        vendorId
        commissionModelType
        flatPercentageCommissionPlan {
          flatPercentage
        }
      }
      booking {
        bookingId
        bookingReference
        bookingSupplementsDetails {
          supplementDetails {
            supplementId
            iconName
            name
            description
            price {
              currency
              amountMicros
            }
          }
        }
        checkin
        checkout
        fees {
          bookingFee {
            currency
            amountMicros
          }
          exchangeFee {
            currency
            amountMicros
          }
        }
        guest {
          firstName
          imageUrl
          lastName
        }
        status
        summary {
          imageUrl
          location {
            address {
              city
              countryCode
            }
          }
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResellerCommissionGQL extends Apollo.Query<ResellerCommissionQuery, ResellerCommissionQueryVariables> {
    document = ResellerCommissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyAmenitiesDocument = gql`
    mutation UpdatePropertyAmenities($input: UpdatePropertyAmenitiesCommand!) {
  updatePropertyAmenities(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyAmenitiesGQL extends Apollo.Mutation<UpdatePropertyAmenitiesMutation, UpdatePropertyAmenitiesMutationVariables> {
    document = UpdatePropertyAmenitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyAmenitiesPropertyDocument = gql`
    query UpdatePropertyAmenitiesProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    amenities {
      amenityIds
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyAmenitiesPropertyGQL extends Apollo.Query<UpdatePropertyAmenitiesPropertyQuery, UpdatePropertyAmenitiesPropertyQueryVariables> {
    document = UpdatePropertyAmenitiesPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyAmenityBundleByAssetDocument = gql`
    query UpdatePropertyAmenityBundleByAsset($assetId: ID!) {
  amenityBundleByAsset(assetId: $assetId) {
    bundleId
    name
    sections {
      sectionId
      name
      amenities {
        amenityId
        name
        description
        iconName
        enabled
      }
    }
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyAmenityBundleByAssetGQL extends Apollo.Query<UpdatePropertyAmenityBundleByAssetQuery, UpdatePropertyAmenityBundleByAssetQueryVariables> {
    document = UpdatePropertyAmenityBundleByAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyCleaningFeesListingDocument = gql`
    query UpdatePropertyCleaningFeesListing($listingId: ID!) {
  listing(listingId: $listingId) {
    cleaningService {
      perDayCleaningFee {
        amount {
          currency
          amountMicros
        }
        description
      }
      perStayCleaningFee {
        amount {
          currency
          amountMicros
        }
        description
      }
      paymentMode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyCleaningFeesListingGQL extends Apollo.Query<UpdatePropertyCleaningFeesListingQuery, UpdatePropertyCleaningFeesListingQueryVariables> {
    document = UpdatePropertyCleaningFeesListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyCleaningServiceDocument = gql`
    mutation UpdatePropertyCleaningService($input: UpdateListingCleaningServiceCommand!) {
  updateListingCleaningService(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyCleaningServiceGQL extends Apollo.Mutation<UpdatePropertyCleaningServiceMutation, UpdatePropertyCleaningServiceMutationVariables> {
    document = UpdatePropertyCleaningServiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyGeneralDocument = gql`
    mutation UpdatePropertyGeneral($input: UpdatePropertyGeneralCommand!) {
  updatePropertyGeneral(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyGeneralGQL extends Apollo.Mutation<UpdatePropertyGeneralMutation, UpdatePropertyGeneralMutationVariables> {
    document = UpdatePropertyGeneralDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyGeneralPropertyDocument = gql`
    query UpdatePropertyGeneralProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    general {
      approximateValue {
        amountMicros
        currency
      }
      bathrooms
      bedrooms {
        bedSizes
      }
      description
      name
      propertyStyle
      propertyType
      sleeps
    }
    location {
      address {
        line1
      }
    }
    propertyId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyGeneralPropertyGQL extends Apollo.Query<UpdatePropertyGeneralPropertyQuery, UpdatePropertyGeneralPropertyQueryVariables> {
    document = UpdatePropertyGeneralPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyHouseRulesDocument = gql`
    mutation UpdatePropertyHouseRules($input: UpdatePropertyHouseRulesCommand!) {
  updatePropertyHouseRules(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyHouseRulesGQL extends Apollo.Mutation<UpdatePropertyHouseRulesMutation, UpdatePropertyHouseRulesMutationVariables> {
    document = UpdatePropertyHouseRulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyHouseRulesPropertyDocument = gql`
    query UpdatePropertyHouseRulesProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    houseRules {
      allowsChildren
      allowsEvents
      allowsInfants
      allowsPets
      allowsSmoking
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyHouseRulesPropertyGQL extends Apollo.Query<UpdatePropertyHouseRulesPropertyQuery, UpdatePropertyHouseRulesPropertyQueryVariables> {
    document = UpdatePropertyHouseRulesPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyLocationDocument = gql`
    mutation UpdatePropertyLocation($input: UpdatePropertyLocationCommand!) {
  updatePropertyLocation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyLocationGQL extends Apollo.Mutation<UpdatePropertyLocationMutation, UpdatePropertyLocationMutationVariables> {
    document = UpdatePropertyLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyLocationPropertyDocument = gql`
    query UpdatePropertyLocationProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    location {
      address {
        additionalInstructions
        city
        countryCode
        line1
        line2
        postalCode
        region
      }
      geoLocation {
        lat
        lng
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyLocationPropertyGQL extends Apollo.Query<UpdatePropertyLocationPropertyQuery, UpdatePropertyLocationPropertyQueryVariables> {
    document = UpdatePropertyLocationPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyPhotosDocument = gql`
    mutation UpdatePropertyPhotos($input: UpdatePropertyPhotosCommand!) {
  updatePropertyPhotos(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyPhotosGQL extends Apollo.Mutation<UpdatePropertyPhotosMutation, UpdatePropertyPhotosMutationVariables> {
    document = UpdatePropertyPhotosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyPhotosPropertyDocument = gql`
    query UpdatePropertyPhotosProperty($propertyId: ID!) {
  property(propertyId: $propertyId) {
    imagery {
      photos {
        description
        url
        useAsCover
        useAsFloorplan
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyPhotosPropertyGQL extends Apollo.Query<UpdatePropertyPhotosPropertyQuery, UpdatePropertyPhotosPropertyQueryVariables> {
    document = UpdatePropertyPhotosPropertyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertyPhotosSignedUrlDocument = gql`
    mutation UpdatePropertyPhotosSignedUrl($input: PropertyPhotoSignUrlRequest!) {
  propertyPhotoSignUrl(input: $input) {
    contentType
    httpMethod
    signedUrl
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertyPhotosSignedUrlGQL extends Apollo.Mutation<UpdatePropertyPhotosSignedUrlMutation, UpdatePropertyPhotosSignedUrlMutationVariables> {
    document = UpdatePropertyPhotosSignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertySupplementBundlesDocument = gql`
    query UpdatePropertySupplementBundles($assetId: ID!) {
  supplementBundleByAsset(assetId: $assetId) {
    bundleId
    name
    enabled
    sections {
      sectionId
      name
      supplements {
        supplementId
        name
        description
        iconName
        price {
          currency
          amountMicros
        }
        enabled
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertySupplementBundlesGQL extends Apollo.Query<UpdatePropertySupplementBundlesQuery, UpdatePropertySupplementBundlesQueryVariables> {
    document = UpdatePropertySupplementBundlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertySupplementIdsListingDocument = gql`
    query UpdatePropertySupplementIdsListing($listingId: ID!) {
  listing(listingId: $listingId) {
    supplements {
      listingId
      mandatorySupplements {
        id
        price {
          currency
          amountMicros
        }
        description
      }
      optionalSupplements {
        id
        price {
          currency
          amountMicros
        }
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertySupplementIdsListingGQL extends Apollo.Query<UpdatePropertySupplementIdsListingQuery, UpdatePropertySupplementIdsListingQueryVariables> {
    document = UpdatePropertySupplementIdsListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePropertySupplementsListingDocument = gql`
    mutation UpdatePropertySupplementsListing($input: UpdateListingSupplementsCommand!) {
  updateListingSupplements(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePropertySupplementsListingGQL extends Apollo.Mutation<UpdatePropertySupplementsListingMutation, UpdatePropertySupplementsListingMutationVariables> {
    document = UpdatePropertySupplementsListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InviteStaffMemberDocument = gql`
    mutation InviteStaffMember($input: InviteStaffMemberCommand!) {
  inviteStaffMember(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InviteStaffMemberGQL extends Apollo.Mutation<InviteStaffMemberMutation, InviteStaffMemberMutationVariables> {
    document = InviteStaffMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMemberFromStaffDocument = gql`
    mutation RemoveMemberFromStaff($input: RemoveMemberFromStaffCommand!) {
  removeMemberFromStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMemberFromStaffGQL extends Apollo.Mutation<RemoveMemberFromStaffMutation, RemoveMemberFromStaffMutationVariables> {
    document = RemoveMemberFromStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StaffMemberInvitationsDocument = gql`
    query StaffMemberInvitations($staffId: ID!) {
  staffMemberInvitations(staffId: $staffId) {
    email
    firstName
    host
    invitationId
    lastName
    manager
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StaffMemberInvitationsGQL extends Apollo.Query<StaffMemberInvitationsQuery, StaffMemberInvitationsQueryVariables> {
    document = StaffMemberInvitationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StaffMembersDocument = gql`
    query StaffMembers($staffId: ID!) {
  staffMembers(staffId: $staffId) {
    userId
    email
    firstName
    lastName
    host
    manager
    boss
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StaffMembersGQL extends Apollo.Query<StaffMembersQuery, StaffMembersQueryVariables> {
    document = StaffMembersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStaffPhotoSignedUrlDocument = gql`
    mutation UpdateStaffPhotoSignedUrl($input: StaffPhotoSignUrlRequest!) {
  staffPhotoSignUrl(input: $input) {
    contentType
    httpMethod
    signedUrl
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStaffPhotoSignedUrlGQL extends Apollo.Mutation<UpdateStaffPhotoSignedUrlMutation, UpdateStaffPhotoSignedUrlMutationVariables> {
    document = UpdateStaffPhotoSignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBrandBusinessOwnerInvitationDocument = gql`
    mutation AcceptBrandBusinessOwnerInvitation($invitationId: ID!) {
  acceptBrandBusinessOwnerInvitation(invitationId: $invitationId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBrandBusinessOwnerInvitationGQL extends Apollo.Mutation<AcceptBrandBusinessOwnerInvitationMutation, AcceptBrandBusinessOwnerInvitationMutationVariables> {
    document = AcceptBrandBusinessOwnerInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBrandBusinessOwnerInvitationPhoneDocument = gql`
    mutation AcceptBrandBusinessOwnerInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBrandBusinessOwnerInvitationPhoneGQL extends Apollo.Mutation<AcceptBrandBusinessOwnerInvitationPhoneMutation, AcceptBrandBusinessOwnerInvitationPhoneMutationVariables> {
    document = AcceptBrandBusinessOwnerInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBrandBusinessOwnerInvitationTermsDocument = gql`
    mutation AcceptBrandBusinessOwnerInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBrandBusinessOwnerInvitationTermsGQL extends Apollo.Mutation<AcceptBrandBusinessOwnerInvitationTermsMutation, AcceptBrandBusinessOwnerInvitationTermsMutationVariables> {
    document = AcceptBrandBusinessOwnerInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptBrandBusinessOwnerInvitationInvitationDocument = gql`
    query AcceptBrandBusinessOwnerInvitationInvitation($invitationId: ID!) {
  brandBusinessOwnerInvitation(invitationId: $invitationId) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptBrandBusinessOwnerInvitationInvitationGQL extends Apollo.Query<AcceptBrandBusinessOwnerInvitationInvitationQuery, AcceptBrandBusinessOwnerInvitationInvitationQueryVariables> {
    document = AcceptBrandBusinessOwnerInvitationInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPmcBusinessOwnerInvitationDocument = gql`
    mutation AcceptPmcBusinessOwnerInvitation($invitationId: ID!) {
  acceptPmcBusinessOwnerInvitation(invitationId: $invitationId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPmcBusinessOwnerInvitationGQL extends Apollo.Mutation<AcceptPmcBusinessOwnerInvitationMutation, AcceptPmcBusinessOwnerInvitationMutationVariables> {
    document = AcceptPmcBusinessOwnerInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPmcBusinessOwnerInvitationPhoneDocument = gql`
    mutation AcceptPmcBusinessOwnerInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPmcBusinessOwnerInvitationPhoneGQL extends Apollo.Mutation<AcceptPmcBusinessOwnerInvitationPhoneMutation, AcceptPmcBusinessOwnerInvitationPhoneMutationVariables> {
    document = AcceptPmcBusinessOwnerInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPmcBusinessOwnerInvitationTermsDocument = gql`
    mutation AcceptPmcBusinessOwnerInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPmcBusinessOwnerInvitationTermsGQL extends Apollo.Mutation<AcceptPmcBusinessOwnerInvitationTermsMutation, AcceptPmcBusinessOwnerInvitationTermsMutationVariables> {
    document = AcceptPmcBusinessOwnerInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPmcBusinessOwnerInvitationInvitationDocument = gql`
    query AcceptPmcBusinessOwnerInvitationInvitation($invitationId: ID!) {
  pmcBusinessOwnerInvitation(invitationId: $invitationId) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPmcBusinessOwnerInvitationInvitationGQL extends Apollo.Query<AcceptPmcBusinessOwnerInvitationInvitationQuery, AcceptPmcBusinessOwnerInvitationInvitationQueryVariables> {
    document = AcceptPmcBusinessOwnerInvitationInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptStaffMemberInvitationDocument = gql`
    mutation AcceptStaffMemberInvitation($input: AcceptStaffMemberInvitationCommand!) {
  acceptStaffMemberInvitation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptStaffMemberInvitationGQL extends Apollo.Mutation<AcceptStaffMemberInvitationMutation, AcceptStaffMemberInvitationMutationVariables> {
    document = AcceptStaffMemberInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptStaffMemberInvitationPhoneDocument = gql`
    mutation AcceptStaffMemberInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptStaffMemberInvitationPhoneGQL extends Apollo.Mutation<AcceptStaffMemberInvitationPhoneMutation, AcceptStaffMemberInvitationPhoneMutationVariables> {
    document = AcceptStaffMemberInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptStaffMemberInvitationTermsDocument = gql`
    mutation AcceptStaffMemberInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptStaffMemberInvitationTermsGQL extends Apollo.Mutation<AcceptStaffMemberInvitationTermsMutation, AcceptStaffMemberInvitationTermsMutationVariables> {
    document = AcceptStaffMemberInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptStaffMemberInvitationSummaryDocument = gql`
    query AcceptStaffMemberInvitationSummary($invitationId: ID!) {
  staffMemberInvitationSummary(invitationId: $invitationId) {
    invitationId
    staffId
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptStaffMemberInvitationSummaryGQL extends Apollo.Query<AcceptStaffMemberInvitationSummaryQuery, AcceptStaffMemberInvitationSummaryQueryVariables> {
    document = AcceptStaffMemberInvitationSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTravelAgentBusinessOwnerInvitationDocument = gql`
    mutation AcceptTravelAgentBusinessOwnerInvitation($invitationId: ID!) {
  acceptTravelAgentBusinessOwnerInvitation(invitationId: $invitationId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTravelAgentBusinessOwnerInvitationGQL extends Apollo.Mutation<AcceptTravelAgentBusinessOwnerInvitationMutation, AcceptTravelAgentBusinessOwnerInvitationMutationVariables> {
    document = AcceptTravelAgentBusinessOwnerInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTravelAgentBusinessOwnerInvitationPhoneDocument = gql`
    mutation AcceptTravelAgentBusinessOwnerInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTravelAgentBusinessOwnerInvitationPhoneGQL extends Apollo.Mutation<AcceptTravelAgentBusinessOwnerInvitationPhoneMutation, AcceptTravelAgentBusinessOwnerInvitationPhoneMutationVariables> {
    document = AcceptTravelAgentBusinessOwnerInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTravelAgentBusinessOwnerInvitationTermsDocument = gql`
    mutation AcceptTravelAgentBusinessOwnerInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTravelAgentBusinessOwnerInvitationTermsGQL extends Apollo.Mutation<AcceptTravelAgentBusinessOwnerInvitationTermsMutation, AcceptTravelAgentBusinessOwnerInvitationTermsMutationVariables> {
    document = AcceptTravelAgentBusinessOwnerInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptTravelAgentBusinessOwnerInvitationInvitationDocument = gql`
    query AcceptTravelAgentBusinessOwnerInvitationInvitation($invitationId: ID!) {
  travelAgentBusinessOwnerInvitation(invitationId: $invitationId) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptTravelAgentBusinessOwnerInvitationInvitationGQL extends Apollo.Query<AcceptTravelAgentBusinessOwnerInvitationInvitationQuery, AcceptTravelAgentBusinessOwnerInvitationInvitationQueryVariables> {
    document = AcceptTravelAgentBusinessOwnerInvitationInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountOwnedPmcsDocument = gql`
    query AccountOwnedPmcs {
  ownedPmcs {
    pmcId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountOwnedPmcsGQL extends Apollo.Query<AccountOwnedPmcsQuery, AccountOwnedPmcsQueryVariables> {
    document = AccountOwnedPmcsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccountOwnedTravelAgentsDocument = gql`
    query AccountOwnedTravelAgents {
  ownedTravelAgents {
    travelAgentId
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountOwnedTravelAgentsGQL extends Apollo.Query<AccountOwnedTravelAgentsQuery, AccountOwnedTravelAgentsQueryVariables> {
    document = AccountOwnedTravelAgentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBankAccountDocument = gql`
    mutation UpdateBankAccount($input: UpdateBankAccountCommand!) {
  updateBankAccount(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBankAccountGQL extends Apollo.Mutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables> {
    document = UpdateBankAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBankAccountDetailsDocument = gql`
    query UpdateBankAccountDetails {
  bankAccountDetails {
    accountId
    bankAccount {
      accountType
      accountHolderName
      bankName
      countryCode
      bankCode
      transitNumber
      accountNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBankAccountDetailsGQL extends Apollo.Query<UpdateBankAccountDetailsQuery, UpdateBankAccountDetailsQueryVariables> {
    document = UpdateBankAccountDetailsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonalInformationDocument = gql`
    mutation UpdatePersonalInformation($input: UpdatePersonalInformationCommand!) {
  updatePersonalInformation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalInformationGQL extends Apollo.Mutation<UpdatePersonalInformationMutation, UpdatePersonalInformationMutationVariables> {
    document = UpdatePersonalInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonalInformationProfileDocument = gql`
    query UpdatePersonalInformationProfile {
  account {
    accountId
    personalProfile {
      email
      firstName
      lastName
      phoneNumber {
        countryCode
        nationalNumber
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalInformationProfileGQL extends Apollo.Query<UpdatePersonalInformationProfileQuery, UpdatePersonalInformationProfileQueryVariables> {
    document = UpdatePersonalInformationProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonalPhotoDocument = gql`
    mutation UpdatePersonalPhoto($input: UpdatePersonalPhotoCommand!) {
  updatePersonalPhoto(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalPhotoGQL extends Apollo.Mutation<UpdatePersonalPhotoMutation, UpdatePersonalPhotoMutationVariables> {
    document = UpdatePersonalPhotoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonalPhotoProfileDocument = gql`
    query UpdatePersonalPhotoProfile {
  account {
    accountId
    personalProfile {
      email
      photoUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalPhotoProfileGQL extends Apollo.Query<UpdatePersonalPhotoProfileQuery, UpdatePersonalPhotoProfileQueryVariables> {
    document = UpdatePersonalPhotoProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMemberPhotoSignedUrlDocument = gql`
    mutation UpdateMemberPhotoSignedUrl($input: ProfilePhotoSignUrlRequest!) {
  profilePhotoSignUrl(input: $input) {
    contentType
    httpMethod
    signedUrl
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMemberPhotoSignedUrlGQL extends Apollo.Mutation<UpdateMemberPhotoSignedUrlMutation, UpdateMemberPhotoSignedUrlMutationVariables> {
    document = UpdateMemberPhotoSignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStaffDocument = gql`
    mutation CreateStaff($input: CreateStaffCommand!) {
  createStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStaffGQL extends Apollo.Mutation<CreateStaffMutation, CreateStaffMutationVariables> {
    document = CreateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignPersonalStaffToMemberDocument = gql`
    mutation AssignPersonalStaffToMember($input: AssignPersonalStaffToMemberCommand!) {
  assignPersonalStaffToMember(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignPersonalStaffToMemberGQL extends Apollo.Mutation<AssignPersonalStaffToMemberMutation, AssignPersonalStaffToMemberMutationVariables> {
    document = AssignPersonalStaffToMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePersonalStaffDocument = gql`
    query UpdatePersonalStaff {
  myPersonalStaff {
    displayName
    email
    phoneNumber {
      countryCode
      nationalNumber
    }
    photoUrl
    staffId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePersonalStaffGQL extends Apollo.Query<UpdatePersonalStaffQuery, UpdatePersonalStaffQueryVariables> {
    document = UpdatePersonalStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStaffDocument = gql`
    mutation UpdateStaff($input: UpdateStaffCommand!) {
  updateStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStaffGQL extends Apollo.Mutation<UpdateStaffMutation, UpdateStaffMutationVariables> {
    document = UpdateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdatePmcAssignStaffToPmcDocument = gql`
    mutation PartnerUpdatePmcAssignStaffToPmc($input: AssignStaffToPmcCommand!) {
  assignStaffToPmc(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdatePmcAssignStaffToPmcGQL extends Apollo.Mutation<PartnerUpdatePmcAssignStaffToPmcMutation, PartnerUpdatePmcAssignStaffToPmcMutationVariables> {
    document = PartnerUpdatePmcAssignStaffToPmcDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdatePmcCreateStaffDocument = gql`
    mutation PartnerUpdatePmcCreateStaff($input: CreateStaffCommand!) {
  createStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdatePmcCreateStaffGQL extends Apollo.Mutation<PartnerUpdatePmcCreateStaffMutation, PartnerUpdatePmcCreateStaffMutationVariables> {
    document = PartnerUpdatePmcCreateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdatePmcListStaffDocument = gql`
    query PartnerUpdatePmcListStaff($pmcId: ID!) {
  pmcStaff(pmcId: $pmcId) {
    displayName
    email
    phoneNumber {
      countryCode
      nationalNumber
    }
    photoUrl
    staffId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdatePmcListStaffGQL extends Apollo.Query<PartnerUpdatePmcListStaffQuery, PartnerUpdatePmcListStaffQueryVariables> {
    document = PartnerUpdatePmcListStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdatePmcUpdateStaffDocument = gql`
    mutation PartnerUpdatePmcUpdateStaff($input: UpdateStaffCommand!) {
  updateStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdatePmcUpdateStaffGQL extends Apollo.Mutation<PartnerUpdatePmcUpdateStaffMutation, PartnerUpdatePmcUpdateStaffMutationVariables> {
    document = PartnerUpdatePmcUpdateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdateTravelAgentAssignStaffToTravelAgentDocument = gql`
    mutation PartnerUpdateTravelAgentAssignStaffToTravelAgent($input: AssignStaffToTravelAgentCommand!) {
  assignStaffToTravelAgent(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateTravelAgentAssignStaffToTravelAgentGQL extends Apollo.Mutation<PartnerUpdateTravelAgentAssignStaffToTravelAgentMutation, PartnerUpdateTravelAgentAssignStaffToTravelAgentMutationVariables> {
    document = PartnerUpdateTravelAgentAssignStaffToTravelAgentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdateTravelAgentCreateStaffDocument = gql`
    mutation PartnerUpdateTravelAgentCreateStaff($input: CreateStaffCommand!) {
  createStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateTravelAgentCreateStaffGQL extends Apollo.Mutation<PartnerUpdateTravelAgentCreateStaffMutation, PartnerUpdateTravelAgentCreateStaffMutationVariables> {
    document = PartnerUpdateTravelAgentCreateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdateTravelAgentListStaffDocument = gql`
    query PartnerUpdateTravelAgentListStaff($travelAgentId: ID!) {
  travelAgentStaff(travelAgentId: $travelAgentId) {
    displayName
    email
    phoneNumber {
      countryCode
      nationalNumber
    }
    photoUrl
    staffId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateTravelAgentListStaffGQL extends Apollo.Query<PartnerUpdateTravelAgentListStaffQuery, PartnerUpdateTravelAgentListStaffQueryVariables> {
    document = PartnerUpdateTravelAgentListStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartnerUpdateTravelAgentUpdateStaffDocument = gql`
    mutation PartnerUpdateTravelAgentUpdateStaff($input: UpdateStaffCommand!) {
  updateStaff(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartnerUpdateTravelAgentUpdateStaffGQL extends Apollo.Mutation<PartnerUpdateTravelAgentUpdateStaffMutation, PartnerUpdateTravelAgentUpdateStaffMutationVariables> {
    document = PartnerUpdateTravelAgentUpdateStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddApplicationPhotosDocument = gql`
    mutation AddApplicationPhotos($input: AddApplicationPhotosCommand!) {
  addApplicationPhotos(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddApplicationPhotosGQL extends Apollo.Mutation<AddApplicationPhotosMutation, AddApplicationPhotosMutationVariables> {
    document = AddApplicationPhotosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationPhotosSignedUrlDocument = gql`
    mutation ApplicationPhotosSignedUrl($input: ApplicationPhotoSignUrlRequest!) {
  applicationPhotoSignUrl(input: $input) {
    contentType
    httpMethod
    signedUrl
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationPhotosSignedUrlGQL extends Apollo.Mutation<ApplicationPhotosSignedUrlMutation, ApplicationPhotosSignedUrlMutationVariables> {
    document = ApplicationPhotosSignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationDocument = gql`
    mutation Application($input: SubmitMemberApplicationCommand!) {
  submitMemberApplication(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationGQL extends Apollo.Mutation<ApplicationMutation, ApplicationMutationVariables> {
    document = ApplicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplicationActiveMemberApplicationDocument = gql`
    query ApplicationActiveMemberApplication {
  activeMemberApplication {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplicationActiveMemberApplicationGQL extends Apollo.Query<ApplicationActiveMemberApplicationQuery, ApplicationActiveMemberApplicationQueryVariables> {
    document = ApplicationActiveMemberApplicationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BalanceLogDocument = gql`
    query BalanceLog {
  myBalanceLog {
    tokenType {
      name
      symbol
    }
    logLines {
      amount
      balance
      date
      description
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BalanceLogGQL extends Apollo.Query<BalanceLogQuery, BalanceLogQueryVariables> {
    document = BalanceLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CalendarListingsDocument = gql`
    query CalendarListings($memberId: ID!) {
  listingsByMember(memberId: $memberId) {
    status
    asset {
      assetId
      assetType
    }
    listingId
    summary {
      city
      countryCode
      title
      subtitle
      photos {
        url
        useAsCover
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CalendarListingsGQL extends Apollo.Query<CalendarListingsQuery, CalendarListingsQueryVariables> {
    document = CalendarListingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GuestbookBusinessCustomerDocument = gql`
    query GuestbookBusinessCustomer($businessId: ID!, $userId: String!) {
  businessCustomer(businessId: $businessId, userId: $userId) {
    userId
    email
    firstName
    lastName
    phoneNumber {
      countryCode
      nationalNumber
    }
    photoUrl
    trips {
      tripId
      bookings {
        tripId
        bookingId
        listingId
        status
        bookingReference
        checkin
        checkout
        nights
        numberOfGuests
        fees {
          tokenType {
            name
            symbol
          }
          tokenFee {
            amount
          }
          exchangeFee {
            amountMicros
            currency
          }
          bookingFee {
            amountMicros
            currency
          }
        }
        guest {
          guestId
          firstName
          lastName
          imageUrl
        }
        summary {
          name
          imageUrl
          location {
            geoLocation {
              lat
              lng
            }
            address {
              line1
              line2
              city
              postalCode
              countryCode
              additionalInstructions
            }
          }
        }
        bookingSupplementsDetails {
          supplementDetails {
            supplementId
            iconName
            name
            description
            price {
              amountMicros
              currency
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GuestbookBusinessCustomerGQL extends Apollo.Query<GuestbookBusinessCustomerQuery, GuestbookBusinessCustomerQueryVariables> {
    document = GuestbookBusinessCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TripSummaryHostDocument = gql`
    query TripSummaryHost($listingId: ID!) {
  hostingTeamByListing(listingId: $listingId) {
    displayName
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TripSummaryHostGQL extends Apollo.Query<TripSummaryHostQuery, TripSummaryHostQueryVariables> {
    document = TripSummaryHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GuestbookClienteleByBusinessIdDocument = gql`
    query GuestbookClienteleByBusinessId($businessId: ID!) {
  clienteleByBusinessId(businessId: $businessId) {
    clienteleId
    businessId
    customers {
      userId
      firstName
      lastName
      email
      phoneNumber {
        countryCode
        nationalNumber
      }
      photoUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GuestbookClienteleByBusinessIdGQL extends Apollo.Query<GuestbookClienteleByBusinessIdQuery, GuestbookClienteleByBusinessIdQueryVariables> {
    document = GuestbookClienteleByBusinessIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayRescheduleBookingBookingDocument = gql`
    query PayRescheduleBookingBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    tripId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayRescheduleBookingBookingGQL extends Apollo.Query<PayRescheduleBookingBookingQuery, PayRescheduleBookingBookingQueryVariables> {
    document = PayRescheduleBookingBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayRescheduleBookingOrderDocument = gql`
    query PayRescheduleBookingOrder($orderId: ID!) {
  rescheduleBookingOrder(orderId: $orderId) {
    bookingId
    checkin
    checkout
    feesDue {
      tokenType {
        name
        symbol
        exchangeRate {
          amountMicros
          currency
        }
      }
      tokenFee {
        amount
      }
      exchangeFee {
        amountMicros
        currency
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayRescheduleBookingOrderGQL extends Apollo.Query<PayRescheduleBookingOrderQuery, PayRescheduleBookingOrderQueryVariables> {
    document = PayRescheduleBookingOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayStayExtensionBookingDocument = gql`
    query PayStayExtensionBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    tripId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayStayExtensionBookingGQL extends Apollo.Query<PayStayExtensionBookingQuery, PayStayExtensionBookingQueryVariables> {
    document = PayStayExtensionBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayStayExtensionOrderDocument = gql`
    query PayStayExtensionOrder($orderId: ID!) {
  stayExtensionOrder(orderId: $orderId) {
    bookingId
    checkout
    feesDue {
      tokenType {
        name
        symbol
        exchangeRate {
          amountMicros
          currency
        }
      }
      tokenFee {
        amount
      }
      exchangeFee {
        amountMicros
        currency
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayStayExtensionOrderGQL extends Apollo.Query<PayStayExtensionOrderQuery, PayStayExtensionOrderQueryVariables> {
    document = PayStayExtensionOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmListingByTravelDatesDocument = gql`
    query ConfirmListingByTravelDates($listingId: ID!, $checkin: LocalDate!, $checkout: LocalDate!) {
  listingByTravelDates(
    listingId: $listingId
    checkin: $checkin
    checkout: $checkout
  ) {
    asset {
      assetId
      assetType
    }
    checkin
    checkout
    fees {
      tokenType {
        name
        symbol
      }
      tokenFee {
        amount
      }
      exchangeFee {
        amountMicros
        currency
      }
      bookingFee {
        amountMicros
        currency
      }
    }
    listingId
    summary {
      city
      countryCode
      houseRules {
        otherRules
        rules {
          enabled
          icon
          subtitle
          title
        }
      }
      title
      subtitle
      photos {
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmListingByTravelDatesGQL extends Apollo.Query<ConfirmListingByTravelDatesQuery, ConfirmListingByTravelDatesQueryVariables> {
    document = ConfirmListingByTravelDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmBookingCancelCheckoutDocument = gql`
    mutation ConfirmBookingCancelCheckout($input: CancelCheckoutRequest!) {
  cancelCheckout(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmBookingCancelCheckoutGQL extends Apollo.Mutation<ConfirmBookingCancelCheckoutMutation, ConfirmBookingCancelCheckoutMutationVariables> {
    document = ConfirmBookingCancelCheckoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmBookingDocument = gql`
    query ConfirmBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    checkin
    checkout
    summary {
      imageUrl
      location {
        address {
          city
          countryCode
        }
      }
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmBookingGQL extends Apollo.Query<ConfirmBookingQuery, ConfirmBookingQueryVariables> {
    document = ConfirmBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmPriceBreakdownTokenBalanceDocument = gql`
    query ConfirmPriceBreakdownTokenBalance {
  myTokenBalance {
    accountBalanceId
    amount
    tokenType {
      name
      exchangeRate {
        currency
        amountMicros
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmPriceBreakdownTokenBalanceGQL extends Apollo.Query<ConfirmPriceBreakdownTokenBalanceQuery, ConfirmPriceBreakdownTokenBalanceQueryVariables> {
    document = ConfirmPriceBreakdownTokenBalanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumerSignupDocument = gql`
    mutation ConsumerSignup($input: SignupConsumerRequest!) {
  signupConsumer(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumerSignupGQL extends Apollo.Mutation<ConsumerSignupMutation, ConsumerSignupMutationVariables> {
    document = ConsumerSignupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumerSignupUpdatePersonalInformationDocument = gql`
    mutation ConsumerSignupUpdatePersonalInformation($input: UpdatePersonalInformationCommand!) {
  updatePersonalInformation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumerSignupUpdatePersonalInformationGQL extends Apollo.Mutation<ConsumerSignupUpdatePersonalInformationMutation, ConsumerSignupUpdatePersonalInformationMutationVariables> {
    document = ConsumerSignupUpdatePersonalInformationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumerSignupAcceptTermsDocument = gql`
    mutation ConsumerSignupAcceptTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumerSignupAcceptTermsGQL extends Apollo.Mutation<ConsumerSignupAcceptTermsMutation, ConsumerSignupAcceptTermsMutationVariables> {
    document = ConsumerSignupAcceptTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConsumerSignupAccountExistsDocument = gql`
    query ConsumerSignupAccountExists {
  account {
    accountId
    accountHolderId
    personalProfile {
      email
      firstName
      lastName
      phoneNumber {
        countryCode
        nationalNumber
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConsumerSignupAccountExistsGQL extends Apollo.Query<ConsumerSignupAccountExistsQuery, ConsumerSignupAccountExistsQueryVariables> {
    document = ConsumerSignupAccountExistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FavouriteDocument = gql`
    query Favourite($favouriteType: FavouriteType, $referenceId: String!) {
  favourite(favouriteType: $favouriteType, referenceId: $referenceId) {
    reference {
      type
      referenceId
    }
    addedAsFavouriteByOthers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FavouriteGQL extends Apollo.Query<FavouriteQuery, FavouriteQueryVariables> {
    document = FavouriteDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToFavouritesDocument = gql`
    mutation AddToFavourites($input: AddToFavouritesCommand!) {
  addToFavourites(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToFavouritesGQL extends Apollo.Mutation<AddToFavouritesMutation, AddToFavouritesMutationVariables> {
    document = AddToFavouritesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveFromFavouritesDocument = gql`
    mutation RemoveFromFavourites($input: RemoveFromFavouritesCommand!) {
  removeFromFavourites(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveFromFavouritesGQL extends Apollo.Mutation<RemoveFromFavouritesMutation, RemoveFromFavouritesMutationVariables> {
    document = RemoveFromFavouritesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FavouriteListingDocument = gql`
    query FavouriteListing($listingId: ID!) {
  listing(listingId: $listingId) {
    listingId
    status
    summary {
      title
      subtitle
      city
      countryCode
      decidingFactors {
        primaryDecidingFactors {
          enabled
          icon
          title
          subtitle
        }
      }
      highlightedFeatures {
        features {
          title
          description
          iconName
        }
      }
      photos {
        url
        description
        useAsCover
        useAsFloorplan
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FavouriteListingGQL extends Apollo.Query<FavouriteListingQuery, FavouriteListingQueryVariables> {
    document = FavouriteListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FavouritesDocument = gql`
    query Favourites {
  favourites {
    reference {
      type
      referenceId
    }
    addedAsFavouriteByOthers
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FavouritesGQL extends Apollo.Query<FavouritesQuery, FavouritesQueryVariables> {
    document = FavouritesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FeaturedListingListingsDocument = gql`
    query FeaturedListingListings($featuredListingCode: String!) {
  featuredListings(featuredListingCode: $featuredListingCode) {
    listingCode
    name
    publicListingEntries {
      listingId
      photos {
        url
        description
        useAsCover
      }
      city
      countryCode
      title
      decidingFactors {
        primaryDecidingFactors {
          enabled
          icon
          title
          subtitle
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FeaturedListingListingsGQL extends Apollo.Query<FeaturedListingListingsQuery, FeaturedListingListingsQueryVariables> {
    document = FeaturedListingListingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptAssetOwnerInvitationDocument = gql`
    mutation AcceptAssetOwnerInvitation($input: AcceptAssetOwnerInvitationCommand!) {
  acceptAssetOwnerInvitation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptAssetOwnerInvitationGQL extends Apollo.Mutation<AcceptAssetOwnerInvitationMutation, AcceptAssetOwnerInvitationMutationVariables> {
    document = AcceptAssetOwnerInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptAssetOwnerAddressDocument = gql`
    mutation AcceptAssetOwnerAddress($input: UpdatePersonalAddressCommand!) {
  updatePersonalAddress(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptAssetOwnerAddressGQL extends Apollo.Mutation<AcceptAssetOwnerAddressMutation, AcceptAssetOwnerAddressMutationVariables> {
    document = AcceptAssetOwnerAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptAssetOwnerInvitationPhoneDocument = gql`
    mutation AcceptAssetOwnerInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptAssetOwnerInvitationPhoneGQL extends Apollo.Mutation<AcceptAssetOwnerInvitationPhoneMutation, AcceptAssetOwnerInvitationPhoneMutationVariables> {
    document = AcceptAssetOwnerInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptAssetOwnerInvitationTermsDocument = gql`
    mutation AcceptAssetOwnerInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptAssetOwnerInvitationTermsGQL extends Apollo.Mutation<AcceptAssetOwnerInvitationTermsMutation, AcceptAssetOwnerInvitationTermsMutationVariables> {
    document = AcceptAssetOwnerInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptAssetOwnerInvitationSummaryDocument = gql`
    query AcceptAssetOwnerInvitationSummary($invitationId: ID!) {
  assetOwnerInvitationSummary(invitationId: $invitationId) {
    invitationId
    assetId
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptAssetOwnerInvitationSummaryGQL extends Apollo.Query<AcceptAssetOwnerInvitationSummaryQuery, AcceptAssetOwnerInvitationSummaryQueryVariables> {
    document = AcceptAssetOwnerInvitationSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HotelHeaderHotelDocument = gql`
    query HotelHeaderHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    general {
      name
    }
    imagery {
      photos {
        url
      }
    }
    location {
      address {
        city
        countryCode
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HotelHeaderHotelGQL extends Apollo.Query<HotelHeaderHotelQuery, HotelHeaderHotelQueryVariables> {
    document = HotelHeaderHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelAmenitiesDocument = gql`
    mutation UpdateHotelAmenities($input: UpdateHotelAmenitiesCommand!) {
  updateHotelAmenities(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelAmenitiesGQL extends Apollo.Mutation<UpdateHotelAmenitiesMutation, UpdateHotelAmenitiesMutationVariables> {
    document = UpdateHotelAmenitiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelAmenitiesHotelDocument = gql`
    query UpdateHotelAmenitiesHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    amenities {
      amenityIds
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelAmenitiesHotelGQL extends Apollo.Query<UpdateHotelAmenitiesHotelQuery, UpdateHotelAmenitiesHotelQueryVariables> {
    document = UpdateHotelAmenitiesHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelAmenityBundleByAssetDocument = gql`
    query UpdateHotelAmenityBundleByAsset($assetId: ID!) {
  amenityBundleByAsset(assetId: $assetId) {
    bundleId
    name
    sections {
      sectionId
      name
      amenities {
        amenityId
        name
        description
        iconName
        enabled
      }
    }
    enabled
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelAmenityBundleByAssetGQL extends Apollo.Query<UpdateHotelAmenityBundleByAssetQuery, UpdateHotelAmenityBundleByAssetQueryVariables> {
    document = UpdateHotelAmenityBundleByAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelGeneralDocument = gql`
    mutation UpdateHotelGeneral($input: UpdateHotelGeneralCommand!) {
  updateHotelGeneral(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelGeneralGQL extends Apollo.Mutation<UpdateHotelGeneralMutation, UpdateHotelGeneralMutationVariables> {
    document = UpdateHotelGeneralDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelGeneralHotelDocument = gql`
    query UpdateHotelGeneralHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    general {
      averageDailyRate {
        amountMicros
        currency
      }
      description
      name
      stars
      website
    }
    hotelId
    location {
      address {
        line1
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelGeneralHotelGQL extends Apollo.Query<UpdateHotelGeneralHotelQuery, UpdateHotelGeneralHotelQueryVariables> {
    document = UpdateHotelGeneralHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelHouseRulesDocument = gql`
    mutation UpdateHotelHouseRules($input: UpdateHotelHouseRulesCommand!) {
  updateHotelHouseRules(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelHouseRulesGQL extends Apollo.Mutation<UpdateHotelHouseRulesMutation, UpdateHotelHouseRulesMutationVariables> {
    document = UpdateHotelHouseRulesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelHouseRulesHotelDocument = gql`
    query UpdateHotelHouseRulesHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    houseRules {
      allowsChildren
      earliestCheckin
      latestCheckin
      maximumStay
      minimumStay
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelHouseRulesHotelGQL extends Apollo.Query<UpdateHotelHouseRulesHotelQuery, UpdateHotelHouseRulesHotelQueryVariables> {
    document = UpdateHotelHouseRulesHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelLocationDocument = gql`
    mutation UpdateHotelLocation($input: UpdateHotelLocationCommand!) {
  updateHotelLocation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelLocationGQL extends Apollo.Mutation<UpdateHotelLocationMutation, UpdateHotelLocationMutationVariables> {
    document = UpdateHotelLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelLocationHotelDocument = gql`
    query UpdateHotelLocationHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    location {
      address {
        additionalInstructions
        city
        countryCode
        line1
        line2
        postalCode
        region
      }
      geoLocation {
        lat
        lng
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelLocationHotelGQL extends Apollo.Query<UpdateHotelLocationHotelQuery, UpdateHotelLocationHotelQueryVariables> {
    document = UpdateHotelLocationHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelPhotosDocument = gql`
    mutation UpdateHotelPhotos($input: UpdateHotelPhotosCommand!) {
  updateHotelPhotos(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelPhotosGQL extends Apollo.Mutation<UpdateHotelPhotosMutation, UpdateHotelPhotosMutationVariables> {
    document = UpdateHotelPhotosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelPhotosHotelDocument = gql`
    query UpdateHotelPhotosHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    imagery {
      photos {
        description
        url
        useAsCover
        useAsFloorplan
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelPhotosHotelGQL extends Apollo.Query<UpdateHotelPhotosHotelQuery, UpdateHotelPhotosHotelQueryVariables> {
    document = UpdateHotelPhotosHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelPhotosSignedUrlDocument = gql`
    mutation UpdateHotelPhotosSignedUrl($input: HotelPhotoSignUrlRequest!) {
  hotelPhotoSignUrl(input: $input) {
    contentType
    httpMethod
    signedUrl
    url
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelPhotosSignedUrlGQL extends Apollo.Mutation<UpdateHotelPhotosSignedUrlMutation, UpdateHotelPhotosSignedUrlMutationVariables> {
    document = UpdateHotelPhotosSignedUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelGoodToKnowDocument = gql`
    mutation UpdateHotelGoodToKnow($input: UpdateHotelGoodToKnowCommand!) {
  updateHotelGoodToKnow(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelGoodToKnowGQL extends Apollo.Mutation<UpdateHotelGoodToKnowMutation, UpdateHotelGoodToKnowMutationVariables> {
    document = UpdateHotelGoodToKnowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelGoodToKnowHotelDocument = gql`
    query UpdateHotelGoodToKnowHotel($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    goodToKnow {
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelGoodToKnowHotelGQL extends Apollo.Query<UpdateHotelGoodToKnowHotelQuery, UpdateHotelGoodToKnowHotelQueryVariables> {
    document = UpdateHotelGoodToKnowHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AssignStaffToHotelDocument = gql`
    mutation AssignStaffToHotel($input: AssignStaffToHotelCommand!) {
  assignStaffToHotel(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AssignStaffToHotelGQL extends Apollo.Mutation<AssignStaffToHotelMutation, AssignStaffToHotelMutationVariables> {
    document = AssignStaffToHotelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelStaffDocument = gql`
    query UpdateHotelStaff($hotelId: ID!) {
  hotelStaff(hotelId: $hotelId) {
    displayName
    email
    phoneNumber {
      countryCode
      nationalNumber
    }
    photoUrl
    staffId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelStaffGQL extends Apollo.Query<UpdateHotelStaffQuery, UpdateHotelStaffQueryVariables> {
    document = UpdateHotelStaffDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelSupplementsDocument = gql`
    query UpdateHotelSupplements($hotelId: ID!) {
  hotel(hotelId: $hotelId) {
    rooms {
      hotelRoomId
      roomType
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelSupplementsGQL extends Apollo.Query<UpdateHotelSupplementsQuery, UpdateHotelSupplementsQueryVariables> {
    document = UpdateHotelSupplementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelSupplementBundlesDocument = gql`
    query UpdateHotelSupplementBundles($assetId: ID!) {
  supplementBundleByAsset(assetId: $assetId) {
    bundleId
    name
    enabled
    sections {
      sectionId
      name
      supplements {
        supplementId
        name
        description
        iconName
        price {
          currency
          amountMicros
        }
        enabled
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelSupplementBundlesGQL extends Apollo.Query<UpdateHotelSupplementBundlesQuery, UpdateHotelSupplementBundlesQueryVariables> {
    document = UpdateHotelSupplementBundlesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelSupplementIdsListingDocument = gql`
    query UpdateHotelSupplementIdsListing($listingId: ID!) {
  listing(listingId: $listingId) {
    supplements {
      listingId
      mandatorySupplements {
        id
        price {
          currency
          amountMicros
        }
        description
      }
      optionalSupplements {
        id
        price {
          currency
          amountMicros
        }
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelSupplementIdsListingGQL extends Apollo.Query<UpdateHotelSupplementIdsListingQuery, UpdateHotelSupplementIdsListingQueryVariables> {
    document = UpdateHotelSupplementIdsListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateHotelSupplementsListingDocument = gql`
    mutation UpdateHotelSupplementsListing($input: UpdateListingSupplementsCommand!) {
  updateListingSupplements(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateHotelSupplementsListingGQL extends Apollo.Mutation<UpdateHotelSupplementsListingMutation, UpdateHotelSupplementsListingMutationVariables> {
    document = UpdateHotelSupplementsListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HotelsDocument = gql`
    query Hotels {
  hotels {
    general {
      name
    }
    imagery {
      photos {
        url
        useAsCover
      }
    }
    hotelId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HotelsGQL extends Apollo.Query<HotelsQuery, HotelsQueryVariables> {
    document = HotelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConversationDocument = gql`
    query Conversation($conversationId: ID!, $device: String!) {
  conversationToken(conversationId: $conversationId, device: $device) {
    identity
    token
    flokId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConversationGQL extends Apollo.Query<ConversationQuery, ConversationQueryVariables> {
    document = ConversationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendConversationMessageRequestDocument = gql`
    mutation SendConversationMessageRequest($input: SendConversationMessageRequest!) {
  sendConversationMessage(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendConversationMessageRequestGQL extends Apollo.Mutation<SendConversationMessageRequestMutation, SendConversationMessageRequestMutationVariables> {
    document = SendConversationMessageRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InboxDocument = gql`
    query Inbox {
  inbox {
    messageThreads {
      latestMessage {
        sender {
          displayName
          imageUrl
          participantId
        }
        text
        created
      }
      messageThreadId
      participants {
        chatServerIdentity
        displayName
        imageUrl
        participantId
      }
      subject {
        subjectId
        subjectType
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InboxGQL extends Apollo.Query<InboxQuery, InboxQueryVariables> {
    document = InboxDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MessageThreadBookingDocument = gql`
    query MessageThreadBooking($bookingId: ID!) {
  booking(bookingId: $bookingId) {
    bookingId
    tripId
    checkin
    checkout
    nights
    status
    summary {
      imageUrl
      location {
        address {
          city
          countryCode
        }
      }
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MessageThreadBookingGQL extends Apollo.Query<MessageThreadBookingQuery, MessageThreadBookingQueryVariables> {
    document = MessageThreadBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MessageThreadResourceScopesDocument = gql`
    query MessageThreadResourceScopes($resourceId: ID!) {
  resourceScopes(resourceId: $resourceId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MessageThreadResourceScopesGQL extends Apollo.Query<MessageThreadResourceScopesQuery, MessageThreadResourceScopesQueryVariables> {
    document = MessageThreadResourceScopesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BookingEnquiryListingByTravelDatesDocument = gql`
    query BookingEnquiryListingByTravelDates($listingId: ID!, $checkin: LocalDate!, $checkout: LocalDate!) {
  listingByTravelDates(
    listingId: $listingId
    checkin: $checkin
    checkout: $checkout
  ) {
    listingId
    checkin
    checkout
    summary {
      city
      countryCode
      title
      subtitle
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BookingEnquiryListingByTravelDatesGQL extends Apollo.Query<BookingEnquiryListingByTravelDatesQuery, BookingEnquiryListingByTravelDatesQueryVariables> {
    document = BookingEnquiryListingByTravelDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitBookingEnquiryDocument = gql`
    mutation SubmitBookingEnquiry($input: SubmitBookingEnquiryRequest!) {
  submitBookingEnquiry(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitBookingEnquiryGQL extends Apollo.Mutation<SubmitBookingEnquiryMutation, SubmitBookingEnquiryMutationVariables> {
    document = SubmitBookingEnquiryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyGuestDocument = gql`
    query MyGuest($bookingId: ID!) {
  myGuest(bookingId: $bookingId) {
    bookingId
    listingId
    tripId
    bookingReference
    checkin
    checkout
    guest {
      firstName
      imageUrl
      lastName
    }
    nights
    status
    summary {
      imageUrl
      location {
        address {
          city
          countryCode
        }
      }
      name
    }
    bookingSupplementsDetails {
      supplementDetails {
        supplementId
        iconName
        name
        description
        price {
          amountMicros
          currency
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyGuestGQL extends Apollo.Query<MyGuestQuery, MyGuestQueryVariables> {
    document = MyGuestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyGuestResourceScopesDocument = gql`
    query MyGuestResourceScopes($resourceId: ID!) {
  resourceScopes(resourceId: $resourceId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyGuestResourceScopesGQL extends Apollo.Query<MyGuestResourceScopesQuery, MyGuestResourceScopesQueryVariables> {
    document = MyGuestResourceScopesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyGuestsDocument = gql`
    query MyGuests {
  myGuests {
    bookingId
    bookingReference
    checkin
    checkout
    guest {
      firstName
      imageUrl
      lastName
    }
    listingId
    nights
    status
    tripId
    summary {
      imageUrl
      location {
        address {
          city
          countryCode
        }
      }
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyGuestsGQL extends Apollo.Query<MyGuestsQuery, MyGuestsQueryVariables> {
    document = MyGuestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTripHostDocument = gql`
    query MyTripHost($listingId: ID!) {
  hostingTeamByListing(listingId: $listingId) {
    displayName
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTripHostGQL extends Apollo.Query<MyTripHostQuery, MyTripHostQueryVariables> {
    document = MyTripHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTripDocument = gql`
    query MyTrip($tripId: ID!) {
  trip(tripId: $tripId) {
    tripId
    bookings {
      bookingId
      bookingReference
      checkin
      checkout
      listingId
      nights
      status
      fees {
        bookingFee {
          amountMicros
          currency
        }
        exchangeFee {
          amountMicros
          currency
        }
        tokenFee {
          amount
        }
        tokenType {
          name
          symbol
        }
      }
      summary {
        imageUrl
        location {
          address {
            city
            countryCode
          }
        }
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTripGQL extends Apollo.Query<MyTripQuery, MyTripQueryVariables> {
    document = MyTripDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTripSummaryHostDocument = gql`
    query MyTripSummaryHost($listingId: ID!) {
  hostingTeamByListing(listingId: $listingId) {
    displayName
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTripSummaryHostGQL extends Apollo.Query<MyTripSummaryHostQuery, MyTripSummaryHostQueryVariables> {
    document = MyTripSummaryHostDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyTripsDocument = gql`
    query MyTrips {
  trips {
    bookings {
      bookingId
      listingId
      checkin
      checkout
      status
      summary {
        imageUrl
        location {
          address {
            city
            countryCode
          }
        }
        name
      }
    }
    tripId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyTripsGQL extends Apollo.Query<MyTripsQuery, MyTripsQueryVariables> {
    document = MyTripsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PasswordlessLoginExchangeAuthCodeDocument = gql`
    query PasswordlessLoginExchangeAuthCode($code: String!) {
  exchangeAuthCode(code: $code) {
    accessToken
    expiresIn
    tokenType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PasswordlessLoginExchangeAuthCodeGQL extends Apollo.Query<PasswordlessLoginExchangeAuthCodeQuery, PasswordlessLoginExchangeAuthCodeQueryVariables> {
    document = PasswordlessLoginExchangeAuthCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingsByMemberDocument = gql`
    query ListingsByMember($memberId: ID!) {
  listingsByMember(memberId: $memberId) {
    summary {
      city
      countryCode
      highlightedAmenities
      highlightedFeatures {
        features {
          iconName
          title
          description
        }
      }
      title
      subtitle
      photos {
        url
        useAsCover
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingsByMemberGQL extends Apollo.Query<ListingsByMemberQuery, ListingsByMemberQueryVariables> {
    document = ListingsByMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PropertiesDocument = gql`
    query Properties($skip: Int!, $limit: Int!) {
  properties(skip: $skip, limit: $limit) {
    general {
      name
    }
    imagery {
      photos {
        url
        useAsCover
      }
    }
    propertyId
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PropertiesGQL extends Apollo.Query<PropertiesQuery, PropertiesQueryVariables> {
    document = PropertiesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PropertiesListingOnboardingStatusDocument = gql`
    query PropertiesListingOnboardingStatus($listingId: ID!) {
  listingOnboardingStatus(listingId: $listingId) {
    completed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PropertiesListingOnboardingStatusGQL extends Apollo.Query<PropertiesListingOnboardingStatusQuery, PropertiesListingOnboardingStatusQueryVariables> {
    document = PropertiesListingOnboardingStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StartPropertyOnboardingDocument = gql`
    mutation StartPropertyOnboarding($input: StartPropertyOnboardingCommand!) {
  startPropertyOnboarding(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartPropertyOnboardingGQL extends Apollo.Mutation<StartPropertyOnboardingMutation, StartPropertyOnboardingMutationVariables> {
    document = StartPropertyOnboardingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExportUrlDocument = gql`
    query ExportUrl($listingId: ID!) {
  exportUrl(listingId: $listingId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportUrlGQL extends Apollo.Query<ExportUrlQuery, ExportUrlQueryVariables> {
    document = ExportUrlDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingSettingsDocument = gql`
    mutation UpdateBookingSettings($input: UpdateBookingSettingsCommand!) {
  updateBookingSettings(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingSettingsGQL extends Apollo.Mutation<UpdateBookingSettingsMutation, UpdateBookingSettingsMutationVariables> {
    document = UpdateBookingSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBookingSettingsListingDocument = gql`
    query UpdateBookingSettingsListing($listingId: ID!) {
  listing(listingId: $listingId) {
    allowInstantBook
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBookingSettingsListingGQL extends Apollo.Query<UpdateBookingSettingsListingQuery, UpdateBookingSettingsListingQueryVariables> {
    document = UpdateBookingSettingsListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PropertyHeaderListingDocument = gql`
    query PropertyHeaderListing($listingId: ID!) {
  listing(listingId: $listingId) {
    summary {
      city
      countryCode
      title
      subtitle
      photos {
        url
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PropertyHeaderListingGQL extends Apollo.Query<PropertyHeaderListingQuery, PropertyHeaderListingQueryVariables> {
    document = PropertyHeaderListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingOnboardingInProgressOnboardingStatusDocument = gql`
    query ListingOnboardingInProgressOnboardingStatus($listingId: ID!) {
  listingOnboardingStatus(listingId: $listingId) {
    completed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingOnboardingInProgressOnboardingStatusGQL extends Apollo.Query<ListingOnboardingInProgressOnboardingStatusQuery, ListingOnboardingInProgressOnboardingStatusQueryVariables> {
    document = ListingOnboardingInProgressOnboardingStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPropertyOwnerInvitationDocument = gql`
    mutation AcceptPropertyOwnerInvitation($input: AcceptPropertyOwnerInvitationCommand!) {
  acceptPropertyOwnerInvitation(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPropertyOwnerInvitationGQL extends Apollo.Mutation<AcceptPropertyOwnerInvitationMutation, AcceptPropertyOwnerInvitationMutationVariables> {
    document = AcceptPropertyOwnerInvitationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPropertyOwnerAddressDocument = gql`
    mutation AcceptPropertyOwnerAddress($input: UpdatePersonalAddressCommand!) {
  updatePersonalAddress(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPropertyOwnerAddressGQL extends Apollo.Mutation<AcceptPropertyOwnerAddressMutation, AcceptPropertyOwnerAddressMutationVariables> {
    document = AcceptPropertyOwnerAddressDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPropertyOwnerInvitationPhoneDocument = gql`
    mutation AcceptPropertyOwnerInvitationPhone($input: UpdatePersonalPhoneNumberCommand!) {
  updatePersonalPhoneNumber(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPropertyOwnerInvitationPhoneGQL extends Apollo.Mutation<AcceptPropertyOwnerInvitationPhoneMutation, AcceptPropertyOwnerInvitationPhoneMutationVariables> {
    document = AcceptPropertyOwnerInvitationPhoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPropertyOwnerInvitationTermsDocument = gql`
    mutation AcceptPropertyOwnerInvitationTerms($input: AcceptConsumerTermsAndConditionsCommand!) {
  acceptConsumerTermsAndConditions(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPropertyOwnerInvitationTermsGQL extends Apollo.Mutation<AcceptPropertyOwnerInvitationTermsMutation, AcceptPropertyOwnerInvitationTermsMutationVariables> {
    document = AcceptPropertyOwnerInvitationTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AcceptPropertyOwnerInvitationSummaryDocument = gql`
    query AcceptPropertyOwnerInvitationSummary($invitationId: ID!) {
  propertyOwnerInvitationSummary(invitationId: $invitationId) {
    invitationId
    propertyId
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPropertyOwnerInvitationSummaryGQL extends Apollo.Query<AcceptPropertyOwnerInvitationSummaryQuery, AcceptPropertyOwnerInvitationSummaryQueryVariables> {
    document = AcceptPropertyOwnerInvitationSummaryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DepositDaysListingDocument = gql`
    query DepositDaysListing($listingId: ID!) {
  listing(listingId: $listingId) {
    status
    asset {
      assetId
      assetType
    }
    listingId
    summary {
      city
      countryCode
      title
      subtitle
      photos {
        url
        useAsCover
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DepositDaysListingGQL extends Apollo.Query<DepositDaysListingQuery, DepositDaysListingQueryVariables> {
    document = DepositDaysListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayMembershipSubscriptionDocument = gql`
    mutation PayMembershipSubscription($input: PayMembershipSubscriptionRequest!) {
  payMembershipSubscription(input: $input) {
    sessionId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayMembershipSubscriptionGQL extends Apollo.Mutation<PayMembershipSubscriptionMutation, PayMembershipSubscriptionMutationVariables> {
    document = PayMembershipSubscriptionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayMembershipSubscriptionAccountDocument = gql`
    query PayMembershipSubscriptionAccount {
  account {
    accountId
    membership {
      membershipPlan {
        id
        title
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayMembershipSubscriptionAccountGQL extends Apollo.Query<PayMembershipSubscriptionAccountQuery, PayMembershipSubscriptionAccountQueryVariables> {
    document = PayMembershipSubscriptionAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayMembershipSubscriptionEligibleSubscriptionsDocument = gql`
    query PayMembershipSubscriptionEligibleSubscriptions {
  eligibleMembershipSubscriptions {
    membershipPlan {
      id
      title
      description
    }
    recurringPrice {
      amountMicros
      currency
    }
    billingPeriod
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayMembershipSubscriptionEligibleSubscriptionsGQL extends Apollo.Query<PayMembershipSubscriptionEligibleSubscriptionsQuery, PayMembershipSubscriptionEligibleSubscriptionsQueryVariables> {
    document = PayMembershipSubscriptionEligibleSubscriptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletePropertyOnboardingAccountDocument = gql`
    query CompletePropertyOnboardingAccount {
  account {
    accountId
    membership {
      membershipPlan {
        id
        title
        description
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletePropertyOnboardingAccountGQL extends Apollo.Query<CompletePropertyOnboardingAccountQuery, CompletePropertyOnboardingAccountQueryVariables> {
    document = CompletePropertyOnboardingAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletePropertyOnboardingStatusDocument = gql`
    query CompletePropertyOnboardingStatus($listingId: ID!) {
  listingOnboardingStatus(listingId: $listingId) {
    assetOnboardingCompleted
    completed
    meetsMinimumRequiredAvailability
    memberOnboardingCompleted
    membershipActive
    canInviteOwner
    ownerAssigned
    hostingTeamAssigned
    readyForPublishing
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletePropertyOnboardingStatusGQL extends Apollo.Query<CompletePropertyOnboardingStatusQuery, CompletePropertyOnboardingStatusQueryVariables> {
    document = CompletePropertyOnboardingStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletePropertyOnboardingPublishListingDocument = gql`
    mutation CompletePropertyOnboardingPublishListing($input: PublishListingCommand!) {
  publishListing(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletePropertyOnboardingPublishListingGQL extends Apollo.Mutation<CompletePropertyOnboardingPublishListingMutation, CompletePropertyOnboardingPublishListingMutationVariables> {
    document = CompletePropertyOnboardingPublishListingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompletePropertyOnboardingReferMemberDocument = gql`
    mutation CompletePropertyOnboardingReferMember($input: ReferMemberCommand!) {
  referMember(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompletePropertyOnboardingReferMemberGQL extends Apollo.Mutation<CompletePropertyOnboardingReferMemberMutation, CompletePropertyOnboardingReferMemberMutationVariables> {
    document = CompletePropertyOnboardingReferMemberDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ListingOnboardingFinishedOnboardingStatusDocument = gql`
    query ListingOnboardingFinishedOnboardingStatus($listingId: ID!) {
  listingOnboardingStatus(listingId: $listingId) {
    completed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListingOnboardingFinishedOnboardingStatusGQL extends Apollo.Query<ListingOnboardingFinishedOnboardingStatusQuery, ListingOnboardingFinishedOnboardingStatusQueryVariables> {
    document = ListingOnboardingFinishedOnboardingStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PublicWishlistListingsDocument = gql`
    query PublicWishlistListings($wishlistId: ID!) {
  publicWishlist(wishlistId: $wishlistId) {
    wishlistId
    name
    wishes {
      wishId
      searchListing {
        fees {
          tokenType {
            name
          }
          tokenFee {
            amount
          }
          exchangeFee {
            amountMicros
            currency
          }
          bookingFee {
            amountMicros
            currency
          }
        }
        priceRange {
          lowest {
            tokenType {
              name
            }
            tokenFee {
              amount
            }
            exchangeFee {
              amountMicros
              currency
            }
            bookingFee {
              amountMicros
              currency
            }
          }
          highest {
            tokenType {
              name
            }
            tokenFee {
              amount
            }
            exchangeFee {
              amountMicros
              currency
            }
            bookingFee {
              amountMicros
              currency
            }
          }
        }
        availableFrom {
          start
          end
        }
        listingId
        status
        availabilityType
        summary {
          city
          countryCode
          highlightedFeatures {
            features {
              iconName
              title
              description
            }
          }
          decidingFactors {
            primaryDecidingFactors {
              enabled
              icon
              title
              subtitle
            }
          }
          highlightedAmenities
          title
          subtitle
          photos {
            url
            useAsCover
          }
        }
        operator {
          operatorId
          name
          logoUrl
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PublicWishlistListingsGQL extends Apollo.Query<PublicWishlistListingsQuery, PublicWishlistListingsQueryVariables> {
    document = PublicWishlistListingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCriteriaBrandsDocument = gql`
    query SearchCriteriaBrands {
  brands {
    brandId
    name
    logoUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCriteriaBrandsGQL extends Apollo.Query<SearchCriteriaBrandsQuery, SearchCriteriaBrandsQueryVariables> {
    document = SearchCriteriaBrandsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCriteriaVisibilityConstraintDocument = gql`
    query SearchCriteriaVisibilityConstraint {
  visibilityConstraint {
    canSeeExchangeProperties
    canSeeRentalProperties
    canSeeHotels
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCriteriaVisibilityConstraintGQL extends Apollo.Query<SearchCriteriaVisibilityConstraintQuery, SearchCriteriaVisibilityConstraintQueryVariables> {
    document = SearchCriteriaVisibilityConstraintDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchCriteriaWhereToMenuOptionsDocument = gql`
    query SearchCriteriaWhereToMenuOptions($includeComingSoon: Boolean!, $availabilityTypes: [AvailabilityType]!) {
  searchOptions(
    includeComingSoon: $includeComingSoon
    availabilityTypes: $availabilityTypes
  ) {
    groupedRegions {
      groupName
      regions {
        parentRegionId
        regionId
        name
        boundingBox {
          swLat
          swLng
          neLat
          neLng
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchCriteriaWhereToMenuOptionsGQL extends Apollo.Query<SearchCriteriaWhereToMenuOptionsQuery, SearchCriteriaWhereToMenuOptionsQueryVariables> {
    document = SearchCriteriaWhereToMenuOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchListingGreetingPersonalProfileDocument = gql`
    query SearchListingGreetingPersonalProfile {
  account {
    accountId
    personalProfile {
      email
      firstName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchListingGreetingPersonalProfileGQL extends Apollo.Query<SearchListingGreetingPersonalProfileQuery, SearchListingGreetingPersonalProfileQueryVariables> {
    document = SearchListingGreetingPersonalProfileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchListingsDocument = gql`
    query SearchListings($input: Criteria!) {
  searchListings(input: $input) {
    count
    paging {
      offset
      size
    }
    results {
      fees {
        tokenType {
          name
        }
        tokenFee {
          amount
        }
        exchangeFee {
          amountMicros
          currency
        }
        bookingFee {
          amountMicros
          currency
        }
      }
      priceRange {
        lowest {
          tokenType {
            name
          }
          tokenFee {
            amount
          }
          exchangeFee {
            amountMicros
            currency
          }
          bookingFee {
            amountMicros
            currency
          }
        }
        highest {
          tokenType {
            name
          }
          tokenFee {
            amount
          }
          exchangeFee {
            amountMicros
            currency
          }
          bookingFee {
            amountMicros
            currency
          }
        }
      }
      commission {
        rate
      }
      availableFrom {
        start
        end
      }
      listingId
      status
      availabilityType
      summary {
        city
        countryCode
        highlightedFeatures {
          features {
            iconName
            title
            description
          }
        }
        decidingFactors {
          primaryDecidingFactors {
            enabled
            icon
            title
            subtitle
          }
        }
        highlightedAmenities
        title
        subtitle
        photos {
          url
          useAsCover
        }
      }
      obfuscatedLocation {
        location {
          lat
          lng
        }
      }
      operator {
        operatorId
        name
        logoUrl
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchListingsGQL extends Apollo.Query<SearchListingsQuery, SearchListingsQueryVariables> {
    document = SearchListingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddToWishlistWishlistsDocument = gql`
    query AddToWishlistWishlists {
  wishlists {
    wishlistId
    userId
    name
    status
    wishes {
      wishId
      searchListing {
        listingId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddToWishlistWishlistsGQL extends Apollo.Query<AddToWishlistWishlistsQuery, AddToWishlistWishlistsQueryVariables> {
    document = AddToWishlistWishlistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddWishToWishlistDocument = gql`
    mutation AddWishToWishlist($input: AddWishToWishlistCommand!) {
  addWishToWishlist(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddWishToWishlistGQL extends Apollo.Mutation<AddWishToWishlistMutation, AddWishToWishlistMutationVariables> {
    document = AddWishToWishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveWishFromWishlistDocument = gql`
    mutation RemoveWishFromWishlist($input: RemoveWishFromWishlistCommand!) {
  removeWishFromWishlist(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveWishFromWishlistGQL extends Apollo.Mutation<RemoveWishFromWishlistMutation, RemoveWishFromWishlistMutationVariables> {
    document = RemoveWishFromWishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWishlistDocument = gql`
    mutation CreateWishlist($input: CreateWishlistCommand!) {
  createWishlist(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWishlistGQL extends Apollo.Mutation<CreateWishlistMutation, CreateWishlistMutationVariables> {
    document = CreateWishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SelectWishlistsDocument = gql`
    query SelectWishlists {
  wishlists {
    wishlistId
    userId
    name
    status
    wishes {
      wishId
      searchListing {
        listingId
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectWishlistsGQL extends Apollo.Query<SelectWishlistsQuery, SelectWishlistsQueryVariables> {
    document = SelectWishlistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWishlistDocument = gql`
    mutation UpdateWishlist($input: UpdateWishlistCommand!) {
  updateWishlist(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWishlistGQL extends Apollo.Mutation<UpdateWishlistMutation, UpdateWishlistMutationVariables> {
    document = UpdateWishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WishlistDocument = gql`
    query Wishlist($wishlistId: ID!) {
  wishlist(wishlistId: $wishlistId) {
    wishlistId
    userId
    name
    status
    wishes {
      wishId
      searchListing {
        fees {
          tokenType {
            name
          }
          tokenFee {
            amount
          }
          exchangeFee {
            amountMicros
            currency
          }
          bookingFee {
            amountMicros
            currency
          }
        }
        priceRange {
          lowest {
            tokenType {
              name
            }
            tokenFee {
              amount
            }
            exchangeFee {
              amountMicros
              currency
            }
            bookingFee {
              amountMicros
              currency
            }
          }
          highest {
            tokenType {
              name
            }
            tokenFee {
              amount
            }
            exchangeFee {
              amountMicros
              currency
            }
            bookingFee {
              amountMicros
              currency
            }
          }
        }
        availableFrom {
          start
          end
        }
        listingId
        status
        availabilityType
        summary {
          city
          countryCode
          highlightedFeatures {
            features {
              iconName
              title
              description
            }
          }
          decidingFactors {
            primaryDecidingFactors {
              enabled
              icon
              title
              subtitle
            }
          }
          highlightedAmenities
          title
          subtitle
          photos {
            url
            useAsCover
          }
        }
        operator {
          operatorId
          name
          logoUrl
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WishlistGQL extends Apollo.Query<WishlistQuery, WishlistQueryVariables> {
    document = WishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WishlistsDocument = gql`
    query Wishlists {
  wishlists {
    wishlistId
    userId
    name
    status
    wishes {
      wishId
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WishlistsGQL extends Apollo.Query<WishlistsQuery, WishlistsQueryVariables> {
    document = WishlistsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ExpireWishlistDocument = gql`
    mutation ExpireWishlist($input: ExpireWishlistCommand!) {
  expireWishlist(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExpireWishlistGQL extends Apollo.Mutation<ExpireWishlistMutation, ExpireWishlistMutationVariables> {
    document = ExpireWishlistDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }