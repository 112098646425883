import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core'
import { environment } from '../../../../../environments/environment'

@Directive({
    host: {
        '[attr.src]': 'src',
    },
    selector: '[app-imagekit]',
})
export class ImagekitDirective implements OnChanges {
    @Input() source: string
    src: string
    @Input() transform: string | undefined

    ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            this.updateSrc(changes.source.currentValue)
        }
    }

    private updateSrc(source: string) {
        if (!source) {
            this.src = source
            return
        }

        let src = source.replace(environment.imagekit.source, environment.imagekit.target)

        if (this.transform) {
            src += `/${this.transform}`
        }

        this.src = src
    }
}
