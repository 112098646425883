<ng-container *ngIf="authenticated">
    <app-travelagent-desktop-nav *ngIf="isTravelAgent">
        <ng-content select="div[data-role=header]"></ng-content>
    </app-travelagent-desktop-nav>
    <app-desktop-nav *ngIf="!isTravelAgent"></app-desktop-nav>
    <app-mobile-nav *ngIf="!isTravelAgent" (toggleNav)="navOpen = true"></app-mobile-nav>
    <ng-content></ng-content>
</ng-container>

<ng-container *ngIf="!authenticated">
    <app-anonymous-desktop-nav></app-anonymous-desktop-nav>
    <app-anonymous-mobile-nav></app-anonymous-mobile-nav>
</ng-container>
