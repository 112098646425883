import { Component } from '@angular/core'
import { Account, RoleManager } from '../../../../../injectable'
import { UnsubscribeOnDestroy } from '../../../../../utility'

@Component({
    selector: 'app-user-pill',
    styleUrls: ['user-pill.scss'],
    templateUrl: 'user-pill.html'
})
export class UserPillComponent extends UnsubscribeOnDestroy {
    account: Account
    displayName: string

    // Constructor
    constructor(private _roleManager: RoleManager) {
        super()

        this._subscriptions.push(_roleManager.queryAccountAsync().subscribe(account => {
            if (account) {
                this.account = account
                this.displayName = this.account.personalProfile?.firstName || 'Anonymous'
            }
        }))
    }
}
