import { Inject, Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { ScriptLoader } from './script-loader'
import { UnsubscribeOnDestroy } from '../utility'
import { SupportChatGQL, SupportChatQuery } from '../../graphql'
import { TitleService } from './title-service'
import { Auth } from './auth'

/**
 * There are several (outdated) examples for getting HubSpot chat
 * and visitor identification to work.
 *
 * These are the API docs that we used as reference:
 * https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
 * https://developers.hubspot.com/docs/api/conversation/visitor-identification
 *
 * But also,
 * https://community.hubspot.com/t5/APIs-Integrations/Identify-a-contact-in-a-chat-widget/td-p/272410
 */
@Injectable({
    providedIn: 'root'
})
export class SupportChat extends UnsubscribeOnDestroy {
    supportChat: SupportChatQuery['supportChat']
    startedInitialization = false

    // Constructor
    constructor(private _scriptLoader: ScriptLoader,
                private _supportChatGQL: SupportChatGQL,
                private _titleService: TitleService,
                private _auth: Auth,
                @Inject('window') private _window: any) {
        super()

        this.initialize()

        this._auth.authenticatedSubject.subscribe(authenticated => {
            // {MD 18/11/2020} Need to be authenticated before querying the api
            if (authenticated) {
                this.refreshFromServer()
            }
        })
    }

    // Public
    chatEnabled(): boolean {
        return !!environment.hubspot.chatAccountId
    }

    openChat() {
        this._window['HubSpotConversations'].widget.open()
    }

    // Private
    private initialize() {
        if (!this.chatEnabled()) { return }
        if (this.startedInitialization) { return }

        this.startedInitialization = true

        // Make sure HubSpot script isn't loaded until
        // authenticated with both auth0 and HubSpot
        this._window['hsConversationsSettings'] = {
            loadImmediately: false
        }

        this._scriptLoader.load(`//js.hs-scripts.com/${environment.hubspot.chatAccountId}.js`).then(() => {
            this._subscriptions.push(this._titleService.titleUpdatedSubject.subscribe(updatedTitle => {
                const _hsq = this._window['_hsq'] = this._window['_hsq'] || []
                _hsq.push(['setPath', updatedTitle.urlAfterRedirects])
                _hsq.push(['trackPageView'])
            }))

            this.refreshFromServer()
        })
    }

    private refreshFromServer() {
        this._subscriptions.push(this._supportChatGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.supportChat = result.data.supportChat
                    if (this.supportChat) { this.pushUserToHubspot() }
                }
            }))
    }

    private pushUserToHubspot() {
        // LEX-1087 Hubspot chat - Multiple chats (not merged)
        // LEX-1089 Hubspot chat - Chat not using correct HS contact (when using different members)
        this._window['hsConversationsSettings'] = {
            identificationEmail: this.supportChat.identificationEmail,
            identificationToken: this.supportChat.identificationToken
        }
        this._window['HubSpotConversations']?.widget.load()

        // LEX-1088 Hubspot contacts - Customer not always showing 'breadcrumbs'
        const _hsq = this._window['_hsq'] = this._window['_hsq'] || []
        _hsq.push(['identify', {
            email: this.supportChat.identificationEmail
        }])
        _hsq.push(['trackPageView'])

        console.log('Identified chat visitor ', this.supportChat)
    }
}
