import { Injectable, Input } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private history: string[] = []

    constructor(private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                let url = event.urlAfterRedirects
                if (this.history.length > 0) {
                    const previousUrl = this.history[this.history.length - 1]
                    if (previousUrl != url) {
                        this.history.push(url)
                    }
                } else {
                    this.history.push(url)
                }
            }
        })
    }

    back(fallbackUrl: string = '/'): void {
        this.history.pop()
        if (this.history.length > 0) {
            const previousUrl = this.history[this.history.length - 1]
            this.router.navigateByUrl(previousUrl)
        } else {
            this.router.navigateByUrl(fallbackUrl)
        }
    }
}
