import { Component, Inject, OnInit } from '@angular/core'
import { MediaObserver } from '@angular/flex-layout'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { IAlbum, Lightbox, LIGHTBOX_EVENT, LightboxConfig, LightboxEvent } from 'ngx-lightbox'
import { Subscription } from 'rxjs'
import { environment } from '../../../../../environments/environment'
import { AssetPhotoType } from '../../../../../graphql'
import { Amplitude } from '../../../../injectable'

@Component({
    selector: 'app-gallery',
    styleUrls: ['gallery.scss'],
    templateUrl: 'gallery.html'
})
export class GalleryComponent implements OnInit {
    private _subscription: Subscription;

    album: IAlbum[] = [] // Constructor
    loading = true

    constructor(@Inject(MAT_DIALOG_DATA) public photos: Photos,
                private _amplitude: Amplitude,
                private _lightbox: Lightbox,
                private _lighboxConfig: LightboxConfig,
                private _lightboxEvent: LightboxEvent,
                private _dialogRef: MatDialogRef<GalleryComponent>,
                private media: MediaObserver) {
        _lighboxConfig.containerElementResolver = (doc: Document) => doc.getElementById('gallery')


    }

    ngOnInit(): void {
        this.album = this.photos?.photos?.map(img => ({
            src: this.transform(img.url, 'tr:q-70'),
            thumb: this.transform(img.url, 'tr:q-30'),
            caption: img.description
        }) as IAlbum)

        this._amplitude.logEventAndIncrement('travel.view-gallery.count', 'travel.view-gallery', {
            subjectId: this.photos?.subjectId,
            photoCount: this.photos?.photos?.length
        })
    }

    open(i: number) {
        // register your subscription and callback whe open lightbox is fired
        this._subscription = this._lightboxEvent.lightboxEvent$.subscribe(event => this._onReceivedEvent(event));

        console.log('opening', this.album, i, this.media.isActive('lt-md'))
        this._lightbox.open(this.album, i, { wrapAround: true, showImageNumberLabel: true, centerVertically: this.media.isActive('lt-md') })

        // Specifically capture which photo triggered the 'open of lightbox'.
        this._amplitude.logEvent('travel.open-lightbox', {
            subjectId: this.photos?.subjectId,
            photoIndex: i,
            photoCount: this.photos?.photos?.length
        })
        // Workaround - On open of lightbox, event does not contain photo index. Instead capturing index here.
        this._amplitude.logEventAndIncrement('travel.view-photo.count','travel.view-lightbox-photo', {
            subjectId: this.photos?.subjectId,
            photoIndex: i,
            photoCount: this.photos?.photos?.length
        })
    }

    close(): void {
        this._lightbox.close()
    }

    transform(source, transform) {
        let src = source.replace(environment.imagekit.source, environment.imagekit.target)
        if (this.transform) { src += `/${transform}` }
        return src
    }

    onImageLoad(evt) {
        if (evt && evt.target) {
            this.loading = false
        }
    }

    private _onReceivedEvent(event: any): void {
        if (event.id === LIGHTBOX_EVENT.CLOSE) {
            // unsubscribe the event when lightbox is closed
            this._subscription.unsubscribe();
        } else if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
            this._amplitude.logEventAndIncrement('travel.view-photo.count', 'travel.view-lightbox-photo', {
                subjectId: this.photos?.subjectId,
                photoIndex: event.data,  // -> image index that lightbox is switched to
                photoCount: this.photos?.photos?.length
            })
        }
    }
}

export interface Photos {
    /**
     * ID of the subject to which the photos belong. Mostly
     * used for analytics
     */
    subjectId: string
    /**
     * The list of photos
     */
    photos: AssetPhotoType[]
}
