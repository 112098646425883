import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { environment } from '../../../environments/environment'

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    // Constructor
    constructor() {
        if (!environment.sentry.dsn) { return }

        // Based on https://docs.sentry.io/platforms/javascript/angular/
        Sentry.init({
            dsn: environment.sentry.dsn,
            release: environment.sentry.release,
            attachStacktrace: true,
            // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
            // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
            // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
            integrations: [
                // @ts-ignore
                new Sentry.Integrations.TryCatch({ XMLHttpRequest: false }),
                new TracingIntegrations.BrowserTracing(),
                Sentry.replayIntegration({
                    // Additional SDK configuration goes in here, for example:
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
            tracesSampleRate: 1.0,
            replaysSessionSampleRate: 1.0
        })
    }

    // Public
    handleError(error) {
        const extractedError = this.extractError(error) || 'Handled unknown error'

        if (!environment.sentry.dsn) {
            console.error(extractedError)
            return
        }

        // Capture handled exception and send it to Sentry.
        const eventId = Sentry.captureException(extractedError)

        // Optionally show user dialog to provide details on what happened.
        // Sentry.showReportDialog({ eventId })
    }

    // Private
    extractError(error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null
    }
}
