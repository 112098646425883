import { Component } from '@angular/core'
import { environment } from '../../../../../../environments/environment'
import { Amplitude, ScopeManager } from '../../../../../injectable'
import { UnsubscribeOnDestroy } from '../../../../../utility'

@Component({
    selector: 'app-travelagent-desktop-nav',
    styleUrls: ['travelagent-desktop-nav.scss'],
    templateUrl: 'travelagent-desktop-nav.html'
})
export class TravelAgentDesktopNavComponent extends UnsubscribeOnDestroy {

    constructor
   (
        public _amplitude: Amplitude,
        private _scopeManager: ScopeManager
                /*private _router: Router*/) {
        super()
    }

    hasScope(scope: string): boolean {
        return this._scopeManager.hasScope(scope)
    }

    navigateToPrivacyPolicy() {
        window.open(environment.tenant.legal.privacy, '_blank')
    }

    navigateToTerms() {
        window.open(environment.tenant.legal.terms, '_blank')
    }

    navigateToFaq() {
        window.open(environment.tenant.faq, '_blank')
    }
}
