<nav class="background-color-white full-width padding-2" fxHide.gt-sm fxLayoutAlign="center space-between">

    <div fxFlex="35" fxLayoutAlign="start center">
        <button flok-button back-button fxLayoutAlign="start center" [widthAuto]="true" [fallbackUrl]="'/search-listings/public'">
            <mat-icon>chevron_left</mat-icon>

            <span>Back</span>

            <div class="size-1"></div>
        </button>
    </div>

<!--
    <a fxFlex="40" [routerLink]="['/search-listings/public']"  fxLayoutAlign="center center">
        <img class="height-5" app-logo/>
    </a>

    <div fxFlex="25" fxFlex fxLayoutAlign="end center">
        <button (click)="toggleNav.next()" mat-icon-button>
            <mat-icon>menu</mat-icon>
        </button>
    </div>
-->
    <div fxFlex fxLayoutAlign="end center">
        <div [routerLink]="['/signup']"
             routerLinkActive="active"
             class="menu-main">Signup</div>

        <div class="size-2" fxLayoutAlign="center center">|</div>

        <div (click)="navigateToLogin()"
             routerLinkActive="active"
             class="menu-main">Login</div>
    </div>
</nav>
