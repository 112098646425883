import { CommonModule, TitleCasePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { RouterModule } from '@angular/router'
import { NgxCurrencyModule } from 'ngx-currency'
import { DropzoneModule } from 'ngx-dropzone-wrapper'
import { LightboxModule } from 'ngx-lightbox'
import { moleculeComponents } from './molecule'
import { organismComponents } from './organism'

@NgModule({
    declarations: [
        ...moleculeComponents,
        ...organismComponents
    ],
    exports: [
        ...moleculeComponents,
        ...organismComponents
    ],
    imports: [
        CommonModule,
        DropzoneModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        NgxCurrencyModule,
        RouterModule,
        LightboxModule,
    ],
    providers: [
        TitleCasePipe
    ]
})
export class SharedFlokModule {}
