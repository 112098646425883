import { Component, Input } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import { StaffMemberInvitationSummary } from '../../../../graphql'

@Component({
    selector: 'app-invitation-accepted',
    templateUrl: 'invitation-accepted.html',
    styleUrls: ['invitation-accepted.scss']
})
export class InvitationAcceptedComponent {
    @Input() summary: StaffMemberInvitationSummary
    tenantName = environment.tenant.name

    constructor(private _activatedRoute: ActivatedRoute,
                private _router: Router) {
    }

    // Public
    goToMemberArea() {
        this._router.navigate(['/'], { relativeTo: this._activatedRoute })
    }
}
