import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { PayRescheduleBookingRequest, RescheduleBookingOrderRequest, } from '../../../../graphql'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-pay-reschedule-booking',
    templateUrl: 'pay-reschedule-booking.html'
})
export class PayRescheduleBookingComponent extends UnsubscribeOnDestroy {
    loading = false
    order: RescheduleBookingOrderRequest
    tripId: string | undefined

    // Constructor
    constructor(private _activatedRoute: ActivatedRoute,
                // private _payRescheduleBookingBookingGQL: PayRescheduleBookingBookingGQL,
                // private _payRescheduleBookingGQL: PayRescheduleBookingGQL,
                // private _payRescheduleBookingOrderGQL: PayRescheduleBookingOrderGQL,
                private _router: Router) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            this.requestOrder(paramMap.get('orderId') || '')
        }))
    }

    // Public
    reschedule() {
        this.loading = true

        const url = window.location.href.split('?')[0]
        const command: PayRescheduleBookingRequest = {
            bookingRequest: {
                bookingId: this.order.bookingId,
                checkin: this.order.checkin,
                checkout: this.order.checkout
            },
            cancelUrl: url,
            successUrl: `${url.slice(0, url.indexOf('/confirm'))}/my-trips/${this.tripId}`
        }

/*
        this._payRescheduleBookingGQL.mutate({ input: command }).subscribe(async response => {
            if (response.data) {
                if (response.data.payRescheduleBooking) {
                    const stripe = await loadStripe(environment.stripe.key, {
                        stripeAccount: environment.stripe.connectedAccount
                    })

                    const { error } = await stripe.redirectToCheckout({
                        sessionId: response.data.payRescheduleBooking.sessionId
                    })

                    console.log(error)
                } else {
                    this._router.navigateByUrl(`/my-trips/${this.tripId}`)
                }
            }
        })
*/
    }

    // Private
    private requestBooking(bookingId: string) {
/*
        this._subscriptions.push(this._payRescheduleBookingBookingGQL
            .watch({ bookingId: bookingId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) { this.tripId = result.data['booking']['tripId'] }
            }))
*/
    }

    private requestOrder(orderId: string) {
/*
        this._subscriptions.push(this._payRescheduleBookingOrderGQL
            .watch({ orderId: orderId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    this.order = result.data['rescheduleBookingOrder'] as RescheduleBookingOrderRequest
                    this.requestBooking(this.order.bookingId)
                }
            }))
*/
    }
}
