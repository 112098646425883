import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { CountryISO } from 'ngx-intl-tel-input'
import {
    AcceptConsumerTermsAndConditionsCommand,
    ConsumerSignupAcceptTermsGQL,
    ConsumerSignupAccountExistsGQL,
    ConsumerSignupGQL,
    ConsumerSignupUpdatePersonalInformationGQL,
    SignupConsumerRequest,
    UpdatePersonalInformationCommand
} from '../../../graphql'
import { Auth, RoleManager } from '../../injectable'
import { LegalDocumentType } from '../../module/shared-member/organism/legal-document-links/legal-document-links'
import { countryCodeToCountryIso } from '../../phone-utility'
import { generateId, UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-consumer-signup',
    templateUrl: 'consumer-signup.html'
})
export class ConsumerSignupComponent extends UnsubscribeOnDestroy {
    CountryISO = CountryISO
    country: CountryISO
    LegalDocumentType = LegalDocumentType
    formGroup: FormGroup

    targetListingId: string | undefined
    checkin: string | undefined
    checkout: string | undefined
    email: string
    loading = false
    creatingConsumer = false

    // Constructor
    constructor(private _activatedRoute: ActivatedRoute,
                private _consumerSignupGQL: ConsumerSignupGQL,
                private _consumerSignupUpdatePersonalInformationGQL: ConsumerSignupUpdatePersonalInformationGQL,
                private _consumerSignupAcceptTermsGQL: ConsumerSignupAcceptTermsGQL,
                private _consumerSignupAccountExistsGQL: ConsumerSignupAccountExistsGQL,
                private _auth: Auth,
                private _roleManager: RoleManager,
                private _router: Router,
                private _snackBar: MatSnackBar) {
        super()

        this._activatedRoute.paramMap.subscribe(paramMap => {
            this.targetListingId = paramMap.get('targetListingId')

            if (paramMap.get('checkin') && paramMap.get('checkout')) {
                this.checkin = paramMap.get('checkin')
                this.checkout = paramMap.get('checkout')
            }

             this.initializeAsync()
        })
    }

    // Public
    signup() {
        if (this.creatingConsumer) {
            console.log('Already sent a SignupConsumerRequest. Ignoring duplicate request...')
            return
        }
        this.creatingConsumer = true

        const signupConsumerRequest: SignupConsumerRequest = {
            userId: generateId('usr'),
            email: this.email,
        }

        this._consumerSignupGQL.mutate({ input: signupConsumerRequest }).subscribe(response => {
            this.creatingConsumer = false
            if (response.errors && response.errors.length) {
                console.log('Error signing up consumer', JSON.stringify(response.errors))
                return
            }

            this._roleManager.queryPermissions()
        }, (error) => {
            console.log('Error signing up consumer', error)
            this.creatingConsumer = false
        })
    }

    next() {
        // Show a loader for some time - success path simply moves to next screen. Failure will stay on this screen.
        this.loading = true

        this.updatePersonalInformation()
    }

    // Private
    private acceptTerms() {
        const command: AcceptConsumerTermsAndConditionsCommand = {
            accepted: true
        }

        this._subscriptions.push(this._consumerSignupAcceptTermsGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.log('Error accepting terms for host invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error accepting terms for host invitation')
            } else {
                // Allow for some processing on the backend
                setTimeout(() => {
                    if (this.targetListingId) {
                        if (this.checkin && this.checkout) {
                            this._router.navigate(['/listing', this.targetListingId, this.checkin, this.checkout])
                        } else {
                            this._router.navigate(['/listing', this.targetListingId])
                        }
                    } else {
                        this._router.navigate(['/search-listings'])
                    }
                }, 1000)
            }
        }, (error) => {
            console.log('Error accepting terms for host invitation', error)
            this.loading = false
        }))
    }

    private checkExistingSignup() {
        this._subscriptions.push(this._consumerSignupAccountExistsGQL
            .watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    if (!result.data?.account) {
                        this.signup()
                        return
                    }

                    this._router.navigate(['/search-listings'])
                }
            }))
    }

    private createFormGroup() {
        this.formGroup = new FormGroup({
            firstName: new FormControl('', Validators.required),
            lastName: new FormControl('', Validators.required),
            phoneNumber: new FormControl('', Validators.required),
            termsAndConditionsAccepted: new FormControl(false, Validators.requiredTrue)
        })
    }

    private async initializeAsync() {
        await this._auth.checkAuthenticationAsync(true)

        const user = await this._auth.userAsync()
        if (user) { // {MD 30/07/2020} Avoids throwing if user is not logged in
            this.country = countryCodeToCountryIso(user.countryCode) || CountryISO.UnitedStates
            this.email = user.email
            this.checkExistingSignup()
            this.createFormGroup()
        }
    }

    private updatePersonalInformation() {
        if (!this.formGroup.valid) { return }

        const phoneNumber = this.formGroup.value.phoneNumber
        console.log('Registering phone number', phoneNumber)

        const command: UpdatePersonalInformationCommand = {
            firstName: this.formGroup.value.firstName,
            lastName: this.formGroup.value.lastName,
            phoneNumber: {
                countryCode: phoneNumber.dialCode,
                nationalNumber: phoneNumber.nationalNumber
            }
        }

        this._subscriptions.push(this._consumerSignupUpdatePersonalInformationGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.log('Error updating phone number host invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error updating phone number')
            } else {
                this.acceptTerms()
            }
        }, (error) => {
            console.log('Error updating phone number for host invitation', error)
            this._snackBar.open('Error updating phone number')
            this.loading = false
        }))
    }
}
