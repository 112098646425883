import { AfterContentInit, Component } from '@angular/core'
import { Role } from '../../../../../graphql'
import { Auth, RoleManager } from '../../../../injectable'
import { UnsubscribeOnDestroy } from '../../../../utility'

@Component({
    selector: 'app-nav',
    styleUrls: ['nav.scss'],
    templateUrl: 'nav.html'
})
export class NavComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    navOpen = false
    authenticated: boolean
    isTravelAgent: boolean

    constructor(private _auth: Auth,
                private _roleManager: RoleManager) {
        super()
    }

    ngAfterContentInit(): void {
        this._subscriptions.push(this._auth.authenticatedSubject.subscribe(authenticated => {
            this._subscriptions.push(this._roleManager.rolesSubject.subscribe(roles => {
                this.isTravelAgent = this._roleManager.hasRole(Role.TravelAgent)
                this.authenticated = authenticated
            }))
        }))
    }
}
