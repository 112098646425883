<div fxLayoutAlign="start stretch">
    <div class="side-panel-wrapper" fxHide fxShow.gt-sm>
        <app-promotional-side-panel></app-promotional-side-panel>
    </div>

    <div class="background-color-yellow full-screen-height overflow-y-scroll"
         fxFlex
         fxLayout="column"
         fxLayoutAlign="center center">
        <div class="container-xxs padding-1">
            <h3 class="margin-top-4 text-align-center">Access denied</h3>

            <p class="margin-top-3 text-medium">
                Denying access as you are currently logged in as Backoffice Admin.
            </p>

            <p class="margin-top-3 text-medium">
                Backoffice admins are not allowed access to the members area.
            </p>

            <div class="margin-top-3">

                <app-responsive-button-container>
                    <button color="accent"
                            flok-button
                            [fullWidth]="true"
                            [routerLink]="['/clear-storage']"
                            size="medium">
                        <app-logout label="Logout as admin and proceed"></app-logout>
                    </button>
                </app-responsive-button-container>
            </div>
        </div>
    </div>
</div>
