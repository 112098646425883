import { CountryISO } from 'ngx-intl-tel-input'
import { PhoneNumber } from '../graphql'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()

export function phoneNumberToString(phoneNumber: PhoneNumber): string {
    let readableNumber = `${phoneNumber.countryCode}${phoneNumber.nationalNumber}`

    return readableNumber.startsWith('+') ? readableNumber : `+${readableNumber}`
}

export function countryCodeToCountryIso(countryCode: string): CountryISO {
    if (!countryCode) { return }

    countryCode = countryCode.startsWith('+') ? countryCode.substring(1) : countryCode

    const regionCodeForCountryCode = phoneUtil.getRegionCodeForCountryCode(countryCode)
    return Object.keys(CountryISO)
        .map(key => CountryISO[key])
        .filter(isNaN)
        .find(value => value == regionCodeForCountryCode.toLocaleLowerCase())
}
