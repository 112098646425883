import { Component } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { environment } from '../../../environments/environment'
import { AssetPhotoType, FeaturedListingListingsGQL, PublicListing, PublicListingEntry } from '../../../graphql'
import { Amplitude } from '../../injectable'
import { GalleryComponent } from '../../module/shared-flok/organism/gallery/gallery'

import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-featured-listing',
    templateUrl: 'featured-listing.html',
    styleUrls: ['featured-listing.scss']
})
export class FeaturedListingComponent extends UnsubscribeOnDestroy {

    featuredListingCode: string
    publicListing: PublicListing
    corporateSiteUrl: string

    constructor(private _activatedRoute: ActivatedRoute,
                private _amplitude: Amplitude,
                private _fetchFeaturedListingGQL: FeaturedListingListingsGQL,
                private _dialog: MatDialog,
                private _snackBar: MatSnackBar,
                private _router: Router,
                private _title: Title) {
        super()

        this.corporateSiteUrl = environment.tenant.corporateWebsite

        this._activatedRoute.paramMap.subscribe(paramMap => {
            this.featuredListingCode = paramMap.get('featuredListingCode')
            this.loadFeaturedListings()
        })
    }

    private loadFeaturedListings() {
        console.log('featuredListingCode', this.featuredListingCode)
        this._fetchFeaturedListingGQL.fetch({ featuredListingCode: this.featuredListingCode }, { fetchPolicy: 'no-cache' })
            .subscribe(result => {
                this.publicListing = result.data?.featuredListings as PublicListing
                this._title.setTitle(this.publicListing?.name)

                this._amplitude.logEvent('Featured Listings Page Viewed', {
                    code: this.featuredListingCode
                })
            })
    }

    public coverPhotoUrl(photos: AssetPhotoType[]): string {
        return photos.find(photo => photo.useAsCover)?.url || photos[0].url
    }

    viewPhotos(listing: PublicListingEntry) {
        this._dialog.open(GalleryComponent, {
            data: {
                subjectId: listing?.listingId,
                photos: listing.photos
            },
            panelClass: 'bookCardDialog',
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
        })
    }

    onClick() {
        window.open(this.corporateSiteUrl, '_blank')
    }
}
