import { SearchListingStatus } from '../graphql'

export const dialogWidth = {
    md: '800px',
    xs: '600px',
    xxs: '360px'
}
export const millisecondsInADay = 1000 * 60 * 60 * 24
export const searchListingsCriteriaKey = 'searchListings.criteria.v3'
export const searchMapCenterKey = 'searchMap.center.v1'
export const activeWishlistKey = 'wishlist.active.v1'
export const wishlistStatusKey = 'wishlist.status.v1'
export const presentmentCurrencyKey = 'presentmentCurrency'

export const emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

export enum ThemeColor { Accent = 'accent', Primary = 'primary', Warn = 'Warn' }

export const placeHolderSearchListing = {
    listingId: '',
    summary: {
        photos: [],
        title: '',
        highlightedAmenities: [],
        houseRules: {
            rules: []
        },
        decidingFactors: {
            primaryDecidingFactors: []
        },
        highlightedFeatures: {
            listingId: '',
            features: []
        }
    },
    asset: {
        assetId: '',
    },
    obfuscatedLocation: {
        range: 6,
        location: null
    },
    status: SearchListingStatus.Available
}
