<div class="container-sm padding-1" fxLayout="column" fxLayoutAlign="start center">
    <div class="position-relative">
        <img src="assets/element/application/received-flok.svg"/>

        <img class="received" src="assets/element/application/received.svg"/>
    </div>

    <h3 class="margin-top-2 text-align-center">Invitation expired</h3>

    <p class="container-xs margin-top-3 text-align-center text-large">
        Your invitation with reference {{summary.invitationId | uppercase }} has expired
    </p>

    <p class="margin-top-3 text-align-center text-medium" *ngIf="chatEnabled">
        <span>In case you need help, </span>

        <a (click)="openChat()"
           class="border-dashed-bottom color-primary text-decoration-none cursor-pointer">
            please talk to us
        </a>
    </p>
</div>

