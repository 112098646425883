<div class="background-color-yellow">
    <app-nav>
        <div class="full-screen-min-height" fxLayout="column" fxLayoutAlign="start center">
            <div class="container-md padding-1">
                <h4 class="margin-top-4" i18n="@@balance-log$heading">Balance History Log</h4>

                <p class="margin-top-2 text-medium"
                   i18n="@@balance-log$no-transactions-text"
                   *ngIf="!logLines?.length">
                    There have not been any transactions on your account yet
                </p>

                <div *ngIf="logLines?.length"
                     class="padding-horizontal-1 margin-top-4"
                     ngClass.gt-xs="padding-horizontal-3"
                     fxLayoutAlign="start center">
                    <p class="color-grey-secondary-important text-small text-transform-uppercase"
                       fxFlex="20"
                       i18n="@@balance-log$date-column">
                        Date
                    </p>

                    <p class="color-grey-secondary-important text-small text-transform-uppercase"
                       fxFlex="65"
                       fxFlex.gt-xs="50"
                       i18n="@@balance-log$reference-column">
                        Reference
                    </p>

                    <p class="color-grey-secondary-important text-small text-transform-uppercase"
                       fxFlex="15"
                       fxLayoutAlign.lt-sm="end center"
                       i18n="@@balance-log$transaction-column">
                        Transaction
                    </p>

                    <p class="color-grey-secondary-important text-small text-transform-uppercase"
                       fxHide
                       fxShow.gt-xs
                       fxFlex="15"
                       i18n="@@balance-log$balance-column">
                        Balance
                    </p>
                </div>

                <div class="padding-top-1" *ngIf="logLines && tokenType">
                    <app-balance-log-line [tokenType]="tokenType"
                                          [logLine]="logLine"
                                          *ngFor="let logLine of logLines"></app-balance-log-line>
                </div>
            </div>
        </div>
    </app-nav>
</div>
