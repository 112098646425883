import { Component, Input } from '@angular/core'
import { AbstractControl } from '@angular/forms'

@Component({
    selector: 'app-flok-label',
    styleUrls: ['flok-label.scss'],
    templateUrl: 'flok-label.html'
})
export class FlokLabelComponent {
    @Input() control: AbstractControl | undefined
}
