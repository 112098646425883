import { AfterContentInit, Component, EventEmitter, Input, NgZone, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { CountryISO } from 'ngx-intl-tel-input'
import {
    AcceptConsumerTermsAndConditionsCommand,
    AcceptStaffMemberInvitationCommand,
    AcceptStaffMemberInvitationGQL,
    AcceptStaffMemberInvitationPhoneGQL,
    AcceptStaffMemberInvitationTermsGQL,
    StaffMemberInvitationSummary,
    UpdatePersonalPhoneNumberCommand
} from '../../../../graphql'
import { RoleManager, ScriptLoader, User } from '../../../injectable'
import { LegalDocumentType } from '../../../module/shared-member/organism/legal-document-links/legal-document-links'
import { countryCodeToCountryIso } from '../../../phone-utility'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-invitation-invited',
    templateUrl: 'invitation-invited.html',
    styleUrls: ['invitation-invited.scss']
})
export class InvitationInvitedComponent extends UnsubscribeOnDestroy implements AfterContentInit {
    @Input() summary: StaffMemberInvitationSummary
    @Input() user: User
    @Output() invitationAccepted = new EventEmitter<boolean>()

    CountryISO = CountryISO
    country: CountryISO
    LegalDocumentType = LegalDocumentType
    formGroup: FormGroup

    _accountUpdated = false

    constructor(private _acceptInvitationGQL: AcceptStaffMemberInvitationGQL,
                private _acceptTermsGQL: AcceptStaffMemberInvitationTermsGQL,
                private _activatedRoute: ActivatedRoute,
                private _router: Router,
                private _roleManager: RoleManager,
                private _snackBar: MatSnackBar,
                private _scriptLoader: ScriptLoader,
                private _updatePhoneGQL: AcceptStaffMemberInvitationPhoneGQL,
                private _zone: NgZone) {
        super()
    }

    ngAfterContentInit(): void {
        this.country = countryCodeToCountryIso(this.user.countryCode) || CountryISO.UnitedStates
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', Validators.required),
            termsAndConditionsAccepted: new FormControl(false, Validators.requiredTrue)
        })
    }

    accept() {
        if (!this.summary) {
            throw new Error(`Tried to accept a property owner invitation without required ids`)
        }

        const command: AcceptStaffMemberInvitationCommand = {
            invitationId: this.summary.invitationId,
        }

        // Eventually consistent. Notify other components and wait for Account to be created.
        this.invitationAccepted.emit(true)

        this._subscriptions.push(this._roleManager.accountIdSubject.subscribe(accountId => {
            this._accountUpdated = !!accountId

            if (this._accountUpdated) {
                this.updatePhoneNumber()
                this.acceptTerms()

                setTimeout(() => {
                    this._router.navigate(['/'], { relativeTo: this._activatedRoute })
                }, 1000)
            }
        }))
        this.waitForAccountCreation()


        this._subscriptions.push(this._acceptInvitationGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                const message = `We are experiencing technical difficulties. Please try again later.`
                this._snackBar.open(message, 'OK', {
                    duration: undefined
                })
                throw new Error('Error accepting property owner invitation' + JSON.stringify(response.errors))
            }
        }))
    }

    private waitForAccountCreation(attempt = 0) {
        if (attempt >= 20) { return }
        if (this._accountUpdated) { return }

        this._roleManager.refresh()

        setTimeout(() => this.waitForAccountCreation(++attempt), 500)
    }

    // Private
    private updatePhoneNumber() {
        const phoneNumber = this.formGroup.value.phoneNumber
        console.log('Registering phone number', phoneNumber)

        const command: UpdatePersonalPhoneNumberCommand = {
            phoneNumber: {
                countryCode: phoneNumber.dialCode,
                nationalNumber: phoneNumber.nationalNumber
            }
        }

        this._subscriptions.push(this._updatePhoneGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.log('Error updating phone number for property owner invitation', JSON.stringify(response.errors))
            }
        }, (error) => {
            console.log('Error updating phone number for property owner invitation', error)
        }))
    }

    private acceptTerms() {
        const command: AcceptConsumerTermsAndConditionsCommand = {
            accepted: true
        }

        this._subscriptions.push(this._acceptTermsGQL.mutate({ input: command }).subscribe(response => {
            if (response?.errors?.length) {
                this._snackBar.open('There was an error accepting your terms and conditions, please try again later')
                return
            }
        }, (error) => {
            console.log('Error accepting terms for property owner invitation', error)
        }))
    }
}
