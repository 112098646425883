import { Component, Input } from '@angular/core'
import { StaffMemberInvitationSummary } from '../../../../graphql'
import { SupportChat } from '../../../injectable'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-invitation-expired',
    templateUrl: 'invitation-expired.html',
    styleUrls: ['invitation-expired.scss']
})
export class InvitationExpiredComponent extends UnsubscribeOnDestroy {
    @Input() summary: StaffMemberInvitationSummary
    chatEnabled = false

    constructor(private _supportChat: SupportChat) {
        super()

        this.chatEnabled = _supportChat.chatEnabled()
    }

    // Public
    openChat() {
        this._supportChat.openChat()
    }
}
