<nav class="background-color-white full-width" fxHide fxShow.gt-sm fxLayoutAlign="center center">
    <div fxLayoutAlign="start center" class="cursor-pointer">
        <button flok-button back-button fxLayoutAlign="start center" [widthAuto]="true" [fallbackUrl]="'/search-listings/public'">
            <mat-icon>chevron_left</mat-icon>

            <span>Back</span>

            <div class="size-1"></div>
        </button>
    </div>

    <div fxFlex fxLayoutAlign="center center" class="cursor-pointer">
        <div [routerLink]="['/search-listings/public']"
             routerLinkActive="active"
             class="menu-main">
            <img class="height-5 logo" app-logo/>
        </div>
    </div>

    <div fxLayoutAlign="end center" class="cursor-pointer">
        <div [routerLink]="['/signup']"
             routerLinkActive="active"
             class="menu-main">Signup</div>

        <div (click)="navigateToLogin()"
             routerLinkActive="active"
             class="menu-main">Login</div>
    </div>
</nav>
