<div class="background-color-yellow" fxLayoutAlign="center center" *ngIf="order">
    <div class="container-sm padding-1">
        <div fxLayout="column" fxLayoutAlign="start stretch">
            <div fxLayoutAlign="start center">
                <h4 class="margin-top-5" fxFlex>Reschedule booking</h4>

                <img fxHide fxShow.gt-sm src="/assets/illustration/flok-1.svg">
            </div>

            <app-confirm-booking [bookingId]="order.bookingId"></app-confirm-booking>

            <div class="margin-top-1 padding-horizontal-2 text-medium" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>New checkin - checkout:</span>

                    <span fxFlex></span>

                    <span>{{order.checkin | date:'mediumDate'}}</span>

                    <span class="size-1"></span>

                    <span>{{order.checkout | date:'mediumDate'}}</span>
                </div>
            </div>

            <div class="border-top-dark-green-opacity margin-top-2 padding-horizontal-2 padding-top-2">
                <app-confirm-price-breakdown [fees]="order.feesDue"></app-confirm-price-breakdown>
            </div>

            <div class="margin-top-3">
                <app-responsive-button-container>
                    <div fxLayoutAlign="center center" *ngIf="loading">
                        <mat-spinner color="accent" diameter="32"></mat-spinner>
                    </div>

                    <div class="size-2"></div>

                    <button (click)="reschedule()"
                            color="accent"
                            flok-button>
                        Confirm
                    </button>
                </app-responsive-button-container>
            </div>
        </div>
    </div>
</div>
