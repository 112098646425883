import { Component } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { CountryISO } from 'ngx-intl-tel-input'
import {
    AcceptConsumerTermsAndConditionsCommand,
    AcceptBrandBusinessOwnerInvitationGQL,
    AcceptBrandBusinessOwnerInvitationInvitationGQL,
    AcceptBrandBusinessOwnerInvitationPhoneGQL,
    AcceptBrandBusinessOwnerInvitationTermsGQL,
    BrandBusinessOwnerInvitationStatus,
    Role,
    UpdatePersonalPhoneNumberCommand
} from '../../../graphql'
import { Auth, RoleManager } from '../../injectable'
import { LegalDocumentType } from '../../module/shared-member/organism/legal-document-links/legal-document-links'
import { countryCodeToCountryIso } from '../../phone-utility'
import { UnsubscribeOnDestroy } from '../../utility'

@Component({
    selector: 'app-accept-brand-business-owner-invitation',
    templateUrl: 'accept-brand-business-owner-invitation.html'
})
export class AcceptBrandBusinessOwnerInvitationComponent extends UnsubscribeOnDestroy {
    loading = true
    _accountCreated = false

    Role = Role
    CountryISO = CountryISO
    country: CountryISO
    LegalDocumentType = LegalDocumentType
    formGroup: FormGroup
    invitationId: string
    listingId: string

    userExistsAlready: boolean
    status: BrandBusinessOwnerInvitationStatus
    BusinessOwnerInvitationStatus = BrandBusinessOwnerInvitationStatus
    acceptInProgress = false

    constructor(private _activatedRoute: ActivatedRoute,
                private _acceptBusinessOwnerInvitationGQL: AcceptBrandBusinessOwnerInvitationGQL,
                private _acceptBusinessOwnerInvitationInvitationGQL: AcceptBrandBusinessOwnerInvitationInvitationGQL,
                private _acceptBusinessOwnerInvitationPhoneGQL: AcceptBrandBusinessOwnerInvitationPhoneGQL,
                private _acceptBusinessOwnerInvitationTermsGQL: AcceptBrandBusinessOwnerInvitationTermsGQL,
                private _auth: Auth,
                private _roleManager: RoleManager,
                private _router: Router,
                private _snackBar: MatSnackBar) {
        super()

        this._subscriptions.push(this._activatedRoute.paramMap.subscribe(paramMap => {
            this.invitationId = paramMap.get('invitationId')
            this.listingId = paramMap.get('listingId')

            this.initializeAsync().then(() => {
                // a signed-up user
                this._roleManager.rolesSubject.subscribe(roles => {
                    this.loading = false
                    this.userExistsAlready = roles && roles.length > 0
                });
                this._roleManager.refresh()
        })
        }))
    }

    acceptInvitation() {
        if (!this.invitationId) {
            throw new Error(`Tried to accept a host invitation without invitation id`)
        }

        this.loading = true
        this.acceptInProgress = true

        this._subscriptions.push(this._acceptBusinessOwnerInvitationGQL.mutate({ invitationId: this.invitationId }).subscribe(response => {
            if (response.errors && response.errors.length) {
                console.error('Error accepting staff member invitation', JSON.stringify(response.errors))
                return
            }

            this._roleManager.accountIdSubject.subscribe(accountId => {
                this._accountCreated = !!accountId

                if (this._accountCreated) {
                    this.updatePhoneNumber()
                    this.acceptTerms()

                    setTimeout(() => {
                        this.navigateToPlatform()
                    }, 1000)
                }
            })
            this.waitForAccountCreation()
        }, (error) => {
            console.error('Error accepting staff member invitation', error)
            this.loading = true
            this.acceptInProgress = false
        }))
    }

    next() {
        this.acceptInvitation()
    }

    navigateToPlatform() {
        this._router.navigate(['../../'], { relativeTo: this._activatedRoute })
    }

    private acceptTerms() {
        const command: AcceptConsumerTermsAndConditionsCommand = {
            accepted: true
        }

        this._subscriptions.push(this._acceptBusinessOwnerInvitationTermsGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.error('Error accepting terms for invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error accepting terms for invitation')
            }
        }, (error) => {
            console.error('Error accepting terms for host invitation', error)
        }))
    }

    private checkInvitation() {
        this._subscriptions.push(this._acceptBusinessOwnerInvitationInvitationGQL
            .watch({ invitationId: this.invitationId }, { fetchPolicy: 'no-cache' })
            .valueChanges
            .subscribe(result => {
                if (!result.loading) {
                    if (!result.data?.brandBusinessOwnerInvitation) {
                        this._router.navigateByUrl('/not-found', { skipLocationChange: true })
                        return
                    }

                    this.status = result.data.brandBusinessOwnerInvitation.status
                }
            }))
    }

    private createFormGroup() {
        this.formGroup = new FormGroup({
            phoneNumber: new FormControl('', Validators.required),
            termsAndConditionsAccepted: new FormControl(false, Validators.requiredTrue)
        })
    }

    private async initializeAsync() {
        await this._auth.checkAuthenticationAsync(true)

        const user = await this._auth.userAsync()
        if (user) { // {MD 30/07/2020} Avoids throwing if user is not logged in
            this.country = countryCodeToCountryIso(user.countryCode) || CountryISO.UnitedStates
            this.checkInvitation()
            this.createFormGroup()
        }
    }

    private updatePhoneNumber() {
        if (!this.formGroup.valid) { return }

        const phoneNumber = this.formGroup.value.phoneNumber
        console.log('Registering phone number', phoneNumber)

        const command: UpdatePersonalPhoneNumberCommand = {
            phoneNumber: {
                countryCode: phoneNumber.dialCode,
                nationalNumber: phoneNumber.nationalNumber
            }
        }

        this._subscriptions.push(this._acceptBusinessOwnerInvitationPhoneGQL.mutate({ input: command }).subscribe(response => {
            if (response.errors) {
                console.log('Error updating phone number host invitation', JSON.stringify(response.errors))
                this._snackBar.open('Error updating phone number')
            }
        }, (error) => {
            console.log('Error updating phone number for host invitation', error)
            this._snackBar.open('Error updating phone number')
        }))
    }


    private waitForAccountCreation(attempt = 0) {
        if (attempt >= 20) { return }
        if (this._accountCreated) { return }

        this._roleManager.refresh()

        setTimeout(() => this.waitForAccountCreation(++attempt), 500)
    }
}
