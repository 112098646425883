import { Component } from '@angular/core'
import { BalanceLogGQL, BalanceLogQuery, TokenType } from '../../../../graphql'
import { Amplitude } from '../../../injectable'
import { UnsubscribeOnDestroy } from '../../../utility'

@Component({
    selector: 'app-balance-log',
    templateUrl: 'balance-log.html'
})
export class BalanceLogComponent extends UnsubscribeOnDestroy {
    tokenType: BalanceLogQuery['myBalanceLog']['tokenType']
    logLines: BalanceLogQuery['myBalanceLog']['logLines']

    // Constructor
    constructor(private _amplitude: Amplitude,
                private _balanceLogGQL: BalanceLogGQL) {
        super()

        this._amplitude.logEvent('Account Page Viewed', { pageName: 'Balance Log' })

        this._subscriptions.push(this._balanceLogGQL.watch({}, { fetchPolicy: 'no-cache' })
            .valueChanges.subscribe(result => {
            if (!result.loading) {
                this.tokenType = result.data.myBalanceLog.tokenType
                this.logLines = result.data.myBalanceLog.logLines
                if (this.logLines.length > 1) { this.logLines = [...this.logLines].reverse() }
            }
        }))
    }
}
